import { useEffect, useState } from "react";

import { useEventStore } from "@/store/useEventStore";
import PhaseDropdown from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/PhaseDropdown";
import PodSwitcher from "../../PodSwitcher";
import { Button } from "@repo/ui/button";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import SkipToNextPhaseModal from "@/components/Modal/Event/Organize/SkipToNextPhaseModal";
import FinalizeActivityModal from "@/components/Modal/Event/Organize/FinalizeActivityModal";
import OrganizeActions from "./OrganizeActions";

const FinalizeActivityModalContainer = ({
  isFinalizeActivityModalOpen,
  setIsFinalizeActivityModalOpen,
}) => {
  const { currentTournament } = useEventStore();
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Modal
        open={isFinalizeActivityModalOpen}
        onOpenChange={setIsFinalizeActivityModalOpen}
      >
        <ModalTrigger asChild>
          <Button
            disabled={currentTournament?.pairingInfo?.active > 0}
            className="text-sm text-white rounded-full"
            variant="gradient-contained"
            size="sm"
          >
            Finalize Event
          </Button>
        </ModalTrigger>
        <FinalizeActivityModal
          handleClose={() => setIsFinalizeActivityModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

const OrganizeHeaderNavigation = ({ showPodSwitcher = true }) => {
  const {
    allPhases,
    activePhases,
    currentTournament,
    currentTournamentRound,
    activeStage,
    nextPhase,
  } = useEventStore();
  const [isSkipToNextPhaseModalOpen, setIsSkipToNextPhaseModalOpen] =
    useState(false);
  const [isFinalizeActivityModalOpen, setIsFinalizeActivityModalOpen] =
    useState(false);
  const stages = [];
  allPhases?.forEach((phase) => {
    stages[phase?.stage - 1]
      ? stages[phase?.stage - 1].push(phase)
      : (stages[phase?.stage - 1] = [phase]);
  });
  const highestStageNumberForPod = allPhases?.reduce(
    (max, phase) => Math.max(max, phase?.stage),
    0
  );
  const isPreview = activePhases.some((phase) => phase.status === "preview");
  const showRecommendedFinalizeActivity =
    highestStageNumberForPod === activeStage &&
    currentTournament?.currentRound === currentTournament?.totalRounds &&
    !isPreview;

  if (stages?.length === 0) {
    return null;
  }
  return (
    <div className="w-full p-4 bg-zinc-950/90 rounded-lg flex flex-row flex-wrap space-y-4 items-center justify-between">
      <div className="flex flex-row gap-4">
        {stages?.length > 0 &&
          stages.map((phases, index) => (
            <div key={index}>
              <PhaseDropdown
                activeStage={activeStage}
                phases={phases}
                stageNumber={index + 1}
              />
              {stages?.length !== 1 && index !== stages.length - 1 && (
                <span>→</span>
              )}
            </div>
          ))}
      </div>
      <div className="flex gap-2">
        {nextPhase && !showRecommendedFinalizeActivity && (
          <Modal
            open={isSkipToNextPhaseModalOpen}
            onOpenChange={setIsSkipToNextPhaseModalOpen}
          >
            <ModalTrigger asChild>
              <Button
                className="text-sm text-white rounded-full"
                variant={isPreview ? "gradient-outlined" : "gradient-contained"}
                size="sm"
                rounded
                style={{
                  padding: isPreview ? null : "0.5rem 1rem",
                  fontSize: isPreview ? null : "0.875rem",
                }}
              >
                <span>
                  Start{" "}
                  {nextPhase?.name === "Swiss" ? "Rounds" : nextPhase?.name}
                </span>
              </Button>
            </ModalTrigger>
            <SkipToNextPhaseModal
              handleClose={() => setIsSkipToNextPhaseModalOpen(false)}
            />
          </Modal>
        )}
        {!nextPhase && showRecommendedFinalizeActivity && (
          <FinalizeActivityModalContainer
            buttonVariant="text"
            isFinalizeActivityModalOpen={isFinalizeActivityModalOpen}
            setIsFinalizeActivityModalOpen={setIsFinalizeActivityModalOpen}
          />
        )}
        {!showRecommendedFinalizeActivity &&
          currentTournament?.currentRound ===
            currentTournamentRound?.roundNumber && <OrganizeActions />}
      </div>
    </div>
  );
};

export default OrganizeHeaderNavigation;
