import React from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { InformationIcon } from "@/icons/Information";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalDescription, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { goBackRoundMutationFn } from "@repo/api/base";
import { useEventStore } from "@/store/useEventStore";
import { WarningIcon } from "@/icons/Warning";
import { Alert, AlertDescription, AlertTitle } from "@cardeio/ui/components/ui/alert"
import { CircleAlert, Undo2 } from "lucide-react";

const RevertToPreviousRoundModal = ({ handleClose }) => {
  const { currentTournament, currentTournamentRound, refetch } = useEventStore();
  const revertToPreviousRoundMutation = useMutation({
    mutationFn: goBackRoundMutationFn,
    onSuccess: () => {
      refetch();
      toast.success('Successfully started next round!');
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue starting the next round.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    revertToPreviousRoundMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentId: currentTournament?.id,
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>{`Fix Round ${currentTournamentRound?.roundNumber - 1} Results`}</ModalTitle>
        <ModalDescription>Adjust reporting for previous matches.</ModalDescription>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px] mb-6'>
        <WarningIcon className="h-24 w-24 text-red-400/70" aria-hidden="true" />

        <Alert variant="destructive" className="text-inherit bg-destructive/30">
          <AlertTitle className="flex items-center gap-3"><CircleAlert className="h-4 w-4 text-red-500" />This action is destructive and irreversible</AlertTitle>
          <AlertDescription className="ml-7">
            This will DELETE the current round and revert back to the previous played round.
          </AlertDescription>
        </Alert>

        <p className="text-sm text-center text-foreground">
          {`This action is typically used to modify previous round results.`} <br /><br />
          {`If you are looking for match results from the previous round without destroying the current one, look for the “View Round History” dropdown below your Round Pairings title.`}
        </p>
      </div>
      <ModalFooter className='flex items-center justify-center gap-6 sm:justify-center '>
        <Button
          style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
          variant='destructive'
          onClick={onSubmit}
          rounded
          className="flex items-center justify-center"

        >
          <Undo2 className="h-4 w-4 mr-2 text-red-500" />
          Fix Previous Round Results
        </Button>
        <Button
          className='h-fit'
          dataTooltipTarget="tooltip-default"
          rounded
          style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          variant='gradient-contained'
          onClick={handleClose}

        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default RevertToPreviousRoundModal;
