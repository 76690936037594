import React, { useEffect } from "react";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { useGameStore } from "@/store/useGameStore";

const EventBannerSelectionSection = ({ form }) => {
  const watchAllFields = form.watch();
  const { games } = useGameStore();
  const selectedGame = games?.find(game => game?.id === watchAllFields?.gameId);

  const allBannerImages = selectedGame?.resourceImages?.filter(image => image.imageType === 'bannerImage') || [];

  // De-duplicate banner images based on URL
  const uniqueUrls = new Set();
  const bannerImages = allBannerImages.filter(image => {
    const url = image.image.url;
    if (uniqueUrls.has(url)) {
      return false;
    }
    uniqueUrls.add(url);
    return true;
  });

  // Set a default banner image if none is selected
  useEffect(() => {
    if (!watchAllFields.bannerImage && bannerImages.length > 0) {
      form.setValue('bannerImage', bannerImages[0].image.url);
    }
  }, [selectedGame, bannerImages, form, watchAllFields.bannerImage]);

  if (!selectedGame || bannerImages.length === 0) {
    return null;
  }

  return (
    <FormCard
      title="Event Image"
      className="mb-4 border border-border backdrop-blur-sm"
      variant="uap"
    >
      <div className='flex flex-wrap justify-start gap-4 rounded-lg mt-4 overflow-scroll scrollbar-hide h-[300px]'>
        {bannerImages?.map(bannerImage => (
          <button
            key={bannerImage.image.url}
            className={`w-[48%] aspect-[7/3] bg-cover bg-no-repeat bg-center rounded-lg transition-colors ${bannerImage.image.url === watchAllFields.bannerImage
              ? 'border-orange-500 border-2'
              : 'border-card border hover:border-orange-300'
              }`}
            style={{ backgroundImage: `url(${bannerImage.image.url})` }}
            onClick={() => form.setValue('bannerImage', bannerImage.image.url)}
            type="button"
          />
        ))}
      </div>
    </FormCard>
  );
};

export default EventBannerSelectionSection;
