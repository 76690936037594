import React from "react"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { Button } from "@cardeio/ui/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@cardeio/ui/components/ui/dialog"

import AutocompleteInput from "./autocomplete-input"

interface GoogleAddress {
	address1?: string
	address2?: string
	city?: string
	state?: string
	country?: string
	zip?: string
	geo?: {
		lat: number
		lng: number
	}
	formatted_address?: string
}

interface GoogleAddressComponent {
	long_name: string
	short_name: string
	types: string[]
}

interface GooglePlacesResult {
	address_components: GoogleAddressComponent[]
	formatted_address: string
	geometry: {
		location: {
			lat(): number
			lng(): number
		}
	}
	international_phone_number?: string
	website?: string
}

interface ManualAddressForm {
	address1: string
	address2: string
	city: string
	state: string
	country: string
	postal_code: string
}

interface GooglePlacesPrediction {
	description: string
	place_id: string
}

interface GooglePlacesAutocompleteInputProps {
	apiKey: string
	value?: string
	onChange?: (value: string) => void
	onAddressSelect?: (address: GoogleAddress, rawResponse?: GooglePlacesResult) => void
	placeholder?: string
	className?: string
	inputClassName?: string
	inputVariant?: "default" | "uap"
	initialSearchValue?: string
	showFallbackDialog?: boolean
	showFallbackDialogButton?: boolean
	onKeyDown?: (e: React.KeyboardEvent) => void
}

const googleBusinessFields = [
	"address_components",
	"geometry.location",
	"formatted_address",
	"name",
	"opening_hours",
	"photos",
	"website",
	"international_phone_number",
]

export function GooglePlacesAutocompleteInput({
	apiKey,
	value,
	onChange,
	onAddressSelect,
	placeholder = "Search location...",
	className,
	inputClassName,
	inputVariant,
	initialSearchValue,
	showFallbackDialog = false,
	showFallbackDialogButton = false,
	onKeyDown,
}: GooglePlacesAutocompleteInputProps) {
	const [isDialogOpen, setIsDialogOpen] = React.useState(false)
	const [manualAddress, setManualAddress] = React.useState<ManualAddressForm>({
		address1: "",
		address2: "",
		city: "",
		state: "",
		country: "",
		postal_code: "",
	})
	const [addressLine1Error, setAddressLine1Error] = React.useState(false)

	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey,
		options: {
			fields: googleBusinessFields,
		},
	})

	// Ref to track the last searched value to prevent duplicate searches
	const lastSearchedRef = React.useRef<string>("")

	// Effect to handle initialSearchValue changes
	React.useEffect(() => {
		const trimmedValue = initialSearchValue?.trim() || ""

		// Only search if we have a value and haven't searched for it yet
		if (trimmedValue && trimmedValue !== lastSearchedRef.current) {
			lastSearchedRef.current = trimmedValue
			const timeoutId = setTimeout(() => {
				getPlacePredictions({ input: trimmedValue })
			}, 300) // Add a small delay to prevent rapid re-renders

			return () => {
				clearTimeout(timeoutId)
			}
		}
	}, [initialSearchValue, getPlacePredictions])

	const handleSelectedValueChange = (placeId: string) => {
		if (placesService && placeId) {
			placesService.getDetails(
				{
					placeId,
					fields: googleBusinessFields,
				},
				(res: GooglePlacesResult) => {
					if (res) {
						const address: GoogleAddress = {
							geo: {
								lat: res.geometry.location.lat(),
								lng: res.geometry.location.lng(),
							},
							formatted_address: res.formatted_address,
						}

						let floor = null
						let room = null
						let subpremise = null
						let premise = null
						let streetNumber = ""
						let route = ""
						let locality = null
						let sublocalityLevel1 = null
						let postalTown = null
						let administrativeAreaLevel3 = null
						let administrativeAreaLevel2 = null

						res.address_components.forEach((component: GoogleAddressComponent) => {
							if (component?.types?.includes("floor")) {
								floor = component.short_name
							}
							if (component?.types?.includes("room")) {
								room = component.short_name
							}
							if (component?.types?.includes("subpremise")) {
								subpremise = component.short_name
							}
							if (component?.types?.includes("premise")) {
								premise = component.short_name
							}
							if (component?.types?.includes("street_number")) {
								streetNumber = component.short_name
							}
							if (component?.types?.includes("route")) {
								route = component.short_name
							}
							if (component.types.includes("locality")) {
								locality = component.long_name
							}
							if (component.types.includes("sublocality_level_1")) {
								sublocalityLevel1 = component.long_name
							}
							if (component.types.includes("postal_town")) {
								postalTown = component.long_name
							}
							if (component.types.includes("administrative_area_level_3")) {
								administrativeAreaLevel3 = component.long_name
							}
							if (component.types.includes("administrative_area_level_2")) {
								administrativeAreaLevel2 = component.long_name
							}
							if (component?.types?.includes("administrative_area_level_1")) {
								address.state = component.short_name
							}
							if (component?.types?.includes("country")) {
								address.country = component.short_name
							}
							if (component?.types?.includes("postal_code")) {
								address.zip = component.short_name
							}
						})

						address.address1 = [floor, room, subpremise, premise, streetNumber, route]
							.filter((x) => !!x)
							.join(", ")

						// Now you can add your city preference logic here
						address.city =
							locality ||
							sublocalityLevel1 ||
							postalTown ||
							administrativeAreaLevel3 ||
							administrativeAreaLevel2 ||
							""

						onAddressSelect?.(address, res)
						onChange?.(res.formatted_address)
					}
				}
			)
		}
	}

	const handleSearchValueChange = (searchValue: string) => {
		onChange?.(searchValue)
		getPlacePredictions({ input: searchValue })
	}

	const handleManualAddressChange =
		(field: keyof ManualAddressForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
			setManualAddress((prev) => ({
				...prev,
				[field]: e.target.value,
			}))
			if (field === "address1") {
				setAddressLine1Error(false)
			}
		}

	const handleManualAddressSubmit = async () => {
		if (!manualAddress.address1) {
			setAddressLine1Error(true)
			return
		}

		// Try to get coordinates from state + zip + country if available
		let coordinates = { lat: 45, lng: 45 }
		if (manualAddress.state && manualAddress.postal_code && manualAddress.country) {
			const searchQuery = `${manualAddress.state} ${manualAddress.postal_code} ${manualAddress.country}`
			try {
				await new Promise((resolve) => {
					placesService?.getPlacePredictions(
						{ input: searchQuery },
						async (predictions: GooglePlacesPrediction[]) => {
							if (predictions && predictions.length > 0) {
								placesService?.getDetails(
									{
										placeId: predictions[0].place_id,
										fields: ["geometry.location"],
									},
									(result: GooglePlacesResult) => {
										if (result?.geometry?.location) {
											coordinates = {
												lat: result.geometry.location.lat(),
												lng: result.geometry.location.lng(),
											}
										}
										resolve(undefined)
									}
								)
							} else {
								resolve(undefined)
							}
						}
					)
				})
			} catch (error) {
				console.error("Error getting coordinates:", error)
			}
		}

		const formattedAddress = [
			manualAddress.address1,
			manualAddress.address2,
			manualAddress.city,
			manualAddress.state,
			manualAddress.postal_code,
			manualAddress.country,
		]
			.filter(Boolean)
			.join(", ")

		const address: GoogleAddress = {
			address1: manualAddress.address1,
			address2: manualAddress.address2,
			city: manualAddress.city,
			state: manualAddress.state,
			country: manualAddress.country,
			zip: manualAddress.postal_code,
			formatted_address: formattedAddress,
			geo: coordinates,
		}

		onAddressSelect?.(address)
		onChange?.(formattedAddress)
		setIsDialogOpen(false)
	}

	const handleDialogOpen = (input: string) => {
		setManualAddress((prev) => ({
			...prev,
			address1: input,
		}))
		setIsDialogOpen(true)
	}

	return (
		<>
			<div className="relative">
				<AutocompleteInput
					searchValue={value || ""}
					onSearchValueChange={handleSearchValueChange}
					selectedValue={value || ""}
					onSelectedValueChange={handleSelectedValueChange}
					items={placePredictions.map((prediction) => ({
						value: prediction.place_id,
						label: prediction.description,
					}))}
					isLoading={isPlacePredictionsLoading}
					placeholder={placeholder}
					className={className}
					inputClassName={inputClassName}
					inputVariant={inputVariant}
					emptyMessage={(input) => {
						return showFallbackDialog ? (
							<>No results found. Use "{input}"?</>
						) : (
							<>No results found.</>
						)
					}}
					onEmptyMessagePressed={(input) => {
						if (showFallbackDialog) {
							handleDialogOpen(input)
						}
					}}
					onKeyDown={onKeyDown}
				/>
				{showFallbackDialogButton && (
					<Button
						variant="outline"
						size="sm"
						className="absolute right-1 top-1/2 -translate-y-1/2 h-7"
						onClick={() => handleDialogOpen(value || "")}
					>
						Edit manually
					</Button>
				)}
			</div>
			{showFallbackDialog && (
				<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Enter Address</DialogTitle>
						</DialogHeader>
						<div className="flex flex-col gap-4 py-4">
							<div className="space-y-2">
								<label className="text-sm font-medium">Address line 1</label>
								<input
									type="text"
									value={manualAddress.address1}
									onChange={handleManualAddressChange("address1")}
									className={`w-full p-2 border rounded ${
										addressLine1Error ? "border-red-500" : ""
									}`}
									placeholder="Address line 1"
								/>
								{addressLine1Error && (
									<p className="text-sm text-red-500">Address line 1 is required</p>
								)}
							</div>
							<div className="space-y-2">
								<label className="text-sm font-medium">Address line 2 (Optional)</label>
								<input
									type="text"
									value={manualAddress.address2}
									onChange={handleManualAddressChange("address2")}
									className="w-full p-2 border rounded"
									placeholder="Address line 2"
								/>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div className="space-y-2">
									<label className="text-sm font-medium">City</label>
									<input
										type="text"
										value={manualAddress.city}
										onChange={handleManualAddressChange("city")}
										className="w-full p-2 border rounded"
										placeholder="City"
									/>
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">State / Province / Region</label>
									<input
										type="text"
										value={manualAddress.state}
										onChange={handleManualAddressChange("state")}
										className="w-full p-2 border rounded"
										placeholder="State"
										maxLength={2}
									/>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<div className="space-y-2">
									<label className="text-sm font-medium">Postal Code</label>
									<input
										type="text"
										value={manualAddress.postal_code}
										onChange={handleManualAddressChange("postal_code")}
										className="w-full p-2 border rounded"
										placeholder="Postal Code"
									/>
								</div>
								<div className="space-y-2">
									<label className="text-sm font-medium">Country</label>
									<input
										type="text"
										value={manualAddress.country}
										onChange={handleManualAddressChange("country")}
										className="w-full p-2 border rounded"
										placeholder="Country"
										maxLength={2}
									/>
								</div>
							</div>
						</div>
						<DialogFooter>
							<DialogClose asChild>
								<Button variant="outline">Cancel</Button>
							</DialogClose>
							<Button onClick={handleManualAddressSubmit}>Save</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}
