import React from "react";

interface AddFiveIconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

export const AddFiveIcon: React.FC<AddFiveIconProps> = ({
  className,
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.0007 36.6668C29.2054 36.6668 36.6673 29.2049 36.6673 20.0002C36.6673 10.7954 29.2054 3.3335 20.0007 3.3335C10.7959 3.3335 3.33398 10.7954 3.33398 20.0002C3.33398 29.2049 10.7959 36.6668 20.0007 36.6668Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6287 24.5333C18.8815 24.5333 18.2659 24.372 17.782 24.0493C17.3065 23.7267 16.9286 23.2597 16.6484 22.6483L17.9221 21.986C18.0494 22.3936 18.2405 22.729 18.4952 22.9922C18.7584 23.2469 19.1447 23.3743 19.6542 23.3743C20.0108 23.3743 20.3122 23.3106 20.5585 23.1832C20.8132 23.0474 21.0085 22.8606 21.1443 22.6228C21.2802 22.3766 21.3481 22.0837 21.3481 21.744C21.3481 21.3874 21.2844 21.0903 21.1571 20.8525C21.0297 20.6148 20.8472 20.4365 20.6094 20.3176C20.3717 20.1902 20.0745 20.1265 19.7179 20.1265C19.4716 20.1265 19.2424 20.152 19.0301 20.203C18.8178 20.2539 18.6268 20.3346 18.457 20.445C18.2872 20.5468 18.1386 20.6827 18.0112 20.8525L16.8904 20.636L17.3999 16H22.2906V17.1717H18.4825L18.1768 19.5407L18.1513 19.6808H18.1768C18.3721 19.4685 18.6226 19.3029 18.9282 19.1841C19.2424 19.0652 19.6117 19.0058 20.0363 19.0058C20.5797 19.0058 21.0467 19.1204 21.4373 19.3496C21.8278 19.5704 22.1335 19.8803 22.3543 20.2794C22.575 20.6784 22.6854 21.1412 22.6854 21.6676C22.6854 22.1261 22.609 22.5337 22.4562 22.8903C22.3033 23.2469 22.0868 23.5483 21.8066 23.7946C21.5264 24.0408 21.1995 24.2276 20.8259 24.355C20.4608 24.4739 20.0618 24.5333 19.6287 24.5333Z"
        fill="white"
      />
      <path
        d="M12.7317 17.1206V23.5651H11.5473V17.1206H12.7317ZM15.2662 19.7697V20.9033H9V19.7697H15.2662Z"
        fill="white"
      />
      <path
        d="M23.6938 24V19.25H24.8623L24.9098 20.105C25.0681 19.7883 25.2834 19.5508 25.5558 19.3925C25.8344 19.2342 26.1416 19.155 26.4773 19.155C26.8193 19.155 27.1264 19.2342 27.3988 19.3925C27.6774 19.5508 27.8864 19.7852 28.0258 20.0955C28.1271 19.8865 28.2633 19.7123 28.4343 19.573C28.6053 19.4337 28.7953 19.3292 29.0043 19.2595C29.2133 19.1898 29.4254 19.155 29.6408 19.155C29.9511 19.155 30.2329 19.2183 30.4863 19.345C30.7459 19.4717 30.9518 19.6617 31.1038 19.915C31.2558 20.1683 31.3318 20.4913 31.3318 20.884V24H30.0303V21.1405C30.0303 20.7922 29.9543 20.5452 29.8023 20.3995C29.6503 20.2538 29.4539 20.181 29.2133 20.181C29.0233 20.181 28.8459 20.2285 28.6813 20.3235C28.5229 20.4185 28.3963 20.561 28.3013 20.751C28.2126 20.9347 28.1683 21.1658 28.1683 21.4445V24H26.8668V21.1405C26.8668 20.7922 26.7876 20.5452 26.6293 20.3995C26.4773 20.2538 26.2809 20.181 26.0403 20.181C25.8756 20.181 25.7109 20.2253 25.5463 20.314C25.3879 20.4027 25.2549 20.542 25.1473 20.732C25.0459 20.922 24.9953 21.1722 24.9953 21.4825V24H23.6938Z"
        fill="white"
      />
    </svg>
  );
};
