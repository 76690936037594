import { useAvailableGames } from "@/store/useGameStore";
import TaskStatusBadge from "./TaskStatusBadge";
import { cn } from "@/utils/cn";
import { Button } from "@cardeio/ui/components/ui/button";
import { CircleFadingPlus } from "lucide-react";
import Loading from "@/components/Loading";
import getSentInquiriesQueryOptions from "@/data/inquiry/sent";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { getIsDevEnvironment } from "@/utils";
import { GameCard } from "@/components/GameCard";
import { Sheet, SheetContent, SheetTrigger } from "@cardeio/ui/components/ui/sheet";
import GameSheet from "@/components/Sheets/Game";
import { useState } from "react";
import { useParams } from "@tanstack/react-router";
import { entityQueryOptions } from "@/data/establishment/entity/getEntity";

const getGameStatus = (gameInquiry) => {
  if (gameInquiry?.status === 'acknowledged' || gameInquiry?.status === 'sent') {
    return 'received';
  }
  if (gameInquiry?.status === 'inProgress') {
    return 'pending';
  }
  if (gameInquiry?.status === 'changesRequested') {
    return 'rejectedMissingInfo';
  }
  if (gameInquiry?.status === 'canceled') {
    return 'rejectedCancelled';
  }
  if (gameInquiry?.status === 'resolved' && gameInquiry?.resolution?.outcome === 'rejected') {
    return 'rejected';
  }
}

const GameStoreCard = ({ game, gameInquiry }) => {
  const gameStatus = getGameStatus(gameInquiry);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>
        <div>
          <GameCard
            game={game}
            className={cn({
              'border-red-500 bg-card-error': gameStatus === 'rejectedMissingInfo'
            })}
            contentClassName="justify-end gap-2"
          >
            <>
              <div className="flex-1" />
              <TaskStatusBadge status={gameStatus} />
              {gameStatus === 'rejectedMissingInfo' &&
                <Button variant={"outline-gradient"}>
                  Complete Application
                </Button>
              }
              {gameStatus === undefined &&
                <Button variant={"gradient"} className="rounded-full text-base" rounded>
                  <div className="flex items-center gap-2">
                    <CircleFadingPlus className="w-4 h-4 mr-2" />
                    Apply to Add
                  </div>
                </Button>
              }
              {gameStatus === 'rejected' &&
                <div className="text-white text-xs">
                  Please check your messages for more details
                </div>
              }
            </>
          </GameCard>
        </div>
      </SheetTrigger>
      <SheetContent className="bg-carde-gray-500 w-[50vw] sm:max-w-[50%]">
        <GameSheet
          game={game}
          applications={{
            organizedPlay: {
              status: gameInquiry?.status
            }
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

export const AppliedGames = ({ search }) => {
  const params = useParams({ strict: false });
  const { data: availableGames, isLoading } = useAvailableGames()
  const { data: establishment } = useSuspenseQuery(
    entityQueryOptions(params),
  );
  const { data: sentInquiries } = useQuery(getSentInquiriesQueryOptions({ type: 'gameEstablishmentApplication', sourceId: establishment.id }))

  if (isLoading) return <Loading />
  return <div className="flex flex-wrap gap-4">
    {availableGames?.filter((game) => game.name.toLowerCase().includes(search.toLowerCase())).map((game) => (
      <GameStoreCard key={game.id} game={game} gameInquiry={sentInquiries?.find((inquiry) => inquiry.targetType === 'Game' && inquiry.targetId === game.id)} />
    ))}
  </div>;
};
