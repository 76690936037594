import { useEffect, useState } from "react";
import usePrevious from "@/hooks/usePrevious";
// import { Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { Input } from "@cardeio/ui/components/ui/input"
import { cn } from "@/utils/cn";
import { Link } from "@tanstack/react-router";
import { Search } from "lucide-react";

const SearchGamesSection = ({ description, games, handleSelect, selectedGames, title, subtitle, entityId }) => {
  const [searchInput, setSearchInput] = useState('');
  const previousSearchInput = usePrevious(searchInput);
  const [filteredGames, setFilteredGames] = useState(games);

  useEffect(() => {
    const updatedGames = searchInput
      ? games.filter((gameInfo) => gameInfo.name.toLowerCase().includes(searchInput.toLowerCase()))
      : games;

    setFilteredGames(updatedGames);

  }, [searchInput, previousSearchInput, games]);

  const GameButton = ({ game }) => {
    const isSelected = selectedGames.find((gameId) => gameId === game.id);
    const logo = game?.resourceImages?.find(image => image.imageType === 'logo')?.image?.url;
    const firstBanner = game?.resourceImages?.find(image => image.imageType === 'bannerImage' && image.order === 0)?.image?.url;

    return (
      <Button
        className={cn(
          'flex flex-col items-center justify-center rounded-lg p-4 bg-[#09090b80] border border-border w-[23%] gap-3',
          isSelected && 'border-orange-500 bg-[linear-gradient(0deg,rgba(246,117,25,0.08)_0%,rgba(246,117,25,0.08)_100%),#2C2C2C]'
        )}
        key={game.id}
        onClick={() => handleSelect(game.id, isSelected)}
      >
        <div className="relative w-full h-24 rounded-md overflow-hidden mb-2">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={firstBanner}
            alt={`${game?.name} banner`}
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
            <img
              className="max-h-8 max-w-none"
              src={logo}
              alt={`${game?.name} logo`}
            />
          </div>
        </div>
        <div className="text-xs font-light">
          {game?.name}
        </div>
      </Button>
    )
  }

  const searchInputComponent = (
    <div className="relative">
      <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
      <Input
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder='Search'
        className='w-[300px] border border-border bg-[#09090b80] pl-8'
        value={searchInput}
      />
    </div>
  );

  return (
    <FormCard
      title={title || "Select a game"}
      subtitle={subtitle || description}
      headerContent={searchInputComponent}
      className="mb-4 border border-border"
      variant="uap"
    >
      <div className='flex justify-center items-start flex-wrap gap-4'>
        {filteredGames?.map((game) => (
          <GameButton game={game} key={game.id} />
        ))}
      </div>
      <div className="text-xs font-medium leading-none mt-4">
        Don't see the game you want to make an event for?{' '}
        <Link to={`/entity/${entityId}/`} className="text-ring hover:underline">
          Apply for more games
        </Link>
      </div>
    </FormCard>
  )
}

export default SearchGamesSection;
