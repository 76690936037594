import * as React from "react"
import { CurrencyInput } from './Currency';
import InputUrl from './InputUrl';

import { cn } from "../utils"
const Input = React.forwardRef(({ className, isItemClickable = false, type, appendItem = null, prependItem = null, ...props }, ref) => {
  if (appendItem) {
    return (
      <label className='relative text-zinc-400 focus-within:text-zinc-200 block'>
        <input
          type={type}
          onWheel={type === 'number' ? (e) => e.target.blur() : undefined}
          className={cn(
            "flex py-[7px] h-auto w-full rounded-md border border-border input-background px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus:ring-accent focus:border-accent disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
        <span className={`${isItemClickable ? 'pointer-events-none ' : ''}absolute top-0 right-0`}>
          {appendItem}
        </span>
      </label>
    )
  }
  if (prependItem) {
    return (
      <label className='relative text-zinc-400 focus-within:text-zinc-200 block'>
        <span className={`${isItemClickable ? 'pointer-events-none ' : ''}absolute top-[30%] left-2`}>
          {prependItem}
        </span>
        <input
          type={type}
          onWheel={type === 'number' ? (e) => e.target.blur() : undefined}
          className={cn(
            "flex w-full rounded-md border border-border input-background px-4 py-[7px] h-auto text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus:ring-accent focus:border-accent disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
      </label>
    )
  }
  return (
    (<input
      type={type}
      onWheel={type === 'number' ? (e) => e.target.blur() : undefined}
      className={cn(
        inputDefaultStyle,
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Input.displayName = "Input"

const inputDefaultStyle = "flex w-full rounded-md border border-zinc-200 input-background px-3 py-[7px] h-auto text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus:ring-pink-600 focus:border-secondary disabled:cursor-not-allowed disabled:opacity-50"

export { CurrencyInput, Input, InputUrl, inputDefaultStyle }
