import React from "react";
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Heading from '@tiptap/extension-heading'
import TextAlign from '@tiptap/extension-text-align'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Input } from "@cardeio/ui/components/ui/input";
import FormCard from "@cardeio/ui/components/forms/form-card";
import RichTextEditor from "@/components/RichTextEditor";

const EventAdditionalInfoSection = ({ form }) => {
  const editor = useEditor({
    onUpdate: (e) => {
      form.setValue('description', JSON.stringify(e.editor.getJSON()))
      form.setValue('descriptionHtml', e.editor.getHTML())
    },
    extensions: [
      StarterKit.configure(),
      Highlight,
      Heading,
      TaskList,
      TaskItem,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount.configure({
        limit: 10000,
      }),
    ],
  });

  return (
    <FormCard title="Event Name" className="mb-4 border border-border" variant="uap">
      <div className='flex flex-col gap-6'>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Event Name</FormLabel>
              <FormControl>
                <Input placeholder="Event Name" variant="uap" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Additional Event Information</FormLabel>
              <RichTextEditor editor={editor} className="input-background border-border" />
            </FormItem>
          )}
        />
      </div>
    </FormCard>
  )
}

export default EventAdditionalInfoSection;
