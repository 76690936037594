"use client"
import React from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { Button } from "./Button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../Command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../Popover"
import { cn } from "../utils";

export { GameComboBox } from './Games';

export const ComboBox = ({ className, idCode = 'id', itemDisplay, labelCode = 'label', subLabelCode, groups, items, placeholder = 'Select Item...', setValue, value, clearOnReselect = false }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`flex items-center w-[400px] justify-between py-[7px] h-auto ${className}`}
        >
          {value ? items.find((item) => item?.[idCode] === value)?.[labelCode] : placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-input-background w-[400px] p-0">
        <Command className='w-[400px]'>
          <CommandInput placeholder="Search..." className="h-9" />
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandList className='h-[200px]'>
            {groups ?
              Object.keys(groups).map((groupKey) => (
                <CommandGroup key={groupKey}>
                  <h5 className='text-lg font-bold text-center uppercase bg-input-background py-1'>{groupKey}</h5>
                  {groups[groupKey].map((item) => (
                    <CommandItem
                      className={cn(
                        "cursor-pointer py-4 pl-4",
                        value === item?.[idCode] ? "text-primary" : "text-zinc-200"
                      )}
                      key={item?.[idCode]}
                      value={item?.[idCode]}
                      onSelect={(currentValue) => {
                        setValue(clearOnReselect && currentValue === value ? "" : currentValue)
                        setOpen(false)
                      }}
                    >
                      {item?.[labelCode]}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 text-primary",
                          value === item?.[idCode] ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))
              : (
                <CommandGroup>
                  {items.map((item) => (
                    <CommandItem
                      className={cn(
                        "cursor-pointer py-4 pl-4",
                        value === item?.[idCode] ? "text-primary" : "text-zinc-200"
                      )}
                      key={item?.[idCode]}
                      value={item?.[idCode]}
                      onSelect={(currentValue) => {
                        setValue(clearOnReselect && currentValue === value ? "" : currentValue)
                        setOpen(false)
                      }}
                    >
                      {itemDisplay ? itemDisplay(item) : (
                        <>
                          {item?.[labelCode]}
                          {subLabelCode && (
                            <span className='text-zinc-200/60 ml-1'>- {item?.[subLabelCode]}</span>
                          )}
                        </>
                      )}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 text-primary",
                          value === item?.[idCode] ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default ComboBox;
