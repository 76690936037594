// Prize placement constants used across the application

// Display names for prize placements
export const PRIZE_NAMES = {
  participation: "Participation",
  perWin: "Per Win",
  first: "1st Place",
  second: "2nd Place",
  third: "3rd Place",
  top4: "Top 4",
  top8: "Top 8",
  top16: "Top 16",
  top32: "Top 32",
  top64: "Top 64",
  top128: "Top 128",
  top256: "Top 256",
  top512: "Top 512",
  top1024: "Top 1024",
};

// Short display names for prize placements (used in buttons/badges)
export const PRIZE_SHORT_NAMES = {
  first: "1st",
  second: "2nd",
  third: "3rd",
  top4: "Top 4",
  top8: "Top 8",
  top16: "Top 16",
  top32: "Top 32",
  top64: "Top 64",
  top128: "Top 128",
  top256: "Top 256",
  top512: "Top 512",
  top1024: "Top 1024",
};

// Order of prize placements (for sorting)
export const PRIZE_ORDER = {
  participation: 0,
  perWin: 1,
  first: 2,
  second: 3,
  third: 4,
  top4: 5,
  top8: 6,
  top16: 7,
  top32: 8,
  top64: 9,
  top128: 10,
  top256: 11,
  top512: 12,
  top1024: 13,
};

// Colors for prize placement badges
export const PRIZE_COLORS = {
  first: "#D4AF3780",
  second: "#C0C0C080",
  third: "#CD7F3280",
  top4: "#D0242480",
  top8: "#32DA2A80",
  top16: "#2199D180",
  top32: "#8A3FCD80",
  top64: "#47DDA280",
  top128: "#D95D3E80",
  top256: "#3E7AD980",
  top512: "#7A3ED980",
  top1024: "#3ED97A80",
};

// Array of prize placement keys in order (for iteration)
export const PRIZE_PLACEMENT_KEYS = [
  "first",
  "second",
  "third",
  "top4",
  "top8",
  "top16",
  "top32",
  "top64",
  "top128",
  "top256",
  "top512",
  "top1024",
];

// Helper function to filter prizes based on capacity
export const filterPrizesByCapacity = (capacity) => {
  return PRIZE_PLACEMENT_KEYS.filter(key => {
    if (key === 'top64') return capacity >= 64;
    if (key === 'top128') return capacity >= 128;
    if (key === 'top256') return capacity >= 256;
    if (key === 'top512') return capacity >= 512;
    if (key === 'top1024') return capacity >= 1024;
    return true;
  });
}; 