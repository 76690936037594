import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { createRouter } from "@tanstack/react-router";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import "./index.css";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import "react-phone-number-input/style.css";
import "photoswipe/dist/photoswipe.css";
import { LDProvider } from "./LDProviderApp";
import Loading from "@/components/Loading";

// Create a new router instance
export const router = createRouter({
  routeTree,
  context: {
    auth: undefined,
    games: undefined,
    userData: undefined,
  },
  defaultPendingComponent: Loading,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Initialize Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN || "",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/cardeio\.io/],
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 0.1,
  replaysOnErrorSampleRate:
    import.meta.env.VITE_SENTRY_ERROR_SAMPLE_RATE || 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "production",
  enabled:
    Boolean(import.meta.env.VITE_SENTRY_DSN) &&
    (import.meta.env.VITE_SENTRY_ENABLED || false),
});

// Render the app
const rootElement = document.getElementById("app");
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        issuer={import.meta.env.VITE_AUTH0_ISSUER}
        authorizationParams={{
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
          scope: "openid profile email offline_access",
        }}
      >
        <LDProvider />
      </Auth0Provider>
    </StrictMode>,
  );
}
