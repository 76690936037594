import { ACTIVITY_LIMIT_TYPES, EVENT_OWNER_TYPE } from '@/constants';
import dayjs from 'dayjs';
import { each, every, get, some, find, isEqual } from 'lodash';

export const generateDates = (activity, game) => {
  const today = dayjs().utc().hour(12).minute(0).second(0).millisecond(0);
  const twoYearsFromNow = today.add(2, 'year').add(1, 'day');

  let relation;
  if (activity.ownerType === EVENT_OWNER_TYPE.Game) return {
    invalidDates: [],
    firstValidDate: dayjs().toISOString()
  };
  if (activity.ownerType === EVENT_OWNER_TYPE.Establishment)
    relation = activity.owner.gameEstablishments.find(ge => ge.gameId === game.id);
  if (activity.ownerType === EVENT_OWNER_TYPE.User)
    relation = activity.owner.gameUsers.find(gu => gu.gameId === game.id);


  const { activityLevels, gameActivities, activityTemplates } = game;

  if (!activity.activityLevelId || !activity.gameActivityId || !activity.activityTemplateId || !game) {
    return {
      invalidDates: [],
      firstValidDate: dayjs().toISOString()
    };
  }

  const activityLevel = activityLevels.find(({ id }) => id === activity.activityLevelId);
  const gameActivity = gameActivities.find(({ id }) => id === activity.gameActivityId);
  const activityTemplate = activityTemplates.find(({ id }) => id === activity.activityTemplateId);

  const dataLimits = [];
  const relationLimits = [];
  activityLevel?.limits?.forEach((l) => {
    if (l.type === ACTIVITY_LIMIT_TYPES.relationData) relationLimits.push(l);
    if (l.type === ACTIVITY_LIMIT_TYPES.data) dataLimits.push(l);
  });
  gameActivity?.limits?.forEach((l) => {
    if (l.type === ACTIVITY_LIMIT_TYPES.relationData) relationLimits.push(l);
    if (l.type === ACTIVITY_LIMIT_TYPES.data) dataLimits.push(l);
  });
  activityTemplate?.limits?.forEach((l) => {
    if (l.type === ACTIVITY_LIMIT_TYPES.relationData) relationLimits.push(l);
    if (l.type === ACTIVITY_LIMIT_TYPES.data) dataLimits.push(l);
  });

  const invalidDates = [];
  let firstValidDate;
  let currentDate = today;
  while (currentDate.isBefore(twoYearsFromNow)) {
    const invalid = (() => {
      for (const l of dataLimits) {
        if (!matchRecord({ startsAt: currentDate.toISOString() }, l.match)) continue;
        if (!checkConditions(l.conditions, { ...activity, startsAt: currentDate.toISOString() }))
          return true;
      }
      for (const l of relationLimits) {
        if (!matchRecord({ startsAt: currentDate.toISOString() }, l.match)) continue;
        if (!checkConditions(l.conditions, relation)) return true
      }
    })()
    if (invalid) addInvalidDate(invalidDates, currentDate);
    else firstValidDate ??= currentDate.toISOString();
    currentDate = currentDate.add(1, 'day');
  }

  return {
    firstValidDate,
    invalidDates: invalidDates.map(d => ({ startDate: d.startDate.toISOString(), endDate: d.endDate.toISOString() })),
  };
};

const addInvalidDate = (invalidDates, currentDate) => {
  if (!invalidDates.length) return invalidDates.push({ startDate: currentDate.subtract(1, 'day'), endDate: currentDate });
  const latest = invalidDates[invalidDates.length - 1];
  if (currentDate.diff(latest.endDate, 'days') === 1) return latest.endDate = currentDate;
  else invalidDates.push({ startDate: currentDate.subtract(1, 'day'), endDate: currentDate });
}

export const matchRecord = (data, query) => {
  let match = true;

  each(query, (value, key) => {
    if (['string', 'number'].includes(typeof value)) {
      if (data[key] !== value) match = false;
      return;
    }
    if (typeof value === 'object') {
      if (value.not) {
        if (data[key] === value.not) match = false;
        return;
      }
      if (value.some) {
        const found = find(data[key], (record) => {
          return every(value.some, (v, k) => {
            return record[k] === v;
          });
        });
        if (!found) match = false;
        return;
      }
      if (value.gte && data[key] < value.gte) match = false;
      if (value.gt && data[key] <= value.gt) match = false;
      if (value.lte && data[key] > value.lte) match = false;
      if (value.lt && data[key] >= value.lt) match = false;
    }
    if (!isEqual(data[key], value)) match = false;
  });

  return match;
};

export const checkConditions = (conditions, data) => {
  if (conditions?.all) {
    return all(conditions.all, data);
  } else if (conditions?.any) {
    return any(conditions.any, data);
  } else if (conditions?.field) {
    return check(conditions, data);
  } else {
    return Boolean(conditions);
  }
};

const all = (conditions, data) => {
  return every(conditions, (condition) => {
    return checkConditions(condition, data);
  });
};

const any = (conditions, data) => {
  return some(conditions, (condition) => {
    return checkConditions(condition, data);
  });
};

const check = (condition, data) => {
  const value = condition.value;
  const fieldValue = get(data, condition.field);

  switch (condition.operator) {
    case Operator.equal:
      return fieldValue === value;
    case Operator.notEqual:
      return fieldValue !== value;
    case Operator.lessThan:
      return fieldValue < value;
    case Operator.lessThanEqual:
      return fieldValue <= value;
    case Operator.greaterThan:
      return fieldValue > value;
    case Operator.greaterThanEqual:
      return fieldValue >= value;
    case Operator.in:
      return value?.includes(fieldValue);
    case Operator.notIn:
      return !value?.includes(fieldValue);
    case Operator.contains:
      return fieldValue?.includes(value);
    case Operator.doesNotContain:
      return !fieldValue?.includes(value);
  }
};

const Operator = {
  equal: 'equal',
  notEqual: 'notEqual',
  lessThan: 'lessThan',
  lessThanEqual: 'lessThanEqual',
  greaterThan: 'greaterThan',
  greaterThanEqual: 'greaterThanEqual',
  contains: 'contains',
  doesNotContain: 'doesNotContain',
  in: 'in',
  notIn: 'notIn',
}