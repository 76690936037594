type updateRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  id: string;
  type: "tournamentRound" | "activityPhase";
  total?: number;
  start?: boolean;
  startTime?: string;
};

export const updateRoundTimerMutationFn = async ({
  clientAxiosInstance,
  id,
  type,
  total,
  start = true,
  startTime,
}: updateRoundTimerMutationFnParams) => {
  const endpoint = `/api/organize/${type}s/${id}/updateTimer`;
  return clientAxiosInstance.put(endpoint, {
    duration: total,
    start: start,
    startTime: startTime,
  });
};
