import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ChevronRightIcon } from '@heroicons/react/20/solid'

dayjs.extend(relativeTime)

const statuses = {
  offline: 'text-gray-500 bg-gray-100/10',
  online: 'text-green-400 bg-green-400/10',
  error: 'text-rose-400 bg-rose-400/10',
}

const environments = {
  Preview: 'text-zinc-400 bg-zinc-400/10 ring-zinc-400/20',
  Production: 'text-secondary bg-secondary/10 ring-secondary/30',
}

const EntityCard = ({ item, Link = () => null }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  return (
    <li key={item.id} className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-800/50">
      <div className="min-w-0 flex-auto">
        <div className="flex items-center gap-x-3">
          <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
          <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
            <Link href={item.href} className="flex gap-x-2">
              <span className="truncate">{item.name}</span>
              <span className="absolute inset-0" />
            </Link>
          </h2>
        </div>
        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          {/*<p className="truncate uppercase text-2xs">{item.role}</p>*/}
          {/*<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">*/}
          {/*  <circle cx={1} cy={1} r={1} />*/}
          {/*</svg>*/}
          <p className="whitespace-nowrap">{`Updated ${dayjs(item.updatedAt).fromNow()}`}</p>
        </div>
      </div>
      <div
        className={classNames(
          environments[item.environment],
          'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset capitalize'
        )}
      >
        {item.role}
      </div>
      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
    </li>
  )
}

export default EntityCard;
