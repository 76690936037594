import { useEventStore } from '@/store/useEventStore';
import { useRootStore } from '@/store/useRootStore';
import { webAxiosInstance } from '@/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { eventQueryOptions } from '@/data/organization/queries';
import { AutoForward, Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@repo/ui/carousel';
import { cn } from '@/utils/cn';
import { Route } from '@/routes/__root';
import { Timer } from '@/components/Timer/Timer';

const ELEMENTS_PER_PAGE = 8;

const IndividualTimer = ({ timer, isOnTimerPage = false }) => {
  const containerRef = useRef(null);
  const timerDigitsRef = useRef(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      if (!containerRef.current || !timerDigitsRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const timerWidth = timerDigitsRef.current.offsetWidth;
      const timerHeight = timerDigitsRef.current.offsetHeight;

      const scaleX = containerWidth / timerWidth;
      const scaleY = containerHeight / timerHeight;
      const newScale = Math.min(scaleX, scaleY) * 0.8;

      setScale(newScale);
    };

    updateScale();
    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [containerRef.current, timerDigitsRef.current]);

  return (
    <div ref={containerRef} className='relative text-base border border-zinc-100/10 rounded-lg p-4 w-full h-full flex flex-col items-center justify-center'>
      <div ref={timerDigitsRef} className='flex flex-col items-center justify-center absolute top-1/2 left-1/2' style={{ transform: `translate(-50%, -50%) scale(${scale})` }}>
        <div className='text-center text-lg'>
          {timer.name} - Pod: {timer.pod}
        </div>
        <Timer
          duration={timer.duration}
          endTime={timer.endTime}
          startTime={timer.startTime}
          editable={false}
          className="flex flex-col items-center"
          showZeroHours={false}
          timerPage={true}
        />
      </div>
    </div>
  );
};

export const TimerPage = () => {
  const { setDefault, setShouldSetDefaultEventState, shouldSetDefaultEventState } = useEventStore();

  const { entityId, eventId } = Route.useParams();

  const { data: eventData } = useQuery({
    ...eventQueryOptions(eventId),
    refetchInterval: 5000,
  });

  const activity = eventData?.data?.activities?.[0];
  const { data: activityData } = useQuery({
    queryKey: [activity?.id, 'activity'],
    queryFn: async () => {
      if (!activity?.id || !activity?.gameId) return null;

      try {
        const res = await webAxiosInstance.get(`/api/play/activities/${activity.id}`, {
          headers: {
            'Game-Id': activity.gameId,
          },
        });

        if (shouldSetDefaultEventState) {
          setDefault(res?.data?.data, eventData?.data);
        } else {
          setShouldSetDefaultEventState(true);
        }
        return res?.data?.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    enabled: Boolean(activity?.id && activity?.gameId),
    refetchInterval: 5000,
  });

  const timers = _.reduce(activityData?.phases, (timers, aps) => {
    if (aps?.[0]?.status !== 'inProgress') {
      return timers;
    }
    // if (['draft', 'deckbuilding'].includes(aps?.[0]?.activityPhaseType)) {
    timers.push({
      name: aps?.[0].name,
      stage: aps?.[0].stage,
      activityPhaseType: aps?.[0].activityPhaseType,
      duration: aps?.[0].duration || 0,
      startTime: aps?.[0].startTime,
      endTime: aps?.[0].endTime
    })
    // }
    // if (aps?.[0]?.activityPhaseType === 'tournament') {
    //   for (const ap of aps) {
    //     const currentRound = ap.tournament.rounds.find(tr => {
    //       return tr.roundNumber === ap.tournament.currentRound
    //     })
    //     timers.push({
    //       name: ap.name,
    //       stage: ap.stage,
    //       pod: ap.pod,
    //       activityPhaseType: ap.activityPhaseType,
    //       roundNumber: currentRound.roundNumber,
    //       duration: currentRound.duration || 0,
    //       startTime: currentRound.startTime,
    //       endTime: currentRound.endTime,
    //     })
    //   }
    // }

    return [...timers]
  }, [])

  const hideHeader = true;

  return <div className='flex flex-col gap-4 mt-24'>
    <div className='flex gap-2'>
      {!hideHeader && <div className='ml-2'>
        <Link href={`/entity/${entityId}/events/${eventId}`}> Back to Event</Link>
      </div>}
      <div className='flex-1 text-center text-5xl'>
        {eventData?.data?.name} - {activity?.name} - Stage: {timers?.[0]?.stage}
      </div>
    </div>
    <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)]'>
      <div className={cn('p-8 w-full h-full', {
        'flex items-center justify-center': timers.length === 1,
      })}>
        {timers.length > 1 ? (
          <Carousel className='w-full h-full' opts={{ loop: true, duration: 100 }}>
            <AutoForward intervalTime={5000} />
            <CarouselContent className='w-full h-full'>
              {timers.length > (ELEMENTS_PER_PAGE * 2) ? _.chunk(timers, ELEMENTS_PER_PAGE).map((timerGroup, groupIndex) => (
                <CarouselItem className='w-full h-full' key={groupIndex}>
                  <div className='grid grid-cols-4 grid-rows-2 gap-4 auto-rows-fr w-full h-full'>
                    {timerGroup.map((timer) => (
                      <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
                    ))}
                  </div>
                </CarouselItem>
              )) : (
                timers.map((timer) => {
                  return <CarouselItem key={timer.name}>
                    <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
                  </CarouselItem>
                })
              )}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        ) : (
          timers.map((timer) => {
            return <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
          })
        )}
      </div>
    </div>
  </div>
}
