import { createFileRoute } from '@tanstack/react-router'
import GamesToolPage from '@/_pages_/auth/Entity/Store/Tools/Games'
import Loading from '@/components/Loading'

export const Route = createFileRoute('/_authenticated/entity/$entityId/games/')(
  {
    pendingComponent: () => <Loading />,
    component: () => <GamesToolPage />,
  },
)
