import { useState } from "react";
import { RiArrowRightLine } from "@remixicon/react";
import { CheckCheck } from "lucide-react";
import { Button } from "@repo/ui/button";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@cardeio/ui/components/ui/accordion";
import SkipToNextPhaseModal from "@/components/Modal/Event/Organize/SkipToNextPhaseModal";
import FinalizeActivityModal from "@/components/Modal/Event/Organize/FinalizeActivityModal";
import { useEventStore } from "@/store/useEventStore";
import DraftTable from "./DraftTable";
import { finalizePreviewMutationFn } from "@repo/api/base";
import { useMutation } from "@tanstack/react-query";
import { webAxiosInstance } from "@/utils/axios";
import { queryClient } from "@/lib/queryClient";
import PodFilter from "../../../PodFilter";
import { Input } from "@repo/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/select";
import PairingCountdownTimer from "./PairingCountdownTimer";
const NonTournamentPairing = ({
  playerRegistrations,
  filters,
  setFilters,
  searchInput,
  setSearchInput,
}) => {
  const {
    activePhaseName,
    allPhases,
    currentTournament,
    activePhases,
    activeStage,
    nextPhase,
  } = useEventStore();

  const [isSkipToNextPhaseModalOpen, setIsSkipToNextPhaseModalOpen] =
    useState(false);
  const [isFinalizeActivityModalOpen, setIsFinalizeActivityModalOpen] =
    useState(false);
  const [expandedPods, setExpandedPods] = useState(["pod-1"]);
  const [activeFilterPodId, setActiveFilterPodId] = useState("all");

  const showRecommendedFinalizeActivity =
    allPhases?.length === activeStage &&
    currentTournament?.currentRound === currentTournament?.originalRounds;

  const finalizePreviewMutation = useMutation({
    mutationFn: finalizePreviewMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activity.id, "activity", "pairings"],
      });

      toast("Successfully Approved Preview!");
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const approvePreviewClick = (podNumber) => {
    const podNumberAsInt = Number(podNumber);
    const id = activePhases?.find((phase) => phase?.pod === podNumberAsInt)?.id;
    finalizePreviewMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityPhaseId: id,
    });
  };

  // Group registrations by activity phase and pod, and sort by seed
  const registrationsByPhase = playerRegistrations?.reduce(
    (acc, registration) => {
      // We only need the first phase user since that's what contains the pod info
      const phaseUser =
        registration.activityRegistrations[0].activityPhaseUsers?.[0];
      if (!phaseUser) return acc;

      const phase = activePhases?.find(
        (p) => p.id === phaseUser.activityPhaseId
      );
      if (!phase) return acc;

      const podNumber = phase.pod;
      if (!acc[podNumber]) {
        const podPhase = activePhases.find((p) => p.pod === Number(podNumber));
        acc[podNumber] = {
          podSize: podPhase.configuration?.podSize || 8,
          players: [],
          startingTableNumber: podPhase.configuration?.startingTableNumber || 1,
          id: podPhase?.id,
          startTime: podPhase?.startTime,
          endTime: podPhase?.endTime,
          duration: podPhase?.duration,
        };
      }
      acc[podNumber].players.push({
        displayName:
          registration.displayName ||
          registration.gameUser?.displayName ||
          "Empty Seat",
        seed: phaseUser.seed || Infinity,
        userId: registration.userId,
        phaseId: phaseUser.activityPhaseId,
        phaseUserId: phaseUser.id,
      });
      return acc;
    },
    {}
  );

  // Sort players by seed within each pod
  Object.values(registrationsByPhase || {}).forEach((pod) => {
    pod.players.sort((a, b) => a.seed - b.seed);
  });

  // Filter registrations based on selected pod
  const filteredRegistrationsByPhase = Object.fromEntries(
    Object.entries(registrationsByPhase || {})
      .filter(([podNumber]) => {
        const phase = activePhases.find(
          (phase) => phase.pod === Number(podNumber)
        );
        return phase?.id === activeFilterPodId || activeFilterPodId === "all";
      })
      .filter(([_, pod]) => {
        return (
          pod.players.some((player) =>
            player.displayName
              .toLowerCase()
              .includes((searchInput || "").toLowerCase())
          ) || !searchInput
        );
      })
      .filter(([_, pod]) => {
        return pod.players.some((player) =>
          player?.resultSubmitted && filters?.resultSubmitted
            ? player.resultSubmitted === filters.resultSubmitted
            : true
        );
      })
  );

  if (
    activePhases.some((phase) => phase?.status === "complete") ||
    activePhases.some((phase) => phase?.status === "preview") ||
    activePhases.some((phase) => phase?.status === "inProgress")
  ) {
    return (
      <div
        className={`bg-zinc-950/90 flex flex-col items-center justify-center h-full w-full rounded-lg ${
          activePhases.some((phase) => phase?.status === "preview")
            ? "border-2 border-dashed border-zinc-700"
            : ""
        }`}
      >
        {activePhases.some((phase) =>
          ["preview", "inProgress"].includes(phase?.status)
        ) && (
          <>
            {activePhases.some(
              (phase) => phase?.activityPhaseType === "draft"
            ) && (
              <div className="w-full max-w-4xl mx-auto">
                <div className="flex justify-between items-center mb-4 p-2">
                  <div className="flex flex-row flex-wrap gap-4 p-2 justify-start mt-2">
                    <PodFilter
                      activeFilterPodId={activeFilterPodId}
                      setActiveFilterPodId={setActiveFilterPodId}
                      activePhases={activePhases}
                    />
                    <div>
                      <p className="text-sm mb-1">Find User</p>
                      <Input
                        onChange={(e) => {
                          setFilters({ ...filters, page: 1 });
                          setSearchInput(e.target.value);
                        }}
                        placeholder="Search by Name..."
                        className="w-[250px] h-9 rounded-md border border-white bg-transparent px-3 py-2 text-sm shadow-sm"
                        value={searchInput}
                      />
                    </div>
                    <div>
                      <p className="text-sm mb-1">Pairing Status</p>
                      <Select
                        defaultValue={"all"}
                        onValueChange={(value) => {
                          setFilters({
                            page: 1,
                            resultSubmitted:
                              value === "all" ? null : value === "submitted",
                          });
                        }}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select a Pairing Status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectTextItem value="all">
                              Show All
                            </SelectTextItem>
                            <SelectTextItem value="remaining">
                              Show Not Submitted
                            </SelectTextItem>
                            <SelectTextItem value="submitted">
                              Show Submitted
                            </SelectTextItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
                <Accordion
                  type="multiple"
                  defaultValue={["pod-1"]}
                  value={expandedPods}
                  onValueChange={setExpandedPods}
                  className="w-full"
                >
                  {Object.entries(filteredRegistrationsByPhase || {}).map(
                    ([podNumber, podData]) => (
                      <AccordionItem
                        key={`pod-${podNumber}`}
                        value={`pod-${podNumber}`}
                        className="border-b border-zinc-700"
                      >
                        <AccordionTrigger className="px-4 hover:no-underline">
                          <div className="flex gap-6 justify-start items-center">
                            <span className="text-lg font-medium text-orange-300">
                              Pod {podNumber}
                            </span>
                            <span className="text-sm text-white">
                              {podData.podSize || 0} players
                            </span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-2 space-y-1">
                            <div className="flex justify-end pt-1">
                              {activePhases.find(
                                (phase) => phase?.pod === Number(podNumber)
                              )?.status === "inProgress" ? (
                                <span className="text-white/90 px-3 py-1 rounded-full border border-white/20 flex items-center gap-2">
                                  <CheckCheck className="w-4 h-4" />
                                  Published
                                </span>
                              ) : (
                                <Button
                                  onClick={() => approvePreviewClick(podNumber)}
                                  loading={finalizePreviewMutation.isPending}
                                  variant="gradient-outlined"
                                  size="sm"
                                  rounded
                                >
                                  <span>Publish Pod {podNumber} Seating</span>
                                </Button>
                              )}
                            </div>
                            <div className="flex flex-col items-start justify-start gap-4">
                              {activePhases.find(
                                (phase) => phase?.pod === Number(podNumber)
                              )?.status === "inProgress" ? (
                                <PairingCountdownTimer
                                  id={podData.id}
                                  podData={podData}
                                  type="activityPhase"
                                />
                              ) : null}
                            </div>
                            <DraftTable
                              pod={{
                                // ...activePhasePod,
                                configuration: { podSize: podData.podSize },
                                players: podData.players,
                                pod: Number(podNumber),
                                startingTableNumber:
                                  podData.startingTableNumber,
                              }}
                            />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    )
                  )}
                </Accordion>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
  const SkipToNextPhaseModalContainer = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Modal
          open={isSkipToNextPhaseModalOpen}
          onOpenChange={setIsSkipToNextPhaseModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              className="bg-zinc-950/70 flex flex-col items-center justify-center border-2 rounded-lg py-[48px]"
              variant="text"
            >
              {activePhaseName && nextPhase?.name ? (
                <h2 className="text-xl font-bold uppercase tracking-[2px]">
                  Advance from {activePhaseName} to {nextPhase?.name}
                </h2>
              ) : (
                <h2 className="text-xl font-bold uppercase tracking-[2px]">
                  Advance to Next Phase
                </h2>
              )}
              <RiArrowRightLine className="w-8 h-8" />
            </Button>
          </ModalTrigger>
          <SkipToNextPhaseModal
            handleClose={() => setIsSkipToNextPhaseModalOpen(false)}
          />
        </Modal>
      </div>
    );
  };

  const FinalizeActivityModalContainer = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Modal
          open={isFinalizeActivityModalOpen}
          onOpenChange={setIsFinalizeActivityModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              className="bg-zinc-950/70 flex flex-col items-center justify-center border-2 rounded-lg py-[48px]"
              variant="text"
            >
              <h2 className="text-xl font-bold uppercase tracking-[2px]">
                Finalize Event
              </h2>
              <RiArrowRightLine className="w-8 h-8" />
            </Button>
          </ModalTrigger>
          <FinalizeActivityModal
            handleClose={() => setIsFinalizeActivityModalOpen(false)}
          />
        </Modal>
      </div>
    );
  };

  return (
    <div className="w-full p-4 bg-zinc-950 rounded-lg flex items-center justify-center gap-4 mt-8 h-[300px]">
      {showRecommendedFinalizeActivity ? (
        <FinalizeActivityModalContainer />
      ) : (
        <SkipToNextPhaseModalContainer />
      )}
    </div>
  );
};

export default NonTournamentPairing;
