import React from "react";

interface CastIconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

export const CastIcon: React.FC<CastIconProps> = ({
  className,
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 12.5V9.75C6 9.02065 6.295 8.32118 6.8201 7.80546C7.3452 7.28973 8.05739 7 8.8 7H31.2C31.9426 7 32.6548 7.28973 33.1799 7.80546C33.705 8.32118 34 9.02065 34 9.75V26.25C34 26.9793 33.705 27.6788 33.1799 28.1945C32.6548 28.7103 31.9426 29 31.2 29H22.8M6 18C8.86633 18.2987 11.5417 19.5537 13.5799 21.5555C15.6181 23.5572 16.8958 26.1849 17.2 29M6 23.5C7.38972 23.7542 8.66851 24.4157 9.66808 25.3974C10.6677 26.3791 11.3412 27.6351 11.6 29M6 29H6.014"
        stroke="#FFF7ED"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4355 34V35.2715H32.1533V41.56H30.7281V35.2715H28.4355V34H34.4355Z"
        fill="#FAFAFA"
      />
      <path
        d="M24.3516 34.0117C25.0302 34.0117 25.6259 34.1286 26.1386 34.3623C26.6513 34.5961 27.0887 34.9429 27.4506 35.4029L26.5797 36.4095C26.2706 36.0174 25.9313 35.7309 25.5618 35.5499C25.1999 35.3614 24.7776 35.2671 24.2951 35.2671C23.9784 35.2671 23.7145 35.3086 23.5034 35.3916C23.2998 35.4745 23.149 35.5838 23.0509 35.7195C22.9605 35.8553 22.9152 36.0061 22.9152 36.172C22.9152 36.3755 22.9944 36.549 23.1527 36.6922C23.3111 36.8279 23.5825 36.941 23.9671 37.0315L25.3582 37.3482C26.0971 37.5141 26.6249 37.7629 26.9416 38.0947C27.2659 38.4189 27.428 38.8411 27.428 39.3614C27.428 39.8515 27.2998 40.2738 27.0434 40.6281C26.7871 40.975 26.4289 41.2427 25.969 41.4312C25.509 41.6121 24.9812 41.7026 24.3855 41.7026C23.8653 41.7026 23.3865 41.6423 22.9492 41.5216C22.5194 41.3935 22.1348 41.22 21.7955 41.0014C21.4562 40.7827 21.1735 40.5301 20.9473 40.2436L21.8295 39.1918C22.0029 39.433 22.2178 39.6479 22.4741 39.8364C22.7305 40.0249 23.0208 40.1757 23.345 40.2888C23.6768 40.3944 24.0236 40.4472 24.3855 40.4472C24.6947 40.4472 24.9548 40.4132 25.1659 40.3454C25.3846 40.27 25.5467 40.1682 25.6523 40.04C25.7654 39.9043 25.8219 39.7422 25.8219 39.5537C25.8219 39.3576 25.7541 39.1918 25.6183 39.056C25.4902 38.9128 25.2489 38.8034 24.8945 38.728L23.3789 38.3887C22.9416 38.2832 22.5646 38.1475 22.2479 37.9816C21.9388 37.8081 21.7013 37.5895 21.5354 37.3256C21.3771 37.0541 21.2979 36.7337 21.2979 36.3642C21.2979 35.9194 21.4185 35.5197 21.6598 35.1654C21.9011 34.811 22.2479 34.532 22.7003 34.3284C23.1603 34.1173 23.7107 34.0117 24.3516 34.0117Z"
        fill="#FAFAFA"
      />
      <path
        d="M20.5735 41.5892H18.9562L18.3341 39.7683H15.4726L14.8506 41.5892H13.2559L15.9816 34.1245H17.8478L20.5735 41.5892ZM15.8233 38.6146H17.9835L16.8977 35.3573L15.8233 38.6146Z"
        fill="#FAFAFA"
      />
      <path
        d="M12.7635 39.5311C12.6202 40.0136 12.394 40.417 12.0848 40.7412C11.7757 41.0655 11.41 41.3067 10.9878 41.4651C10.5655 41.6234 10.1018 41.7026 9.59662 41.7026C8.85769 41.7026 8.21678 41.548 7.6739 41.2389C7.13855 40.9297 6.72385 40.4886 6.42978 39.9156C6.14326 39.3426 6 38.6564 6 37.8572C6 37.0579 6.14326 36.3718 6.42978 35.7987C6.72385 35.2257 7.13855 34.7846 7.6739 34.4754C8.21678 34.1663 8.85015 34.0117 9.574 34.0117C10.0867 34.0117 10.5504 34.0833 10.9651 34.2266C11.3798 34.3623 11.7305 34.5735 12.017 34.86C12.311 35.1465 12.5259 35.5084 12.6617 35.9457L11.2479 36.5452C11.1197 36.0928 10.9237 35.7723 10.6598 35.5838C10.3959 35.3878 10.0566 35.2898 9.64186 35.2898C9.23469 35.2898 8.87654 35.3878 8.5674 35.5838C8.26579 35.7799 8.03205 36.0702 7.86617 36.4547C7.70029 36.8317 7.61735 37.2992 7.61735 37.8572C7.61735 38.4076 7.69275 38.8751 7.84355 39.2596C8.00189 39.6442 8.23186 39.9345 8.53347 40.1305C8.83507 40.3265 9.20453 40.4246 9.64186 40.4246C10.049 40.4246 10.3996 40.3152 10.6937 40.0966C10.9953 39.8779 11.2064 39.5461 11.3271 39.1013L12.7635 39.5311Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
