import { cn } from "@/utils/cn";
import { Tooltip, TooltipTrigger, TooltipContent } from "@cardeio/ui/components/ui/tooltip";
import { Check } from "lucide-react";

export const GameCard = ({ game, className, children, contentClassName }) => {
  return (
    <div className="mb-10 w-60 max-w-60 h-80 text-center flex flex-col items-center justify-center">
      <div className="min-w-40 max-w-40 min-h-40 max-h-40 bg-zinc-900 flex items-center justify-center rounded-full border border-alpha/20 -mb-20 z-10">
        {game.resourceImages.length > 0 ? (
          <img
            className="min-w-40 max-w-40 min-h-40 max-h-40 object-scale-down scale-90"
            src={
              game?.resourceImages?.find(
                (image) => image.imageType === "logo"
              )?.image?.url
            }
            alt={game.name}
          />
        ) : (
          <h3>{game.name}</h3>
        )}
      </div>
      <div className={cn("rounded-lg w-full bg-alpha/20 h-full flex flex-col border border-alpha/20 pt-20 pb-5 gap-4 min-h-64", className)}>
        <div className="relative flex flex-col items-center">
          <h3 className="text-lg font-bold mt-2">{game.name}</h3>
          {game?.official && (
            <div className="flex items-center gap-1 mt-1">
              <div className="w-[7px] h-2.5 bg-gradient-to-t from-carde-orange-300 to-carde-orange-500 rounded-sm flex items-center justify-center">
                <Check className="w-1.5 h-2 text-zinc-900" strokeWidth={5} />
              </div>
              <span className="text-[9px] leading-[9px] uppercase tracking-[1.2px] font-medium bg-gradient-to-t from-carde-orange-300 to-carde-orange-500 text-transparent bg-clip-text">OFFICIAL PARTNER</span>
            </div>
          )}
        </div>
        <div className={cn("flex flex-col gap-2 items-center flex-1", contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};
