import dayjs from 'dayjs';

const convertIntervalToMomentInterval = (interval) => {
  switch (interval) {
    case 'daily':
      return 'day';
    case 'weekly':
      return 'week';
    case 'monthly':
      return 'month';
  }
};

export const createRecurringEventDates = async (startDate, endDate, interval, amount, endRecurringDate) => {
  const recurringDates = [];

  if (endRecurringDate) {
    // Calculate based on end date
    const momentInterval = convertIntervalToMomentInterval(interval);
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const recurringEnd = dayjs(endRecurringDate);
    const duration = end.diff(start, 'minute');

    let current = start.add(1, momentInterval);
    let i = 0;

    while (current.isBefore(recurringEnd) && i < 100) { // Limit to 100 occurrences for safety
      const currentEnd = current.add(duration, 'minute');
      recurringDates.push({
        startDate: current.toISOString(),
        endDate: currentEnd.toISOString()
      });

      current = current.add(1, momentInterval);
      i++;
    }
  } else {
    // Calculate based on amount
    const numberAmount = Number(amount);
    for (let i = 0; i < numberAmount; i++) {
      if (i !== 0) {
        const startDateToAdd = dayjs(startDate).add(i, convertIntervalToMomentInterval(interval));
        const endDateToAdd = dayjs(endDate).add(i, convertIntervalToMomentInterval(interval));

        recurringDates.push({
          startDate: startDateToAdd.toISOString(),
          endDate: endDateToAdd.toISOString()
        });
      }
    }
  }

  return recurringDates;
};
