import { useAuth0 } from '@auth0/auth0-react';

const useHandleSilentAuth = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSilentAuth = async (options = {}) => {
    try {
      await loginWithRedirect({
        prompt: 'none',
        ...options
      });
    } catch (error) {
      if (error.error === 'login_required' || error.error === 'consent_required') {
        await loginWithRedirect(options);
      } else {
        console.error('An error occurred during authentication', error);
      }
    }
  };

  const handleSignup = () => handleSilentAuth({ authorizationParams: { screen_hint: "signup" } });
  const handleLogin = () => handleSilentAuth();

  return { handleSignup, handleLogin };
};

export default useHandleSilentAuth;
