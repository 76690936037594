import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@cardeio/ui/components/ui/select";

const SelectSingleField = ({ data, field }) => {
  const { options, default: defaultValue } = data;
  const { onChange, value } = field;

  const handleSelectChange = (newValue) => {
    onChange(JSON.parse(newValue));
  };

  return (
    <div>
      <Select
        defaultValue={JSON.stringify(defaultValue) || ''}
        onValueChange={handleSelectChange}
        value={JSON.stringify(value) || ''}
        variant="uap"
      >
        <SelectTrigger className='bg-alpha/50 border-border'>
          <SelectValue placeholder='Please Select One' />
        </SelectTrigger>
        <SelectContent className='w-full'>
          <SelectGroup>
            {options.map((option) => (
              <SelectItem className='flex flex-col items-start justify-start' key={option.label} value={JSON.stringify(option.value)}>
                {option.label}
                {option?.description && (
                  <p className='text-muted-foreground italic ml-4'>{option.description}</p>
                )}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

export default SelectSingleField;
