import { Shell } from '@/components/Navigation/shell'
import { useParams } from '@tanstack/react-router'
import { useOrganizationApplications, useResolveStaffApplication } from '@/data/establishment/staffApplications'
import { useRootStore } from '@/store/useRootStore'
import { useAppliedGames } from '@/store/useGameStore'
import { DataTable } from '@/components/DataTable'
import { Badge } from '@cardeio/ui/components/ui/badge'
import { Checkbox } from '@cardeio/ui/components/ui/checkbox'
import { Button } from '@cardeio/ui/components/ui/button'
import { Mail, PanelLeftClose, PanelRight, X, Check, Ban } from 'lucide-react'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@cardeio/ui/components/ui/sheet'
import { useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@cardeio/ui/components/ui/select'
import { toast } from 'sonner'
import { useQueryClient } from '@tanstack/react-query'

const RoleBadge = ({ role }) => {
  const roles = {
    member: "Member",
    owner: "Owner",
    manager: "Manager",
    viewer: "Viewer",
    judge: "Judge",
    scorekeeper: "Scorekeeper",
  }

  return (
    <div className="bg-carde-gray-400 inline-flex items-center rounded-full px-3 py-1.5 text-sm whitespace-nowrap">
      <span className="text-white truncate">{roles[role] || role || 'N/A'}</span>
    </div>
  )
}

const ApplicationTypeBadge = ({ type }) => {
  const types = {
    employeeApplication: "Staff",
    gameEstablishmentApplication: "Game Establishment",
    establishmentApplication: "Establishment",
    tournamentOrganizerApplication: "Tournament Organizer",
    activityLevelApplication: "Activity Level",
  }

  return (
    <div className="bg-carde-gray-400 inline-flex items-center rounded-full px-3 py-1.5 text-sm whitespace-nowrap">
      <span className="text-white truncate">
      {types[type] || type || 'Unknown'}
      </span>
      </div>
  )
}

const ApplicationSheet = ({ open, onOpenChange, application }) => {
  const { entityId } = useParams({ strict: false })
  const [selectedGame, setSelectedGame] = useState('')
  const [approveAllGames, setApproveAllGames] = useState(false)
  const resolveApplication = useResolveStaffApplication()
  const { data: appliedGames } = useAppliedGames()
  const queryClient = useQueryClient()

  if (!application) return null

  const handleResolve = async (approved) => {
    try {
      // Format the resolution data according to the backend schema requirements
      const resolutionData = {
        applicationId: application.id,
        establishmentId: application.targetId,
        entityId,
        userId: application.sourceId,
        resolution: {
          outcome: approved ? 'approved' : 'denied',
          // For tournament organizers (members), we need to specify game permissions
          ...(application.content?.role === 'member' && approved && {
            // If approveAllGames is enabled, set allGames: true
            // Otherwise, if a specific game is selected, include it in the games array
            ...(approveAllGames 
              ? { allGames: true } 
              : { games: selectedGame ? [{ id: selectedGame, approved: true }] : [] }
            )
          })
        }
      }

      await resolveApplication.mutateAsync(resolutionData)

      toast.success(`Application ${approved ? 'approved' : 'rejected'} successfully`)
      onOpenChange(false)
      queryClient.invalidateQueries({ queryKey: ['organizationApplications'] })
    } catch (error) {
      
      toast.error('Failed to process application', {
        description: error.message
      })
    }
  }

  // Handle toggle of the "approve all games" checkbox
  const handleApproveAllGamesChange = (checked) => {
    setApproveAllGames(checked)
    // Reset selected game when enabling "approve all games"
    if (checked) {
      setSelectedGame('')
    }
  }

  // Determine if this application type needs game selection
  const needsGameSelection = application.content?.role === 'member' || application.type === 'tournamentOrganizerApplication'

  return (
    <Sheet open={open} onOpenChange={onOpenChange} side="right">
      <SheetContent className="rounded-l-2xl border-l-0 bg-carde-gray-500 w-[50vw] sm:max-w-[50%]">
        <div className="space-y-5">
          <div>
            <div className="flex items-center">
              {application.content?.role ? (
                <RoleBadge role={application.content?.role} />
              ) : (
                <ApplicationTypeBadge type={application.type} />
              )}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <h3 className="text-3xl font-semibold text-foreground">
                {application.source?.firstName} {application.source?.lastName}
              </h3>
              <div className="flex gap-2">
                <Button
                  variant="destructive"
                  size="sm"
                  className="rounded-full border border-red-900"
                  onClick={() => handleResolve(false)}
                  disabled={resolveApplication.isPending}
                >
                  <Ban className="h-4 w-4 mr-1 text-red-500" />
                  Reject
                </Button>
                <Button
                  variant="gradient"
                  size="sm"
                  className="rounded-full"
                  onClick={() => handleResolve(true)}
                  disabled={resolveApplication.isPending || (needsGameSelection && !approveAllGames && !selectedGame)}
                >
                  <Check className="h-4 w-4 mr-1" />
                  Approve
                </Button>
              </div>
            </div>
          </div>

          <div className="h-px bg-border" />

          <div className="space-y-5">
            <div className="space-y-2">
              <h4 className="font-medium text-sm text-muted-foreground">CONTACT EMAIL</h4>
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4 text-muted-foreground" />
                <span>{application.source?.email || application.metadata?.user?.email}</span>
              </div>
            </div>

            <div className="space-y-2">
              <h4 className="font-medium text-sm text-muted-foreground">PHONE</h4>
              <p>{application.source?.phone || 'Not provided'}</p>
            </div>

            {needsGameSelection && (
              <>
                <div className="flex items-center space-x-2 pt-2">
                  <Checkbox 
                    id="approve-all-games" 
                    checked={approveAllGames} 
                    onCheckedChange={handleApproveAllGamesChange}
                  />
                  <label
                    htmlFor="approve-all-games"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Approve for all games
                  </label>
                </div>

                <div className="space-y-2">
                  <h4 className="font-medium text-sm text-muted-foreground">GAME</h4>
                  <Select 
                    value={selectedGame} 
                    onValueChange={setSelectedGame} 
                    disabled={approveAllGames}
                  >
                    <SelectTrigger className={`w-full ${approveAllGames ? 'bg-alpha/30 opacity-50' : 'bg-alpha/50'}`}>
                      <SelectValue placeholder="Select a game" />
                    </SelectTrigger>
                    <SelectContent>
                      {appliedGames?.map(game => (
                        <SelectItem key={game.id} value={game.id}>
                          {game.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export function PendingApplicationsPage() {
  const { entityId, orgId } = useParams({ strict: false })
  const { userData } = useRootStore()
  const organizations = userData?.organizations ?? []
  const organization = orgId ? organizations.find(org => org.id === orgId) : organizations[0]
  const { data: applications } = useOrganizationApplications(organization?.id)
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const pendingApplications = applications?.data?.filter(app => app.status !== 'resolved') || []

  const columns = [
    {
      accessorKey: "source.firstName",
      header: "First Name",
      cell: ({ row }) => <div className="px-2">{row.original.source?.firstName || row.original.metadata?.user?.email?.split('@')[0] || 'N/A'}</div>,
      enableSorting: true,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "source.lastName",
      header: "Last Name",
      cell: ({ row }) => <div className="px-2">{row.original.source?.lastName || 'N/A'}</div>,
      enableSorting: true,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "content.role",
      header: "Applying For",
      cell: ({ row }) => (
        <div className="flex items-center">
          {row.original.content?.role ? (
          <RoleBadge role={row.original.content?.role} />
        ) : (
          <ApplicationTypeBadge type={row.original.type} />
          )}
        </div>
      ),
      enableSorting: true,
      size: 200,
    },
    {
      accessorKey: "source.email",
      header: "Contact",
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Mail className="h-4 w-4 text-muted-foreground" />
          <span>{row.original.source?.email || row.original.metadata?.user?.email || 'N/A'}</span>
        </div>
      ),
      enableSorting: true,
      enableGlobalFilter: true,
    },
    {
      id: "actions",
      header: "View",
      cell: ({ row }) => (
        <div className="flex justify-center">
          <Button
            variant="gradient"
            size="sm"
            className="rounded-md py-1 px-1.5"
            onClick={() => {
              setSelectedApplication(row.original)
              setIsSheetOpen(true)
            }}
          >
            <PanelLeftClose className="h-5 w-5" />
          </Button>
        </div>
      ),
      size: 50,
    },
  ]

  return (
    <Shell
      title="Pending Staff Applications"
      subtitle="Game masters and store staff can apply to be added to your store in order to run and manage organized events. You can approve or reject applications from here."
    >
      <div className="p-4">
        <DataTable
          columns={columns}
          data={pendingApplications}
          searchPlaceholder="Search"
          minimizable={false}
          noResultsMessage="No pending applications"
        />
        <ApplicationSheet
          open={isSheetOpen}
          onOpenChange={setIsSheetOpen}
          application={selectedApplication}
        />
      </div>
    </Shell>
  )
}
