import React from "react";
import { Form } from "@repo/ui/form";
import ActivityConfigContainer from "./ConfigContainer";
import PrizingDetailsSection from "./PrizingDetails";
import EventBannerSelectionSection from "./EventBannerSelection";

const Step3 = ({ activeStep, form }) => {
  const stepNumber = 3;
  const isActive = activeStep === stepNumber;

  return (
    <div className={`${isActive ? 'block animate-slideInFromBottom' : 'hidden'} space-y-6`}>
      <Form {...form}>
        <EventBannerSelectionSection form={form} />
        <PrizingDetailsSection form={form} />
      </Form>
    </div>
  );
};

export default Step3;
