import { useEventStore } from "@/store/useEventStore";
import { Select, SelectContent, SelectTextItem, SelectTrigger, SelectValue } from "@repo/ui/select";
import { useEffect } from "react";


const RoundSwitcher = () => {
  const { allPhases, readOnlyRoundId, setReadOnlyRoundId, currentTournamentRound } = useEventStore();
  const rounds = allPhases?.[0]?.tournament?.rounds;
  useEffect(() => {
    if (currentTournamentRound?.id) {
      setReadOnlyRoundId(currentTournamentRound?.id);
    }
  }, [currentTournamentRound?.id]);
  
  if(!rounds?.length) return null
  return (
    <div className="flex flex-col gap-2 mt-4">
      <label className="text-sm font-medium">View Round History</label>
      <Select
        value={readOnlyRoundId}
        defaultValue={currentTournamentRound?.id}
        onValueChange={(value) => {
        setReadOnlyRoundId(value);
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select a round" />
      </SelectTrigger>
      <SelectContent>
        {rounds?.map((round) => (
            <SelectTextItem key={round.id} value={round.id}>
              Round {round.roundNumber} {round.id === currentTournamentRound?.id ? "(Current)" : "(View Only)"}
            </SelectTextItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default RoundSwitcher;