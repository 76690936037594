import { createFileRoute } from '@tanstack/react-router'
import SingleEventPage from '@/_pages_/auth/Entity/Store/Tools/Events/Single'

import Loading from '@/components/Loading'
import { queryClient } from '@/lib/queryClient'
import { eventQueryOptions } from '@/data/organization/queries'

export const Route = createFileRoute(
  '/_authenticated/entity/$entityId/events/$eventId/',
)({
  loader: async ({ params: { eventId } }) => {
    const event = await queryClient.ensureQueryData(eventQueryOptions(eventId))
    return { event }
  },
  component: () => <ManageEventPage />,
  pendingComponent: () => <Loading fullScreen sidebar />,
})

const ManageEventPage = () => {
  return <SingleEventPage />
}
