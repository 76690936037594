import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { EllipsisVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";

dayjs.extend(relativeTime)

const statuses = {
  active: 'text-green-400 bg-green-400/10',
  disabled: 'text-rose-400 bg-rose-400/10',
}

const environments = {
  Preview: 'text-zinc-400 bg-zinc-400/10 ring-zinc-400/20',
  Production: 'text-secondary bg-secondary/10 ring-secondary/30',
}

const MemberCard = ({ item }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  return (
    <li key={item.id} className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-800/50">
      <div className="min-w-0 flex-auto">
        <div className="flex items-center gap-x-3">
          <div className={classNames(statuses[item?.disabled ? 'disabled' : 'active'], 'flex-none rounded-full p-1')}>
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
          <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
            <div className="flex gap-x-2">
              <span className="truncate">{item?.user?.firstName} {item?.user?.lastName}</span>
            </div>
          </h2>
        </div>
        <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          <p className="whitespace-nowrap">{`Updated ${dayjs(item.updatedAt).fromNow()}`}</p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="truncate uppercase text-2xs">{item?.user?.email}</p>

        </div>
      </div>
      <div
        className={classNames(
          environments.Production,
          'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset capitalize'
        )}
      >
        {item.role}
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <button className='pointer rounded-3xl p-2 hover:bg-zinc-200/10'>
            <EllipsisVerticalIcon className="h-5 w-5 flex-none text-zinc-200" aria-hidden="true" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="bg-zinc-950 border-zinc-200/10 w-fit" align='end'>
          <div className='flex flex-col gap-2'>
            <button className='p-4 hover:bg-zinc-900'>
              <p className='text-zinc-100'>
                Edit Member
              </p>
            </button>
            <button className='py-2 px-4 hover:bg-zinc-900'>
              <div className='flex items-center justify-center'>
                <ExclamationCircleIcon className="h-4 w-4 text-red-600 mr-1" aria-hidden="true" />
                <p className='text-sm text-red-600'>
                  Remove
                </p>
              </div>
            </button>
          </div>
        </PopoverContent>
      </Popover>
    </li>
  )
}

export default MemberCard;
