import { useState, useEffect } from "react";
import { Timer } from "@/components/Timer/Timer";
import { CirclePauseIcon, CirclePlayIcon, CircleX } from "lucide-react";
import { AddFiveIcon } from "@/icons/AddFive";
import { CastIcon } from "@/icons/CastIcon";
import { webAxiosInstance } from "@/utils/axios";
import { toast } from "sonner";
import { Link } from "@tanstack/react-router";
import { useTimerMutations } from "../mutations";
import { useEventStore } from "@/store/useEventStore";
import { ConfirmationDialog } from "@/components/ConfirmationDialog";
import { FutureTimeSelector } from "@/components/FutureTimeSelector";
import { useTimer } from "@/hooks/useTimer";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { useConfirmDialogStore } from "@/store/useConfirmDialogStore";

const PairingCountdownTimer = ({
  id = null,
  podData = null,
  type = "tournamentRound",
}) => {
  const { currentTournamentRound, activity, refetch } = useEventStore();
  const { isOpen, title, description, confirmText, onConfirm } =
    useConfirmDialogStore();

  const handleStopTimer = () => {
    useConfirmDialogStore.open({
      title: "Are you sure?",
      description: `Do you want to end the timer for ${timerData?.name || "this phase"}? This will reset any remaining time left in the current activity.`,
      onConfirm: handleStopConfirm,
      confirmText: "End Timer",
    });
  };

  const handleAddTime = () => {
    useConfirmDialogStore.open({
      title: "Are you sure?",
      description:
        "This will add an additional 5 minutes to the activity timer.",
      onConfirm: handleAddTimeConfirm,
      confirmText: "Add time",
    });
  };

  const {
    startTimerMutation,
    pauseTimerMutation,
    stopTimerMutation,
    updateTimerMutation,
  } = useTimerMutations();

  const timerData = podData ? podData : currentTournamentRound;

  const { isNegative } = useTimer({
    startTime: timerData?.startTime,
    endTime: timerData?.endTime,
    duration: timerData?.duration,
  });

  const [total, setTotal] = useState(timerData?.duration || 0);
  const [localTimerValues, setLocalTimerValues] = useState({
    startTime: timerData?.startTime,
    endTime: timerData?.endTime,
    duration: timerData?.duration,
  });

  const resetLocalTimer = () => {
    setLocalTimerValues({
      startTime: null,
      endTime: null,
      duration: 0,
    });
    setTotal(0);
  };

  const isCountingDownToFuture =
    localTimerValues.startTime && !localTimerValues.endTime;

  const startOrResumeTimer = () => {
    if (!localTimerValues.duration && total <= 0) {
      toast.error("Please set a duration for the timer.");
      return;
    }

    startTimerMutation.mutate(
      {
        clientAxiosInstance: webAxiosInstance,
        id: id,
        type: type,
        total: localTimerValues.duration || total,
      },
      {
        onSuccess: (data) => {
          setLocalTimerValues((prev) => ({
            ...prev,
            endTime: data.endTime,
            startTime: data.startTime,
          }));
          refetch();
        },
      }
    );
  };

  const handleTimeUpdate = (newTotal) => {
    setTotal(newTotal);
    setLocalTimerValues((prev) => ({
      ...prev,
      duration: newTotal,
    }));
    updateTimerMutation.mutate(
      {
        clientAxiosInstance: webAxiosInstance,
        id: id,
        type: type,
        total: newTotal,
        start: false,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleStopConfirm = (dontShowAgain) => {
    stopTimerMutation.mutate(
      {
        clientAxiosInstance: webAxiosInstance,
        id: id,
        type: type,
        dontShowAgain,
      },
      {
        onSuccess: () => {
          resetLocalTimer();
          refetch();
        },
      }
    );
  };

  const handleAddTimeConfirm = (dontShowAgain) => {
    handleTimeUpdate(total + 300);
  };

  const handleSetFutureTime = (startTime) => {
    setLocalTimerValues((prev) => ({
      ...prev,
      startTime,
    }));
    updateTimerMutation.mutate(
      {
        clientAxiosInstance: webAxiosInstance,
        id: id,
        type: type,
        total: localTimerValues.duration || total,
        startTime,
        start: false,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleClearFutureTime = () => {
    setLocalTimerValues((prev) => ({
      ...prev,
      startTime: null,
    }));
    updateTimerMutation.mutate(
      {
        clientAxiosInstance: webAxiosInstance,
        id: id,
        type: type,
        total: localTimerValues.duration || total,
        startTime: null,
        start: false,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  // Update local state when timerData changes
  useEffect(() => {
    setLocalTimerValues({
      startTime: timerData?.startTime,
      endTime: timerData?.endTime,
      duration: timerData?.duration,
    });
    setTotal(timerData?.duration || 0);
  }, [timerData]);

  return (
    <>
      <div className="flex items-center gap-1 flex-wrap">
        <Tooltip delayDuration={200}>
          <TooltipTrigger className="h-full">
            <Link
              to={`${window.location.pathname}/activity/${activity?.id}/timer`}
              target="_blank"
            >
              <CastIcon />
            </Link>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-sm">View Full Timer Page</p>
          </TooltipContent>
        </Tooltip>
        <div
          className={`flex flex-col gap-2 items-center justify-center border-2 border-[#CC045A] bg-[#4E0122] p-2.5 py-1 rounded-lg ${isNegative ? "bg-[#7F1D1D]/30 border-[#7F1D1D]" : ""} ${isCountingDownToFuture ? "bg-[#AD3B23]/50 border-[#F67519]" : ""}`}
        >
          <Timer
            duration={localTimerValues.duration}
            endTime={localTimerValues.endTime}
            startTime={localTimerValues.startTime}
            onTimeUpdate={handleTimeUpdate}
            editable={true}
          />
        </div>

        <Tooltip delayDuration={200}>
          <TooltipTrigger className="h-full">
            <FutureTimeSelector
              disabled={
                !localTimerValues.duration ||
                localTimerValues.endTime ||
                isNegative
              }
              hasStartTime={!!localTimerValues.startTime}
              onSelectTime={handleSetFutureTime}
              onClearTime={handleClearFutureTime}
            />
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-sm">Set Future Time</p>
          </TooltipContent>
        </Tooltip>

        {!localTimerValues.endTime && !localTimerValues.startTime && (
          <Tooltip delayDuration={200}>
            <TooltipTrigger className="h-full">
              <button
                onClick={startOrResumeTimer}
                disabled={
                  startTimerMutation.isPending || !localTimerValues.duration
                }
                className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
              >
                <CirclePlayIcon width={40} height={40} strokeWidth={1.25} />
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-sm">Start Timer</p>
            </TooltipContent>
          </Tooltip>
        )}

        {localTimerValues.endTime && (
          <Tooltip delayDuration={200}>
            <TooltipTrigger className="h-full">
              <button
                onClick={() =>
                  pauseTimerMutation.mutate(
                    {
                      clientAxiosInstance: webAxiosInstance,
                      id: id,
                      type: type,
                    },
                    {
                      onSuccess: () => {
                        setLocalTimerValues((prev) => ({
                          ...prev,
                          endTime: null,
                        }));
                        refetch();
                      },
                    }
                  )
                }
                disabled={
                  pauseTimerMutation.isPending || !localTimerValues.endTime
                }
                className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
              >
                <CirclePauseIcon width={40} height={40} strokeWidth={1.25} />
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-sm">Pause Timer</p>
            </TooltipContent>
          </Tooltip>
        )}

        <Tooltip delayDuration={200}>
          <TooltipTrigger className="h-full">
            <button
              disabled={
                updateTimerMutation.isPending ||
                localTimerValues.endTime ||
                isCountingDownToFuture
              }
              onClick={handleAddTime}
              className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
            >
              <AddFiveIcon />
            </button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-sm">Add 5 Minutes</p>
          </TooltipContent>
        </Tooltip>

        <Tooltip delayDuration={200}>
          <TooltipTrigger className="h-full">
            <button
              onClick={handleStopTimer}
              disabled={
                stopTimerMutation.isPending ||
                (!localTimerValues.endTime && !localTimerValues.duration)
              }
              className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
            >
              <CircleX width={40} height={40} strokeWidth={1.25} />
            </button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-sm">Stop Timer</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </>
  );
};

export default PairingCountdownTimer;
