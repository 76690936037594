import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { webAxiosInstance } from "../utils/axios";
import { queryClient } from "../lib/queryClient";
import { useNavigate } from "@tanstack/react-router";
import { useRootStore } from "../store/useRootStore";

export const useUpdateUserProfile = () => {
  const navigate = useNavigate();
  const setNewUserData = useRootStore((state) => state.setNewUserData);

  return useMutation({
    mutationFn: (userData) => {
      return webAxiosInstance.put("/api/core/users/", userData);
    },
    onSuccess: async (data, variables) => {
      setNewUserData(data.data.data);
      await queryClient.invalidateQueries({ queryKey: ["userData"] });
      //force refetch so RedirectionRoot can redirect to the correct page based on userData
      await queryClient.refetchQueries({ queryKey: ["userData"] });

      toast.success("Profile Updated!", {
        description: "Your profile information has been successfully updated.",
      });

      if (variables.role !== "owner") {
        navigate({ to: "/", replace: true });
        return;
      }
      //if the user is an owner, we need to redirect to the store application flow
      navigate({ to: "/", replace: true });

    },
    onError: (error) => {
      toast.error("Update Failed", {
        description:
          error.response?.data?.message ||
          "There was an error updating your profile.",
      });
    },
  });
};
