type disableTicketPriceMutationFnParams = {
  clientAxiosInstance: any;
  ticketPriceId: any;
};

export const disableTicketPriceMutationFn = async ({
  clientAxiosInstance,
  ticketPriceId,
}: disableTicketPriceMutationFnParams) => {
  return clientAxiosInstance.delete(
    `/api/organize/ticketPrices/${ticketPriceId}`,
    { data: {} },
  );
};
