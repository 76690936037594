import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@cardeio/ui/components/ui/card'
import { StoreGames } from './StoreGames'
import { AppliedGames } from './AppliedGames'
import { Input } from '@cardeio/ui/components/ui/input'
import { SearchIcon } from 'lucide-react'
import { useState } from 'react'
import { Shell } from '../../../../components/Navigation/shell'

export const GameDirectory = () => {
  const [searchStoreGames, setSearchStoreGames] = useState('')
  const [searchAppliedGames, setSearchAppliedGames] = useState('')
  return (
    <Shell
      title="Game Directory"
      subtitle="These are games your store distibutes or hosts events for. To add more games to your store, apply for access from here."
    >
      <Card>
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl">Your Store's Games</CardTitle>
            <CardDescription>These are games your store carries or hosts events for.</CardDescription>
          </div>
          <div className="relative min-w-64 ml-2">
            <Input variant="uap" placeholder="Search" className="pl-8" value={searchStoreGames} onChange={(e) => setSearchStoreGames(e.target.value)} />
            <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          </div>
        </CardHeader>
        <CardContent>
          <StoreGames search={searchStoreGames} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl">Apply for More Games</CardTitle>
            <CardDescription>Adding a game to your store requires approval from the game publishers. We will display a status here to track the progress of your application.</CardDescription>
          </div>
          <div className="relative min-w-64 ml-2">
            <Input variant="uap" placeholder="Search" className="pl-8" value={searchAppliedGames} onChange={(e) => setSearchAppliedGames(e.target.value)} />
            <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          </div>
        </CardHeader>
        <CardContent>
          <AppliedGames search={searchAppliedGames} />
        </CardContent>
      </Card>
    </Shell>
  )
}
