import PrintoutBanner from "./Printout";

const MarketingBanners = ({ activity, establishment, event, game }) => {
  return (
    <div className="bg-zinc-100/10 rounded p-4 w-full">
      <div className="flex items-center gap-2">
        <h2 className="text-2xl font-bold">Event Banners</h2>
      </div>

      <div className="flex mt-4 justify-center">
        <PrintoutBanner
          activity={activity}
          customization={activity?.customization?.paperVertical}
          establishment={establishment}
          event={event}
          game={game}
          height={605}
          width={468}
        />
      </div>
    </div>
  );
};

export default MarketingBanners;
