import React from "react";

interface InformationIconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

export const InformationIcon: React.FC<InformationIconProps> = ({
  className,
  width = 100,
  height = 100,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="100" height="100" rx="50" fill="#F67519" fillOpacity="0.5" />
      <circle cx="50" cy="50" r="42" fill="#FF9331" fillOpacity="0.5" />
      <circle cx="50" cy="50" r="32" fill="#FF9331" fillOpacity="0.5" />
      <path
        d="M44.8332 29.3333V39.6666M24.1665 39.6666H75.8332M34.4998 29.3333V39.6666M29.3332 29.3333H70.6665C73.52 29.3333 75.8332 31.6464 75.8332 34.4999V65.4999C75.8332 68.3534 73.52 70.6666 70.6665 70.6666H29.3332C26.4797 70.6666 24.1665 68.3534 24.1665 65.4999V34.4999C24.1665 31.6464 26.4797 29.3333 29.3332 29.3333Z"
        stroke="#FFD4AD"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6123 62.2639L49.6123 53.2639"
        stroke="#FFD4AD"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M49.6123 47.6938L49.6123 47.6751"
        stroke="#FFD4AD"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
