import React from "react";
import { StepNavigation } from "@cardeio/ui/components/step-navigation";
import { Button } from "@cardeio/ui/components/ui/button";
import { ClipboardPen, CircleArrowRight } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@cardeio/ui/components/ui/dropdown-menu";
import { ShellTitle } from "@/components/Navigation/shell";

const EventHeader = ({
  activeStep,
  activityDisplay,
  selectedExternalSite,
  selectedGame,
  setStep,
  values = {},
  onContinue,
  isContinueDisabled,
  continueLoading,
  continueText,
  title,
  saveText,
  saveOnClick,
  saveLoading,
  entityId,
}) => {
  const navigate = useNavigate();
  const steps = [
    {
      number: 1,
      title: "Select a Game",
      question: "What Game Are You Creating an Event For?",
      answer: (
        <div className='flex items-center gap-4'>{selectedGame?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url ? (<img src={selectedGame?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url} className='h-6' />) : (<div>{selectedGame?.name}</div>)} </div>
      ),
      isLocked: true,
    },
    {
      number: 2,
      title: "Event Details",
      question: "What are the basic details of your event?",
      isLocked: false,
    },
    {
      number: 3,
      title: "Activity Setup",
      question: "What type of activity is this event?",
      answer: activityDisplay,
      isLocked: false,
    },
    {
      number: 4,
      title: "Review Event",
      isLocked: false,
    }
  ];

  // Format steps for StepNavigation
  const stepNavigationSteps = steps.map(step => ({
    id: step.number.toString(),
    name: step.title,
    stepNumber: step.number,
    status: activeStep === step.number
      ? "IN_PROGRESS"
      : activeStep > step.number
        ? "COMPLETE"
        : "UPCOMING"
  }));

  const handleStepClick = (step) => {
    const stepNumber = parseInt(step.id);
    if (stepNumber === 1 && entityId) {
      navigate({ to: `/entity/${entityId}/events/create` });
    } else {
      setStep(stepNumber);
    }
  };

  return (
    <div className="flex flex-col w-full mb-8 sticky top-[0px] z-70 bg-background -mt-10 pt-10">
      <div className="mb-6">
        <ShellTitle>{title || `Create a New ${selectedGame?.name || "[Game_Title]"} Event`}</ShellTitle>
      </div>

      <div className="w-full">
        <div className="flex justify-between items-center mb-6 gap-4 flex-wrap md:flex-nowrap">
          <StepNavigation
            steps={stepNavigationSteps}
            activeStepId={activeStep.toString()}
            onStepClick={handleStepClick}
            showArrows={true}
            className="flex-1 min-w-0 px-0"
          />
          <div className="flex items-center gap-2 shrink-0">
            {saveText && saveOnClick && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    disabled={isContinueDisabled || saveLoading}
                    loading={saveLoading}
                    variant='uap-secondary'
                    className="rounded-full"
                  >
                    <ClipboardPen className="w-4 h-4 mr-2" />
                    {saveText}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-input border-input">
                  <DropdownMenuItem className="focus:bg-background cursor-pointer" onClick={saveOnClick}>
                    Save for Later
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            <Button
              disabled={isContinueDisabled || continueLoading}
              loading={continueLoading}
              onClick={onContinue}
              variant='gradient'
              className="rounded-full"
            >
              <CircleArrowRight className="w-4 h-4 mr-2" />
              {activeStep === 1 ? 'Set Up Activity' :
                activeStep === 2 ? 'Set Up Activity' :
                  activeStep === 3 ? 'Review & Publish' :
                    activeStep === 4 ? 'Publish Event' :
                      continueText || 'Continue'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
