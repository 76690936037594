import { useMemo, useState } from "react";
import { get, isEmpty, pick, set } from "lodash";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "@tanstack/react-router";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import dayjs from "dayjs";

import { useGameStore } from "@/store/useGameStore";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { setDefaultValues } from "@/components/Flows/CreateEvent/utils/defaultValues";
import {
  addEventTicketPrice,
  updateActivityMutationFn,
  updateEventMutationFn,
  updateTicketPriceMutationFn,
} from "@repo/api/base";
import { createRecurringEventDates } from "@/components/Flows/CreateEvent/utils/recurring";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import {
  convertAmountToCents,
  getTicketPriceDisplay,
} from "@repo/utils/currency";
import {
  createExternalReference,
  getExternalSiteByKey,
  getExternalSites,
} from "@repo/utils/events";
import { useSelectedGame } from "@/hooks/useSelectedGame";

import EventHeader from "./EventHeader";
import StepOne from "./Steps/SelectGame";
// import StepTwo from "./Steps/HostingInfo";
import StepTwo from "./Steps/EventDetails";
import Step3 from "./Steps/ActivityConfiguration";
import ReviewDraftActivity from "./Steps/ReviewDraftActivity";
import { eventQueryOptions } from "@/data/organization/queries";
import { getEventCreationFormQueryOptions } from "@/data/event/getEventCreationFormQueryOptions";
import { queryClient } from "@/lib/queryClient";
import useDynamicFormFields from "@/lib/hooks/useDynamicFormFields";

const CreateEventFlow = ({ games } = {}) => {
  const { entityId, eventId } = useParams({ strict: false });
  const { userData } = useRootStore();
  const address = userData?.addresses?.find(
    (address) => address.resourceId === entityId
  );
  const navigate = useNavigate();
  const { data: eventData } = useSuspenseQuery(eventQueryOptions(eventId));
  const event = eventData?.data;
  const activity = eventData?.data?.activities?.[0];
  const { data: formData } = useSuspenseQuery(
    getEventCreationFormQueryOptions({
      axiosInstance: webAxiosInstance,
      eventId: event.id,
      gameId: activity?.gameId,
    })
  );

  const { selectedGame, backgroundImageUrl } = useSelectedGame({
    gameId: activity?.gameId,
  });
  const hasPayoutEnabled =
    event?.owner?.entity?.payoutAccount?.payoutEnabled ||
    event?.owner?.entity?.organization?.payoutAccount?.payoutEnabled;

  const [step, setStep] = useState(activity?.activityTemplateId ? 4 : 2);
  const [isHostedOnCardeio, setIsHostedOnCardeio] = useState(
    Object.keys(activity?.externalReference)?.length === 0
  );
  const [externalHostingSite, setExternalHostingSite] = useState(
    Object.keys(activity?.externalReference)[0]
  );
  const [hostingInfo, setHostingInfo] = useState(
    Object.values(activity?.externalReference)[0]?.publicUrl ||
    Object.values(activity?.externalReference)[0]?.code
  );
  const [savingAsDraft, setSavingAsDraft] = useState(false);

  const selectedExternalSite = getExternalSiteByKey(
    selectedGame,
    externalHostingSite
  );

  const form = useForm({
    // resolver: zodResolver(formSchema),
    defaultValues: setDefaultValues({
      activity,
      address,
      event,
      game: selectedGame,
    }),
  });

  const watchAllFields = form?.watch();

  const prefilledFields = useMemo(() => {
    const activityPresets = (formData?.data?.data?.activityPresets || []).map(
      (preset) => ({
        ...preset,
        source: "activityPresets",
      })
    );
    const gameActivityPresets = (
      formData?.data?.data?.gameActivityPresets || []
    ).map((preset) => ({
      ...preset,
      source: "gameActivityPresets",
    }));

    return [...activityPresets, ...gameActivityPresets];
  }, [formData]);

  const onClickPreFill = (prefillId) => {
    if (prefillId == null) {
      return;
    }

    const prefill = prefilledFields?.find(
      (prefill) => prefill.id === prefillId
    );

    // Create a new object with all the values we want to set
    const formValues = { prefill }; // Start with current values

    // Process special fields
    if (prefill.ticketPrices?.length) {
      const ticketPrice = prefill.ticketPrices[0];
      formValues.ticketPrice = parseFloat(
        getTicketPriceDisplay({
          amount: ticketPrice.amount,
          currency: ticketPrice.currency,
        }).replace(/[^0-9.]/g, "")
      );
      formValues.currency = ticketPrice.currency;
      delete formValues.ticketPrices;
    }

    if (prefill.activityName) {
      formValues.name = prefill.activityName;
      delete formValues.activityName;
    }

    // Add all other fields from the prefill
    Object.entries(prefill).forEach(([key, value]) => {
      // Skip the special fields we already handled
      if (key !== 'ticketPrices' && key !== 'activityName') {
        formValues[key] = value;
      }
    });

    delete formValues.startsAt;
    delete formValues.endsAt;
    delete watchAllFields.startsAt;
    delete watchAllFields.endsAt;
    // Update all form values at once
    form.reset({ ...watchAllFields, ...formValues });
  };
  const getActivityDisplay = () => {
    if (watchAllFields?.activityTemplateId) {
      let templateName = "";
      formData?.data?.data?.fields?.activityTemplateId?.forEach(
        (activityTemplate) => {
          activityTemplate?.activityTemplateId.options.forEach((option) => {
            if (option.value === watchAllFields?.activityTemplateId) {
              templateName = option.label;
            }
          });
        }
      );

      return templateName;
    }
    if (watchAllFields?.gameActivityId) {
      let categoryName = "";
      formData?.data?.data?.fields?.gameActivityId?.forEach((gameActivity) => {
        gameActivity?.gameActivityId.options.forEach((option) => {
          if (option.value === watchAllFields?.gameActivityId) {
            categoryName = option.label;
          }
        });
      });

      return categoryName;
    }
    if (watchAllFields?.activityLevelId) {
      let levelName = "";
      formData?.data?.data?.fields?.activityLevelId?.forEach(
        (activityLevel) => {
          activityLevel?.activityLevelId.options.forEach((option) => {
            if (option.value === watchAllFields?.activityLevelId) {
              levelName = option.label;
            }
          });
        }
      );

      return levelName;
    }
  };

  const fields = useMemo(() => {
    if (!formData?.data?.data?.fields) return [];
    const flowFields = { ...(formData?.data?.data?.fields || {}) };

    return Object.keys(flowFields).map((key) => ({
      field: key,
      data: flowFields?.[key],
    }));
  }, [formData?.data?.data?.fields]);

  const { setValue } = form;

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      setStep(4);
      toast("Successfully edited Event!");
    },
    onError: ({ response }) => {
      toast("There was an issue with updating this event.", {
        description: response.data.errorMessage.replace("Unprocessable Content: ", ""),
      });
    },
  });

  const publishEventMutation = useMutation({
    mutationFn: async ({ status = "published" } = {}) => {
      if (event.ticketPrices?.length === 0) {
        await addEventTicketPrice({
          clientAxiosInstance: webAxiosInstance,
          eventId: event.id,
          data: {
            name: "General Admission",
            amount: 0,
            role: "general",
            startsAt: dayjs().toISOString(),
            currency: watchAllFields.currency,
            paymentMethod: watchAllFields.paymentMethod || 'onlineAndInPerson',
          },
        });
      }

      if (watchAllFields.ticketPrice > 0) {
        const ticketPriceId =
          activity?.ticketPrices?.sort(sortTicketPrices)[0]?.id || null;

        await updateTicketPriceMutationFn({
          clientAxiosInstance: webAxiosInstance,
          ticketPriceId,
          data: {
            amount: convertAmountToCents(
              watchAllFields.ticketPrice,
              watchAllFields.currency
            ),
            currency: watchAllFields.currency,
            paymentMethod: watchAllFields.paymentMethod,
          },
        });
      }

      await updateActivityMutationFn({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          ...pick(watchAllFields, [
            "activityLevelId",
            "activityTemplateId",
            "capacity",
            "configuration",
            "description",
            "descriptionHtml",
            "gameActivityId",
            "startsAt",
            "prizeSupport",
          ]),
          entryTime: watchAllFields?.configuration?.entryTime,
          configuration: {
            ...watchAllFields?.configuration,
          },
          externalReference: isHostedOnCardeio
            ? undefined
            : {
              [externalHostingSite || "other"]: createExternalReference(
                selectedExternalSite,
                hostingInfo
              ),
            },
          status,
        },
      });

      const isInPersonEvent = watchAllFields?.configuration?.playSetting === 'inPerson';

      await updateEventMutationFn({
        clientAxiosInstance: webAxiosInstance,
        eventId: event.id,
        data: {
          ...pick(watchAllFields, [
            "bannerImage",
            "endsAt",
            "name",
            "startsAt",
          ]),
          ...(isInPersonEvent ? {
            address: {
              ...watchAllFields?.address,
              formatted_address: undefined
            }
          } : {}),
          configuration: {
            timezone: watchAllFields?.timezone,
            recurring: watchAllFields?.isRecurring || watchAllFields?.recurring,
            recurringType: watchAllFields?.recurringType,
            recurringInterval: watchAllFields?.recurringInterval,
            recurringAmount: watchAllFields?.recurringAmount,
            recurringEndDate: watchAllFields?.recurringEndDate,
            playSetting: watchAllFields?.configuration?.playSetting,
            locationNotes: watchAllFields?.locationNotes,
            ...(!isInPersonEvent ? {
              eventUrl: watchAllFields?.eventUrl
            } : {})
          },
          status,
        },
      });

      // Only create recurring events if we're publishing (not saving as draft)
      if (
        (watchAllFields.recurring || watchAllFields.isRecurring) &&
        status === "published"
      ) {
        const data = pick(watchAllFields, [
          "capacity",
          "configuration",
          "activityTemplateId",
          "gameActivityId",
          "activityLevelId",
          "startsAt",
          "recurringAmount",
          "recurringInterval",
          "recurringType",
          "recurringEndDate",
          "prizeSupport",
        ]);
        const recurringInterval =
          data.recurringInterval || data.recurringType || "weekly";
        const recurringAmount = data.recurringAmount;
        const recurringEndDate = data.recurringEndDate;

        let recurringDates;
        if (recurringEndDate) {
          // If we have an end date, use it to calculate the recurring dates
          recurringDates = await createRecurringEventDates(
            watchAllFields.startsAt,
            watchAllFields.endsAt,
            recurringInterval,
            null, // No amount needed when we have end date
            recurringEndDate
          );
        } else {
          // Otherwise use the amount
          recurringDates = await createRecurringEventDates(
            watchAllFields.startsAt,
            watchAllFields.endsAt,
            recurringInterval,
            recurringAmount
          );
        }
        const activityData = pick(watchAllFields, [
          "activityLevelId",
          "activityTemplateId",
          "capacity",
          "configuration",
          "description",
          "descriptionHtml",
          "gameActivityId",
          "startsAt",
          "address",
          "prizeSupport",
        ])

        if (get(activityData, 'configuration.tournaments').every((tournament) => isEmpty(tournament)) || get(activityData, 'configuration.tournaments').every((tournament) => Object.entries(tournament).every(([key, value]) => isEmpty(value)))) {
          set(activityData, 'configuration.tournaments', undefined)
        }
        const recurringPromises = recurringDates.map(async (date) => {
          const formattedData = {
            ...data,
            activities: [
              {
                ...activity,
                ...activityData,
                status,
                startsAt: date.startDate,
                endsAt: date.endDate,
              },
            ],
            startsAt: date.startDate,
            endsAt: date.endDate,
          };

          return await webAxiosInstance.post(
            `/api/organize/events/${eventId}/copy`,
            formattedData
          );
        });
        // try catch in case some fail we still want to forward to original event
        try {
          await Promise.all(recurringPromises);
        } catch (error) {
          console.error(error);
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["event", eventId] });

      // Only redirect if we're not saving as draft
      if (!savingAsDraft) {
        navigate({ to: `/entity/${entityId}/events/${event.id}` });
      } else {
        // Reset the savingAsDraft state
        setSavingAsDraft(false);
        toast("Event saved as draft successfully!");
      }
    },
    onError: ({ response }) => {
      // Reset the savingAsDraft state in case of error
      setSavingAsDraft(false);
      toast("There was an issue with updating this event.", {
        description: response.data.errorMessage.replace("Unprocessable Content: ", ""),
      });
    },
  });

  const handleActivityConfigUpdate = () => {
    if (
      !watchAllFields.activityLevelId ||
      !watchAllFields.gameActivityId ||
      !watchAllFields.activityTemplateId
    ) {
      setStep(4);
      return;
    }

    const activityData = pick(watchAllFields, [
      "capacity",
      "configuration",
      "activityTemplateId",
      "gameActivityId",
      "activityLevelId",
      "startsAt",
      "address",
      "prizeSupport",
    ])

    if (get(activityData, 'configuration.tournaments').every((tournament) => isEmpty(tournament))
      || get(activityData, 'configuration.tournaments').every((tournament) => Object.entries(tournament).every(([key, value]) => isEmpty(value)))) {
      set(activityData, 'configuration.tournaments', undefined)
    }

    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...activityData,
        entryTime: watchAllFields?.configuration?.entryTime,
        configuration: {
          ...watchAllFields?.configuration,
        },
        externalReference: isHostedOnCardeio
          ? undefined
          : {
            [externalHostingSite || "other"]: createExternalReference(
              selectedExternalSite,
              hostingInfo
            ),
          },
      },
    });
  };

  const handleSaveAsDraft = () => {
    setSavingAsDraft(true);
    publishEventMutation.mutate({ status: "draft" });
  };

  const handleEditSection = (sectionIndex) => {
    if (sectionIndex === 0) {
      setStep(2);
    } else if (sectionIndex === 1) {
      setStep(3);
    }
  };
  const { pendingFields } = useDynamicFormFields(formData?.data?.data, watchAllFields, setValue);

  return (
    <div className="w-full h-full">
      <div className="relative z-10">
        <div className="px-10 sticky top-0 z-100 w-full bg-background before:absolute before:inset-0 before:bg-background before:backdrop-blur-sm">
          <EventHeader
            activeStep={step}
            activityDisplay={getActivityDisplay()}
            selectedExternalSite={selectedExternalSite}
            selectedGame={selectedGame}
            setStep={setStep}
            values={{
              isHostedOnCardeio,
              hostingInfo,
            }}
            onContinue={() => {
              if (step === 1) {
                setStep(2);
              } else if (step === 2) {
                setStep(3);
              } else if (step === 3) {
                setStep(4);
              } else if (step === 4) {
                publishEventMutation.mutate({ status: "published" });
              }
            }}
            isContinueDisabled={
              (step === 1 && !selectedGame?.id) ||
              (step === 2 && pendingFields.length) ||
              (step === 3 &&
                !Array.from(
                  watchAllFields?.checkFieldsRef?.current || []
                ).every(
                  (field) =>
                    watchAllFields[field] !== undefined &&
                    watchAllFields[field] !== null
                )) ||
              (step === 4 &&
                !Array.from(
                  watchAllFields?.checkFieldsRef?.current || []
                ).every(
                  (field) =>
                    watchAllFields[field] !== undefined &&
                    watchAllFields[field] !== null
                ))
            }
            continueLoading={
              step === 4 && publishEventMutation.isPending && !savingAsDraft
            }
            continueText={step === 4 ? "Save and Publish" : "Continue"}
            // Allow saving as draft on step 2 and above
            {...(step >= 2 && {
              saveText: "Save for Later",
              saveOnClick: handleSaveAsDraft,
              saveLoading: publishEventMutation.isPending && savingAsDraft,
            })}
            entityId={entityId}
          />
        </div>

        <div
          className={`w-full mx-auto pt-10 min-h-screen ${backgroundImageUrl ? "bg-cover bg-center bg-no-repeat relative" : ""}`}
          style={
            backgroundImageUrl
              ? { backgroundImage: `url(${backgroundImageUrl})` }
              : {}
          }
        >
          {backgroundImageUrl && (
            <div className="absolute inset-0 bg-black/70 backdrop-blur-sm"></div>
          )}
          <div className="relative z-10">
            <div className="w-full max-w-[800px] mx-auto pb-10 p-4 backdrop-blur-sm rounded-lg">
              <div className="relative">
                <StepOne
                  games={games}
                  onContinue={() => {
                    setStep(2);
                  }}
                  selectedGame={selectedGame}
                  setStep={setStep}
                  activeStep={step}
                />

                <StepTwo
                  activeStep={step}
                  flow={formData?.data?.data}
                  form={form}
                  formFields={fields}
                  game={selectedGame}
                  hasPayoutEnabled={hasPayoutEnabled}
                  prefilledFields={prefilledFields}
                  onClickPreFill={onClickPreFill}
                />

                <Step3
                  activeStep={step}
                  form={form}
                />

                <ReviewDraftActivity activeStep={step} form={form} onEditSection={handleEditSection} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventFlow;
