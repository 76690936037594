import dayjs from "dayjs";
import { some } from "lodash";

export const sortByOrder = (arrayToSort, orderArray) => {
    return arrayToSort.sort((a, b) => {
        const indexA = orderArray.indexOf(a);
        const indexB = orderArray.indexOf(b);

        if (indexA === -1 && indexB === -1) return 0; // Both items not in orderArray
        if (indexA === -1) return 1; // Only a is not in orderArray
        if (indexB === -1) return -1; // Only b is not in orderArray

        return indexA - indexB; // Both items in orderArray
    });
}

export const checkType = (value) => {
    if (value === null) {
        return 'null';
    }

    if (Array.isArray(value)) {
        return 'array';
    }

    // If it's a Date object
    if (value instanceof Date) {
        return 'date';
    }

    // If it's a RegExp object
    if (value instanceof RegExp) {
        return 'regexp';
    }

    // If it's a Promise object (or a thenable)
    if (value instanceof Promise || (typeof value === 'object' && typeof value.then === 'function')) {
        return 'promise';
    }

    // For all other cases, use typeof
    return typeof value;
}

export const getUnknownKey = (obj) => {
    const keys = Object.keys(obj);
    for (const key of keys) {
        if (key !== 'conditions') {
            return key;
        }
    }
    return null; // or however you want to handle the case where there's no other key
}

function getNestedValue(obj, path) {
    if (!path) return obj;
    const keys = path.split('.');
    let current = obj;

    for (const key of keys) {
        if (!key) return undefined;
        if (current[key] === undefined) {
            return undefined; // Or throw an error or return a default value
        }
        current = current[key];
    }
    return current;
}

export function evaluateCondition(obj, condition = {}) {
    if (condition.actions || condition.resourceId) {
        return true;
    }

    if (checkType(condition) === 'boolean') {
        return condition;
    }
    if (condition.all) {
        return evaluateConditions(obj, condition);
    }
    if (condition.any) {
        return any(condition.any, obj);
    }
    const conditionField = condition.field;
    let fieldValue = getNestedValue(obj, conditionField);
    if (dayjs.isDayjs(fieldValue)) {
        fieldValue = dayjs(fieldValue).toISOString();
    }
    switch (condition.operator) {
        case 'equal':
            return fieldValue === condition.value;
        case 'notEqual':
            return fieldValue !== condition.value;
        case 'in':
            return condition.value.includes(fieldValue);
        case 'notIn':
            return !condition.value.includes(fieldValue);
        case 'lessThanEqual':
            return fieldValue <= condition.value;
        case 'greaterThanEqual':
            return fieldValue >= condition.value;
        // ... add other operators as needed
        default:
            console.error(`Unknown operator: ${condition.operator}`);
            return false;
    }
}

export const any = (conditions, data) => {
    return some(conditions, (condition) => {
        return evaluateCondition(data, condition);
    });
};

export function evaluateConditions(data, conditionsObject) {
    if (checkType(conditionsObject) === 'boolean') {
        return conditionsObject;
    }
    if (conditionsObject.all) {
        return conditionsObject.all.every((condition) => evaluateCondition(data, condition));
    }
    if (conditionsObject.any) {
        return any(conditionsObject.any, data);
    }
}