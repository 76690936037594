import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { RiFilter3Line, RiQuestionLine } from "@remixicon/react";

import { Button } from "@cardeio/ui/components/ui/button";
import { Tooltip, TooltipTrigger, TooltipContent } from "@repo/ui/tooltip";
import { Can } from "@repo/utils/permissions";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Input } from "@cardeio/ui/components/ui/input";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const CardHeader = ({
  addConditions,
  addModal,
  buttonTitle,
  buttonAction,
  filters,
  isAddModalOpen,
  setIsAddModalOpen,
  title,
  titleTooltip,
  rightChildren,
  hideTooltip,
  leftChildren
}) => {
  const [searchInput, setSearchInput] = useState("");
  const previousSearchInput = usePrevious(searchInput);

  const debouncedSearch = useRef(
    debounce((value) => {
      filters.onSearch(value);
    }, 500)
  ).current;

  useEffect(() => {
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch(searchInput);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput, debouncedSearch]);

  const AddModal = () => {
    if (buttonAction) {
      return (
        <div className="flex-shrink-0">
          <Button
            variant="gradient-contained"
            rounded
            style={{ padding: "0.75rem 2rem", fontSize: "0.875rem" }}
            onClick={buttonAction}
          >
            {buttonTitle}
          </Button>
        </div>
      );
    }

    return (
      <div className="flex-shrink-0">
        <Modal open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
          <ModalTrigger asChild>
            <Button
              variant="gradient-contained"
              rounded
              style={{ padding: "0.75rem 2rem", fontSize: "0.875rem" }}
            >
              {buttonTitle}
            </Button>
          </ModalTrigger>
          {addModal && addModal()}
        </Modal>
      </div>
    );
  };

  const FilterSection = useCallback(() => {
    if (!filters?.hasFilters) return null;

    if (filters.filterComponent) {
      return filters.filterComponent;
    }

    return (
      <div className="flex-shrink-0">
        <Modal
          open={filters?.isFilterModalOpen}
          onOpenChange={filters?.setIsFilterModalOpen}
        >
          <ModalTrigger asChild>
            <Button size="sm">
              <div className="relative">
                <RiFilter3Line className="h-5 w-5" />
                {filters.hasActiveFilters && (
                  <span className="top-[-4px] start-4 absolute w-2 h-2 bg-red-500 border-[1px] border-white dark:border-gray-800 rounded-full"></span>
                )}
              </div>
            </Button>
          </ModalTrigger>
          {filters?.filterModal && filters?.filterModal()}
        </Modal>
      </div>
    );
  }, [filters]);

  return (
    <div className="border-b border-zinc-800 rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="flex ml-4 mt-2">
          <h3 className="text-2xl font-semibold leading-6 text-zinc-100">
            {title}
          </h3>
          {!hideTooltip && titleTooltip && (
            <Tooltip delayDuration={200}>
              <TooltipTrigger>
                <RiQuestionLine className="ml-2 h-5 w-5" />
              </TooltipTrigger>
              <TooltipContent className="w-[400px]">
                <p>{titleTooltip}</p>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <div className="flex items-center justify-center gap-2">
          {filters?.hasSearch && (
            <Input
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search..."
              className="w-[300px]"
              value={searchInput}
            />
          )}
          {filters?.hasFilters && <FilterSection />}
          {addModal &&
            (addConditions ? (
              <Can I="create" this={addConditions}>
                <AddModal />
              </Can>
            ) : (
              <AddModal />
            ))}
          {rightChildren && rightChildren}
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
