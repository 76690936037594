import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { useInfiniteQuery } from "@tanstack/react-query"

export function useInfiniteScroll(options) {
  const { ref, inView } = useInView({
    threshold: 0.1,
    rootMargin: "0px 0px 200px 0px", // Load more when 200px from the bottom
  })

  const infiniteQuery = useInfiniteQuery(options)

  useEffect(() => {
    if (inView && infiniteQuery.hasNextPage && !infiniteQuery.isFetchingNextPage) {
      infiniteQuery.fetchNextPage()
    }
  }, [
    inView,
    infiniteQuery.fetchNextPage,
    infiniteQuery.hasNextPage,
    infiniteQuery.isFetchingNextPage,
  ])

  return {
    infiniteQuery,
    triggerNextPageRef: ref,
  }
}
