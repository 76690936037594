import dayjs from "dayjs";
import { omit, pick } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import EditDisplayField, { DisplayField } from "@/_pages_/auth/Entity/Store/Tools/Events/Single/EditDisplayField";
import { webAxiosInstance } from "@/utils/axios";
import { useGameStore } from "@/store/useGameStore";
import { updateActivityMutationFn, updateEventMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { checkinPeriodChoices } from "@/components/Flows/CreateEvent/utils/checkInTimes";
import ManageTicketInfoModal from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageTicketInfo";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import ManageActivityConfigModal
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageActivityConfiguration";
import ManageHostingInfoModal
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/Modals/ManageHostingInfo";
import { getExternalSite } from "@repo/utils/events";
import { getTicketPriceDisplay } from "@repo/utils/currency";
import { MinusIcon, PlusIcon } from "lucide-react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@cardeio/ui/components/ui/accordion";
import { useState } from "react";

const ConfigurableOptions = ({ activity, event }) => {
  const { games } = useGameStore();

  const ticketPrice = activity?.ticketPrices?.sort(sortTicketPrices)?.[0];
  const activeGame = games?.find((game) => game.id === activity?.gameId);
  const activityLevel = activeGame?.activityLevels?.find((activityLevel) => activityLevel.id === activity?.activityLevelId);
  const gameActivity = activeGame?.gameActivities?.find((gameActivity) => gameActivity.id === activity?.gameActivityId);
  const activityTemplate = activeGame?.activityTemplates?.find((activityTemplate) => activityTemplate.id === activity?.activityTemplateId);
  const externalSite = getExternalSite(activeGame, activity);
  const [sectionsOpen, setSectionsOpen] = useState({
    eventDetails: false,
    hostingInfo: false,
    ticketInfo: false,
    gameplaySettings: false,
    advancedConfiguration: false,
    tournamentSettings: false,
  });

  const updateActivityMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      toast('Successfully edited Event!');
      queryClient.refetchQueries();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });
  const updateEventMutation = useMutation({
    mutationFn: updateEventMutationFn,
    onSuccess: () => {
      toast('Successfully edited Event!');
      queryClient.refetchQueries();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with updating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleTimingUpdate = (startsAt, endsAt) => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      data: {
        ...omit(event, ['activities', 'address', 'configuration', 'createdAt', 'owner', 'ownerId', 'ownerType', 'registrationCount', 'ticketPrices', 'updatedAt']),
        startsAt,
        endsAt,
      }
    });
  }

  const handleEventUpdate = (name, value) => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      data: {
        ...omit(event, ['activities', 'address', 'configuration', 'createdAt', 'owner', 'ownerId', 'ownerType', 'registrationCount', 'ticketPrices', 'updatedAt']),
        [name]: value,
      }
    });
  }

  const handleActivityUpdate = (name, value) => {
    if (name === 'prizeSupport') {
      const currentPrizeSupport = activity.prizeSupport || {};
      
      const updatedPrizeSupport = {
        ...currentPrizeSupport,
        ...value
      };
      
      updateActivityMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          ...pick(activity, []),
          prizeSupport: updatedPrizeSupport,
        }
      });
    } else {
      updateActivityMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          ...pick(activity, []),
          [name]: value,
        }
      });
    }
  }

  const handlePrizeSupportUpdate = (prizeCategory, data) => {
    // Get the current prizeSupport or initialize an empty object
    const currentPrizeSupport = activity.prizeSupport || {};
    
    // Create the updated prize category data
    const updatedCategory = {
      ...(currentPrizeSupport[prizeCategory] || {}),
      ...data
    };
    
    // Create the updated prizeSupport object
    const updatedPrizeSupport = {
      ...currentPrizeSupport,
      [prizeCategory]: updatedCategory
    };
    
    // Update the activity with the new prizeSupport
    updateActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        ...pick(activity, []),
        prizeSupport: updatedPrizeSupport,
      }
    });
  };

  return (
    <>
      <div className='z-20'>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="event-details" className="border-b-0">
            <AccordionTrigger onClick={() => {
              setSectionsOpen({
                ...sectionsOpen,
                eventDetails: !sectionsOpen.eventDetails,
              });
            }} Icon={sectionsOpen.eventDetails ? MinusIcon : PlusIcon}>
              <h2 className='text-xl font-bold mb-2'>Event Details</h2>
            </AccordionTrigger>
            <AccordionContent>
              <EditDisplayField
                dataType='text'
                defaultValue={event?.name}
                label='Event Name'
                description='The title or name of the event.'
                name='name'
                onUpdate={handleEventUpdate}
                isLocked={event?.status !== 'published'}
              />
              <EditDisplayField
                dataType='dateTime'
                defaultValue={event?.startsAt}
                label='Starts At'
                description='The designated beginning time of the event.'
                minDate={dayjs()}
                name='startsAt'
                onUpdate={(name, value) => {
                  if (dayjs(value).isAfter(dayjs(event?.endsAt))) {
                    handleTimingUpdate(value, dayjs(value).add(4, 'hours'));
                  } else {
                    handleEventUpdate(name, value);
                  }
                }}
                isLocked={event?.status !== 'published'}
              />
              <EditDisplayField
                dataType='dateTime'
                defaultValue={event?.endsAt}
                label='Ends At'
                description='The scheduled conclusion time of the event.'
                minDate={dayjs(event?.startsAt)}
                name='endsAt'
                onUpdate={(name, value) => {
                  if (dayjs(value).isBefore(dayjs(event?.startsAt))) {
                    toast("Invalid Change.", {
                      description: "The Event ends at date cannot be before the Event start date. Please try again.",
                      action: {
                        label: "Contact Support",
                        onClick: () => window.location.href = 'https://carde.io/support',
                      },
                    });
                  } else {
                    handleEventUpdate(name, value);
                  }
                }}
                isLocked={event?.status !== 'published'}
              />
              <EditDisplayField
                dataType='select'
                defaultValue={activity?.entryTime}
                label='Entry Time'
                description='The time attendees can start checking in.'
                name='entryTime'
                onUpdate={handleActivityUpdate}
                isLocked={event?.status !== 'published'}
                selectOptions={{
                  displayLabel: 'label',
                  id: 'id',
                  items: checkinPeriodChoices,
                  onSetValue: (value) => {
                    return checkinPeriodChoices.find(choice => choice.label === value)?.id;
                  }
                }}
              />
              <EditDisplayField
                dataType='number'
                defaultValue={activity?.capacity}
                label='Capacity'
                description='The maximum number of attendees permitted at this event.'
                name='capacity'
                onUpdate={handleActivityUpdate}
                isLocked={event?.status !== 'published'}
              />

              <EditDisplayField
                dataType='boolean'
                defaultValue={event?.public}
                label='Is Event Public?'
                description='If Yes, this event will be visible to the public. If No, this event will only be visible by direct link.'
                name='public'
                onUpdate={handleEventUpdate}
                isLocked={event?.status === 'draft'}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>


      </div>

      {!activeGame?.official && (
        <div className='z-20'>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="hosting-info" className="border-b-0">
              <AccordionTrigger onClick={() => {
                setSectionsOpen({
                  ...sectionsOpen,
                  hostingInfo: !sectionsOpen.hostingInfo,
                });
              }} Icon={sectionsOpen.hostingInfo ? MinusIcon : PlusIcon}>
                <h2 className='text-xl font-bold mb-2'>Hosting Info</h2>
              </AccordionTrigger>
              <AccordionContent>
                {!externalSite ? (
                  <EditDisplayField
                    dataType='text'
                    defaultValue='Carde.io'
                    EditModal={ManageHostingInfoModal}
                    editModalProps={{ activity, eventId: event.id }}
                    label='Platform Name'
                    description='The Platform you will be running this event on.'
                    name='name'
                    onUpdate={() => { }}
                    isLocked={event?.status !== 'published'}
                  />
                ) : (
                  <>
                    <EditDisplayField
                      dataType='text'
                      defaultValue={externalSite?.name}
                      EditModal={ManageHostingInfoModal}
                      editModalProps={{ activity, eventId: event.id }}
                      label='Platform Name'
                      description='The Platform you will be running this event on.'
                      name='name'
                      onUpdate={() => { }}
                      isLocked={event?.status !== 'published'}
                    />
                    {externalSite?.urls?.eventManagement?.joinCode?.name ? (
                      <>
                        <EditDisplayField
                          dataType='text'
                          defaultValue={Object.values(activity?.externalReference)?.[0]?.code}
                          EditModal={ManageHostingInfoModal}
                          editModalProps={{ activity, eventId: event.id }}
                          label={externalSite?.urls?.eventManagement?.joinCode?.name}
                          description={externalSite?.urls?.eventManagement?.joinCode?.shortDescription || 'Code used for players to join the event.'}
                          name='name'
                          onUpdate={() => { }}
                          isLocked={event?.status !== 'published'}
                        />
                        <EditDisplayField
                          dataType='boolean'
                          defaultValue={externalSite?.urls?.eventManagement?.joinCode?.show === 'registered'}
                          label="Is Hidden Until Registration?"
                          description="Is the Code hidden until the player registers?"
                          name='name'
                          onUpdate={() => { }}
                          isLocked={event?.status !== 'published'}
                        />
                      </>
                    ) : (
                      <>
                        <EditDisplayField
                          dataType='text'
                          defaultValue={Object.values(activity?.externalReference)?.[0]?.publicUrl}
                          label="Public URL"
                          description="Public URL that the event is being ran on."
                          name='name'
                          onUpdate={() => { }}
                          isLocked={event?.status !== 'published'}
                        />
                      </>
                    )}
                  </>
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      )}

      <div className='z-10'>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="ticket-info" className="border-b-0">
            <AccordionTrigger onClick={() => {
              setSectionsOpen({
                ...sectionsOpen,
                ticketInfo: !sectionsOpen.ticketInfo,
              });
            }} Icon={sectionsOpen.ticketInfo ? MinusIcon : PlusIcon}>
              <h2 className='text-xl font-bold my-4'>Ticket Info</h2>
            </AccordionTrigger>
            <AccordionContent>
              {event?.status === 'published' && (
                <ManageTicketInfoModal activity={activity} eventId={event.id} ticketPrice={ticketPrice} />
              )}
              <EditDisplayField
                dataType='text'
                EditModal={ManageTicketInfoModal}
                editModalProps={{ activity, ticketPrice }}
                label='Ticket Price'
                description='The numerical value representing the cost of each ticket.'
                isLocked={event?.status !== 'published'}
                defaultValue={getTicketPriceDisplay(ticketPrice)}
              />

              {(ticketPrice?.amount > 0 && (
                <>
                  <EditDisplayField
                    dataType='text'
                    EditModal={ManageTicketInfoModal}
                    editModalProps={{ activity, ticketPrice }}
                    label='Currency'
                    description="The currency used for pricing tickets, reflecting the event's location or preference."
                    isLocked={event?.status !== 'published'}
                    defaultValue={ticketPrice?.currency}
                  />
                </>
              ))}

              <EditDisplayField
                dataType='text'
                EditModal={ManageTicketInfoModal}
                editModalProps={{ activity, ticketPrice }}
                label='Payment Method'
                description="How payments are accepted for this event."
                isLocked={event?.status !== 'published'}
                defaultValue={
                  ticketPrice?.paymentMethod === 'onlineAndInPerson' ? 'In-person and online' :
                  ticketPrice?.paymentMethod === 'inPerson' ? 'In-person only' :
                  ticketPrice?.paymentMethod === 'online' ? 'Online only' : 'In-person and online'
                }
              />

              <DisplayField
                dataType='dateTime'
                label='Registration Start Time'
                description='When ticket sales open to the public.'
                isLocked
                value={ticketPrice?.startsAt || ticketPrice?.createdAt}
              />
              <DisplayField
                dataType='dateTime'
                label='Registration End Time'
                description='The deadline for purchasing tickets.'
                isLocked
                value={ticketPrice?.endsAt}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>




      <div className='z-10'>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="gameplay-settings" className="border-b-0">
            <AccordionTrigger onClick={() => {
              setSectionsOpen({
                ...sectionsOpen,
                gameplaySettings: !sectionsOpen.gameplaySettings,
              });
            }} Icon={sectionsOpen.gameplaySettings ? MinusIcon : PlusIcon}>
              <h2 className='text-xl font-bold my-4'>Gameplay Settings</h2>
            </AccordionTrigger>
            <AccordionContent>
              {event?.status === 'published' && (
                <ManageActivityConfigModal activity={activity} eventId={event.id} />
              )}
              <DisplayField
                dataType='text'
                value={activityLevel?.name}
                label='Activity Level'
                description='The title or name of the event.'
                isLocked
              />
              <DisplayField
                dataType='text'
                value={gameActivity?.name}
                label='Game Activity'
                description='The title or name of the event.'
                isLocked
              />
              <DisplayField
                dataType='text'
                value={activityTemplate?.name}
                label='Activity Template'
                description='The title or name of the event.'
                isLocked
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className='z-10'>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="advanced-configuration" className="border-b-0">
            <AccordionTrigger onClick={() => {
              setSectionsOpen({
                ...sectionsOpen,
                advancedConfiguration: !sectionsOpen.advancedConfiguration,
              });
            }} Icon={sectionsOpen.advancedConfiguration ? MinusIcon : PlusIcon}>
              <h2 className='text-xl font-bold my-4 flex-1 text-left'>Advanced Configuration</h2>
              {event?.status === 'published' && (
                <ManageActivityConfigModal activity={activity} eventId={event.id} />
              )}
            </AccordionTrigger>
            <AccordionContent>
              {activity?.configuration?.allowWalkOns && (
                <DisplayField
                  dataType='boolean'
                  value={activity?.configuration?.allowWalkOns}
                  label='Allow Walk Ons?'
                  description=''
                  isLocked
                />
              )}
              {activity?.configuration?.playStyle && (
                <DisplayField
                  dataType='boolean'
                  value={activity?.configuration?.playStyle}
                  label='Play Style'
                  description=''
                  isLocked
                />
              )}
              {activity?.configuration?.publishDeckLists && (
                <DisplayField
                  dataType='boolean'
                  value={activity?.configuration?.publishDeckLists}
                  label='Publish Deck Lists?'
                  description=''
                  isLocked
                />
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      {activity?.configuration?.tournaments?.[0]?.bestOf?.games && (
        <div className='z-10'>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="tournament-settings" className="border-b-0">
              <AccordionTrigger onClick={() => {
                setSectionsOpen({
                  ...sectionsOpen,
                  tournamentSettings: !sectionsOpen.tournamentSettings,
                });
              }} Icon={sectionsOpen.tournamentSettings ? MinusIcon : PlusIcon}>
                <h2 className='text-xl font-bold my-4 flex-1 text-left'>Tournament Settings</h2>
                {event?.status === 'published' && (
                  <ManageActivityConfigModal activity={activity} eventId={event.id} />
                )}
              </AccordionTrigger>
              <AccordionContent>
                <DisplayField
                  dataType='number'
                  value={activity?.configuration?.tournaments?.[0]?.bestOf?.games}
                  label='Best Of'
                  description=''
                  isLocked
                />

                <DisplayField
                  dataType='number'
                  value={activity?.configuration?.tournaments?.[0]?.bestOf?.duration}
                  label='Round Duration'
                  description=''
                  isLocked
                />


                {activity?.configuration?.tournaments?.[0]?.pairingStyle && (
                  <div className='z-10'>
                    <DisplayField
                      dataType='text'
                      value={activity?.configuration?.tournaments?.[0]?.pairingStyle}
                      label='Pairing Style'
                      description=''
                      isLocked
                    />
                  </div>
                )}

                {activity?.configuration?.tournaments?.[0]?.tournamentFormat && (
                  <div className='z-10'>
                    <DisplayField
                      dataType='text'
                      value={activity?.configuration?.tournaments?.[0]?.tournamentFormat}
                      label='Tournament Format'
                      description=''
                      isLocked
                    />
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </>
  )
}

export default ConfigurableOptions;
