import React from "react";
import { toast } from "sonner";
import { useMutation, useQuery } from "@tanstack/react-query";
import { WarningIcon } from "@/icons/Warning";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { startActivityMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";

const MIN_PLAYERS_TO_START = 1;
const StartEventModal = ({ activity, eventId, handleClose, isOpen, onStart, phase }) => {
  const url = phase?.id ? `activityPhases/${phase?.id}/roster` : `activities/${activity.id}/roster`;

  const { data: fullRoster } = useQuery({
    queryKey: [activity.id, 'activity', 'roster'],
    queryFn: async () => {
      const res = await webAxiosInstance.get(`/api/play/${url}`, {
        params: {
          limit: 9999,
        },
        headers: {
          'game-id': activity.gameId,
        },
      });
      const checkedInCount = res.data.data.filter((player) => player.status === 'checkedIn').length;
      const registeredCount = res.data.data.filter((player) => player.status === 'registered').length;
      const rosterCount = res.data.data.length;

      return {
        rawData: res.data.data,
        checkedInCount,
        rosterCount,
        registeredCount,
      };
    },
    enabled: isOpen,
    gcTime: 1000 * 60 * 60,
  });

  const startActivityMutation = useMutation({
    mutationFn: startActivityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event', eventId] })
      queryClient.invalidateQueries({ queryKey: [activity.id, 'activity', 'pairings'] })
      queryClient.invalidateQueries({ queryKey: [activity.id, 'activity'] })
      toast.success('Successfully started event!');
      onStart && onStart();
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue starting this event.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
      queryClient.invalidateQueries({ queryKey: ['event', eventId] })
      queryClient.invalidateQueries({ queryKey: [activity.id, 'activity', 'pairings'] })
      queryClient.invalidateQueries({ queryKey: [activity.id, 'activity'] })
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    startActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Start Event</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center gap-4 m-auto max-w-[600px]'>
        <WarningIcon className="h-24 w-24 text-red-500" aria-hidden="true" />
        {fullRoster?.checkedInCount < MIN_PLAYERS_TO_START ? (
          <p className='text text-red-400 text-center font-semibold'>
            You must have at least {MIN_PLAYERS_TO_START} participant checked-in to start the event. To check-in players, please go to the 'Registrations' tab.
          </p>
        ) : (
          <p className='text-center font-semibold'>
            Starting this event is an irreversible action. This will start this event and only include participants who are checked-in.
          </p>
        )}

        {fullRoster?.checkedInCount >= MIN_PLAYERS_TO_START &&
          fullRoster?.rawData?.length &&
          fullRoster?.registeredCount !== 0 ? (
          <h4 className='text-xl text-red-400 text-center font-semibold mt-2'>
            {fullRoster?.registeredCount}{' '}
            {fullRoster?.registeredCount === 1
              ? 'Participant'
              : 'Participants'}{' '}
            {fullRoster?.registeredCount === 1 ? 'is' : 'are'} NOT <i>Checked In</i>.{' '}
            {fullRoster?.checkedInCount >= MIN_PLAYERS_TO_START && "Are you sure you'd like to start?"}
          </h4>
        ) : null}
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            disabled={fullRoster?.checkedInCount < MIN_PLAYERS_TO_START || startActivityMutation.isPending}
            onClick={onSubmit}
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            variant='gradient-contained'
            loading={startActivityMutation.isPending}
          >
            Start Event
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
}

export default StartEventModal;
