import { useState, useEffect } from "react";
import { RiArrowDownSLine } from "@remixicon/react";
import { Button } from "@cardeio/ui/components/ui/button";
import { usePrizingStore } from "@/store/usePrizingStore";
import { Input } from "@cardeio/ui/components/ui/input";
import { Trash2 } from "lucide-react";
import { useConfirmDialogStore } from "@/store/useConfirmDialogStore";
import { PRIZE_SHORT_NAMES, PRIZE_COLORS } from "@/constants/prizingConstants";

const PrizingCard = ({ isLast: isLastFromProps, prizeInfo, allPrizes }) => {
  const {
    editingPrizing,
    updatePrizeValue,
    prizeChanges,
    removePrize,
    getLastVisiblePrizeKey,
  } = usePrizingStore();
  const { open: openConfirmDialog } = useConfirmDialogStore();
  const [isOpen, setIsOpen] = useState(
    !prizeInfo.cash || Number(prizeInfo.cash) === 0
  );

  // Get the current values, either from prizeChanges if editing or from prizeInfo
  const currentValues =
    editingPrizing && prizeChanges[prizeInfo.key]
      ? { ...prizeInfo, ...prizeChanges[prizeInfo.key] }
      : prizeInfo;

  // Check if this prize is marked for removal
  const isMarkedForRemoval =
    editingPrizing &&
    prizeChanges[prizeInfo.key] &&
    prizeChanges[prizeInfo.key]._toRemove;

  // Determine if this is the last visible prize
  const lastVisiblePrizeKey = getLastVisiblePrizeKey(allPrizes);
  const isLast = prizeInfo.key === lastVisiblePrizeKey;

  const hasCashPrizing = !(
    !currentValues.cash || Number(currentValues.cash) === 0
  );
  const hasProductPrizing =
    currentValues.product &&
    currentValues.product !== "No Prize" &&
    currentValues.product !== "";

  // Initialize the prize values in the store when editing mode is activated
  useEffect(() => {
    if (editingPrizing) {
      // Only initialize if not already in prizeChanges
      if (!prizeChanges[prizeInfo.key]) {
        updatePrizeValue(prizeInfo.key, "cash", prizeInfo.cash || "");
        updatePrizeValue(prizeInfo.key, "product", prizeInfo.product || "");
      }
    }
  }, [editingPrizing]);

  // Check if this is a newly added prize (not in the original prizeInfo)
  const isNewlyAdded =
    editingPrizing &&
    prizeChanges[prizeInfo.key] &&
    (!prizeInfo.hasOwnProperty("cash") || prizeInfo.cash === undefined) &&
    (!prizeInfo.hasOwnProperty("product") || prizeInfo.product === undefined);

  const handleCashChange = (e) => {
    const value = e.target.value;
    // Remove non-numeric characters except decimal point
    const numericValue = value.replace(/[^0-9.]/g, "");
    
    // Ensure the value is not negative
    if (numericValue !== "" && parseFloat(numericValue) < 0) {
      // If negative, use absolute value
      updatePrizeValue(prizeInfo.key, "cash", Math.abs(parseFloat(numericValue)).toString());
    } else {
      updatePrizeValue(prizeInfo.key, "cash", numericValue);
    }
  };

  const handleProductChange = (e) => {
    const value = e.target.value;
    // Allow any text value for Product Prize
    updatePrizeValue(prizeInfo.key, "product", value);
  };

  const handleRemovePrize = () => {
    const prizeName = PRIZE_SHORT_NAMES[prizeInfo.key] || prizeInfo.title;

    // Show confirmation dialog
    openConfirmDialog({
      title: `Remove ${prizeName} Prize?`,
      description: `Are you sure you want to remove the ${prizeName} prize? This action can be undone before submitting your changes.`,
      onConfirm: () => {
        removePrize(prizeInfo.key);
      },
      confirmText: "Remove",
      cancelText: "Cancel",
    });
  };

  const PlacingBox = () => {
    if (PRIZE_SHORT_NAMES[prizeInfo.key]) {
      return (
        <p
          className="text-sm font-bold text-zinc-200 rounded shadow-sm mb-2 py-1 px-2 w-fit"
          style={{ background: PRIZE_COLORS[prizeInfo.key] }}
        >
          {PRIZE_SHORT_NAMES[prizeInfo.key]}
        </p>
      );
    }
  };

  // Count the number of visible prizes
  const visiblePrizeCount = () => {
    // Count prizes from allPrizes that aren't marked for removal
    const existingPrizeCount = allPrizes.filter(
      (prize) => !prizeChanges[prize.key]?._toRemove
    ).length;

    // Count newly added prizes from prizeChanges
    const newPrizeCount = Object.keys(prizeChanges).filter(
      (key) =>
        !allPrizes.some((p) => p.key === key) && !prizeChanges[key]?._toRemove
    ).length;

    return existingPrizeCount + newPrizeCount;
  };

  // If marked for removal, don't render the component
  if (isMarkedForRemoval) {
    return null;
  }

  return (
    <div
      className={`flex flex-col pb-2 ${isLast ? "" : "border-b-[1px] border-b-zinc-200/40"} ${isNewlyAdded ? "animate-pulse bg-zinc-800/20 p-2 rounded" : ""}`}
    >
      <div className={`flex justify-between items-start`}>
        <div className="flex flex-col">
          {PRIZE_SHORT_NAMES[prizeInfo.key] ? (
            <>
              <PlacingBox />
              {isNewlyAdded && (
                <span className="text-xs text-green-500 italic mb-1">
                  Newly added
                </span>
              )}
            </>
          ) : (
            <>
              <h6 className="text-lg mb-1">{prizeInfo.title}</h6>
              {isNewlyAdded && (
                <span className="text-xs text-green-500 italic">
                  Newly added
                </span>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col items-end">
          {editingPrizing ? (
            <>
              <div className="flex items-center gap-2 mb-2">
                {currentValues.cash !== prizeInfo.cash && !isNewlyAdded && (
                  <span className="text-xs text-red-500/50 italic">
                    Previously ${prizeInfo.cash}
                  </span>
                )}
                <span className="text-sm">$</span>
                <Input
                  type="text"
                  value={currentValues.cash || ""}
                  onChange={handleCashChange}
                  className="w-24 h-8 text-right"
                  placeholder="0.00"
                />
              </div>
              <div className="flex items-center gap-2 mb-2">
                {currentValues.product !== prizeInfo.product && !isNewlyAdded && (
                  <span className="text-xs text-red-500/50 italic">
                    Previously {prizeInfo.product}
                  </span>
                )}
                <Input
                  type="text"
                  value={currentValues.product || ""}
                  onChange={handleProductChange}
                  className="w-full h-8 text-right text-xs"
                  placeholder="Enter Product Prize"
                />
              </div>
              {/* Only show Remove button on the last item and when there's more than one prize */}
              {isLast && visiblePrizeCount() > 1 && (
                <Button
                  onClick={handleRemovePrize}
                  variant="ghost"
                  size="sm"
                  className="mt-2 text-red-500 hover:text-red-700 hover:bg-red-100/10"
                >
                  <Trash2 className="h-4 w-4 mr-1" />
                  Remove {PRIZE_SHORT_NAMES[prizeInfo.key] || prizeInfo.title}
                </Button>
              )}
            </>
          ) : (
            <>
              {hasCashPrizing ? (
                <p className="font-bold mb-2">${currentValues.cash}</p>
              ) : null}
              {hasProductPrizing ? (
                <p className="text-xs text-muted-foreground">
                  {currentValues.product}
                </p>
              ) : (
                <span className="text-xs text-muted-foreground/50">
                  No Product Prize
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrizingCard;
