import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { Checkbox } from "@repo/ui/checkbox";
import { Button } from "@repo/ui/button";

import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogPortal,
} from '@repo/ui/dialog';

import { webAxiosInstance } from "@/utils/axios";
import SearchUserByEmailModalContent from "@/components/Modal/CommonSections/User/SearchUserByEmail";
import { queryClient } from '@/lib/queryClient';
import { addEntityMemberMutationFn, createGuestUserMutationFn, searchUserMutationFn, disableEntityMemberMutationFn } from "@repo/api/base";
import { Input } from "@repo/ui/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@cardeio/ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";

const emailFormSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

const otpFormSchema = z.object({
  otp: z.string().length(6, "OTP must be 6 characters"),
});

const SwapEmailModal = () => {
  const emailForm = useForm({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const otpForm = useForm({
    resolver: zodResolver(otpFormSchema),
    defaultValues: {
      otp: "",
    },
  });

  const changeEmailRequestMutation = useMutation({
    mutationFn: (email) => {
      return webAxiosInstance.post("/api/core/verifications", {
        data: {
          email,
        },
        requestType: 'changeEmail'
      });
    },
  });

  console.log(changeEmailRequestMutation.data);

  const onEmailSubmit = (values, event) => {
    if (event) {
      event.preventDefault();
    }
    changeEmailRequestMutation.mutate(values.email);
  };

  const verifyOTP = useMutation({
    mutationFn: (otp) => {
      return webAxiosInstance.post(`/api/core/verifications/${changeEmailRequestMutation.data.data.data.id}`, {
        otp,
      });
    },
    onSuccess: () => {
      toast.success("Email changed successfully");
      window.location.reload();
    },
    onError: () => {
      toast.error("Failed to change email");
    },
  });

  const onOTPSubmit = (values, event) => {
    if (event) {
      event.preventDefault();
    }
    verifyOTP.mutate(values.otp);
  };

  const handleOTPComplete = (value) => {
    otpForm.setValue("otp", value);
    if (value.length === 6) {
      otpForm.handleSubmit((values) => onOTPSubmit(values))();
    }
  };

  return (
    <DialogPortal>
      <DialogContent className="max-w-md" onPointerDownOutside={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle>Change Email</DialogTitle>
        </DialogHeader>
        {changeEmailRequestMutation?.status !== 'success' && (
          <Form {...emailForm}>
            <form onSubmit={(e) => {
              e.preventDefault();
              emailForm.handleSubmit(onEmailSubmit)(e);
            }} className="space-y-4 p-4">
              <FormField
                control={emailForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="New Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                variant="primary"
                size="sm"
                disabled={changeEmailRequestMutation.isPending}
                onClick={(e) => {
                  e.preventDefault();
                  emailForm.handleSubmit(onEmailSubmit)(e);
                }}
              >
                {changeEmailRequestMutation.isPending ? "Submitting..." : "Continue"}
              </Button>
            </form>
          </Form>
        )}

        {changeEmailRequestMutation?.status === 'success' && (
          <Form {...otpForm}>
            <form onSubmit={(e) => {
              e.preventDefault();
              otpForm.handleSubmit(onOTPSubmit)(e);
            }} className="px-6 pb-6 flex flex-col gap-4 items-center">
              <p className="text-center mb-6">
                Please enter the OTP sent to your new email.
              </p>

              <FormField
                control={otpForm.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP
                        maxLength={6}
                        disabled={verifyOTP?.status === 'pending'}
                        onComplete={handleOTPComplete}
                        value={field.value}
                        onChange={field.onChange}
                        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                variant="primary"
                disabled={verifyOTP?.status === 'pending'}
                onClick={(e) => {
                  e.preventDefault();
                  otpForm.handleSubmit(onOTPSubmit)(e);
                }}
              >
                {verifyOTP?.status === 'pending' ? 'Verifying...' : 'Verify Code'}
              </Button>
            </form>
          </Form>
        )}
      </DialogContent>
    </DialogPortal>
  );
};

export default SwapEmailModal;
