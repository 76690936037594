import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { Sheet, SheetContent, SheetTrigger } from "@repo/ui/sheet";
import GameSheet from "@/components/Sheets/Game";
import { gameTypeMapping } from "@/utils/constants/game";

dayjs.extend(relativeTime);

const appStatuses = {
  approved: "bg-green-800/80",
  pending: "bg-blue-900/80",
  submitted: "bg-blue-900/80",
  denied: "bg-red-600/80",
  revoked: "bg-red-900/80",
  draft: "bg-yellow-600/80",
  noApp: "bg-secondary/80",
};

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

const isObject = (value) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

const GameCard = ({ applications = {}, game, ourGames = false }) => {
  return (
    <Sheet>
      <div className="relative w-full max-w-64 mt-8">
        <div className="absolute w-40	h-40 bg-zinc-900 bg-opacity-80 top-0 flex items-center justify-center rounded-full shadow-lg shadow-black/50 p-8 left-1/2 transform -translate-x-1/2 z-10 pointer-events-none">
          {game?.resourceImages?.length > 0 ? (
            <img
              src={
                game?.resourceImages?.find(
                  (image) => image.imageType === "logo"
                )?.image?.url
              }
            />
          ) : (
            <h3>{game?.name}</h3>
          )}
          {game?.official && (
            <Tooltip>
              <TooltipTrigger>
                <CheckBadgeIcon className="absolute bottom-3 right-3 h-6 w-6 fill-secondary pointer-events-auto" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm">Official Carde.io Partner</p>
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <SheetTrigger className="w-full">
          <div className="relative bg-zinc-900 bg-opacity-60 rounded-lg mt-20 w-full h-56 cursor-pointer shadow-2xl shadow-secondary/5 scale-100 transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg hover:shadow-secondary/20 hover:bg-opacity-100 border border-zinc-200/5">
            <div className="h-full w-full flex flex-col justify-end">
              <div className=" h-full w-full flex flex-col justify-end p-4">
                <div className="flex justify-center gap-2 mb-2">
                  {game?.gameTypes?.map((gameType, idx) => (
                    <div
                      key={idx}
                      className="text-secondary bg-secondary/10 ring-secondary/30 rounded-full flex-none py-1 px-2 text-2xs font-medium ring-1 ring-inset capitalize w-fit"
                    >
                      {gameTypeMapping[gameType]}
                    </div>
                  ))}
                </div>

                <h3 className="text-lg text-center font-bold w-full">
                  {game.name}
                </h3>

                {applications?.organizedPlay?.approvedAt ? (
                  <h3 className="text-2xs text-zinc-200/80 text-center w-full mt-2">
                    No Application Yet
                  </h3>
                ) : applications?.organizedPlay?.requestedAt ? (
                  <h3 className="text-2xs text-zinc-200/80 text-center w-full mt-2">
                    Requested{" "}
                    {dayjs(applications?.organizedPlay?.requestedAt).fromNow()}
                  </h3>
                ) : null}
              </div>
              {ourGames && !isEmptyObject(applications) && (
                <div
                  className={classNames(
                    appStatuses[
                    applications?.organizedPlay?.status?.toLowerCase() ||
                    "noApp"
                    ],
                    "text-2xs text-center font-bold uppercase text-sm w-full p-1 rounded-b-lg"
                  )}
                >
                  {applications?.organizedPlay?.status?.toLowerCase() ===
                    "draft"
                    ? "Finalize Application"
                    : applications?.organizedPlay?.status ||
                    "No Application Yet"}
                </div>
              )}
            </div>
          </div>
        </SheetTrigger>
      </div>

      <SheetContent>
        <GameSheet applications={applications} game={game} />
      </SheetContent>
    </Sheet>
  );
};

export default GameCard;
