import { GripVertical } from "lucide-react"

const DraftTable = ({ pod, className }) => {
  if (!pod) return null

  const podSize = pod.configuration?.podSize || pod.players?.length || 8
  const halfSize = Math.ceil(podSize / 2)

  // Create right column (first n/2 players)
  const rightColumn = Array(halfSize)
    .fill(null)
    .map((_, i) => ({
      seatNumber: i + 1,
      displayName: pod.players[i]?.displayName || "",
    }))

  // Create left column (second n/2 players in reverse)
  const leftColumn = Array(halfSize)
    .fill(null)
    .map((_, i) => ({
      seatNumber: halfSize + i + 1,
      displayName: pod.players[halfSize + i]?.displayName || "",
    }))
    .reverse()

  const tableRows = leftColumn.map((leftSeat, index) => {
    const rightSeat = rightColumn[index]
    return {
      leftSeat,
      tableNumber: index + 1,
      rightSeat,
    }
  })

  return (
    <div className="relative w-full">
      <div className="absolute left-1/2 top-0 h-full w-[280px] -translate-x-1/2 bg-[#ffdab3] rounded-lg" />

      <div className="relative">
        {tableRows.map((row, index) => (
          <div key={row.tableNumber} className="mb-2 grid grid-cols-[1fr_280px_1fr] items-center">
            {/* Left player */}
            {row.leftSeat.displayName && (
              <div className="flex items-center bg-slate-600 text-white px-4 py-3 rounded-l-lg">
                <div className="flex items-center gap-2">
                  <GripVertical className="h-4 w-4 opacity-50 hover:cursor-not-allowed" />
                  <span>{row.leftSeat.displayName}</span>
                </div>
              </div>
            )}
            {!row.leftSeat.displayName && <div />}

            <div className="grid grid-cols-3 items-center justify-items-center px-4 py-4 ">
              <div className="rounded bg-[#c65d36] px-3 py-1 text-white">SEAT {row.leftSeat.seatNumber}</div>
              <div className="text-black font-medium">T{row.tableNumber}</div>
              <div className="rounded bg-[#c65d36] px-3 py-1 text-white">SEAT {row.rightSeat.seatNumber}</div>
            </div>

            {row.rightSeat.displayName && (
              <div className="flex items-center justify-end bg-slate-600 text-white px-4 py-3 rounded-r-lg">
                <div className="flex items-center gap-2">
                  <span>{row.rightSeat.displayName}</span>
                  <GripVertical className="h-4 w-4 opacity-50 hover:cursor-not-allowed" />
                </div>
              </div>
            )}
            {!row.rightSeat.displayName && <div />}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DraftTable

