import { useState } from "react";

import { EllipsisVerticalIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { RiFileCopyLine } from "@remixicon/react";

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import CopyEventModalContent from "@/components/Modal/Event/CopyEvent";
import CancelEventModalContent from "@/components/Modal/Event/CancelEvent";
import ManageEventBannerModal from "@/components/Modal/Event/ManageEventBanner";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

const AdditionalActions = ({ activity, event, game }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCancelEventModalOpen, setIsCancelEventModalOpen] = useState(false);
  const [isCopyEventModalOpen, setIsCopyEventModalOpen] = useState(false);
  const [isManageEventBannerModalOpen, setIsManageEventBannerModalOpen] = useState(false);

  const UpdateBannerModal = () => {
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isManageEventBannerModalOpen} onOpenChange={setIsManageEventBannerModalOpen}>
          <ModalTrigger asChild>
            <Button
              className='h-full flex items-center justify-center gap-1 text-xs font-normal'
              onClick={() => setIsManageEventBannerModalOpen(true)}
              size='sm'
              variant='text'
            >
              <span className='ml-1 text-zinc-100'>Update Banner Image</span>
            </Button>
          </ModalTrigger>
          <ManageEventBannerModal event={event} game={game} handleClose={() => setIsManageEventBannerModalOpen(false)} />
        </Modal>
      </div>
    )
  };

  const CopyEventModal = () => {
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isCopyEventModalOpen} onOpenChange={setIsCopyEventModalOpen}>
          <ModalTrigger asChild>
            <Button
              className='h-full flex items-center justify-center gap-1 text-xs font-normal'
              onClick={() => setIsCopyEventModalOpen(true)}
              size='sm'
              variant='text'
            >
              <RiFileCopyLine className="h-4 w-4 mr-1" aria-hidden="true" />
              <span className='ml-1 text-zinc-100'>Copy Event</span>
            </Button>
          </ModalTrigger>
          <CopyEventModalContent handleClose={() => setIsCopyEventModalOpen(false)} event={event} />
        </Modal>
      </div>
    )
  };

  const CancelEventModal = () => {
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isCancelEventModalOpen} onOpenChange={setIsCancelEventModalOpen}>
          <ModalTrigger asChild>
            <Button
              className='h-full flex items-center justify-center gap-1 text-xs font-normal'
              onClick={() => setIsCancelEventModalOpen(true)}
              size='sm'
              variant='text'
            >
              <ExclamationCircleIcon className="h-4 w-4 text-red-500 mr-1" aria-hidden="true" />
              <span className='text-sm text-red-600'>Cancel Event</span>
            </Button>
          </ModalTrigger>
          <CancelEventModalContent event={event} handleClose={() => setIsCancelEventModalOpen(false)} />
        </Modal>
      </div>
    )
  };

  return (
    <div className='z-10'>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <button className='pointer rounded-3xl p-2 hover:bg-zinc-200/10'>
            <Cog6ToothIcon className="h-5 w-5 flex-none text-zinc-200" aria-hidden="true" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="bg-zinc-950 border-zinc-200/10 w-fit" align='end'>
          <div className="flex flex-col items-center gap-4">
            <UpdateBannerModal />
            <CopyEventModal />
            {activity?.status === 'published' && (
              <CancelEventModal />
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default AdditionalActions;
