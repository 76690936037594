import { useMemo } from 'react';
import { useGameStore } from '@/store/useGameStore';

export const useSelectedGame = ({ gameId }) => {
  const { games } = useGameStore();

  const selectedGame = useMemo(() => {
    return games?.find(game => gameId === game?.id);
  }, [games, gameId]);

  const backgroundImageUrl = useMemo(() => {
    if (selectedGame?.resourceImages?.length > 0) {
      const firstBanner = selectedGame.resourceImages.find(image =>
        image.imageType === 'bannerImage' && image.order === 0
      );
      return firstBanner?.image?.url || null;
    }
    return null;
  }, [selectedGame]);

  return {
    selectedGame,
    backgroundImageUrl
  };
};
