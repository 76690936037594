import { webAxiosInstance } from "@/utils/axios";

export const searchEstablishmentsQueryOptions = (params = {}) => {
  const { lat, lng, radius, name, city, state, country, zip, page } = params;
  const hasCityOrZip = city || zip;
  return {
    queryKey: ["establishments", "search", params],
    queryFn: async () => {
      const { data } = await webAxiosInstance.get("/api/play/establishments", {
        params: {
          lat: hasCityOrZip ? params.lat : undefined,
          lng: hasCityOrZip ? params.lng : undefined,
          radius: hasCityOrZip ? params.radius : undefined,
          name: params.name,
          city: params.city,
          state: params.state,
          country: params.country,
          zip: params.zip,
          page: params.page || 1,
        },
      });
      return data;
    },
  };
};

export const searchEstablishmentsInfiniteQueryOptions = (params = {}) => {
  const { lat, lng, radius, name, city, state, country, zip, page } = params;
  const hasCityOrZip = city || zip;

  return {
    queryKey: ["establishments", "search", "infinite", params],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await webAxiosInstance.get("/api/play/establishments", {
        params: {
          lat: hasCityOrZip ? params.lat : undefined,
          lng: hasCityOrZip ? params.lng : undefined,
          radius: hasCityOrZip ? params.radius : undefined,
          name: params.name,
          city: params.city,
          state: params.state,
          country: params.country,
          zip: params.zip,
          page: pageParam,
        },
      });
      return {
        data: data.data,
        pagination: data.pagination,
        nextPage: data.pagination.nextPage,
      };
    },
    getNextPageParam: (lastPage) => lastPage.pagination.nextPage || undefined,
    initialPageParam: 1,
  };
};

const kmInAMile = 1.60934;

export const convertMilesToKilometers = (miles) => {
  return miles * kmInAMile;
};

export const convertKilometersToMiles = (kilometers) => {
  return kilometers / kmInAMile;
};
