"use client"
import { useState, useEffect, useRef } from "react"
import { MapPin, Search, Store, ExternalLink, UserPlus, CircleAlert, Bug } from "lucide-react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { useQuery, useSuspenseQuery, useMutation } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { Controller } from "react-hook-form"
import { toast } from 'sonner'

import { Button } from "@cardeio/ui/components/ui/button"
import { Card, CardContent } from "@cardeio/ui/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@cardeio/ui/components/ui/select"
import { Input } from "@cardeio/ui/components/ui/input"
import { ScrollArea, ScrollBar } from "@cardeio/ui/components/ui/scroll-area"
import { Alert, AlertDescription, AlertTitle } from "@cardeio/ui/components/ui/alert"
import { Skeleton } from "@cardeio/ui/components/ui/skeleton"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@cardeio/ui/components/ui/dropdown-menu"
import { createFileRoute } from "@tanstack/react-router"
import { searchEstablishmentsQueryOptions, searchEstablishmentsInfiniteQueryOptions, convertMilesToKilometers } from "@/data/establishment/searchEstablishments"
import { useSubmitEmployeeApplication, useSubmitTournamentOrganizerApplication, useUserApplications } from "@/data/establishment/staffApplications"
import { useRootStore } from "@/store/useRootStore"
import { Button as UAPButton } from "@repo/ui/button"
import { GooglePlacesAutocompleteInput } from "@cardeio/ui/components/forms/fields/google-places-autocomplete-input"
import { useInfiniteScroll } from "@/hooks/use-infinite-scroll"
import { Shell } from "@/components/Navigation/shell"

// Sample data
// const stores = [
//   {
//     id: 1,
//     name: "Phoenix Comics and Games",
//     address: "113 Broadway E, Seattle, WA 98102",
//     distance: "1.4 mi",
//     position: { lat: 47.6204, lng: -122.3491 },
//   },
//   {
//     id: 2,
//     name: "Tabletop Village",
//     address: "616 8th Ave S, Seattle, WA 98104",
//     distance: "8.7 mi",
//     position: { lat: 47.5975, lng: -122.3242 },
//   },
//   {
//     id: 3,
//     name: "Uncle's Games",
//     address: "7325 166th Ave NE, Redmond, WA, UA 98052",
//     distance: "9.3 mi",
//     position: { lat: 47.6721, lng: -122.1185 },
//   },
// ]

const MapWithMarkers = ({ stores }) => {
  const [markersReady, setMarkersReady] = useState(false)
  const [debugInfo, setDebugInfo] = useState({ totalStores: 0, validMarkers: 0 })

  // Prepare markers with improved validation
  const markers = stores.map((store) => {
    // Check if store.address and store.address.geo exist and have valid lat/lng values
    if (store.address &&
        store.address.geo &&
        typeof store.address.geo.lat === 'number' &&
        typeof store.address.geo.lng === 'number' &&
        !isNaN(store.address.geo.lat) &&
        !isNaN(store.address.geo.lng)) {
      return {
        id: store.id,
        position: { lat: store.address.geo.lat, lng: store.address.geo.lng },
        title: store.name
      }
    }
    return null
  }).filter(Boolean)

  // Calculate map center with better fallback
  const mapCenter = markers.length > 0
    ? { lat: markers[0].position.lat, lng: markers[0].position.lng }
    : { lat: 41.8781, lng: -87.6298 } // Default to Chicago if no valid markers

  // Calculate bounds only if we have markers
  const bounds = markers.length > 0 ? {
    north: Math.max(...markers.map(marker => marker.position.lat)),
    south: Math.min(...markers.map(marker => marker.position.lat)),
    east: Math.max(...markers.map(marker => marker.position.lng)),
    west: Math.min(...markers.map(marker => marker.position.lng))
  } : undefined

  useEffect(() => {
    // Update debug info
    setDebugInfo({
      totalStores: stores.length,
      validMarkers: markers.length
    })
    
    // Always set markers ready, regardless of whether we have valid markers
    setMarkersReady(true)
  }, [stores, markers.length])

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      center={mapCenter}
      zoom={markers.length > 0 ? 11 : 5}
      bounds={bounds}
    >
      {markers.length > 0 ? (
        markers.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            title={marker.title}
          />
        ))
      ) : stores.length > 0 ? (
        <div className="absolute top-4 left-4 bg-black/70 text-white p-3 rounded-md">
          No valid location data for the stores
        </div>
      ) : (
        <div className="absolute top-4 left-4 bg-black/70 text-white p-3 rounded-md">
          No stores found. Try adjusting your search.
        </div>
      )}
    </GoogleMap>
  )
}

function MyStoresPage() {
  const { userData } = useRootStore()
  const user = userData?.user
  const [useBounds, setUseBounds] = useState(false)
  const [searchParams, setSearchParams] = useState({
    name: "",
    lat: null,
    lng: null,
    radius: undefined,
    city: null,
    state: null,
    country: null,
    zip: null
  })
  const [googleLoaded, setGoogleLoaded] = useState(false)
  const [showDebug, setShowDebug] = useState(false)
  const isLocalhost = window.location.hostname === 'localhost'

  // Check if Google Maps is loaded
  useEffect(() => {
    // If Google Maps is already loaded, set state immediately
    if (window.google && window.google.maps) {
      setGoogleLoaded(true)
      return;
    }

    // Create a MutationObserver to watch for the Google Maps script being added
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          if (window.google && window.google.maps) {
            setGoogleLoaded(true)
            observer.disconnect();
          }
        }
      });
    });

    // Start observing the document for script additions
    observer.observe(document.head, { childList: true, subtree: true });
    observer.observe(document.body, { childList: true, subtree: true });

    // Also set up a fallback polling mechanism
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps) {
        setGoogleLoaded(true)
        observer.disconnect();
      } else {
        setTimeout(checkGoogleMapsLoaded, 500)
      }
    }

    const timeoutId = setTimeout(checkGoogleMapsLoaded, 1000)

    return () => {
      observer.disconnect();
      clearTimeout(timeoutId)
    }
  }, [])

  const { register, handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      location: "",
      distance: "25",
    }
  })

  const { infiniteQuery, triggerNextPageRef } = useInfiniteScroll(
    searchEstablishmentsInfiniteQueryOptions({
      name: searchParams.name,
      lat: searchParams.lat,
      lng: searchParams.lng,
      radius: searchParams.lat && searchParams.lng ? convertMilesToKilometers(Number(searchParams.radius)) : undefined,
      city: searchParams.city,
      state: searchParams.state,
      country: searchParams.country,
      zip: searchParams.zip
    })
  )

  const isLoading = infiniteQuery.isLoading || infiniteQuery.isFetchingNextPage
  const isSuccess = infiniteQuery.isSuccess
  const status = infiniteQuery.status

  // Flatten all pages of data into a single array of stores
  const stores = infiniteQuery.data?.pages.flatMap(page => page.data) ?? []

  // Get the pagination info from the latest page
  const pagination = infiniteQuery.data?.pages[infiniteQuery.data.pages.length - 1]?.pagination ?? {}

  // Fetch user applications
  const userApplicationsQuery = useUserApplications(user?.id)
  
  const hasApplications = userApplicationsQuery.data?.data?.length > 0
  const isApplicationsLoading = userApplicationsQuery.isLoading

  const employeeApplicationMutation = useSubmitEmployeeApplication({
    onSuccess: () => {
      toast.success("Application Submitted", {
        description: "Your staff application has been submitted successfully."
      });
      userApplicationsQuery.refetch();
    }
  });

  const tournamentOrganizerMutation = useSubmitTournamentOrganizerApplication({
    onSuccess: () => {
      toast.success("Application Submitted", {
        description: "Your tournament organizer application has been submitted successfully."
      });
      userApplicationsQuery.refetch();
    }
  });

  const onSubmit = async (data) => {
    // Validate lat/lng before setting search params
    const hasValidCoordinates =
      data.lat !== null &&
      data.lng !== null &&
      !isNaN(data.lat) &&
      !isNaN(data.lng)

    setSearchParams({
      name: data.name || "",
      lat: hasValidCoordinates ? data.lat : null,
      lng: hasValidCoordinates ? data.lng : null,
      radius: data.distance,
      city: data.city || null,
      state: data.state || null,
      country: data.country || null,
      zip: data.zip || null
    })

    // Reset the infinite query when search params change
    infiniteQuery.refetch()
  }

  // Debug component to help diagnose map marker issues
  const DebugPanel = ({ stores }) => {
    if (!showDebug) return null;

    const validGeoStores = stores.filter(store =>
      store.address &&
      store.address.geo &&
      typeof store.address.geo.lat === 'number' &&
      typeof store.address.geo.lng === 'number' &&
      !isNaN(store.address.geo.lat) &&
      !isNaN(store.address.geo.lng)
    );

    return (
      <div className="absolute bottom-4 right-4 z-50 bg-black/80 text-white p-4 rounded-md max-w-md max-h-[300px] overflow-auto">
        <h3 className="font-bold mb-2">Debug Info</h3>
        <div className="text-xs space-y-2">
          <p>Total stores: {stores.length}</p>
          <p>Stores with valid geo data: {validGeoStores.length}</p>
          <p>Search params: {JSON.stringify(searchParams, null, 2)}</p>
          {validGeoStores.length === 0 && stores.length > 0 && (
            <div className="bg-red-900/50 p-2 rounded">
              <p className="font-bold">No valid geo data found!</p>
              <p>First store data: {JSON.stringify(stores[0]?.address, null, 2)}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Shell
    title="Find Stores"
    subtitle="Search for local game shops that you intend to work for and click 'Apply as staff'. If approved, you will be able to schedule and organize events for their store."
  >
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        {isLocalhost && (
        <Button
          variant="outline"
          size="sm"
          className="border-[#333] hover:bg-[#333] hover:text-white"
          onClick={() => setShowDebug(!showDebug)}
        >
          <Bug className="h-4 w-4 mr-2" />
            {showDebug ? "Hide Debug" : "Debug"}
          </Button>
        )}
      </div>

      {isApplicationsLoading ? (
        <div className="space-y-2">
          <Skeleton className="h-8 w-64" />
          <Skeleton className="h-24 w-full" />
        </div>
      ) : !hasApplications ? (
        <Alert variant="uap-outline">
          <CircleAlert className="h-4 w-4 mt-1" />
          <AlertTitle className="font-medium leading-normal text-base">You have not applied to organize events at any store.</AlertTitle>
          <AlertDescription className="font-normal text-sm">
            If you are a tournament organizer, scorekeeper, judge, or want to apply to become one, search for local
            stores in your area and click "Apply as staff". You will receive a notification if approved.
          </AlertDescription>
        </Alert>
      ) : (
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-white">Your Applications</h2>
          <ScrollArea className="w-full whitespace-nowrap pb-4">
            <div className="flex space-x-4 pb-4">
              {userApplicationsQuery.data?.data
                .filter(app => ["tournamentOrganizerApplication", "employeeApplication"].includes(app.type))
                .map((application) => (
                  <Card key={application.id} className="w-[320px] h-[180px] flex-shrink-0 bg-[#1a1a1a] border-none overflow-hidden rounded-xl">
                    <CardContent className="p-0 h-full">
                      <div className="relative h-full flex flex-col">
                        <div className={`absolute top-4 left-4 px-3 py-1 rounded-full text-xs font-medium ${
                          application.status === "resolved"
                            ? "bg-green-900/80 text-green-300"
                            : "bg-[#6e2c4b] text-[#f8b886]"
                        }`}>
                          {application.status === "resolved" ? "Approved to run events" : "PENDING"}
                        </div>
                        <div className="flex-grow flex flex-col justify-end pt-16 px-6 pb-6 bg-[#222]">
                          <h3 className="font-semibold text-xl text-white break-words whitespace-normal line-clamp-2 leading-tight">{application.target.name}</h3>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
            </div>
            <ScrollBar orientation="horizontal" /> 
          </ScrollArea>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="flex flex-col md:flex-row gap-4 items-end">
          <div className="flex-1 w-full md:w-fit">
            <label className="text-sm font-medium text-white">Store Name</label>
            <div className="relative mt-2">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                className="pl-8 bg-[#1a1a1a] border-[#333]"
                placeholder="Search for store name"
                {...register("name")}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    // Clear lat/lng values when searching by name only
                    if (!getValues("location")) {
                      setValue("lat", null)
                      setValue("lng", null)
                    }
                    handleSubmit(onSubmit)()
                  }
                }}
              />
            </div>
          </div>
          <div className="flex-1 w-full md:w-fit">
            <label className="text-sm font-medium text-white">Location</label>
            <div className="relative mt-2 w-full">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <GooglePlacesAutocompleteInput
                    apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                    onAddressSelect={(address) => {

                      field.onChange(address.formatted_address)
                      // Ensure we have valid geo data before setting lat/lng
                      if (address.geo &&
                          typeof address.geo.lat === 'number' &&
                          typeof address.geo.lng === 'number' &&
                          !isNaN(address.geo.lat) &&
                          !isNaN(address.geo.lng)) {
                        setValue("lat", address.geo.lat)
                        setValue("lng", address.geo.lng)

                        // Set location data directly from address object
                        setValue("city", address.city || null)
                        setValue("state", address.state || null)
                        setValue("country", address.country || null)
                        setValue("zip", address.zip || null)

                        // Only submit if we have valid coordinates
                        handleSubmit(onSubmit)()
                      } else {
                        // Show error toast if no valid coordinates
                        toast.error("Could not determine location coordinates", {
                          description: "Please try a different location or be more specific"
                        })
                      }
                    }}
                    className="bg-[#1a1a1a] border-[#333] w-full"
                    placeholder="Enter city or zip code"
                  />
                )}
              />
            </div>
          </div>

          <div className="w-full md:w-[120px]">
            <label className="text-sm font-medium text-white">Distance</label>
            <Controller
              name="distance"
              control={control}
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="mt-2 bg-[#1a1a1a] border-[#333]">
                    <SelectValue placeholder="Select distance" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="10">10 miles</SelectItem>
                    <SelectItem value="25">25 miles</SelectItem>
                    <SelectItem value="50">50 miles</SelectItem>
                    <SelectItem value="100">100 miles</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
          </div>
          <Button
            type="button"
            disabled={isLoading}
            variant="default"
            className="rounded-full"
            onClick={() => {
              // Clear lat/lng values when searching by name only
              if (!getValues("location")) {
                setValue("lat", null)
                setValue("lng", null)
              }
              handleSubmit(onSubmit)()
            }}
          >
            {isLoading ? "Searching..." : "Find Stores"}
          </Button>
        </div>

        <div className="flex flex-col-reverse md:flex-row gap-6">
          <div className="w-full h-[700px] md:w-1/3">
            <ScrollArea className="h-full">
              <div className="space-y-4">
                {isSuccess && stores.map((store, index) => {
                  // Add ref to the third-to-last item to trigger loading more
                  const isThirdToLast = index === stores.length - 3

                  return (
                    <Card
                      key={store.id}
                      className="bg-[#1a1a1a] border-none"
                      ref={isThirdToLast ? triggerNextPageRef : null}
                    >
                      <CardContent className="p-4">
                        <div className="space-y-2">
                          <div className="flex items-center justify-between">
                            <h3 className="font-semibold text-lg text-[#c75d3f] break-words">{store.name}</h3>
                          </div>
                          <p className="text-sm text-muted-foreground flex items-start gap-1">
                            <MapPin className="h-3 w-3 mt-1 flex-shrink-0" />
                            <span className="break-words">
                              {store.address.address1}
                              {store.address.address2 && `, ${store.address.address2}`}
                              {`, ${store.address.city}, ${store.address.state} ${store.address.zip}`}
                            </span>
                          </p>
                          {store.contact.website && (
                            <p className="text-sm text-muted-foreground flex items-start gap-1">
                              <ExternalLink className="h-3 w-3 mt-1 flex-shrink-0" /> 
                              <span className="break-words overflow-hidden">{store.contact.website}</span>
                            </p>
                          )}
                          {store.contact.phone && (
                            <p className="text-sm text-muted-foreground">
                              📞 {store.contact.phone}
                            </p>
                          )}
                          <div className="flex gap-2 pt-2">
                            {store.contact.website && (
                              <Button
                                variant="outline"
                                className="flex-1 border-[#333] hover:bg-[#333] hover:text-white"
                                onClick={() => window.open(store.contact.website, '_blank')}
                              >
                                <ExternalLink className="h-4 w-4 mr-2" />
                                Visit Store
                              </Button>
                            )}
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="flex-1 border-[#333] hover:bg-[#333] hover:text-white">
                                  <UserPlus className="h-4 w-4 mr-2" />
                                  Enrollment Request
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  onClick={() => {
                                    if (!user?.id) {
                                      toast.error("You must be logged in to apply")
                                      return
                                    }
                                    employeeApplicationMutation.mutate({
                                      userId: user.id,
                                      organizationId: store.entity.organizationId
                                    })
                                  }}
                                  disabled={employeeApplicationMutation.isPending || !user?.id}
                                >
                                  {employeeApplicationMutation.isPending ? "Submitting..." : "Apply as Staff"}
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() => {
                                    if (!user?.id) {
                                      toast.error("You must be logged in to apply")
                                      return
                                    }
                                    tournamentOrganizerMutation.mutate({
                                      userId: user.id,
                                      establishmentId: store.id
                                    })
                                  }}
                                  disabled={tournamentOrganizerMutation.isPending || !user?.id}
                                >
                                  {tournamentOrganizerMutation.isPending ? "Submitting..." : "Apply as Guest Organizer"}
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  )
                })}

                {/* Loading indicator at the bottom */}
                {infiniteQuery.isFetchingNextPage && (
                  <div className="py-4 flex justify-center">
                    <Skeleton className="h-8 w-32" />
                  </div>
                )}
              </div>
            </ScrollArea>
          </div>

          <div className="rounded-lg overflow-hidden h-[700px] w-full md:w-2/3 relative">
            {status === 'pending' && (
              <div className="w-full h-full bg-[#1a1a1a]">
                <Skeleton className="w-full h-full" />
              </div>
            )}
            {status === 'error' && (
              <div className="w-full h-full bg-[#1a1a1a] flex items-center justify-center">
                <Alert variant="destructive">
                  <AlertTitle>Error loading map data</AlertTitle>
                  <AlertDescription>Please try your search again</AlertDescription>
                </Alert>
              </div>
            )}
            {status === 'success' && !googleLoaded && (
              <div className="w-full h-full bg-[#1a1a1a] flex items-center justify-center">
                <div className="text-center">
                  <Skeleton className="w-full h-[400px] mb-4" />
                  <p className="text-muted-foreground">Loading Google Maps...</p>
                </div>
              </div>
            )}
            {status === 'success' && googleLoaded && (
              <>
                <MapWithMarkers stores={stores || []} />
                {stores.length > 0 && stores.every(store => !store.address?.geo) && (
                  <div className="absolute top-4 left-4 z-10 bg-black/70 text-white p-3 rounded-md">
                    Some stores don't have valid location data
                  </div>
                )}
                <DebugPanel stores={stores} />
              </>
            )}
          </div>
        </div>
      </form>
    </div>
    </Shell>
  )
}

export const Route = createFileRoute('/_authenticated/organizer/my-stores')({
  component: MyStoresPage
});
