import { createFileRoute } from '@tanstack/react-router'
import TournamentOrganizerProfileForm from '@/components/Forms/TournamentOrganizerProfileForm'
import fadedRedBg from '@/assets/faded-red-bg.jpg'

function UserProfilePage() {
  return (
    <div className="container py-6">
      <TournamentOrganizerProfileForm showEmailSwap />
    </div>
  )
}

export const Route = createFileRoute('/_authenticated/user/')({
  component: UserProfilePage
})
