import { TooltipProvider } from "@repo/ui/tooltip";
import { mongoQueryMatcher, PureAbility } from '@casl/ability';
import { AbilityContext } from "@repo/utils/permissions";
import { ClientAxiosInterceptor } from "@repo/api/web";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from '../store/useRootStore';
import { queryClient } from '@/lib/queryClient';
import { Toaster } from 'sonner';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFlagStore } from '@/store/useFlagStore';
import Cookies from 'js-cookie';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ConfirmationDialog } from './ConfirmationDialog';


const persister = createSyncStoragePersister({
	storage: window.localStorage,
})

export const Providers = ({ children }) => {
	const { accessToken, userData } = useRootStore();
	const allCookies = Cookies.get()
	const { ...flags } = useFlags();
	useFlagStore.setState({ flags });
	const ability = new PureAbility(userData?.permissions?.rules, { conditionsMatcher: mongoQueryMatcher });
	const spoofEmail = allCookies?.['spoof-user-email'];

	return (
		<AbilityContext.Provider value={ability}>
			<ClientAxiosInterceptor clientAxiosInstance={webAxiosInstance} accessToken={accessToken} spoofEmail={spoofEmail}>
				<PersistQueryClientProvider client={queryClient} persistOptions={{
					persister, dehydrateOptions: {
						shouldDehydrateQuery: (query) => {
							const queryIsReadyForPersistance = query.state.status === 'success';
							if (queryIsReadyForPersistance) {
								const { queryKey = [] } = query;
								const excludeFromPersisting = queryKey.includes('userData');
								return !excludeFromPersisting;
							}
							return queryIsReadyForPersistance;
						}
					}
				}}>
					<Toaster toastOptions={{
						classNames: {
							error: 'bg-red-500 border-red-500 text-white',
							success: 'bg-green-500 border-green-500 text-white',
							warning: 'bg-yellow-500 border-yellow-500 text-white',
							info: 'bg-blue-500 border-blue-500 text-white',
						},
					}} position='top-right' duration={3500} />
					<TooltipProvider>
						{children}
						<ConfirmationDialog />
					</TooltipProvider>
				</PersistQueryClientProvider>
			</ClientAxiosInterceptor>
		</AbilityContext.Provider>
	)
}
