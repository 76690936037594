import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { webAxiosInstance } from "@/utils/axios";
import { addEntityMemberMutationFn, createGuestUserMutationFn, searchUserMutationFn } from "@repo/api/base";

export const useSubmitEmployeeApplication = (overrides = null) => useMutation({
  mutationFn: async ({ userId, organizationId }) => {
    const { data } = await webAxiosInstance.post("/api/manage/inquiries/", {
      sourceType: "User",
      sourceId: userId,
      targetType: "Organization",
      targetId: organizationId,
      type: "employeeApplication",
      content: {
        role: "member",
      },
      metadata: {},
    });
    return data;
  },
  onSuccess: overrides?.onSuccess ? overrides.onSuccess : () => {
    toast.success("Application Submitted", {
      description: "Your staff application has been submitted successfully."
    })
  },
  onError: overrides?.onError ? overrides.onError : () => {
    toast.error("Failed to submit staff application", {
      description: "Please try again."
    })
  },
});

export const useUserApplications = (userId) => {
  return useQuery({
    queryKey: ['userApplications', userId],
    queryFn: async () => {
      if (!userId) return { data: [], pagination: { totalResults: 0 } };

      const { data } = await webAxiosInstance.get("/api/manage/inquiries/sent", {
        params: {
          sourceType: "User",
          sourceId: userId,
          type: ["employeeApplication", "tournamentOrganizerApplication"],
        }
      });

      return data;
    },
    enabled: !!userId,
  });
};

export const useOrganizationApplications = (organizationId) => {
  return useQuery({
    queryKey: ['organizationApplications', organizationId],
    queryFn: async () => {
      if (!organizationId) return { data: [], pagination: { totalResults: 0 } };

      const { data } = await webAxiosInstance.get("/api/manage/inquiries/received", {
        params: {
          targetType: "Organization",
          targetId: organizationId,
          type: ["employeeApplication", "tournamentOrganizerApplication"],
        }
      });

      return data;
    },
    enabled: !!organizationId,
  });
};

export const useSubmitTournamentOrganizerApplication = (overrides = null) => useMutation({
  mutationFn: async ({ userId, establishmentId }) => {
    const { data } = await webAxiosInstance.post("/api/manage/inquiries/", {
      sourceType: "User",
      sourceId: userId,
      targetType: "Establishment",
      targetId: establishmentId,
      type: "tournamentOrganizerApplication",
      content: {
        gameIds: ["7d47e0f6-782e-44df-b9b9-b0fb6e4f754f"], // Lorcana game ID
      },
      metadata: {},
    });
    return data;
  },
  onSuccess: overrides?.onSuccess ? overrides.onSuccess : () => {
    toast.success("Application Submitted", {
      description: "Your tournament organizer application has been submitted successfully."
    })
  },
  onError: overrides?.onError ? overrides.onError : () => {
    toast.error("Failed to submit tournament organizer application", {
      description: "Please try again."
    })
  },
});

export const useResolveStaffApplication = () => {
  return useMutation({
    mutationFn: async ({ applicationId, establishmentId, entityId, userId, resolution }) => {
      try {
        // Send the full resolution structure to the inquiry endpoint
        await webAxiosInstance.post(`/api/manage/inquiries/${applicationId}/resolve`, {
          resolution
        })
      } catch (error) {
        console.error('Failed to resolve application:', error)
        throw error; // Re-throw to allow proper error handling
      }

      // If approved, also add the user as an entity member
      if (resolution.outcome === 'approved') {
        return addEntityMemberMutationFn({
          clientAxiosInstance: webAxiosInstance,
          entityId,
          data: {
            userId,
            role: 'member',
            gameId: resolution.games?.[0]?.id, // Use the game from the resolution if available
            entitlements: [
              {
                resourceType: 'Event',
                grants: {
                  manage: 'allow',
                },
              },
              {
                resourceType: 'Activity',
                grants: {
                  manage: 'allow',
                },
              },
            ],
          }
        });
      }
    }
  })
}
