import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/select"

export function SortSelect({ sortBy, onSort }) {
  return (
    <Select value={sortBy} onValueChange={onSort}>
      <SelectTrigger className="bg-zinc-800 text-zinc-100 rounded-md px-3 py-2 text-sm">
        <SelectValue placeholder="Sort by...">
          {sortBy === "lastName" && "Name (A-Z)"}
          {sortBy === "paid" && "Payment Status"}
          {sortBy === "checkedIn" && "Checked In"}
          {sortBy === "registerTime" && "Registration Time"}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="lastName">Name (A-Z)</SelectItem>
          <SelectItem value="paid">Payment Status</SelectItem>
          <SelectItem value="registerTime">Registration Time</SelectItem>
          <SelectItem value="checkedIn">Checked In</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
} 