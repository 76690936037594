import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { RiCheckLine, RiCloseLine, RiLockLine } from '@remixicon/react';
import { Button } from "@repo/ui/button";
import { Input } from "@repo/ui/input";
import { Switch } from "@repo/ui/switch";
import DateTimeSelector from "@/components/Calendar/DateTimeSelector";
import { ComboBox } from "@repo/ui/combobox";

const DisplayFieldBase = ({ dataType = 'text', description, isHovered = false, isLocked = false, label, selectOptions, value }) => {
  const renderValue = () => {
    if (dataType === 'text' || dataType === 'number') {
      return value;
    }
    if (dataType === 'currency') {
      return value / 100;
    }
    if (dataType === 'dateTime') {
      if (!value) {
        return 'N/A';
      }
      return dayjs(value).format('MMM DD, YYYY - h:mm A z');
    }
    if (dataType === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (dataType === 'select') {
      return selectOptions.items.find(option => option?.[selectOptions.id] === value)?.[selectOptions.displayLabel];
    }
  }

  return (
    <div className={`h-full flex justify-between items-center`}>
      <div>
        <h5 className='font-bold z-0'>{label}</h5>
        {description && <p className='text-3xs'>{description}</p>}
      </div>
      <div className={`flex items-center transition-all ${isHovered ? 'opacity-20' : ''}`}>
        <p className='text-xs'>{renderValue(dataType, value)}</p>
        {isLocked && <RiLockLine className='ml-1 w-3 h-3' />}
      </div>
    </div>
  )
}

export const DisplayField = ({ dataType = 'text', description, isLocked = false, label, selectOptions, value }) => {
  return (
    <div className='relative p-2 border-b-[1px] border-b-zinc-200/40 h-12'>
      <DisplayFieldBase
        dataType={dataType}
        description={description}
        isLocked={isLocked}
        label={label}
        selectOptions={selectOptions}
        value={value}
      />
    </div>
  )
}

const EditDisplayField = ({
  dataType = 'text',
  defaultValue,
  description,
  EditModal,
  editModalProps,
  isLocked = false,
  label,
  maxDate,
  minDate,
  name,
  onUpdate,
  selectOptions,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const renderEditField = () => {
    if (dataType === 'text' || dataType === 'number') {
      return (
        <Input type={dataType === 'number' ? 'number' : 'text'} value={value} onChange={e => setValue(e.target.value)} />
      );
    }
    if (dataType === 'dateTime') {
      return (
        <DateTimeSelector
          inputClassName="bg-zinc-100/0 rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-zinc-100 w-full"
          dateValue={{ startDate: dayjs(value).format('YYYY-MM-DD'), endDate: dayjs(value).format('YYYY-MM-DD') }}
          maxDate={maxDate}
          minDate={minDate}
          onDateChange={(val) => {
            let newStartDate = dayjs().add(10, 'minutes');
            if (val.startDate) {
              if (!value || value === 'Invalid Date') {
                newStartDate = dayjs(`${val.startDate} ${dayjs().format('HH:mm')}`)
              } else {
                newStartDate = dayjs(`${val.startDate} ${dayjs(value).format('HH:mm')}`)
              }
            }

            setValue(newStartDate);
          }}
          onTimeChange={(e) => {
            const newStartDate = dayjs(`${dayjs(value).format('YYYY-MM-DD')} ${e.target.value}`);
            setValue(newStartDate);
          }}
          timeValue={dayjs(value).format('HH:mm')}
        />
      );
    }
    if (dataType === 'select') {
      return (
        <ComboBox
          className='w-full'
          items={selectOptions.items}
          setValue={(value) => {
            const updatedValue = selectOptions.onSetValue(value);
            setValue(updatedValue);
          }}
          value={value}
        />
      );
    }
    if (dataType === 'boolean') {
      return (
        <div className='flex items-center'>
          <p className='text-xs mr-2'>{value ? 'Yes' : 'No'}</p>
          <Switch checked={value} onCheckedChange={() => setValue(!value)} />
        </div>
      );
    }
  }

  return (
    <div
      className='relative p-2 border-b-[1px] border-b-zinc-200/40 h-12'
      onMouseEnter={() => {
        if (!isLocked) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isLocked) {
          setIsHovered(false);
        }
      }}
    >
      {isEditing ? (
        <div className='absolute left-0 top-0 w-full h-full flex items-center justify-between'>
          <h5 className='font-bold z-0 w-[40%]'>{label}</h5>
          <div className='flex items-center justify-between gap-1 w-[56%]'>
            {renderEditField()}

            <div className='flex items-center gap-1'>
              <Button
                className='p-0'
                disabled={value === defaultValue}
                onClick={() => {
                  onUpdate(name, value);
                  setIsEditing(false)
                }}
                size='sm'
                variant='gradient-contained'
              >
                <RiCheckLine className='w-3 h-3' />
              </Button>
              <Button
                className='p-0'
                onClick={() => {
                  setIsEditing(false)
                  setValue(defaultValue)
                }}
                size='sm'
                variant='text'
              >
                <RiCloseLine className='w-3 h-3' />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <DisplayFieldBase
          dataType={dataType}
          description={description}
          isHovered={isHovered}
          isLocked={isLocked}
          label={label}
          selectOptions={selectOptions}
          value={value}
        />
      )}

      {isHovered && !isEditing && !isLocked && (
        <div className='absolute left-0 top-0 w-full h-full bg-zinc-800/60 flex items-center justify-end'>
          {EditModal ? (
            <EditModal {...editModalProps}>
              <Button className='text-2xs uppercase mr-2' size='xs' variant='gradient-contained'>
                Edit
              </Button>
            </EditModal>
          ) : (
            <Button className='text-2xs uppercase mr-2' onClick={() => setIsEditing(true)} size='xs' variant='gradient-contained'>
              Edit
            </Button>
          )}

        </div>
      )}
    </div>
  )
}

export default EditDisplayField;
