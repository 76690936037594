import { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "@repo/ui/input";
import { useTimer } from "@/hooks/useTimer";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";

export const Timer = ({
  duration,
  endTime,
  startTime,
  onTimeUpdate,
  editable = false,
  className = "",
  showZeroHours = true,
  timerPage = false,
}) => {
  const [timerValues, setTimerValues] = useState({
    timerHours: "00",
    timerMinutes: "00",
    timerSeconds: "00",
    total: 0
  });
  const [isEditing, setIsEditing] = useState(null);
  const [valueChanged, setValueChanged] = useState(false);
  const [originalValue, setOriginalValue] = useState(null);
  const timerRef = useRef(null);

  const { timeRemaining, isNegative, isCountingDownToFuture } = useTimer({
    startTime,
    endTime,
    duration,
  });

  const getTimeRemaining = useCallback(
    (endTimeStr) => {
      if (!endTime && duration != null) {
        // Ensure duration is always positive for countdown display
        const absDuration = Math.abs(duration);
        return {
          total: duration,
          hours: Math.floor(absDuration / 3600),
          minutes: Math.floor((absDuration % 3600) / 60),
          seconds: absDuration % 60,
        };
      } else if (!endTime && !duration) {
        return {
          total: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      const endTimeMs = new Date(endTimeStr).getTime();
      const now = Date.now();
      const total = endTimeMs - now;
      const totalSeconds = Math.abs(Math.floor(total / 1000));

      return {
        total,
        hours: Math.floor(totalSeconds / 3600),
        minutes: Math.floor((totalSeconds % 3600) / 60),
        seconds: totalSeconds % 60,
      };
    },
    [duration, endTime]
  );

  const startTimer = useCallback(
    (e) => {
      const { total, hours, minutes, seconds } = getTimeRemaining(e);
      setTimerValues({
        timerHours: String(Math.abs(hours)).padStart(2, "0"),
        timerMinutes: String(Math.abs(minutes)).padStart(2, "0"),
        timerSeconds: String(Math.abs(seconds)).padStart(2, "0"),
        total
      });
    },
    [getTimeRemaining]
  );

  const clearTimer = useCallback(
    (e) => {
      if (timerRef.current) clearInterval(timerRef.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1000);
      timerRef.current = id;
    },
    [startTimer]
  );

  useEffect(() => {
    if (endTime) {
      if (!timerRef.current) {
        clearTimer(endTime);
      }
    } else if (duration != null) {
      startTimer(endTime);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [clearTimer, endTime, duration, startTimer]);

  // Update timer values whenever timeRemaining changes
  useEffect(() => {
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    setTimerValues({
      timerHours: String(hours).padStart(2, "0"),
      timerMinutes: String(minutes).padStart(2, "0"),
      timerSeconds: String(seconds).padStart(2, "0"),
      total: timeRemaining
    });
  }, [timeRemaining]);

  const TimeBox = ({ value, unit }) => {
    const handleClick = () => {
      if (editable && !endTime && !isCountingDownToFuture) {
        setIsEditing(unit);
        setOriginalValue(value);
      }
    };

    const handleChange = (e) => {
      const newValue = parseInt(e.target.value) || 0;
      let newTotal = timerValues.total;

      switch (unit) {
        case "hours":
          newTotal = newValue * 3600 + (timerValues.total % 3600);
          break;
        case "minutes":
          newTotal =
            Math.floor(timerValues.total / 3600) * 3600 + newValue * 60 + (timerValues.total % 60);
          break;
        case "seconds":
          newTotal = Math.floor(timerValues.total / 60) * 60 + newValue;
          break;
      }

      // Just update the local state without calling onTimeUpdate
      setTimerValues({
        timerHours: unit === "hours" ? newValue.toString().padStart(2, "0") : timerValues.timerHours,
        timerMinutes: unit === "minutes" ? newValue.toString().padStart(2, "0") : timerValues.timerMinutes,
        timerSeconds: unit === "seconds" ? newValue.toString().padStart(2, "0") : timerValues.timerSeconds,
        total: newTotal
      });
      setValueChanged(true);
    };

    const handleBlur = () => {
      setIsEditing(null);
      if (!endTime && valueChanged && onTimeUpdate) {
        onTimeUpdate(timerValues.total);
        setValueChanged(false);
      }
    };

    if (isEditing === unit && editable && !endTime && !isCountingDownToFuture) {
      return (
        <Input
          type="text"
          min="0"
          max={unit === "hours" ? "23" : "59"}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`w-12 bg-transparent px-0 py-1 text-2xl font-bold text-center focus:outline-none ${isCountingDownToFuture ? "text-orange-300" : ""}`}
          autoFocus
        />
      );
    }

    return (
      <Tooltip delayDuration={200}>
        <TooltipTrigger className="h-full">
          <button
            onClick={handleClick}
            className={`flex items-center justify-center text-2xl font-bold
          ${isNegative ? "text-red-400" : "text-pink-100"}
          ${editable && !endTime && !isCountingDownToFuture ? "hover:opacity-75 cursor-pointer" : "cursor-default"}`}
          >
            {value}
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-sm">Edit {unit}</p>
        </TooltipContent>
      </Tooltip>
    );
  };

  // Calculate display values
  const displayHours = endTime
    ? timerValues.timerHours
    : String(Math.floor(Math.abs(timerValues.total) / 3600)).padStart(2, "0");
  const displayMinutes = endTime
    ? timerValues.timerMinutes
    : String(Math.floor((Math.abs(timerValues.total) % 3600) / 60)).padStart(2, "0");
  const displaySeconds = endTime
    ? timerValues.timerSeconds
    : String(Math.floor(Math.abs(timerValues.total) % 60)).padStart(2, "0");

  // Add helper function to format duration
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const parts = [];
    if (hours > 0) {
      parts.push(String(hours).padStart(2, "0"));
    }
    parts.push(String(minutes).padStart(2, "0"));
    parts.push(String(remainingSeconds).padStart(2, "0"));

    return parts.join(":");
  };

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="flex items-center gap-1">
        {isCountingDownToFuture && (
          <span className="text-sm text-orange-300 mr-2">Starting in:</span>
        )}
        <div className="flex items-center justify-between gap-1">
          {isNegative && <span className="text-2xl text-red-400">-</span>}
          {(Number(displayHours) > 0 || (!endTime && showZeroHours)) && (
            <>
              <TimeBox value={displayHours} unit="hours" />
              <span className="text-2xl text-pink-100">:</span>
            </>
          )}
          <TimeBox value={displayMinutes} unit="minutes" />
          <span className="text-2xl text-pink-100">:</span>
          <TimeBox value={displaySeconds} unit="seconds" />
        </div>
      </div>
      {isCountingDownToFuture && duration && (
        <div
          className={`text-sm text-zinc-400 mt-1 flex gap-2 items-center justify-between `}
        >
          <p>{timerPage ? "Duration:" : "Next Activity Duration:"}</p>
          {formatDuration(duration)}
        </div>
      )}
    </div>
  );
};
