"use client";

import * as React from "react";
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type RowSelectionState,
  type SortingState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@cardeio/ui/components/ui/table";
import { Input } from "@cardeio/ui/components/ui/input";
import { Button } from "@cardeio/ui/components/ui/button";
import {
  Search,
  Plus,
  Minus,
  ChevronUp,
  ChevronDown,
  ArrowUpDown,
} from "lucide-react";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  title: string;
  addButtonLabel?: string;
  onAddClick?: () => void;
  searchPlaceholder?: string;
  minimizable?: boolean;
  noResultsMessage?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  title,
  addButtonLabel = "Add",
  onAddClick,
  searchPlaceholder = "Search...",
  minimizable = true,
  noResultsMessage = "No results.",
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [filtering, setFiltering] = React.useState("");
  const [minimized, setMinimized] = React.useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: {
      rowSelection,
      globalFilter: filtering,
      sorting,
    },
  });

  if (minimized) {
    return (
      <div className="rounded-lg border border-neutral-800 bg-card text-neutral-100">
        <div className="flex items-center justify-between p-4">
          <h2 className="text-xl font-medium">{title}</h2>
          {minimizable && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setMinimized(false)}
              className="text-neutral-400 hover:text-neutral-100"
            >
              <Plus className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-lg border border-[#3B3D3B] bg-card text-neutral-100 p-10">
      <div className="flex items-center justify-between">
        {title && <h2 className="text-3xl font-medium mb-6">{title}</h2>}
        {minimizable && (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setMinimized(true)}
            className="text-neutral-400 hover:text-neutral-100"
          >
            <Minus className="h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex items-center justify-between pb-4 w-full">
        <div className="relative w-full">
          <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder={searchPlaceholder}
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
            className="max-w-sm w-full pl-8 bg-neutral-900 border-border text-neutral-100 focus-visible:ring-neutral-700 placeholder:text-muted-foreground"
          />
        </div>
        {onAddClick && (
          <div className="rounded-md p-[1px] bg-gradient-to-r from-[#F67519] to-[#CC045A]">
            <Button
              onClick={onAddClick}
              className="bg-card hover:bg-neutral-900/30 text-neutral-100 rounded-[calc(0.375rem-1px)] w-full"
            >
              <Plus className="mr-2 h-4 w-4" />
              {addButtonLabel}
            </Button>
          </div>
        )}
      </div>
      <div>
        <div className="rounded-md border border-[#3B3D3B] bg-[#2c2c2c]">
          <Table className="rounded-md">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  className="bg-[#18181B] hover:bg-[#18181B] border-b border-[#3B3D3B]"
                >
                  {headerGroup.headers.map((header, index) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={`text-neutral-400 uppercase text-xs font-normal tracking-wider border-r border-[#3B3D3B] first:rounded-tl-lg last:rounded-tr-lg px-4 ${
                          index === headerGroup.headers.length - 1
                            ? "border-r-0"
                            : ""
                        }`}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none flex items-center justify-between w-full"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getCanSort() &&
                              (header.column.getIsSorted() === "asc" ? (
                                <ChevronUp className="h-4 w-4 ml-2" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <ChevronDown className="h-4 w-4 ml-2" />
                              ) : (
                                <ArrowUpDown className="h-4 w-4 ml-2" />
                              ))}
                          </div>
                        )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="border-b border-[#3B3D3B]"
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <TableCell
                        key={cell.id}
                        className={`py-3 border-r border-[#3B3D3B] ${
                          index === row.getVisibleCells().length - 1
                            ? "border-r-0"
                            : ""
                        }`}
                        style={{
                          minWidth: cell.column.columnDef.minSize,
                          maxWidth: cell.column.columnDef.maxSize,
                          width: cell.column.columnDef.size,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {noResultsMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
