import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useEventStore } from "@/store/useEventStore";
import {
  startRoundTimerMutationFn,
  pauseRoundTimerMutationFn,
  stopRoundTimerMutationFn,
  updateRoundTimerMutationFn,
} from "@repo/api/base";

export function useTimerMutations() {
  const queryClient = useQueryClient();
  const { currentTournamentRound } = useEventStore();

  const startTimerMutation = useMutation({
    mutationFn: startRoundTimerMutationFn,
    onSuccess: (_, variables) => {
    },
    onError: (error) => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const pauseTimerMutation = useMutation({
    mutationFn: pauseRoundTimerMutationFn,
    onSuccess: (_, variables) => {
    },
    onError: (error) => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with pausing the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const stopTimerMutation = useMutation({
    mutationFn: stopRoundTimerMutationFn,
    onSuccess: () => {
    },
    onError: (error) => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with stopping the timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const updateTimerMutation = useMutation({
    mutationFn: updateRoundTimerMutationFn,
    onSuccess: () => {
    },
    onError: (error) => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error updating the timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  return {
    startTimerMutation,
    pauseTimerMutation,
    stopTimerMutation,
    updateTimerMutation,
  };
}
