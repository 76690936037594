type stopRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  id: string;
  type: "tournamentRound" | "activityPhase";
};

export const stopRoundTimerMutationFn = async ({
  clientAxiosInstance,
  id,
  type,
}: stopRoundTimerMutationFnParams) => {
  const endpoint =
    type === "tournamentRound"
      ? `/api/organize/tournamentRounds/${id}/stopTimer`
      : `/api/organize/activityPhases/${id}/stopTimer`;

  return clientAxiosInstance.put(endpoint);
};
