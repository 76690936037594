import React from "react";
import { GameSelectionIllustration } from "../../Illustrations/GameSelection";
import { GameComboBox } from "@repo/ui/combobox";

const StepOne = ({ activeStep, games, selectedGame }) => {
  const stepNumber = 1;
  const isActive = activeStep === stepNumber;

  const gameGroups = {};

  games.forEach((game) => {
    const gameInfo = {
      id: game.id,
      imageUrl: game?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url,
      label: game?.name,
      value: game?.slug?.toLowerCase(),
    };
    game?.gameTypes.forEach((gameType) => {
      if (!gameGroups[gameType]) {
        gameGroups[gameType] = [gameInfo]
      } else {
        gameGroups[gameType].push(gameInfo)
      }
    })
  })
  Object.keys(gameGroups).forEach((gameType) => {
    gameGroups[gameType] = gameGroups[gameType].sort(item => item.value);
  })

  return (
    <div className={`flex items-center justify-between pb-12 w-full ${isActive ? 'block animate-slideInFromBottom' : 'hidden'}`}>
      <div className='w-[40%] max-w-[500px]'>
        <GameSelectionIllustration />
      </div>

      <div className='w-[55%] flex flex-col gap-4'>
        <h2 className='text-xl font-bold'>What Game Are You Creating an Event For?</h2>
        <p className='text-sm text-zinc-200/60 mb-8'>Please select a Game from the options below that you'd like to create an event for.</p>
        <GameComboBox
          groups={gameGroups}
          items={games.map((game) => ({
            id: game.id,
            imageUrl: game?.resourceImages?.find(resImg => resImg.imageType === 'logo')?.image?.url,
            label: game?.name,
            value: game?.slug?.toLowerCase(),
          })).sort(item => item.value)}
        />
      </div>
    </div>
  )
}

export default StepOne;
