import { create } from 'zustand'

export const useConfirmDialogStore = create((set) => ({
  isOpen: false,
  title: '',
  description: '',
  confirmText: 'Confirm',
  typeToConfirmText: null,
  onConfirm: () => {},
  open: ({ title, description, confirmText = 'Confirm', typeToConfirmText = null, onConfirm }) => 
    set({ isOpen: true, title, description, confirmText, typeToConfirmText, onConfirm }),
  close: () => set({ isOpen: false })
}))