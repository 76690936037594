import React, { useEffect, useState } from "react";
import countryToCurrency from "country-to-currency";
import { useNavigate, useParams } from "@tanstack/react-router";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
// import { Switch } from "@repo/ui/switch";
import { Switch } from "@cardeio/ui/components/ui/switch";
import { Input } from "@cardeio/ui/components/ui/input";
import { Button } from "@repo/ui/button";
import { GooglePlacesAutocompleteInput } from "@cardeio/ui/components/forms/fields/google-places-autocomplete-input";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { FormDescription } from "@cardeio/ui/components/ui/form";
import { get } from "lodash";

const EventLocationSection = ({ form, showToggle = false }) => {
  const navigate = useNavigate();
  const [addressValue, setAddressValue] = useState("");
  const [urlError, setUrlError] = useState("");

  const { setValue } = form;
  const formValues = form.watch();

  // Initialize address value on component mount if address is already set
  useEffect(() => {
    if (formValues.address && !addressValue) {
      // This will trigger the other useEffect that formats the address
      const currentAddress = formValues.address;
      setValue('address', { ...currentAddress });
    }
  }, [formValues.address, addressValue, setValue]);

  useEffect(() => {
    if (formValues.inPersonEvent === false) {
      setValue('address', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.inPersonEvent]);

  useEffect(() => {
    if (formValues.address?.country) {
      setValue('currency', countryToCurrency[formValues.address?.country]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.address]);

  // Set initial address value if available
  useEffect(() => {
    if (formValues.address?.formatted_address) {
      setAddressValue(formValues.address.formatted_address);
    } else if (formValues.address) {
      // Construct a formatted address string from the available fields
      const addressParts = [];

      if (formValues.address.name) addressParts.push(formValues.address.name);
      if (formValues.address.address1) addressParts.push(formValues.address.address1);
      if (formValues.address.address2 && formValues.address.address2.trim()) addressParts.push(formValues.address.address2);

      const cityStateZip = [];
      if (formValues.address.city) cityStateZip.push(formValues.address.city);
      if (formValues.address.state) cityStateZip.push(formValues.address.state);
      if (formValues.address.zip) cityStateZip.push(formValues.address.zip);

      if (cityStateZip.length > 0) addressParts.push(cityStateZip.join(', '));
      if (formValues.address.country) addressParts.push(formValues.address.country);

      if (addressParts.length > 0) {
        setAddressValue(addressParts.join(', '));

        // If there's no formatted_address, add it to the address object
        if (!formValues.address.formatted_address) {
          setValue('address', {
            ...formValues.address,
            formatted_address: addressParts.join(', ')
          });
        }
      }
    }
  }, [formValues.address, setValue]);

  const handleAddressSelect = (address) => {
    // Create the address object with the same structure as before
    const formattedAddress = {
      geo: address.geo,
      formatted_address: address.formatted_address
    };

    // Map the address fields to match the expected structure
    if (address.address1) {
      formattedAddress.address1 = address.address1;
    }
    if (address.city) {
      formattedAddress.city = address.city;
    }
    if (address.state) {
      formattedAddress.state = address.state;
    }
    if (address.country) {
      formattedAddress.country = address.country;
    }
    if (address.zip) {
      formattedAddress.zip = address.zip;
    }

    // Preserve any existing fields that might be needed
    if (formValues.address?.id) {
      formattedAddress.id = formValues.address.id;
    }
    if (formValues.address?.resourceType) {
      formattedAddress.resourceType = formValues.address.resourceType;
    }
    if (formValues.address?.resourceId) {
      formattedAddress.resourceId = formValues.address.resourceId;
    }

    setValue('address', formattedAddress);
  };

  const playSetting = get(formValues, 'configuration.playSetting')
  const showAInPersonEvent = playSetting === 'inPerson'

  // Add URL validation function
  const validateUrl = (url) => {
    if (!url) return true; // Allow empty URLs

    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    form.setValue("eventUrl", value);

    if (value && !validateUrl(value)) {
      setUrlError("Please enter a valid URL (e.g., https://example.com)");
    } else {
      setUrlError("");
    }
  };

  return (
    <FormCard
      title="Location"
      subtitle="Where can players participate in this event?"
      className="mb-4 border border-border"
      variant="uap"
    >

      {showToggle && (
        <div>
          <FormField
            control={form.control}
            name="configuration.playSetting"
            render={({ field }) => (
              <FormItem className='flex flex-row items-center gap-2 w-[48%] space-y-0'>
                <FormControl>
                  <Switch
                    id="in-person-event-switch"
                    checked={field.value === 'inPerson'}
                    onCheckedChange={(checked) => field.onChange(checked ? 'inPerson' : 'online')}
                    variant="gradient"
                  />
                </FormControl>
                <FormLabel className='mt-1'>This event is happening in person</FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}

      {showAInPersonEvent && (
        <div className="ml-4 space-y-3">
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className='w-full'>
                <FormLabel className='mt-2'>Location</FormLabel>
                <FormDescription className="max-w-sm">
                  By default, we will use the address of your store. If you wish to change it, please be sure to include any additional instructions.
                </FormDescription>
                <FormControl>
                  <GooglePlacesAutocompleteInput
                    apiKey={import.meta.env.VITE_GOOGLE}
                    value={addressValue}
                    onChange={setAddressValue}
                    onAddressSelect={handleAddressSelect}
                    placeholder="Search location..."
                    className="w-full border-border"
                    inputVariant="uap"
                    showFallbackDialog={true}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="locationNotes"
            render={({ field }) => (
              <FormItem className={"w-full"}>
                <FormLabel>Additional Location Notes</FormLabel>
                <FormControl>
                  <Input placeholder='Ex. "Enter through Macys and turn right at the fountain"' variant="uap" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}
      {!showAInPersonEvent &&
        <FormField
          control={form.control}
          name="eventUrl"
          render={({ field }) => (
            <FormItem className={"w-full"}>
              <FormLabel>Event URL</FormLabel>
              <FormControl>
                <Input
                  placeholder='https://'
                  variant="uap"
                  {...field}
                  onChange={handleUrlChange}
                />
              </FormControl>
              {urlError && <p className="text-destructive text-sm mt-1">{urlError}</p>}
            </FormItem>
          )}
        />}



    </FormCard>
  )
}

export default EventLocationSection;
