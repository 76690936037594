import React from "react";
import * as z from "zod";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";

import { webAxiosInstance } from "@/utils/axios";
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from "@repo/ui/modal";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";
import { copyEventMutationFn } from "@repo/api/base";
import DateTimeSelector from "@/components/Calendar/DateTimeSelector";

const copyEventFormSchema = z.object({
  name: z.string(),
  startsAt: z.any(),
  endsAt: z.any(),
}).partial();

copyEventFormSchema.required({
  name: true,
  startsAt: true,
  endsAt: true,
});

const CopyEventModal = ({ event, handleClose }) => {
  const { entityId } = useParams({ strict: false });
  const queryClient = useQueryClient();

  const form = useForm({
    resolver: zodResolver(copyEventFormSchema),
    defaultValues: {
      name: event.name,
      startsAt: event.startsAt,
      endsAt: event.endsAt,
    },
  });

  const { setValue } = form;
  const formValues = form.watch();

  const copyEventMutation = useMutation({
    mutationFn: copyEventMutationFn,
    onSuccess: ({ data }) => {
      toast.success('Successfully copied event.');
      window.open(`/entity/${entityId}/events/${data.data.id}`, '_blank').focus()
      queryClient.refetchQueries();
      handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an issue with copying this event.",
        action: {
          label: "Contact Us",
          onClick: () => window.location.href = 'https//carde.io/support',
        },
      })
    },
  });

  const onSubmit = (values) => {
    copyEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      eventId: event.id,
      body: {
        activities: event.activities.map((activity) => ({
          id: activity.id,
          startsAt: values.startsAt,
        })),
        name: values.name,
        startsAt: values.startsAt,
        endsAt: values.endsAt,
      }
    });
  }

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Copy Event</ModalTitle>
        <p className='text-3xs text-zinc-200/60 italic'>This action will create a brand new clone of this event with no users.</p>
      </ModalHeader>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="lg:flex-auto space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className='w-full sm:w-[70%]'>
                  <FormLabel>New Event Name</FormLabel>
                  <FormControl>
                    <Input placeholder="New Event Name" onChange={(e) => form.setValue('name', e.target.value)} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className='flex gap-8'>
              <FormField
                control={form.control}
                name="startsAt"
                rules={{
                  required: {
                    value: true,
                    message: 'Start Date & Time is required',
                  },
                }}
                render={({ field }) => (
                  <FormItem className='w-[48%]'>
                    <FormLabel htmlFor="startsAt">Starts At</FormLabel>
                    <FormControl>
                      <DateTimeSelector
                        inputClassName="bg-zinc-100/0 rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-zinc-100 w-full"
                        dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                        minDate={dayjs()}
                        onDateChange={(value) => {
                          let newStartDate = dayjs().add(10, 'minutes');
                          if (value.startDate) {
                            if (!value || value === 'Invalid Date') {
                              newStartDate = dayjs(`${value.startDate} ${dayjs().format('HH:mm')}`)
                            } else {
                              newStartDate = dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`)
                            }
                          }

                          setValue('startsAt', newStartDate);
                          setValue('endsAt', newStartDate.add(4, 'hours'));
                        }}
                        onTimeChange={(e) => {
                          const newStartDate = dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`);
                          setValue('startsAt', newStartDate);
                          setValue('endsAt', newStartDate.add(4, 'hours'));
                        }}
                        timeValue={dayjs(field.value).format('HH:mm')}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="endsAt"
                render={({ field }) => (
                  <FormItem className='w-[48%]'>
                    <FormLabel htmlFor="endsAt">Ends At</FormLabel>
                    <FormControl>
                      <DateTimeSelector
                        inputClassName="bg-zinc-100/0 rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-zinc-100 w-full"
                        dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                        minDate={dayjs(formValues.startsAt)}
                        onDateChange={(value) => {
                          if (!value) {
                            setValue('endsAt', dayjs().add(10, 'minutes'));
                          } else {
                            setValue('endsAt', dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`))
                          }
                        }}
                        onTimeChange={(e) => {
                          setValue('endsAt', dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`))
                        }}
                        timeValue={dayjs(field.value).format('HH:mm')}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>


            <ModalFooter className='items-end'>
              <div>
                <Button
                  onClick={handleClose}
                  style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
                  variant='text'
                >
                  Cancel
                </Button>
                <Button
                  className='h-fit'
                  dataTooltipTarget="tooltip-default"
                  variant='gradient-contained'
                  style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
                  type='submit'
                >
                  Copy Event
                </Button>
              </div>
            </ModalFooter>
          </form>
        </Form>
      </div>
    </ModalContent>
  )
}

export default CopyEventModal;
