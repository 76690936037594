import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "@remixicon/react";
import countryToCurrency from "country-to-currency";
import { useNavigate, useParams } from "@tanstack/react-router";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/form";
import { CurrencyInput } from "@repo/ui/input";
import { Input } from "@cardeio/ui/components/ui/input";
import { Button } from "@cardeio/ui/components/ui/button";
import { Switch } from "@cardeio/ui/components/ui/switch";
import FormCard from "@cardeio/ui/components/forms/form-card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@cardeio/ui/components/ui/select";
import { ExternalLinkIcon, TriangleAlert } from "lucide-react";
import { convertCentsToAmount } from "packages/utils/src/currency";

const EventBasicDetailsSection = ({ form, hasPayoutEnabled }) => {
  const { entityId } = useParams({ strict: false });
  const navigate = useNavigate();
  const formValues = form.watch();
  const [hasEntryFee, setHasEntryFee] = useState(true);
  const [skipForNow, setSkipForNow] = useState(false);

  const { setValue } = form;

  useEffect(() => {
    if (formValues.address?.country) {
      setValue("currency", countryToCurrency[formValues.address?.country]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.address]);

  // Update ticketPrice when hasEntryFee changes
  useEffect(() => {
    if (!hasEntryFee) {
      setValue('ticketPrice', '');
    } else {
      const defaultPrice = formValues.configuration?.defaultTicketPrice || 0;
      setValue('ticketPrice', convertCentsToAmount(defaultPrice, formValues.currency));
    }
  }, [hasEntryFee, setValue, formValues.configuration?.defaultTicketPrice, formValues.currency]);

  return (
    <FormCard
      title="Tickets"
      className="mb-4 border border-border"
      variant="uap"
    >
      <div className="flex flex-col gap-6">
        <div>
          <div className="flex flex-row items-center gap-2 space-y-0">
            <Switch
              id="has-entry-fee-switch"
              checked={hasEntryFee}
              onCheckedChange={setHasEntryFee}
              variant="gradient"
            />
            <FormLabel className="mt-1">This event has an entry fee</FormLabel>
          </div>

          {hasEntryFee && (
            <div className="mt-4 flex flex-col gap-4 w-fit ml-4">
              <FormField
                control={form.control}
                name="paymentMethod"
                render={({ field }) => (
                  <FormItem className="w-fit">
                    <FormLabel>How are you accepting payment?</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="input-background border-border">
                          <SelectValue placeholder="Select payment method" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="onlineAndInPerson">
                          In-person and online
                        </SelectItem>
                        <SelectItem value="inPerson">In-person only</SelectItem>
                        <SelectItem value="online">Online only</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="ticketPrice"
                render={({ field }) => (
                  <FormItem className="w-fit">
                    <FormLabel>Entry Fee</FormLabel>
                    <CurrencyInput
                      className="input-background border-border"
                      placeholder="Entry Fee"
                      {...field}
                      onChangeCurrency={(currency) => {
                        setValue("currency", currency);
                      }}
                      selectedCurrency={formValues.currency}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!hasPayoutEnabled && !skipForNow && (
                <div className="p-4 -ml-4 bg-destructive/30 rounded-lg outline outline-1 outline-offset-[-1px] outline-red-500 flex flex-col justify-start items-start gap-4">
                  <div className="relative flex justify-start items-start">
                    <div className="flex-1 flex items-center gap-2 justify-start">
                      <TriangleAlert className="w-4 h-4 text-red-400" />
                      Please connect a payout account before publishing this
                      event
                    </div>
                  </div>
                  <div className="justify-start text-sm">
                    You may skip this step for now but in order to receive
                    ticket revenue, you must link a payout account before any
                    online ticket is sold.
                  </div>
                  <div className="flex justify-start items-start gap-3">
                    <Button
                      variant="gradient"
                      className="h-9 px-3 py-2 rounded-full flex justify-center items-center gap-2"
                    >
                      <ExternalLinkIcon className="w-4 h-4" />
                      Link a Payout Account
                    </Button>
                    <Button
                      variant="outline-gradient"
                      onClick={() => setSkipForNow(true)}
                      className="h-9 px-3 py-2 rounded-full flex justify-center items-center gap-2 bg-transparent font-normal"
                    >
                      Skip for now
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <FormField
          control={form.control}
          name="capacity"
          render={({ field }) => (
            <FormItem className="w-full ml-4">
              <FormLabel>Maximum Event Capacity</FormLabel>
              <FormControl>
                <Input
                  placeholder="Maximum Event Capacity"
                  type="number"
                  variant="uap"
                  {...field}
                  className="max-w-[12rem]"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </FormCard>
  );
};

export default EventBasicDetailsSection;
