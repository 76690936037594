import { cn } from "@/utils/cn";
import { Badge } from "@cardeio/ui/components/ui/badge";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

const statuses = {
  received: {
    text: 'Publisher Received',
    classes: 'bg-badge-received text-badge-received border-badge-received',
  },
  approvedRetailOnly: {
    text: 'Retail Only',
    classes: 'bg-badge-approved text-green-500 border-badge-approved',
  },
  approvedOpOnly: {
    text: 'Organized Play Only',
    classes: 'bg-green-900/40 text-green-500 border-green-800',
  },
  approvedRetailOP: {
    text: 'Retail + Organized Play',
    classes: 'bg-badge-approved text-green-500 border-badge-approved',
  },
  pending: {
    text: 'Pending Approval',
    classes: 'bg-badge-pending text-badge-pending border-badge-pending',
  },
  draft: {
    text: 'Draft',
    classes: 'bg-badge-pending text-badge-pending border-badge-pending',
  },
  rejected: {
    text: 'Rejected',
    classes: 'bg-badge-rejected text-badge-rejected border-badge-rejected',
    icon: <XMarkIcon className="w-4 h-4 text-destructive-dark" />,
  },
  rejectedMissingInfo: {
    text: 'Missing Information',
    classes: 'bg-badge-rejected text-badge-rejected border-badge-rejected',
    icon: <ExclamationCircleIcon className="w-4 h-4 text-destructive-dark" />,
  },
  rejectedUnsoportedCountry: {
    text: 'Unsupported Country',
    classes: 'bg-badge-rejected text-badge-rejected border-badge-rejected',
  },
  rejectedDisqualified: {
    text: 'Disqualified',
    classes: 'bg-badge-rejected text-badge-rejected border-badge-rejected',
  },
  rejectedCancelled: {
    text: 'Cancelled',
    classes: 'bg-badge-rejected text-badge-rejected border-badge-rejected',
  },
}
const TaskStatusBadge = ({ status }) => {
  return status && statuses[status] && <Badge className={cn("rounded-sm flex gap-2 w-fit self-center font-normal", statuses[status]?.classes)} variant={status}>{statuses[status]?.icon}{statuses[status]?.text}</Badge>;
};

export default TaskStatusBadge;
