import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { 
  addActivityTicketPrice, 
  disableTicketPriceMutationFn, 
  updateTicketPriceMutationFn 
} from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";

const SUPPORT_URL = "https://carde.io/support";

const ERROR_MESSAGE = {
  description: "There was an error with updating this ticket information. Please reach out to support. We look forward to hearing from you!",
  action: {
    label: "Contact Support",
    onClick: () => (window.location.href = SUPPORT_URL),
  },
};

export const useCreateActivityTicketMutation = (eventId, handleClose) => {
  return useMutation({
    mutationFn: addActivityTicketPrice,
    onSuccess: () => {
      toast("Successfully Updated Ticket Information!");
      queryClient.invalidateQueries(["event", eventId]);
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", ERROR_MESSAGE);
    },
  });
};

export const useDeleteTicketPriceMutation = (eventId, handleClose) => {
  return useMutation({
    mutationFn: disableTicketPriceMutationFn,
    onSuccess: () => {
    //   queryClient.invalidateQueries(["event", eventId]);
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        ...ERROR_MESSAGE,
        description: "There was an error with deleting this ticket information. Please reach out to support. We look forward to hearing from you!",
      });
    },
  });
};

export const useUpdateTicketPriceMutation = (eventId, handleClose) => {
  return useMutation({
    mutationFn: updateTicketPriceMutationFn,
    onSuccess: () => {
      toast("Successfully Updated Ticket Information!");
      queryClient.invalidateQueries(["event", eventId]);
      handleClose && handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", ERROR_MESSAGE);
    },
  });
}; 