import OneVsOnePlayerInfo from "@/components/Cards/Variants/Pairings/OneVsOne/PlayerInfo";

const ByeCard = ({ pairing }) => {
  return (
    <>
      <li key={pairing.id} className="mx-2 group flex items-center rounded-xl">
        <button className="w-full h-full flex items-center gap-3">
          <div className="bg-zinc-900 rounded-xl flex items-center justify-center">
            <span className="font-medium flex flex-col items-center justify-center p-2 py-3">
              <div className="text-sm">TABLE</div>
              <span className="text-lg">{pairing.tableNumber}</span>
            </span>
          </div>
          <div className="flex-1 flex w-full h-full items-center py-3 justify-between bg-[#16A34A66]/40 border border-green-500/50 px-2 rounded-xl">
            <OneVsOnePlayerInfo player={pairing?.tournamentPairingUsers?.[0]} />
            <span className="text-2xl text-white me-2">BYE</span>
          </div>
        </button>
      </li>
    </>
  );
};

export default ByeCard;
