import React, { useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { useSpring } from 'react-spring';
import { getTimeZones } from '@vvo/tzdb';
import tzLookup from "tz-lookup";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Switch } from "@cardeio/ui/components/ui/switch";
import { ComboBox } from "@repo/ui/combobox";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { turnTimezonesIntoOptions } from "@/components/Flows/CreateEvent/utils/timezones";
import DateTimeSelector from "@/components/Calendar/DateTimeSelector";
import { checkinPeriodChoices } from "@/components/Flows/CreateEvent/utils/checkInTimes";
import { defaultEventName } from "@/components/Flows/CreateEvent/utils/defaultValues";
import { generateDates } from "../../utils/generateDates";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const countriesOrder = ['United States', 'Canada', 'United Kingdom', 'Mexico', 'Australia', 'Japan'];
const recurringOptions = [
  {
    id: 'daily',
    value: 'daily',
    label: 'Daily',
  },
  {
    id: 'weekly',
    value: 'weekly',
    label: 'Weekly',
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: 'Monthly',
  },
];


export const timezones = getTimeZones()
  .filter((value, index, self) => self.findIndex((v) => v.name === value.name) === index)
  .sort((a, b) => {
    var textA = a?.name?.toUpperCase();
    var textB = b?.name?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export const countries = uniq(timezones.map((value) => value.countryName)).sort((a, b) => {
  const aIndex = countriesOrder.indexOf(a);
  const bIndex = countriesOrder.indexOf(b);
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  } else if (aIndex !== -1) {
    return -1;
  } else if (bIndex !== -1) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
});
const getTZCountryFromCode = (TZCode) => {
  return (
    timezones.find((timeZone) => {
      return timeZone?.countryCode === TZCode?.toUpperCase();
    })?.countryName || null
  );
};

const EventDateTimeSection = ({ form, game, flow }) => {
  const {
    formState: { defaultValues },
    setValue,
  } = form;


  const formValues = form.watch();
  const address = formValues?.address;



  useEffect(() => {
    if (address?.geo?.lat && address?.geo?.lng && !formValues.timezone) {
      const newTimezone = tzLookup(address.geo.lat, address.geo.lng);
      setValue('timezone', newTimezone);
    }
  }, [address?.geo?.lat, address?.geo?.lng, formValues.timezone, setValue]);

  const [TZCountry, setTZCountry] = useState(address?.country ? getTZCountryFromCode(address.country) : null);

  useEffect(() => {
    if (address?.country) {
      setTZCountry(getTZCountryFromCode(address.country));
    }
  }, [address?.country]);

  const formatTimezoneForDisplay = (timezone) => {
    if (!timezone) return '';
    if (typeof timezone === 'string') {
      return timezone;
    }
    return timezone.label || timezone.id || timezone;
  };

  const spring = useSpring(() => ({
    to: async (next) => {
      await next({ opacity: 1, marginLeft: '0px' });
    },

    config: {
      mass: 1,
      tension: 500,
      friction: 15,
    },
    from: {
      position: 'absolute',
      bottom: '10px',
      left: '25px',
      opacity: 0,
      marginLeft: '-10px',
    },
    immediate: true,
    reset: true,
    reverse: true,
  }));

  const tiggle = spring[1]

  useEffect(() => {
    if (formValues.startsAt && defaultValues.endsAt === null) {
      // Create a new dayjs object to avoid mutation issues
      setValue('endsAt', dayjs(formValues.startsAt).add(4, 'hours'));
    }

    // Only update the name if it's a day-based name and needs to be updated
    const nameFirstWord = formValues.name?.split(' ')?.[0]?.toLowerCase();
    if (nameFirstWord && ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].includes(nameFirstWord)) {
      const newName = defaultEventName(formValues.startsAt, game);
      // Only update if the name actually changed to avoid unnecessary re-renders
      if (formValues.name !== newName) {
        setValue('name', newName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.endsAt, formValues.startsAt, game]);

  // Separate useEffect for name updates to reduce dependencies
  useEffect(() => {
    if (formValues.inPersonEvent === true && formValues.address?.country) {
      setTZCountry(getTZCountryFromCode(formValues?.address?.country));

      // Only update timezone if we have valid geo coordinates
      if (formValues?.address?.geo?.lat && formValues?.address?.geo?.lng) {
        const newTimezone = tzLookup(formValues.address.geo.lat, formValues.address.geo.lng);
        // Only update if the timezone actually changed
        if (formValues.timezone !== newTimezone) {
          setValue('timezone', newTimezone);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.address, formValues.inPersonEvent]);

  useEffect(() => {
    const currentUsersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (formValues.timezone?.id && currentUsersTimezone !== formValues.timezone?.id) {
      tiggle({
        marginLeft: '0px',
        config: {
          mass: 1,
          tension: 500,
          friction: 15,
        },
        from: {
          marginLeft: '-10px',
          opacity: 1,
        },
        immediate: false,
        reset: true,
        reverse: true,
      });
    }
  }, [formValues.timezone, tiggle]);

  // Keep recurring fields in sync for backward compatibility
  useEffect(() => {
    if (formValues.isRecurring !== undefined) {
      setValue('recurring', formValues.isRecurring);
    }
    if (formValues.recurringType !== undefined) {
      setValue('recurringInterval', formValues.recurringType);
    }
    if (formValues.recurringEndDate !== undefined) {
      // Calculate recurringAmount based on end date and interval
      if (formValues.recurringEndDate && formValues.recurringType) {
        const startDate = dayjs(formValues.startsAt);
        const endDate = dayjs(formValues.recurringEndDate);
        let amount = 0;

        if (formValues.recurringType === 'daily') {
          amount = endDate.diff(startDate, 'day');
        } else if (formValues.recurringType === 'weekly') {
          amount = endDate.diff(startDate, 'week');
        } else if (formValues.recurringType === 'monthly') {
          amount = endDate.diff(startDate, 'month');
        }

        setValue('recurringAmount', amount > 0 ? amount : 1);
      }
    }
  }, [formValues.isRecurring, formValues.recurringType, formValues.recurringEndDate, formValues.startsAt, setValue]);

  const datesObject = useMemo(() => {
    return generateDates(formValues, game)
  }, [formValues, game])

  useEffect(() => {
    if (!formValues.startsAt && datesObject.firstValidDate) {
      setValue('startsAt', datesObject.firstValidDate)
      setValue('endsAt', dayjs(datesObject.firstValidDate).add(4, 'hours'))
    }
  }, [datesObject, formValues.startsAt, setValue])

  return (
    <FormCard
      title="Date & Time"
      subtitle="When is your event?"
      className="mb-4 border border-border"
      variant="uap"
    >
      <div className='flex justify-between'>
        <FormField
          control={form.control}
          name="startsAt"
          rules={{
            required: {
              value: true,
              message: 'Start Date & Time is required',
            },
          }}
          render={({ field }) => (
            <FormItem className='w-[48%]'>
              <FormLabel htmlFor="startsAt">Starts At</FormLabel>
              <FormControl>
                <DateTimeSelector
                  inputClassName="input-background rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-border w-full"
                  dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                  minDate={datesObject.firstValidDate ?? dayjs()}
                  disabledDates={datesObject.invalidDates}
                  onDateChange={(value) => {
                    let newStartDate = dayjs().add(10, 'minutes');
                    if (value.startDate) {
                      if (!value || value === 'Invalid Date') {
                        newStartDate = dayjs(`${value.startDate} ${dayjs().format('HH:mm')}`)
                      } else {
                        newStartDate = dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`)
                      }
                    }

                    setValue('startsAt', newStartDate);
                    setValue('endsAt', dayjs(newStartDate.valueOf()).add(4, 'hours'));
                  }}
                  onTimeChange={(e) => {
                    const newStartDate = dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`);
                    setValue('startsAt', newStartDate);
                    setValue('endsAt', dayjs(newStartDate.valueOf()).add(4, 'hours'));
                  }}
                  startFrom={datesObject.firstValidDate}
                  timeValue={dayjs(field.value).format('HH:mm')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="endsAt"
          render={({ field }) => (
            <FormItem className='w-[48%]'>
              <FormLabel htmlFor="endsAt">Ends At</FormLabel>
              <FormControl>
                <DateTimeSelector
                  inputClassName="input-background rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-border w-full"
                  dateValue={{ startDate: dayjs(field.value).format('YYYY-MM-DD'), endDate: dayjs(field.value).format('YYYY-MM-DD') }}
                  minDate={datesObject.firstValidDate ?? dayjs()}
                  disabledDates={datesObject.invalidDates}
                  onDateChange={(value) => {
                    if (!value) {
                      setValue('endsAt', dayjs().add(10, 'minutes'));
                    } else {
                      setValue('endsAt', dayjs(`${value.startDate} ${dayjs(field.value).format('HH:mm')}`))
                    }
                  }}
                  startFrom={datesObject.firstValidDate}
                  onTimeChange={(e) => {
                    setValue('endsAt', dayjs(`${dayjs(field.value).format('YYYY-MM-DD')} ${e.target.value}`))
                  }}
                  timeValue={dayjs(field.value).format('HH:mm')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className='flex flex-col gap-6'>
        <FormField
          control={form.control}
          name="configuration.entryTime"
          render={({ field }) => (
            <FormItem className='w-[32%]'>
              <FormLabel>Check-In Time</FormLabel>
              <FormControl>
                <ComboBox
                  className='w-full input-background border-border'
                  items={checkinPeriodChoices}
                  setValue={(val) => {
                    const updatedValue = checkinPeriodChoices.find(choice => choice.label === val);
                    field.onChange(updatedValue.id);
                  }}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {!formValues.inPersonEvent && (
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem className='w-[32%]'>
                <FormLabel>Timezone</FormLabel>
                <FormControl>
                  <ComboBox
                    className='w-full input-background border-border'
                    items={turnTimezonesIntoOptions(TZCountry)}
                    setValue={(val) => {
                      const updatedValue = turnTimezonesIntoOptions(TZCountry).find(choice => choice.label === val);
                      field.onChange(updatedValue?.id || val);
                    }}
                    value={formatTimezoneForDisplay(field.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <div>
          <FormField
            control={form.control}
            name="isRecurring"
            render={({ field }) => (
              <FormItem className='flex flex-row items-center gap-2 space-y-0'>
                <FormControl>
                  <Switch
                    id="recurring-event-switch"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    variant="gradient"
                  />
                </FormControl>
                <FormLabel className='mt-1'>Make this event recurring</FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      {formValues.isRecurring && (
        <div className="ml-4 space-y-3">
          <FormField
            control={form.control}
            name="recurringType"
            render={({ field }) => (
              <FormItem className='w-full'>
                <FormLabel>Recurring Type</FormLabel>
                <FormControl>
                  <ComboBox
                    className='w-full input-background border-border'
                    items={recurringOptions}
                    setValue={(val) => {
                      const updatedValue = recurringOptions.find(choice => choice.label === val);
                      field.onChange(updatedValue.value);
                    }}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="recurringEndDate"
            render={({ field }) => (
              <FormItem className='w-full'>
                <FormLabel>Recurring End Date</FormLabel>
                <FormControl>
                  <DateTimeSelector
                    inputClassName="input-background rounded-l-lg text-zinc-100 text-sm px-3 py-[7px] border-border w-full"
                    dateValue={{
                      startDate: field.value ? dayjs(field.value).format('YYYY-MM-DD') : '',
                      endDate: field.value ? dayjs(field.value).format('YYYY-MM-DD') : ''
                    }}
                    minDate={dayjs(formValues.endsAt)}
                    onDateChange={(value) => {
                      if (!value) {
                        setValue('recurringEndDate', null);
                      } else {
                        setValue('recurringEndDate', dayjs(value.startDate).endOf('day'))
                      }
                    }}
                    timeValue=""
                    hideTimeInput={true}
                    startFrom={datesObject.firstValidDate}

                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}
    </FormCard>
  )
}

export default EventDateTimeSection;
