import { Suspense, useContext, useState } from "react";
import SideNavigation from "@/components/Navigation/Side";
import { orgRouteConfig } from "@/components/Navigation/Side/routes/orgConfig";
import { establishmentRouteConfig } from "@/components/Navigation/Side/routes/establishmentConfig";
import { gameRouteConfig } from "@/components/Navigation/Side/routes/gameConfig";
import { baseRouteConfig } from "@/components/Navigation/Side/routes/baseConfig";
import { AbilityContext } from "@repo/utils/permissions";
import { useParams, useRouterState } from "@tanstack/react-router";
import { useRootStore } from "../store/useRootStore";
import IntercomCardeBot from "./Assistant/IntercomCardeBot";

const getDefaultSelectedNavTab = (pathname) => {
  const splitPath = pathname.split("/");
  if (splitPath?.[1] === "org" && splitPath?.[3] !== "entity") {
    if (splitPath?.[3]) {
      return splitPath?.[3];
    } else {
      return "";
    }
  } else if (splitPath?.[1] === "org" && splitPath?.[3] === "entity") {
    if (splitPath?.[5]) {
      return splitPath?.[5];
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const getDefaultNavToShow = (
  pathname,
  orgId,
  entityId,
  ability,
  activeEntity
) => {
  const splitPath = pathname.split("/");
  if (splitPath?.[1] === "org" && splitPath?.[3] !== "entity") {
    return orgRouteConfig(ability, orgId).filter((item) => item.show);
  } else if (splitPath?.[1] === "org" && splitPath?.[3] === "entity") {
    if (activeEntity?.type === "Establishment") {
      return establishmentRouteConfig(ability, orgId, entityId).filter(
        (item) => item.show
      );
    } else {
      return gameRouteConfig(ability, orgId, entityId).filter(
        (item) => item.show
      );
    }
  } else {
    return baseRouteConfig;
  }
};

const PageContentContainer = ({ children }) => {
  const { userData } = useRootStore();
  const router = useRouterState();
  const pathname = router.location.pathname;
  const ability = useContext(AbilityContext);
  const { entityId, orgId } = useParams({ strict: false });

  const activeEntity =
    userData?.organizations
      ?.find((org) => org.id === orgId)
      ?.entities.find((entity) => entity.id === entityId) || [];
  const [navToShow, setNavToShow] = useState(
    getDefaultNavToShow(pathname, orgId, entityId, ability, activeEntity)
  );
  const [selectedNavTab, setSelectedNavTab] = useState(
    getDefaultSelectedNavTab(pathname)
  );

  const hasSubSidebar =
    navToShow.find((nav) => nav.key === selectedNavTab)?.subRoutes?.length > 0;

  const route = window.location.pathname;
  const hasTimer = route.includes('/timer');

  if (hasTimer) {
    return <div>{children}</div>
  }

  return (
    <>
      <div>
        {children}
      </div>
    </>
  );
};

export default PageContentContainer;
