"use client";

import {
  BadgeCheck,
  Bell,
  Building2,
  Check,
  ChevronsUpDown,
  CreditCard,
  LogOut,
  NewspaperIcon,
  Plus,
  Sparkles,
  User,
  Store,
} from "lucide-react";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@cardeio/ui/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@repo/ui/dropdown";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@cardeio/ui/components/ui/sidebar";
import { Link, useParams } from "@tanstack/react-router";
import {
  ArrowLeftStartOnRectangleIcon,
  BuildingStorefrontIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
} from "@heroicons/react/20/solid";
import { useRootStore } from "@/store/useRootStore";
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { findEntityById } from "@/utils/entity/findEntityById";
import React from "react";
import { cn } from "@/utils/cn";

export function NavEntity({ details = {}, context = "org" }) {
  const { userData } = useRootStore();
  const organizations = userData?.organizations ?? [];
  const { entityId, orgId } = useParams({ strict: false });
  const organization = orgId
    ? organizations.find((org) => org.id === orgId)
    : organizations[0];
  const currentOrg =
    context === "entity"
      ? findOrganizationByEntityId(userData?.organizations, entityId) ||
        userData?.organizations[0]
      : organization;
  const currentEntity =
    findEntityById(currentOrg?.entities, entityId) || currentOrg?.entities[0];

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage
                  src="https://storage.googleapis.com/cardeio-images/cardeio/cardeio-icon.webp"
                  alt={details.name}
                />
                <AvatarFallback className="rounded-lg">CN</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{details.name}</span>
                <span className="truncate text-xs">{details.extra?.name}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-80 min-w-56 rounded-lg bg-sidebar-accent border-sidebar-accent"
            side={"bottom"}
            align="end"
            sideOffset={4}
          >
            {organizations.map((org) => {
              const isCurrentOrg = currentOrg?.id === org.id;
              const stores = org.entities?.filter(
                (entity) => entity.type === "Establishment"
              );
              const storeCount = stores?.length || 0;
              return (
                <React.Fragment key={org.id}>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className={cn("flex items-center justify-between", {
                        "bg-white/5": isCurrentOrg,
                      })}
                    >
                      <div className="flex items-center gap-2 min-w-0">
                        <Building2 className="size-5 flex-shrink-0" />
                        <span className="truncate">{org.name}</span>
                      </div>
                      <div className="flex items-center gap-2 flex-shrink-0">
                        <span className="text-xs text-muted-foreground whitespace-nowrap">
                          {storeCount} {storeCount === 1 ? "Store" : "Stores"}
                        </span>
                        <ChevronsUpDown className="size-4" />
                      </div>
                    </DropdownMenuItem>
                    {stores?.length > 0 && (
                      <div className="pl-2 py-1">
                        <DropdownMenuLabel className="font-normal text-xs text-muted-foreground">
                          Stores
                        </DropdownMenuLabel>
                        {stores.map((entity) => {
                          const isCurrentStore =
                            currentEntity?.id === entity.id;
                          return (
                            <DropdownMenuItem
                              key={entity.id}
                              className="pl-2 text-sm"
                            >
                              <Link
                                to={`/entity/${entity.id}`}
                                className={cn(
                                  "w-full flex gap-2 hover:opacity-80 cursor-pointer hover:bg-gray-400 items-center justify-between min-w-0",
                                  {
                                    "bg-white/5": isCurrentStore,
                                  }
                                )}
                              >
                                <div className="flex items-center gap-2 min-w-0">
                                  {entity.logoUrl ? (
                                    <Avatar className="h-5 w-5 flex-shrink-0">
                                      <AvatarImage
                                        src={entity.logoUrl}
                                        alt={entity.name}
                                      />
                                      <AvatarFallback>
                                        <Store className="size-5" />
                                      </AvatarFallback>
                                    </Avatar>
                                  ) : (
                                    <Store className="size-5 flex-shrink-0" />
                                  )}
                                  <span className="truncate">
                                    {entity.name}
                                  </span>
                                </div>
                                {isCurrentStore && (
                                  <Check className="size-4 text-orange-400 flex-shrink-0" />
                                )}
                              </Link>
                            </DropdownMenuItem>
                          );
                        })}
                      </div>
                    )}
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                </React.Fragment>
              );
            })}

            <DropdownMenuItem className="border-t border-b border-border">
              <Link
                to="/organizer/my-stores"
                className={cn(
                  "w-full flex gap-2 hover:opacity-80 cursor-pointer hover:bg-gray-400 items-center justify-between",
                  {
                    "bg-white/5": context === "user",
                  }
                )}
              >
                <div className="flex items-center gap-2">
                  <User className="size-5" />
                  Store Finder & My Events
                </div>
                {context === "user" && (
                  <Check className="size-4 text-orange-400" />
                )}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem>
              <Link className="w-full flex gap-2 hover:opacity-80 cursor-pointer hover:bg-gray-400 items-center">
                <Plus className="size-5" />
                Create a new organization
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
