import { useState, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

export const useClearCache = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const clearCache = useCallback(async () => {
    setIsLoading(true);
    try {
      await queryClient.invalidateQueries();
      await queryClient.resetQueries();
    } finally {
      setIsLoading(false);
    }
  }, [queryClient]);

  return {
    isLoading,
    clearCache
  };
};

