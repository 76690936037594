import { createFileRoute, Outlet } from '@tanstack/react-router'
import AdminAuthHeaderContainer from '@/components/Navigation/Top/AdminAuthHeaderContainer'
import { SidebarInset, SidebarProvider, SidebarTrigger } from '@cardeio/ui/components/ui/sidebar'
import { AppSidebar } from '@/components/Navigation/Side/AppSidebar'
import { Separator } from '@cardeio/ui/components/ui/separator'
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@cardeio/ui/components/ui/breadcrumb'
import { UserIcon } from '@heroicons/react/20/solid'
import ComingSoonPage from '@/components/StaticPages/ComingSoon'
import { NavEntity } from '@/components/Navigation/Side/nav-entity'
import { useRootStore } from '@/store/useRootStore'
import UserPage from '@/_pages_/auth/User/UserPage'

function Layout() {
  const userData = useRootStore((state) => state.userData)
  return (
    <SidebarProvider>
      <AppSidebar TopNav={() => <NavEntity details={{ name: userData?.user?.displayName }} context="user" />} items={[{ title: 'Profile', url: '/user', icon: () => <UserIcon className='w-4 h-4' /> }]} />
      <SidebarInset className="h-screen flex flex-col">
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-background">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbPage>User Profile</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex-1 min-h-0">
          <main className='h-full overflow-y-auto'>
            <AdminAuthHeaderContainer />
            <Outlet />
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  )
}

export const Route = createFileRoute('/_authenticated/user')({
  component: Layout
})


