import { compact } from "lodash";
import { getTimeZones } from '@vvo/tzdb';

const timezones = getTimeZones()
  .filter((value, index, self) => self.findIndex((v) => v.name === value.name) === index)
  .sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

export const turnTimezonesIntoOptions = (tzs, TZCountry = null) => {
  // If no country is selected, return all timezones
  if (!TZCountry) {
    return timezones
      .sort((a, b) => b.currentTimeOffsetInMinutes - a.currentTimeOffsetInMinutes)
      .map((timezone) => {
        return {
          label: `${timezone.abbreviation} - ${
            timezone?.mainCities?.length ? compact(timezone?.mainCities).slice(0, 2).join(', ') : timezone.name
          }`,
          id: timezone.name,
          value: timezone.name,
        };
      });
  }
  return tzs
    .filter((i) => i.countryName === TZCountry)
    .reduce((acc, curr) => {
      const existingTimezone = acc.find((timezone) => timezone.abbreviation === curr.abbreviation);
      if (existingTimezone) {
        existingTimezone.mainCities = compact(existingTimezone.mainCities).concat(compact(curr.mainCities));
      } else {
        acc.push({
          abbreviation: curr.abbreviation,
          mainCities: [...compact(curr.mainCities)],
          ...curr,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => b.currentTimeOffsetInMinutes - a.currentTimeOffsetInMinutes)
    .map((timezone) => {
      return {
        label: `${timezone.abbreviation} - ${
          timezone?.mainCities?.length ? compact(timezone?.mainCities).slice(0, 2).join(', ') : ''
        }`,
        id: timezone.name,
        value: timezone.name,
      };
    });
};

export const getDefaultTimezoneObjFromOptions = (timezoneId) => {
  const TZCountry = timezones.find((timezone) => timezone.name === timezoneId).countryName ?? null;
  return turnTimezonesIntoOptions(timezones, TZCountry).find((timezone) => timezone.id === timezoneId) ?? timezoneId;
};
