import React from "react";

interface WarningIconProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

export const WarningIcon: React.FC<WarningIconProps> = ({
  className,
  width = 100,
  height = 100,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="50" cy="50" r="50" fill="#DC2626" fillOpacity="0.5" />
      <circle cx="50" cy="50" r="42" fill="#DC2626" fillOpacity="0.5" />
      <circle cx="50" cy="50" r="33" fill="#DC2626" fillOpacity="0.7" />
      <path
        d="M64.7609 46.6665V29.9998C64.7609 28.2317 64.196 26.536 63.1903 25.2858C62.1847 24.0355 60.8208 23.3332 59.3986 23.3332C57.9764 23.3332 56.6125 24.0355 55.6069 25.2858C54.6012 26.536 54.0363 28.2317 54.0363 29.9998M54.0363 43.3332V23.3332C54.0363 21.5651 53.4713 19.8694 52.4657 18.6191C51.4601 17.3689 50.0961 16.6665 48.674 16.6665C47.2518 16.6665 45.8879 17.3689 44.8822 18.6191C43.8766 19.8694 43.3116 21.5651 43.3116 23.3332V29.9998M43.3116 29.9998V44.9998M43.3116 29.9998C43.3116 28.2317 42.7467 26.536 41.7411 25.2858C40.7354 24.0355 39.3715 23.3332 37.9493 23.3332C36.5271 23.3332 35.1632 24.0355 34.1576 25.2858C33.152 26.536 32.587 28.2317 32.587 29.9998V56.6665M64.7612 36.6665C64.7612 34.8984 65.3262 33.2027 66.3318 31.9525C67.3374 30.7022 68.7013 29.9998 70.1235 29.9998C71.5457 29.9998 72.9096 30.7022 73.9152 31.9525C74.9209 33.2027 75.4858 34.8984 75.4858 36.6665V56.6665C75.4858 63.7389 73.226 70.5217 69.2035 75.5227C65.181 80.5237 59.7253 83.3332 54.0366 83.3332H48.6742C41.167 83.3332 36.609 80.4665 32.6141 75.5332L22.9619 63.5332C22.0394 62.263 21.5452 60.6004 21.5815 58.8896C21.6178 57.1789 22.1818 55.551 23.1569 54.343C24.132 53.1351 25.4433 52.4396 26.8195 52.4005C28.1957 52.3614 29.5312 52.9818 30.5496 54.1332L35.2684 59.9998"
        stroke="#F8FAFC"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.1523 53.5V64.5M52.1523 70.8697H52.1697"
        stroke="#F8FAFC"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
