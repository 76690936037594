import { createFileRoute, redirect } from '@tanstack/react-router'
import GameLandingPage from '@/_pages_/auth/Entity/Game/Landing';
import { useRootStore } from '@/store/useRootStore';
import { findEntityById } from "@/utils/entity/findEntityById";
import { queryClient } from '@/lib/queryClient';
import { queryOptions } from "@tanstack/react-query";

import StoreLandingPage from '@/_pages_/auth/Entity/Store/Overview';
import Loading from '@/components/Loading';
import { findOrganizationByEntityId } from '@/utils/entity/allOrgEntities';
import { getEstablishmentResourceImages } from "@/data/establishment/images/getEstablishmentResourceImages";
import { toast } from 'sonner';
import { Button } from '@repo/ui/button';
import { ENTITY_TYPE } from '@/constants';
import { Suspense } from 'react';
import ComingSoonPage from '@/components/StaticPages/ComingSoon';

// eslint-disable-next-line react-refresh/only-export-components
export const resourceImageOptions = ({ establishmentId }) => queryOptions({
	queryKey: [establishmentId, 'resourceImages'],
	queryFn: async () => await getEstablishmentResourceImages({ establishmentId }),
	enabled: !!establishmentId
})

export const Route = createFileRoute('/_authenticated/entity/$entityId/')({
	loader: async ({ params }) => {
		const { entityId } = params;
		return redirect({
			to: `/entity/${entityId}/events`,
			search: {
				viewType: 'calendar'
			}
		});
	},
	component: () => <Loading fullScreen sidebar />,
	pendingComponent: () => <Loading fullScreen sidebar />,
});

const EntityLanding = () => {
	const { entityId } = Route.useParams();
	const { range } = Route.useSearch();
	const userData = useRootStore(state => state.userData);

	const org = findOrganizationByEntityId(userData?.organizations, entityId);
	const entity = findEntityById(org?.entities, entityId);

	if (entity?.type === ENTITY_TYPE.Establishment) {
		return (
			<StoreLandingPage entityId={entityId} userData={userData} />
		)
	}

	if (entity?.type === ENTITY_TYPE.Game) {
		return (
			<ComingSoonPage />
		)
	}

	return (
		<div className="flex items-center justify-center flex-col gap-4 h-screen">
			<h2 className="text-2xl text-zinc-300">Something went wrong</h2>
			<Button
				onClick={() => window.location.href = 'https://carde.io/support'}
				variant="gradient-contained"
				className="mt-4"
			>
				Contact Support
			</Button>
		</div>
	);
};
