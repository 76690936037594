import { ReactNode } from "react"
import { Card, CardContent } from "@cardeio/ui/components/ui/card"
import { Skeleton } from "@cardeio/ui/components/ui/skeleton"
import { cn } from "@cardeio/ui/lib/utils"
import { cva, type VariantProps } from "class-variance-authority"

const formCardVariants = cva("shadow-none", {
	variants: {
		variant: {
			default: "",
			uap: "",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

const formCardContentVariants = cva("space-y-6", {
	variants: {
		variant: {
			default: "py-6 md:py-10 px-7 md:px-[60px]",
			uap: "p-10 rounded-lg",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

interface FormCardProps extends VariantProps<typeof formCardVariants> {
	title?: string
	subtitle?: string
	disabled?: boolean
	skeleton?: boolean
	headerContent?: ReactNode
	children: ReactNode
	className?: string
}

export default function FormCard({
	title,
	subtitle,
	className,
	disabled = false,
	skeleton = false,
	headerContent,
	children,
	variant,
}: FormCardProps) {
	return (
		<Card
			className={cn(
				formCardVariants({ variant }),
				className,
				disabled && "opacity-50 pointer-events-none",
				skeleton && "w-full min-w-full"
			)}
		>
			<CardContent
				className={cn(
					formCardContentVariants({ variant }),
					skeleton && "w-full min-w-full"
				)}
			>
				{(title || subtitle || headerContent || skeleton) && (
					<div className="flex justify-between items-start">
						{(title || subtitle || skeleton) && (
							<div className="space-y-2">
								{skeleton ? (
									<>
										<Skeleton className="h-8 w-2/3 min-w-72" />
										<Skeleton className="h-4 w-full min-w-64" />
										<div className="flex-1">
											<Skeleton className="mt-4 flex-1 " />
										</div>
									</>
								) : (
									<>
										{title && (
											<h3 className="text-2xl font-semibold leading-6 tracking-[-0.36px] text-card-foreground">
												{title}
											</h3>
										)}
										{subtitle && (
											<p className="text-sm font-normal leading-5 text-muted-foreground">
												{subtitle}
											</p>
										)}
									</>
								)}
							</div>
						)}
						{headerContent && !skeleton && (
							<div className="flex-shrink-0">{headerContent}</div>
						)}
					</div>
				)}
				{children}
			</CardContent>
		</Card>
	)
}
