import * as React from "react"
import { cn } from "../utils"
import { inputDefaultStyle } from "./index";
import { getCurrencyObj } from "@repo/utils/currency";
import ComboBox from "../ComboBox";
import { Input } from "@cardeio/ui/components/ui/input";

const CurrencyInput = React.forwardRef(({ className, onChange, onChangeCurrency, selectedCurrency, value, ...props }, ref) => {
  const allCurrencies = Object.values(getCurrencyObj());
  const handleWheel = (event) => {
    event.target.blur();

  };
  return (
    <div className='flex items-center justify-between gap-2'>
      <Input
        type='number'
        variant="uap"
        className={className}
        onChange={onChange}
        ref={ref}
        value={value}
        onWheel={handleWheel}
        {...props}
      />
      <ComboBox
        className='w-24 input-background border-border'
        idCode='currencyCode'
        labelCode='symbol'
        placeholder={selectedCurrency}
        subLabelCode='currencyCode'
        valueCode='currencyCode'
        items={allCurrencies}
        setValue={onChangeCurrency}
        value={selectedCurrency}
      />
    </div>
  );
})
CurrencyInput.displayName = "CurrencyInput"

export { CurrencyInput }
