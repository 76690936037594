import { useState } from "react";
import { sortBy } from "lodash";
import { Link, useParams } from "@tanstack/react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@cardeio/ui/components/ui/accordion";
import PrizingCard from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/PrizingCard";
import ConfigurableOptions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details/ConfigurableOptions";
import { webAxiosInstance } from "@/utils/axios";
import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/avatar";
import { Button } from "@cardeio/ui/components/ui/button";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import CancelEventModalContent from "@/components/Modal/Event/CancelEvent";

import "./style.css";
import { eventQueryOptions } from "@/data/organization/queries";
import RichTextEditor from "@/components/RichTextEditor";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import CharacterCount from "@tiptap/extension-character-count";
import { updateActivityMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { toast } from "sonner";
import Loading from "@/components/Loading";
import { MinusIcon, PlusIcon } from "lucide-react";
import { usePrizingStore } from "@/store/usePrizingStore";
import { PRIZE_NAMES, PRIZE_SHORT_NAMES, PRIZE_ORDER, PRIZE_PLACEMENT_KEYS } from "@/constants/prizingConstants";

// Component to add the next available prize
const AddNextPrizeButton = ({ prizeSupport, activity }) => {
  const { addNewPrize, prizeChanges } = usePrizingStore();

  // Find the next available prize placing
  const getNextAvailablePrize = () => {
    // Get existing keys from both prizeSupport and prizeChanges
    const existingKeys = [
      ...Object.keys(prizeChanges || {}).filter(
        (key) => !prizeChanges[key]?._toRemove
      ), // Filter out keys marked for removal
      ...prizeSupport.map((prize) => prize.key),
    ].filter((key) => PRIZE_PLACEMENT_KEYS.includes(key));

    for (const placingKey of PRIZE_PLACEMENT_KEYS) {
      if (!existingKeys.includes(placingKey)) {
        return placingKey;
      }
    }

    return null; // All prizes are already added
  };

  const nextPrize = getNextAvailablePrize();

  if (!nextPrize) {
    return null; // No more prizes to add
  }

  // Check if capacity is sufficient for the next prize
  const capacity = activity?.capacity || 0;
  const isCapacitySufficient = (() => {
    if (nextPrize.startsWith('top')) {
      const requiredCapacity = parseInt(nextPrize.replace('top', ''), 10);
      return capacity >= requiredCapacity;
    }
    return true;
  })();

  return (
    <div className="relative">
      <Button
        onClick={() => addNewPrize(nextPrize)}
        variant="gradient"
        size="sm"
        className="w-full mt-2"
        disabled={!isCapacitySufficient}
      >
        Add {PRIZE_SHORT_NAMES[nextPrize]} Prize
      </Button>
      {!isCapacitySufficient && (
        <div className="text-xs text-amber-600 mt-1 flex items-center">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" />
          Increase capacity to at least {nextPrize.replace('top', '')} to enable this prize
        </div>
      )}
    </div>
  );
};

const EventDetailsTab = ({ registrations }) => {
  const { eventId } = useParams({ strict: false });
  const [isEdittingDescription, setIsEdittingDescription] = useState(false);
  const { data: eventData, isLoading: isLoadingEvent } = useQuery(
    eventQueryOptions(eventId)
  );
  const event = eventData?.data;
  const activity = eventData?.data?.activities?.[0];
  const updateEventMutation = useMutation({
    mutationFn: updateActivityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["event", event.id] });
      toast("Successfully Update Event Banner!");
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with editing the event banner. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const updatePrizingMutation = useMutation({
    mutationFn: (params) => {
      return updateActivityMutationFn(params);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["event", event.id] });
      toast("Successfully updated prizing information!");
      setEditingPrizing(false);
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error updating the prizing information. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const { editingPrizing, setEditingPrizing, submitPrizing, prizeChanges } =
    usePrizingStore();

  const handlePrizeSupportUpdate = (prizeCategory, data) => {
    const currentPrizeSupport = activity.prizeSupport || {};

    // Check if prizeCategory is an object containing all prize changes
    if (typeof prizeCategory === "object" && !data) {
      // This is a batch update with all prize changes
      const allPrizeChanges = prizeCategory;
      const updatedPrizeSupport = { ...currentPrizeSupport };

      // Apply all prize changes at once
      Object.keys(allPrizeChanges).forEach((key) => {
        // If the prize is marked for removal, delete it from updatedPrizeSupport
        if (allPrizeChanges[key]?._toRemove) {
          delete updatedPrizeSupport[key];
        } else {
          // Otherwise, update it

          updatedPrizeSupport[key] = {
            ...(currentPrizeSupport[key] || {}),
            ...allPrizeChanges[key],
          };
        }
      });

      // Update with all changes in one API call
      updatePrizingMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          prizeSupport: updatedPrizeSupport,
        },
      });
    } else {
      // This is a single prize update (original behavior)
      const updatedCategory = {
        ...(currentPrizeSupport[prizeCategory] || {}),
        ...data,
      };

      const updatedPrizeSupport = {
        ...currentPrizeSupport,
        [prizeCategory]: updatedCategory,
      };

      updatePrizingMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          prizeSupport: updatedPrizeSupport,
        },
      });
    }
  };

  const editor = useEditor({
    content: activity?.descriptionHtml,
    onUpdate: (e) => {
      e.editor.getHTML();
    },
    extensions: [
      StarterKit.configure(),
      Highlight,
      TaskList,
      TaskItem,
      CharacterCount.configure({
        limit: 10000,
      }),
    ],
  });

  const handleSaveDescription = () => {
    updateEventMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity.id,
      data: {
        description: JSON.stringify(editor?.getJSON()),
        descriptionHtml: editor?.getHTML(),
      },
      headers: {
        "Game-Id": activity.gameId,
      },
    });
  };

  const [isCancelEventModalOpen, setIsCancelEventModalOpen] = useState(false);
  const [sectionsOpen, setSectionsOpen] = useState({
    externalOrganizers: false,
  });
  const commentators = registrations.filter(
    (registration) =>
      registration?.activityRegistrations?.find((actReg) =>
        actReg.roles.includes("commentator")
      )?.id
  );
  const judges = registrations.filter(
    (registration) =>
      registration?.activityRegistrations?.find((actReg) =>
        actReg.roles.includes("judge")
      )?.id
  );
  const organizers = registrations.filter(
    (registration) =>
      registration?.activityRegistrations?.find((actReg) =>
        actReg.roles.includes("organizer")
      )?.id
  );

  let prizeSupport = [];
  if (activity?.prizeSupport) {
    prizeSupport = [];
    activity?.prizeSupport && Object.keys(activity?.prizeSupport).forEach((prizeKey) => {
      if (
        activity?.prizeSupport[prizeKey]?.cash ||
        activity?.prizeSupport[prizeKey]?.product
      ) {
        prizeSupport.push({
          ...activity?.prizeSupport[prizeKey],
          key: prizeKey,
          order: PRIZE_ORDER[prizeKey],
          title: PRIZE_NAMES[prizeKey],
        });
      }
    });
  } else {
    prizeSupport = [];
    activity?.configuration?.prizing && Object.keys(activity?.configuration?.prizing).forEach((prizeKey) => {
      if (
        activity?.configuration?.prizing[prizeKey]?.cash ||
        activity?.configuration?.prizing[prizeKey]?.product
      ) {
        prizeSupport.push({
          ...activity?.configuration?.prizing[prizeKey],
          key: prizeKey,
          order: PRIZE_ORDER[prizeKey],
          title: PRIZE_NAMES[prizeKey],
        });
      }
    });
  }

  const CancelEventModal = () => {
    return (
      <div className="flex-shrink-0 h-full mt-2">
        <Modal
          open={isCancelEventModalOpen}
          onOpenChange={setIsCancelEventModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              className="h-full flex items-center justify-center gap-1 text-xs font-normal"
              onClick={() => setIsCancelEventModalOpen(true)}
              size="sm"
              variant="text"
            >
              <ExclamationCircleIcon
                className="h-4 w-4 text-red-500 mr-1"
                aria-hidden="true"
              />
              <span className="text-sm text-red-600">Cancel Event</span>
            </Button>
          </ModalTrigger>
          <CancelEventModalContent
            event={event}
            handleClose={() => setIsCancelEventModalOpen(false)}
            navigateTo={`/entity/${event?.owner?.entity?.id}/events`}
          />
        </Modal>
      </div>
    );
  };

  if (isLoadingEvent) {
    return <Loading fullScreen />;
  }

  if (activity?.status === "draft") {
    return (
      <div className="min-h-[200px] flex flex-col items-center justify-center gap-2">
        <h1 className="text-3xl font-bold">
          Please Finalize Event Creation to Continue
        </h1>
        <p className="text-2xs text-zinc-300/70 mb-4 italic font-bold">
          Events in a Draft State need more information in order to add users,
          run, and complete.
        </p>
        <Link to="draft">
          <Button variant="gradient">Finalize Draft</Button>
        </Link>
        <CancelEventModal />
      </div>
    );
  }

  return (
    <div className="w-full z-10">
      <div className="flex flex-col w-full bg-zinc-800/90 p-4 rounded-lg gap-4 z-10">
        <ConfigurableOptions activity={activity} event={event} />
      </div>

      <div className="flex flex-col w-full bg-zinc-800/90 p-4 rounded-lg gap-4 z-10">
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="external-organizers" className="border-b-0">
            <AccordionTrigger
              onClick={() => {
                setSectionsOpen({
                  ...sectionsOpen,
                  externalOrganizers: !sectionsOpen.externalOrganizers,
                });
              }}
              Icon={sectionsOpen.externalOrganizers ? MinusIcon : PlusIcon}
              className="uppercase"
            >
              <h2 className="text-xl font-bold mb-2">
                External Organizers
                <span className="ml-1 mb-2 text-xs">
                  {organizers?.length > 0 ? ` (${organizers.length})` : ""}
                </span>
              </h2>
            </AccordionTrigger>
            <AccordionContent className="space-y-8">
              <div>
                <div
                  className={`w-full h-[50px] flex items-center ${organizers?.length > 0 ? "ml-8" : "justify-center"}`}
                >
                  {organizers?.length > 0 ? (
                    organizers.map((organizer) => (
                      <div className="flex items-center gap-2">
                        <Avatar className="h-12 w-12">
                          <AvatarImage
                            src={
                              organizer?.activityRegistrations?.[0]?.gameUser
                                ?.imageUrl
                            }
                            className="h-12 w-12"
                          />
                          <AvatarFallback>{`${organizer?.user?.firstName[0]} ${organizer?.user?.lastName[0]}`}</AvatarFallback>
                        </Avatar>

                        <p className="text-xs uppercase">
                          {organizer?.user?.firstName}{" "}
                          {organizer?.user?.lastName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-2xs uppercase">
                      No External Organizers Yet
                    </p>
                  )}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-bold mb-2">
                  Judges
                  <span className="ml-1 mb-2 text-xs">
                    {judges?.length > 0 ? ` (${judges.length})` : ""}
                  </span>
                </h2>
                <div
                  className={`w-full h-[50px] flex items-center ${judges?.length > 0 ? "ml-8" : "justify-center"}`}
                >
                  {judges?.length > 0 ? (
                    judges.map((judge) => (
                      <div className="flex items-center gap-4">
                        <Avatar className="h-12 w-12">
                          <AvatarImage
                            src={
                              judge?.activityRegistrations?.[0]?.gameUser
                                ?.imageUrl
                            }
                            className="h-12 w-12"
                          />
                          <AvatarFallback>{`${judge?.user?.firstName[0]} ${judge?.user?.lastName[0]}`}</AvatarFallback>
                        </Avatar>

                        <p className="text-xs uppercase">
                          {judge?.user?.firstName} {judge?.user?.lastName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-2xs uppercase">No Judges Yet</p>
                  )}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-bold mb-2">
                  Commentators
                  <span className="ml-1 mb-2 text-xs">
                    {commentators?.length > 0
                      ? ` (${commentators.length})`
                      : ""}
                  </span>
                </h2>
                <div
                  className={`w-full h-[50px] flex items-center ${commentators?.length > 0 ? "ml-8" : "justify-center"}`}
                >
                  {commentators?.length > 0 ? (
                    commentators.map((commentator) => (
                      <div className="flex items-center gap-4">
                        <Avatar className="h-12 w-12">
                          <AvatarImage
                            src={
                              commentator?.activityRegistrations?.[0]?.gameUser
                                ?.imageUrl
                            }
                            className="h-12 w-12"
                          />
                          <AvatarFallback>{`${commentator?.user?.firstName[0]} ${commentator?.user?.lastName[0]}`}</AvatarFallback>
                        </Avatar>

                        <p className="text-xs uppercase">
                          {commentator?.user?.firstName}{" "}
                          {commentator?.user?.lastName}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-2xs uppercase">No Commentators Yet</p>
                  )}
                </div>
              </div>

              <div className="group">
                <h2 className="text-xl font-bold mb-2 pe-2 flex items-center justify-between">
                  Prizing
                  <div className="flex items-center gap-2">
                    <Button
                      onClick={() => setEditingPrizing(!editingPrizing)}
                      variant={"gradient"}
                      size="sm"
                      className={`group-hover:opacity-100 opacity-0 transition-opacity ${editingPrizing ? "opacity-100 border-destructive" : "opacity-0"}`}
                    >
                      {editingPrizing ? "Cancel" : "Edit"}
                    </Button>
                    {editingPrizing && (
                      <Button
                        onClick={() => submitPrizing(handlePrizeSupportUpdate)}
                        variant={"destructive-outline"}
                        size="sm"
                        className={`transition-opacity ${editingPrizing ? "opacity-100 border-destructive" : "opacity-0"}`}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </h2>
                <div className="flex flex-col w-full gap-4">
                  {Object.keys(prizeSupport)?.length > 0 ||
                    (editingPrizing && Object.keys(prizeChanges).length > 0) ? (
                    (() => {
                      // Create the combined array of prizes
                      const combinedPrizes = [
                        ...Object.values(prizeSupport || {}),
                        ...Object.keys(prizeChanges || {})
                          .filter(
                            (key) =>
                              // Only include keys not in prizeSupport and not marked for removal
                              !prizeSupport.some((p) => p.key === key) &&
                              !prizeChanges[key]?._toRemove
                          )
                          .map((key) => ({
                            key,
                            order: PRIZE_ORDER[key] || 999,
                          })),
                      ];

                      // Sort the prizes by order
                      const sortedPrizes = sortBy(
                        combinedPrizes,
                        (prize) => prize.order
                      );

                      // Render the prizes
                      return sortedPrizes.map((prize, index) => (
                        <PrizingCard
                          key={prize.key}
                          prizeInfo={prize}
                          isLast={index === sortedPrizes.length - 1}
                          allPrizes={prizeSupport}
                        />
                      ));
                    })()
                  ) : (
                    <div className="w-full h-[50px] flex items-center justify-center">
                      <p className="text-2xs uppercase">No Prizing Data</p>
                    </div>
                  )}

                  {editingPrizing && (
                    <AddNextPrizeButton prizeSupport={prizeSupport} activity={activity} />
                  )}
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between group">
                  <h2 className="text-xl font-bold mb-2">
                    Additional Information
                  </h2>
                  {isEdittingDescription ? (
                    <div className="flex items-center gap-2">
                      <Button
                        onClick={() => {
                          handleSaveDescription();
                          setIsEdittingDescription(false);
                        }}
                        variant="gradient"
                        className="transition-opacity"
                        size="sm"
                      >
                        {"SAVE"}
                      </Button>
                      <Button
                        onClick={() => setIsEdittingDescription(false)}
                        variant="gradient"
                        className="transition-opacity"
                        size="sm"
                      >
                        {"CANCEL"}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      onClick={() => setIsEdittingDescription(true)}
                      variant="gradient"
                      className="opacity-0 group-hover:opacity-100 transition-opacity"
                      size="sm"
                    >
                      {"EDIT"}
                    </Button>
                  )}
                </div>
                {isEdittingDescription ? (
                  <RichTextEditor editor={editor} />
                ) : (
                  <div
                    id="description-info"
                    dangerouslySetInnerHTML={{
                      __html: activity?.descriptionHtml,
                    }}
                  />
                )}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default EventDetailsTab;
