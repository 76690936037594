import * as React from "react"
import { cn } from "@cardeio/ui/lib/utils"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
				"outline-gradient":
					"rounded-full p-0.5 transition-all duration-500 bg-gradient-to-tr from-secondary via-red-500 to-primary bg-size-150 bg-pos-0 hover:bg-pos-100 font-semibold",
				"play-default":
					"gap-x-1 bg-[#D3BA84] text-accent-foreground hover:bg-[#E0CFA9] uppercase font-bold tracking-wide leading-6",
				"play-destructive":
					"gap-x-1 bg-[#D3082F] text-foreground hover:bg-[#D3082F]/80 uppercase font-bold tracking-wide leading-6",
				"play-outline":
					"gap-x-1 bg-transparent text-foreground hover:bg-background border border-foreground uppercase font-bold tracking-wide leading-6",
				"play-outline-secondary":
					"gap-x-1 bg-background text-secondary hover:background/80 border border-secondary hover:border-[#E0CFA9] hover:text-[#E0CFA9] uppercase font-bold tracking-wide leading-6",
				destructive:
					"bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
				"destructive-outline":
					"border border-destructive text-destructive-foreground bg-destructive/20 hover:bg-destructive/30",
				outline:
					"border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
				"dotted-outline":
					"border border-dashed border-white bg-background shadow-sm hover:bg-accent hover:text-accent-foreground opacity-50",
				secondary:
					"bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
				gradient:
					"text-white font-semibold transition-opacity duration-300 carde-gradient-dark hover:carde-gradient-light",
				"uap-secondary": "bg-carde-gray-400 text-white hover:bg-carde-gray-400/80",
			},
			size: {
				"play-default": "h-9 px-4 py-2 rounded-full",
				default: "h-9 px-4 py-2",
				sm: "h-8 rounded-md px-3 text-xs",
				lg: "h-10 rounded-md px-8",
				icon: "h-9 w-9",
			},
			bgStyle: {
				gradient: "bg-gradient-to-tr from-uap-secondary to-uap-primary text-primary",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, bgStyle, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button"

		if (variant === "outline-gradient") {
			return (
				<button
					className={cn(
						buttonVariants({ variant, size, className, bgStyle }),
						"px-[1px] py-[1px]"
					)}
					ref={ref}
					{...props}
				>
					<span className={cn("flex rounded-full w-full h-full bg-[#191919] px-4 py-2")}>
						{props.children}
					</span>
				</button>
			)
		}

		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className, bgStyle }))}
				ref={ref}
				{...props}
			/>
		)
	}
)
Button.displayName = "Button"

export { Button, buttonVariants }
