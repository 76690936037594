import { Outlet, createFileRoute } from "@tanstack/react-router";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "@cardeio/ui/components/ui/sidebar";
import { AppSidebar } from "@/components/Navigation/Side/AppSidebar";
import { Separator } from "@cardeio/ui/components/ui/separator";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "@cardeio/ui/components/ui/breadcrumb";
import AdminAuthHeaderContainer from "@/components/Navigation/Top/AdminAuthHeaderContainer";
import { BuildingStorefrontIcon, CalendarIcon } from "@heroicons/react/20/solid";
import { useRootStore } from "@/store/useRootStore";
import { NavEntity } from "@/components/Navigation/Side/nav-entity";

function Layout() {
  const { userData } = useRootStore();
  const user = userData?.user;
  const hideSidebar = window.location.pathname === '/organizer' || window.location.pathname === '/organizer/';

  const items = [
    {
      title: 'Find Stores',
      url: '/organizer/my-stores',
      icon: () => <BuildingStorefrontIcon className="w-4 h-4" />
    },
    {
      title: 'Events',
      url: '/organizer/events',
      icon: () => <CalendarIcon className="w-4 h-4" />
    }
  ];

  const userDetails = {
    name: user?.displayName || user?.email,
    email: user?.email
  };

  return (
    <SidebarProvider>
      {!hideSidebar && <AppSidebar items={items} TopNav={() => <NavEntity details={userDetails} context="user" />} />}
      <SidebarInset className="h-screen flex flex-col">
        {!hideSidebar && <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-black/80">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbPage>Organizer</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>}
        <div className="flex-1 min-h-0">
          <main className="h-full overflow-y-auto">
            <AdminAuthHeaderContainer />
              <div className="max-w-[1400px] mx-auto p-10">
                <Outlet />
              </div>
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}

export const Route = createFileRoute('/_authenticated/organizer')({
  component: Layout
});
