import { useState } from 'react';
import dayjs from "dayjs";
import { capitalize } from 'lodash';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { webAxiosInstance } from '@/utils/axios';
import { queryClient } from '@/lib/queryClient';
import { Button } from '@repo/ui/button';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '@repo/ui/modal';
import { organizerDropUserMutationFn, organizerUndropUserMutationFn } from "@repo/api/base";
import { useEventStore } from "@/store/useEventStore";
import { REGISTRATION_STATUS } from '@/constants';
import { getTicketPriceDisplay } from '@repo/utils/currency';
import { WarningIcon } from '@/icons/Warning';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

const AVAILABLE_REGISTRATION_NEXT_STEPS = {
  [REGISTRATION_STATUS.registered]: [REGISTRATION_STATUS.unregistered, REGISTRATION_STATUS.checkedIn],
  [REGISTRATION_STATUS.checkedIn]: [REGISTRATION_STATUS.registered, REGISTRATION_STATUS.unregistered],
  [REGISTRATION_STATUS.unregistered]: [REGISTRATION_STATUS.registered, REGISTRATION_STATUS.checkedIn],
  [REGISTRATION_STATUS.active]: []
}

const statuses = {
  active: 'text-green-600 bg-green-500/10 border-green-500/20',
  canceled: 'text-red-700 bg-rose-700/10 border-red-700/20',
  checkedIn: 'text-green-600 bg-green-500/10 border-green-500/20',
  dropped: 'text-red-700 bg-rose-700/10 border-red-700/20',
  registered: 'text-blue-400 bg-blue-400/10 border-blue-400/20',
}

const EventRegistrationCard = ({ activity, event, registration } = {}) => {
  const [isStatusModalOpen, setStatusModalOpen] = useState(false)
  const { currentTournament } = useEventStore();
  const [openDropModal, setOpenDropModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const activityReg = registration?.activityRegistrations?.[0];
  const activityPhaseUser = registration?.phaseUser?.status || registration?.activityRegistrations?.[0]?.status;
  const roundDropped = registration?.phaseUser?.roundDropped
  const isFreeEvent = event?.ticketPrices?.every(price => price.amount === 0);
  const isFreeActivity = activity?.ticketPrices?.every(price => price.amount === 0);

  const classNames = (...classes) => classes.filter(Boolean).join(' ');
  const checkInMutation = useMutation({
    mutationFn: async ({ id, gameId }) => {
      await webAxiosInstance.put(`/api/organize/registrations/${id}`, {
        status: REGISTRATION_STATUS.checkedIn,
      }, {
        headers: {
          'game-id': gameId,
        }
      });
    }
  });

  const checkOutMutation = useMutation({
    mutationFn: async ({ id, gameId }) => {
      await webAxiosInstance.put(`/api/organize/registrations/${id}`, {
        status: 'registered',
      }, {
        headers: {
          'game-id': gameId,
        }
      });
    }
  });

  const dropPlayerMutation = useMutation({
    mutationFn: organizerDropUserMutationFn,
    onSuccess: () => {
      toast.error("Successfully dropped player.", { toastId: 'dropUser' });
      queryClient.invalidateQueries({ queryKey: ['event', event.id] })
      setOpenDropModal(false);
    },
    onError: () => {
      toast.error("There was an error updating this user's status", { toastId: 'dropUser' });
    },
  });

  const undropPlayerMutation = useMutation({
    mutationFn: organizerUndropUserMutationFn,
    onSuccess: () => {
      toast.success("Successfully undropped player.", { toastId: 'dropUser' });
      queryClient.invalidateQueries({ queryKey: ['event', event.id] })
      setOpenDropModal(false);
    },
    onError: () => {
      toast.error("There was an error updating this user's status", { toastId: 'dropUser' });
    },
  });

  const unregisterMutation = useMutation({
    mutationFn: async ({ id }) => {
      await webAxiosInstance.put(`/api/organize/registrations/${id}`, {
        status: 'unregistered',
      }, {
        headers: {
          'game-id': activityReg?.gameUser?.gameId,
        }
      });
    }
  });

  const checkInAction = async () => {
    await checkInMutation.mutateAsync({ id: activityReg.id, gameId: activityReg?.gameUser?.gameId }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['event', event.id] })
        queryClient.invalidateQueries({ queryKey: ['activity', 'roster'] });
        toast.success('Player successfully checked in!')
      },
      onError: () => {
        toast.error('Cannot check in player at this time.')
      }
    })
  }

  const checkOutAction = async () => {
    await checkOutMutation.mutateAsync({ id: activityReg.id, gameId: activityReg?.gameUser?.gameId }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['event', event.id] })
        toast.success('Player successfully unregistered.')
      },
      onError: () => {
        toast.error('Cannot check out player at this time.')
      }
    })
  }

  const dropAction = () => {
    dropPlayerMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      playerId: registration?.phaseUser?.id
    })
  }

  const unregisterAction = async () => {
    await unregisterMutation.mutateAsync({ id: activityReg.id, type: 'unregister' }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['event', event.id] })
        toast.success('Successfully unregistered player!')
      },
      onError: () => {
        toast.error('Cannot unregister at this time.')
      }
    })
  }

  const refundAction = async () => {
    await unregisterMutation.mutateAsync({ id: activityReg.id, type: 'refund', user: activityReg }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['event', event.id] })
        toast.success('Refund is successful!')
      },
      onError: () => {
        toast.error('Cannot Refund at this time.')
      }
    })
  }

  const registrationMapDetails = {
    [REGISTRATION_STATUS.registered]: { action: checkOutAction, text: "Register" },
    [REGISTRATION_STATUS.checkedIn]: { action: checkInAction, text: "Check In" },
    [REGISTRATION_STATUS.unregistered]: { action: unregisterAction, text: "Unregister" }
  }

  return (
    <>
      <li key={registration.id}
        className="relative bg-zinc-800/50 group flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-700/70 border-y-[2px] border-zinc-400/20 overflow-hidden"
      >
        <div className="min-w-0 flex-auto">
          <div className="flex items-center justify-between gap-x-3">
            <div className='flex items-end gap-1'>
              <div className='flex flex-col w-[300px]'>
                <div className='flex items-center gap-2 mb-2'>
                  <div className={classNames(statuses[activityPhaseUser], 'flex-none rounded-full p-1')}>
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                  <p
                    className={classNames(statuses[activityPhaseUser], 'border-[1px] flex-none rounded-full px-[8px] uppercase text-2xs')}
                  >
                    {activityPhaseUser}
                  </p>
                </div>
                <h2
                  className="min-w-0 text-sm font-semibold leading-6 text-white whitespace-nowrap overflow-hidden text-ellipsis">
                  <span
                    className="text-xl font-bold truncate">{registration?.user?.displayName}</span>
                  <span className="absolute inset-0" />
                </h2>
                <p className="min-w-0 text-white">
                  <span className="text-2xs truncate">{registration?.user?.email}</span>
                  <span className="absolute inset-0" />
                </p>
                {registration?.phaseUser?.seed !== null && registration?.phaseUser?.seed !== undefined ?
                  (
                    <p className="min-w-0 leading-6 text-white font-bold text-sm">
                      Pod: {registration?.phaseUser?.phase?.pod} - Seat: {registration?.phaseUser?.seed}
                    </p>
                  ) : null}
              </div>
            </div>

            <div className='flex gap-2 items-center relative z-10'>
              {registration?.payment?.amount || activityReg?.payment?.amount ? (
                <div className='flex flex-col justify-center items-center z-10 px-4 py-1 rounded-lg bg-zinc-800/80'>
                  <p
                    className={`text-2xs uppercase tracking-widest text-white`}>{capitalize(registration?.payment?.status || activityReg?.payment?.status)}</p>
                  <p
                    className={`font-bold text-white`}>
                    {getTicketPriceDisplay({ amount: (registration?.payment?.amount || 0) + (activityReg?.payment?.ticketFee || 0), currency: registration?.payment?.currency || activityReg?.payment?.currency })}
                  </p>
                </div>
              ) : !isFreeEvent || !isFreeActivity ? (
                <div className='flex flex-col justify-center items-center z-10 px-4 py-1 rounded-lg bg-zinc-800/60'>
                  <p className='text-xs font-bold uppercase'>Did Not</p>
                  <p className='text-2xs uppercase tracking-widest'>Pay</p>
                </div>
              ) : null}

              {activityPhaseUser === 'registered' && (
                <Button
                  className={`bg-green-700/80`}
                  onClick={() => {
                    checkInAction();
                  }}
                  size='xs'
                >
                  Check In
                </Button>
              )}
              {event.status === 'inProgress' && activityPhaseUser === 'active' && (
                <Button
                  className='bg-red-700/80'
                  onClick={() => {
                    setOpenDropModal(true);
                  }}
                  size='xs'
                >
                  Drop
                </Button>
              )}
              {event.status === 'inProgress' && activityPhaseUser === 'dropped' && currentTournament?.currentRound === roundDropped && (
                <Button
                  className='bg-red-700/80'
                  onClick={() => {
                    undropPlayerMutation.mutate({
                      clientAxiosInstance: webAxiosInstance,
                      playerId: registration?.phaseUser?.id
                    })
                  }}
                  size='xs'
                >
                  Reinstate
                </Button>
              )}
              {
                activity?.status === 'published' && activityPhaseUser !== 'canceled' &&
                <div className='z-10'>
                  <button onClick={() => setStatusModalOpen(true)} className='pointer rounded-3xl p-2 hover:bg-zinc-200/10'>
                    <EllipsisVerticalIcon className="h-5 w-5 flex-none text-zinc-200" aria-hidden="true" />
                  </button>

                </div>
              }
            </div>
          </div>
        </div>
        {/* <div
          className={`absolute h-full w-[40%] right-0 top-0 bg-no-repeat bg-cover bg-center opacity-75 z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85`}
          style={{
            backgroundImage: `url(${activityReg?.gameUser?.imageUrl})`,
            maskImage: 'linear-gradient(to left, rgba(0, 0, 0, 1.0) 20%, transparent 100%)',
          }}
        /> */}
      </li>


      {/* Drop Participant Modal*/}
      <Modal open={openDropModal} onOpenChange={setOpenDropModal}>
        <ModalContent className='max-w-3xl'>
          <ModalHeader>
            <ModalTitle>Are you sure you want to drop this player?</ModalTitle>
          </ModalHeader>
          <div className='flex flex-col items-center gap-2 mt-10'>
            <WarningIcon className="h-24 w-24 text-red-500" aria-hidden="true" />
            <div className='flex items-center gap-5 bg-destructive-light border border-destructive-dark p-4 rounded-lg mt-10'>
              <div>
                {
                  registration?.user?.imageUrl ?
                    <img src={registration?.user?.imageUrl} alt={registration?.user?.displayName} className="w-12 h-12 rounded-full" />
                    :
                    <div className='w-12 h-12 rounded-full bg-zinc-300 flex items-center justify-center'>
                    </div>
                }
              </div>
              <div className='flex flex-col '>
                <div>
                  {registration?.user?.displayName}
                </div>
                <div>
                  {registration?.user?.email}
                </div>
              </div>
            </div>
            <p className='mt-10 text-center'>Would you like to continue to drop this player? This action will remove them from future rounds and pairings where applicable. This may also remove their ability to receive automated rewards for this event.</p>
          </div>
          <ModalFooter className='items-end mt-10'>
            <div className='w-full flex gap-2 justify-center'>
              <Button
                onClick={() => setOpenDropModal(false)}
                style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
                variant='contained'
                rounded
              >
                Cancel
              </Button>
              <Button
                onClick={dropAction}
                className='h-fit border border-destructive-dark bg-destructive-light'
                rounded
                dataTooltipTarget="tooltip-default"
                variant='contained'
                style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              >
                {`Drop ${registration?.user?.displayName} from Event`}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*Refund Modal*/}
      <Modal open={openRefundModal} onOpenChange={setOpenRefundModal}>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Refund Player?</ModalTitle>
          </ModalHeader>
          <p>A refund will be processed for the participant.</p>
          <ModalFooter className='items-end'>
            <div>
              <Button
                onClick={() => setOpenRefundModal(false)}
                style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
                variant='text'
              >
                Cancel
              </Button>
              <Button
                onClick={() => refundAction(activityReg.id)}
                className='h-fit'
                dataTooltipTarget="tooltip-default"
                variant='gradient-contained'
                style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
                type='submit'
              >
                Refund
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal open={isStatusModalOpen} onOpenChange={setStatusModalOpen}>
        <ModalContent className='max-w-md'>
          <ModalHeader>
            <ModalTitle>Available Next Steps</ModalTitle>
          </ModalHeader>
          <p>Choose the next step for the participant:</p>
          <div className="flex flex-col gap-2">
            {AVAILABLE_REGISTRATION_NEXT_STEPS[activityPhaseUser]?.map((step) => (
              <Button
                key={step}
                onClick={async () => {
                  await registrationMapDetails[step].action()
                  setStatusModalOpen(false);
                }}
                variant='gradient-contained'
                style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
              >
                {registrationMapDetails[step]?.text}
              </Button>
            ))}
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EventRegistrationCard;
