import { webAxiosInstance } from "@/utils/axios"

const getSentInquiries = async ({ type, sourceId }) => {
  const { data } = await webAxiosInstance.get('/api/manage/inquiries/sent', { params: { type, sourceType: 'Establishment', sourceId } })
  return data?.data
}

const getSentInquiriesQueryOptions = ({ type, sourceId }) => ({
  queryKey: ['sentInquiries', type, sourceId],
  queryFn: () => getSentInquiries({ type, sourceId }),
})

export default getSentInquiriesQueryOptions
