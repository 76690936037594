export const getExternalSites = (game) => game?.externalSites?.filter(gameDetails => gameDetails.siteTypes.includes('externalTournamentManager'));
export const getExternalSite = (game, activity) => {
  if (!activity?.externalReference) return null;
  return getExternalSites(game)?.find(siteDetails => siteDetails.id === Object.keys(activity.externalReference)?.[0]);
}
export const getExternalSiteByKey = (game, key) => {
  return getExternalSites(game)?.find(siteDetails => siteDetails.id === key);
}

const buildReferenceUrl = (externalSite, hostingObj, isPublic = false) => {
  if (externalSite?.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.prependUrl && externalSite?.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.urlKey) {
    return `${externalSite.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.prependUrl}${hostingObj[externalSite.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.urlKey]}${externalSite.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.appendUrl || ''}`;
  }
  return externalSite?.urls?.[isPublic ? 'eventPlay' : 'eventManagement']?.url;
}

export const createExternalReference = (externalSite, hostingInfo) => {
  const hostingObj = {};

  if (externalSite?.urls?.eventManagement?.joinCode?.name) {
    hostingObj.code = hostingInfo;
    hostingObj.showPublic = externalSite?.urls?.eventManagement?.joinCode?.show || 'always';
  } else if (externalSite?.urls?.eventManagement?.eventId?.name) {
    hostingObj.eventId = hostingInfo;
    hostingObj.showPublic = externalSite?.urls?.eventManagement?.eventId?.show || 'always';
  } else {
    hostingObj.publicUrl = hostingInfo;
    hostingObj.showPublic = 'always';
  }
  if (!externalSite?.urls?.eventManagement?.canIFrame) {
    hostingObj.instructions = externalSite?.urls?.eventManagement?.instructions || 'Please use the provided link to run this event.';
  }
  hostingObj.canIFrame = externalSite?.urls?.eventManagement?.canIFrame;
  hostingObj.eventManagementName = externalSite?.urls?.eventManagement?.name;
  hostingObj.hostingUrl = buildReferenceUrl(externalSite, hostingObj);
  hostingObj.publicUrl = buildReferenceUrl(externalSite, hostingObj, true);

  return hostingObj;
}
