import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { Input } from "@cardeio/ui/components/ui/input";
import { Button } from "@cardeio/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "@cardeio/ui/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormDescription,
  FormLabel,
} from "@cardeio/ui/components/ui/form";
import { Dialog } from "@cardeio/ui/components/ui/dialog";
import { GooglePlacesAutocompleteInput } from "@cardeio/ui/components/forms/fields/google-places-autocomplete-input";
import { useUpdateUserProfile } from "../../hooks/useUpdateUserProfile";
import { useRootStore } from "@/store/useRootStore";
import fadedRedBg from "@/assets/faded-red-bg.jpg";
import SwapEmailModal from "@/components/Modal/Entity/Members/Swap";
import { Alert, AlertTitle } from "@cardeio/ui/components/ui/alert";
import {
  RadioGroup,
  RadioGroupItem,
} from "@cardeio/ui/components/ui/radio-group";
import { Terminal } from "lucide-react";

const ROLES = [
  // TODO: uncomment this when we store application flow in UAP
  // {
  //   id: "owner",
  //   value: "owner",
  //   title: "I am a store owner",
  //   description: "You own a store and would like to use Carde Cloud tools to manage your store and organize events"
  // },
  {
    id: "employee",
    value: "employee",
    title: "I am a store employee",
    description:
      "You work for one or more established game store(s) and would like to be added as a staff member",
  },
  {
    id: "guest",
    value: "guest",
    title: "I am an event organizer, scorekeeper, and/or tournament judge",
    description:
      "Partner with stores and games to organize, run, and assist with organized play events",
  },
];

const TournamentOrganizerProfileForm = ({
  showEmailSwap = false,
  isNewUser = false,
}) => {
  const { userData } = useRootStore();
  const { logout, user: auth0User } = useAuth0();
  const user = userData?.user;
  const [addressValue, setAddressValue] = useState("");
  const [addressWasSelected, setAddressWasSelected] = useState(false);
  const [addressWasEdited, setAddressWasEdited] = useState(false);
  const [showSwapEmailModal, setShowSwapEmailModal] = useState(false);
  const isAddressSelectedRef = useRef(false);

  const hasFirstAndLastName = user?.firstName && user?.lastName;

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      role: "guest",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "XC",
        geo: {
          lat: 0,
          lng: 0,
        },
      },
    },
  });

  const { formState } = form;

  useEffect(() => {
    if (user) {
      form.reset({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phone: user.phone || "",
        address: {
          address1: user.address?.address1 || "",
          address2: user.address?.address2 || "",
          city: user.address?.city || "",
          state: user.address?.state || "",
          primary: user.address?.primary || true,
          defaultShipping: user.address?.defaultShipping || true,
          defaultBilling: user.address?.defaultBilling || true,
          zip: user.address?.zip || user.zipCode || "",
          country: user.address?.country || "XC",
          geo: user.address?.geo || {
            lat: 0,
            lng: 0,
          },
        },
      });

      // Set the address display value if available
      if (user.address?.formatted_address) {
        setAddressValue(user.address.formatted_address);
      } else if (userData?.addresses[0]) {
        const address = userData?.addresses[0];
        // Construct a formatted address string from the available fields
        const addressParts = [];

        if (address.name) addressParts.push(address.name);
        if (address.address1) addressParts.push(address.address1);
        if (address.address2 && address.address2.trim())
          addressParts.push(address.address2);

        const cityStateZip = [];
        if (address.city) cityStateZip.push(address.city);
        if (address.state) cityStateZip.push(address.state);
        if (address.zip) cityStateZip.push(address.zip);

        if (cityStateZip.length > 0) addressParts.push(cityStateZip.join(", "));
        if (address.country) addressParts.push(address.country);

        if (addressParts.length > 0) {
          setAddressValue(addressParts.join(", "));
        }
      } else if (user.zipCode) {
        setAddressValue(user.zipCode);
      }
    }
  }, [user, form]);

  const updateUserProfile = useUpdateUserProfile();

  const onSubmit = (data) => {
    delete data.address.formatted_address;
    if (!data?.address?.address2) {
      data.address.address2 = "";
    }

    data.address = {
      ...data.address,
      resourceType: "User",
      resourceId: user?.id,
      primary: true,
      defaultShipping: false,
      defaultBilling: false,
    };

    updateUserProfile.mutate(data);
  };

  const handleAddressSelect = (address) => {
    // Mark that this change is coming from the autocomplete selection
    isAddressSelectedRef.current = true;

    // Map address_1 to address1 if it exists
    const formattedAddress = { ...address };

    form.setValue("address", formattedAddress);
    setAddressWasSelected(true);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full relative inset-0 min-h-full w-full bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: !hasFirstAndLastName ? `url(${fadedRedBg})` : "none",
      }}
    >
      <div className="flex flex-col items-center justify-center mb-8">
        <img
          src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
          className="h-28 mb-4"
          alt="Carde.io Logo"
        />
      </div>

      <Card className="w-full max-w-4xl rounded-lg overflow-hidden border border-border">
        <CardContent className="p-8">
          {isNewUser && (
            <Alert variant="uap-outline" className="mb-14">
              <AlertTitle className="flex items-center mb-0">
                <Terminal className="w-4 h-4 mr-3 text-carde-orange-400" />
                Logged in as {user?.email}
              </AlertTitle>
            </Alert>
          )}
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-white">
              Tell us about yourself
            </h1>
            <p className="text-muted-foreground mt-2">
              Your name, phone number, and zip code will be shown to stores that
              you apply to join.
            </p>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              {showEmailSwap && (
                <div className="flex gap-2 items-end">
                  <div className="flex-1">
                    <FormLabel className="text-white">Email</FormLabel>
                    <Input
                      disabled
                      value={user?.email || ""}
                      className="bg-[#1a1a1a] border-[#333] text-white"
                    />
                  </div>
                  <Button
                    type="button"
                    variant="secondary"
                    className="rounded-full border-[#333] border"
                    onClick={() => setShowSwapEmailModal(true)}
                  >
                    Change email
                  </Button>
                </div>
              )}
              <Dialog
                open={showSwapEmailModal}
                onOpenChange={setShowSwapEmailModal}
              >
                <SwapEmailModal
                  handleClose={() => setShowSwapEmailModal(false)}
                  currentUser={user}
                  currentEntityUser={userData?.entityUsers?.[0]}
                />
              </Dialog>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormField
                  control={form.control}
                  name="firstName"
                  rules={{ required: "First name is required" }}
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <FormLabel className="text-white">First Name*</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-[#1a1a1a] border-[#333] text-white placeholder:text-gray-500 placeholder:italic"
                          placeholder="First Name"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastName"
                  rules={{ required: "Last name is required" }}
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <FormLabel className="text-white">Last Name*</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-[#1a1a1a] border-[#333] text-white placeholder:text-gray-500 placeholder:italic"
                          placeholder="Last Name"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <FormLabel className="text-white">Phone Number</FormLabel>
                      <FormDescription className="text-xs">
                        We only share this data with stores and games you apply
                        to join
                      </FormDescription>
                      <FormControl>
                        <Input
                          className="bg-[#1a1a1a] border-[#333] text-white placeholder:text-gray-500 placeholder:italic"
                          placeholder="555-555-5555"
                          maxLength={12}
                          type="tel"
                          pattern="[0-9]*"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            field.onChange(e.target.value);
                          }}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="address"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <FormLabel className="text-white">
                        Address or Zip Code
                      </FormLabel>
                      <FormDescription className="text-xs">
                        We use this information to help you locate nearby stores
                      </FormDescription>
                      <FormControl>
                        <GooglePlacesAutocompleteInput
                          apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                          value={addressValue}
                          onChange={(value) => {
                            setAddressValue(value);
                            // Only set addressWasSelected to false if this change
                            // is not coming from an autocomplete selection
                            if (!isAddressSelectedRef.current) {
                              setAddressWasSelected(false);
                              setAddressWasEdited(true);
                            }
                            // Reset the ref for the next change
                            isAddressSelectedRef.current = false;
                          }}
                          onAddressSelect={handleAddressSelect}
                          placeholder="Address or Zip Code"
                          className="w-full border-[#333]"
                          inputClassName="bg-[#1a1a1a] border-[#333] text-white placeholder:text-gray-500 placeholder:italic"
                          showFallbackDialog={true}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {isNewUser && (
                <>
                  <div className="mb-6">
                    <CardTitle className="text-2xl font-bold text-white">
                      What would you like to use Carde Cloud for?
                    </CardTitle>
                    <CardDescription className="text-muted-foreground mt-2">
                      While you may have multiple roles, please select the one
                      you intend to use most often first.
                    </CardDescription>
                  </div>

                  <div className="mb-6">
                    <FormField
                      control={form.control}
                      name="role"
                      render={({ field }) => (
                        <FormItem className="space-y-2">
                          <FormControl>
                            <RadioGroup
                              defaultValue={"guest"}
                              value={field.value}
                              onValueChange={field.onChange}
                            >
                              {ROLES.map((role) => (
                                <div
                                  key={role.id}
                                  className="flex items-start gap-2"
                                >
                                  <RadioGroupItem
                                    id={role.id}
                                    value={role.value}
                                    className="mt-0.5"
                                  />
                                  <label
                                    htmlFor={role.id}
                                    className="flex flex-col gap-1"
                                  >
                                    <div className="text-sm">{role.title}</div>
                                    <div className="text-muted-foreground text-xs">
                                      {role.description}
                                    </div>
                                  </label>
                                </div>
                              ))}
                            </RadioGroup>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              )}
              <div className="flex justify-between pt-4">
                {hasFirstAndLastName && (
                  <Button
                    type="button"
                    variant="outline"
                    className="rounded-full border-[#333] hover:bg-[#333] hover:text-white"
                    onClick={handleLogout}
                  >
                    Log Out
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="gradient"
                  className="rounded-full"
                  disabled={
                    updateUserProfile.isPending ||
                    (!addressWasSelected && addressWasEdited) ||
                    addressValue === ""
                  }
                >
                  {updateUserProfile.isPending ? "Updating..." : "Continue"}
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default TournamentOrganizerProfileForm;
