import React from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { Input } from "@repo/ui/input";

const TimeSelect = ({ onChange, value }) => {
  return (
    <form className="max-w-[8rem] mx-auto">
      <div className="relative">
        <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
          <svg
            className="w-4 h-4 text-zinc-300"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <Input
          className="rounded-r-lg rounded-l-none ml-[-1px] border-border input-background"
          min="09:00"
          max="18:00"
          onChange={onChange}
          required
          type='time'
          value={value || "00:00"}
        />
      </div>
    </form>
  )
}

const DateTimeSelector = ({ dateValue, onDateChange, onTimeChange, timeValue, minDate, disableDates, ...props }) => {

  return (
    <div className='flex items-center w-full'>
      <Datepicker
        className='z-40 border-border input-background'
        displayFormat={'ddd. MMM DD, YYYY'}
        primaryColor={"orange"}
        useRange={false}
        asSingle={true}
        toggleClassName={undefined}
        value={dateValue}
        onChange={onDateChange}
        minDate={minDate}
        disabledDates={disableDates}
        {...props}
      />
      <TimeSelect onChange={onTimeChange} value={timeValue} />
    </div>
  )
}

export default DateTimeSelector;
