import React from "react";
import { cloneDeep, compact, isArray, uniqBy } from "lodash";
import { Controller } from "react-hook-form";
import { Label } from "@cardeio/ui/components/ui/label";
import { Separator } from '@cardeio/ui/components/ui/separator'

import { VisualizationField } from "@repo/ui/form";
import FieldRenderContainer from "@/components/Flows/CreateEvent/Steps/FieldRenderContainer";
import { checkType, evaluateCondition, evaluateConditions, getUnknownKey, sortByOrder } from "@/lib/createEventHelpers";
import FormCard from "@cardeio/ui/components/forms/form-card";

const ActivityConfigContainer = ({ flow, form, fields = [], isActive = true }) => {
  const {
    control,
    watch,
    resetField,
    setValue,
  } = form;
  const watchAllFields = watch();

  const validFields = {
    gameActivityId: fields.find((x) => x.field === 'gameActivityId').data.filter((x) => evaluateCondition(watchAllFields, x.conditions))?.map((y) => y.gameActivityId)?.map((x) => x.options).flat().map((x) => x.value),
    activityLevelId: fields.find((x) => x.field === 'gameActivityId').data.filter((x) => x.gameActivityId.options.some((y) => y.value === watchAllFields.gameActivityId))?.map(x => x.conditions.value).flat(),
  }

  return (
    <FormCard title="Format" subtitle="What activity format is this event?" className="mb-4 border border-border backdrop-blur-sm" variant="uap">
      <div className={`${isActive ? 'animate-slideInFromBottom block' : 'hidden'}`}>
        {fields.sort((a, b) => flow?.order.indexOf(a.field) - flow?.order.indexOf(b.field)).map((field) => {
          if (checkType(field.data) === 'object' && field.field !== 'Advanced Options') {
            return (
              <div className='mb-6'>
                <div>
                  {field.data.visualization !== 'hidden' && (
                    <Label className='mb-1 mt-2'>
                      {field.data.label}
                    </Label>
                  )}
                </div>
                <Controller
                  rules={{
                    validate: (value) => {
                      if (!value && field.data.visualization !== 'hidden') {
                        if (value === false) {
                          return true;
                        }
                        return `${field.data.label} is required`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name={field.field}
                  defaultValue={field.data.default ?? null}
                  render={(renderProps) => (
                    <VisualizationField
                      edit={false}
                      evaluateConditions={evaluateConditions}
                      visualization={field.data.visualization}
                      formValues={watchAllFields}
                      data={field.data}
                      setValue={setValue}
                      {...renderProps}
                    />
                  )}
                />
              </div>
            )
          }

          if (field.field === 'Advanced Options') {
            return sortByOrder(Object.keys(field.data), flow?.order).map((key, index) => {
              const data = field.data[key];

              // const fieldToRender = data.find((x) => evaluateConditions(watchAllFields, x.conditions));
              const fieldsToRender = data.map((x) => {
                const shouldRender = watchAllFields?.activityLevelId && watchAllFields?.gameActivityId && evaluateConditions(watchAllFields, x.conditions);

                if (shouldRender) {
                  return x;
                } else {
                  return null;
                }
              });
              return <>

                {fieldsToRender.map((fieldToRender) => {
                  if (fieldToRender) {
                    const fieldToRenderKey = getUnknownKey(fieldToRender);
                    const fieldData = fieldToRender[fieldToRenderKey];

                    return (
                      <>
                        <FieldRenderContainer
                          key={fieldToRenderKey}
                          fieldName={fieldToRenderKey}
                          reset={() => resetField(fieldToRenderKey)}
                        >
                          <div className='mb-6 space-y-1.5'>
                            {fieldData.visualization !== 'hidden' && (
                              <Label className='mt-2 mb-1'>
                                {fieldData.label}
                              </Label>
                            )}
                            <div>
                              <Controller
                                control={control}
                                name={fieldToRenderKey}
                                defaultValue={fieldData.default ?? null}
                                rules={{}}
                                render={(renderProps) => (
                                  <VisualizationField
                                    edit={false}
                                    evaluateConditions={evaluateConditions}
                                    setValue={setValue}
                                    visualization={fieldData.visualization}
                                    data={fieldData}
                                    formValues={watchAllFields}
                                    {...renderProps}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </FieldRenderContainer>
                      </>
                    );
                  }
                })}
              </>
            });
          }

          if (checkType(field.data) === 'array') {
            // eslint-disable-next-line no-prototype-builtins
            if (field.data.some((i) => i.hasOwnProperty('conditions'))) {
              const fieldsToRender = field.data.map((x) => {
                const shouldRender = x.conditions.all
                  ? evaluateConditions(watchAllFields, x.conditions)
                  : evaluateCondition(watchAllFields, x.conditions);
                if (x.activityLevelId || x.gameActivityId || (watchAllFields?.activityLevelId && watchAllFields?.gameActivityId && shouldRender)) {
                  return x;
                } else {
                  return null;
                }
              });

              // TODO: at a later date... backend will merge options from all fields if they have the same key
              const clonedFieldsToRender = cloneDeep(fieldsToRender);
              const dedupedfieldsToRender =
                compact(clonedFieldsToRender)?.reduce((acc, curr) => {
                  if (curr) {
                    Object.keys(curr).forEach((key) => {
                      if (key !== 'conditions') {
                        const existing = acc.find((x) => x[key]);
                        if (existing) {
                          const clonedExistingOptions = [...existing[key].options];
                          const clonedCurrOptions = [...curr[key].options];
                          const mergedOptions = clonedExistingOptions.concat(clonedCurrOptions);
                          existing[key].options = uniqBy(mergedOptions, 'value');
                          if (existing[key].visualization === 'hidden') {
                            if (curr[key].visualization !== 'hidden') {
                              existing[key].visualization = curr[key].visualization;
                            } else {
                              existing[key].visualization = 'buttonGroupSingle';
                            }
                          }
                        } else {
                          acc.push({ [key]: curr[key] });
                        }
                      }
                    });
                  }
                  return acc;
                }, []);

              return dedupedfieldsToRender?.map((fieldToRender, index, array) => {
                if (fieldToRender) {
                  const fieldToRenderKey = getUnknownKey(fieldToRender);
                  const fieldData = fieldToRender[fieldToRenderKey];

                  return (<>
                    <FieldRenderContainer
                      key={fieldToRenderKey}
                      fieldName={fieldToRenderKey}
                      reset={() => resetField(fieldToRenderKey)}
                    >
                      <div className='mb-6 space-y-1.5'>
                        {fieldData.visualization !== 'hidden' && (
                          <Label className='mb-1 mt-2'>
                            {fieldData.label}
                          </Label>
                        )}
                        <div>
                          <Controller
                            control={control}
                            name={fieldToRenderKey}
                            defaultValue={fieldData.default ?? null}
                            rules={{}}
                            render={({ field, ...renderProps }) => (
                              <VisualizationField
                                edit={false}
                                evaluateConditions={evaluateConditions}
                                setValue={setValue}
                                visualization={fieldData.options?.length > 1 ? fieldData.visualization : 'hidden'}
                                data={fieldData}
                                formValues={watchAllFields}
                                invalidFields={validFields[fieldToRenderKey]?.length > 0 ? fieldData.options.filter((x) => validFields[fieldToRenderKey] && isArray(validFields[fieldToRenderKey]) && !validFields[fieldToRenderKey].includes(x.value)).map((x) => x.value) : []}
                                field={{
                                  ...field, onChange: (v) => {
                                    if (fieldToRenderKey === 'gameActivityId') {
                                      const conditions = fieldsToRender.filter((x) => x?.[fieldToRenderKey]?.options.some((y) => y.value === v)).map((x) => x.conditions);
                                      if (conditions && !conditions?.some((x) => evaluateCondition(watchAllFields, x))) {
                                        setValue('activityLevelId', undefined)
                                      }
                                    }
                                    if (fieldToRenderKey === 'activityLevelId') {
                                      const conditions = fields.find((x) => x.field === 'gameActivityId').data.filter((x) => evaluateCondition({ ...watchAllFields, [fieldToRenderKey]: v }, x.conditions))?.map((y) => y.gameActivityId)?.map((x) => x.options).flat().map((x) => x.value)
                                      if (!conditions.includes(watchAllFields.gameActivityId)) {
                                        setValue('gameActivityId', undefined)
                                      }
                                    }
                                    // TODO: check which is the valid field from conditions to set in form, add condition to only set it on gameActivityId
                                    field.onChange(v)
                                  }
                                }}
                                {...renderProps}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </FieldRenderContainer>
                    {watchAllFields?.activityLevelId && watchAllFields?.gameActivityId && fieldToRenderKey === 'gameActivityId' && <Separator className="my-4" />}
                  </>
                  );
                }
              });
            }
          }
        })}
      </div>
    </FormCard>
  )
}

export default ActivityConfigContainer;
