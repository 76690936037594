import { createFileRoute } from "@tanstack/react-router";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { startOfWeek, addDays, format } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { RiCalendarLine, RiListUnordered } from "@remixicon/react";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import { useUserApplications } from "@/data/establishment/staffApplications";
import { useNavigate } from "@tanstack/react-router";

import { Card, CardHeader, CardContent } from "@cardeio/ui/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@cardeio/ui/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@repo/ui/tabs";
import Loading from "@/components/Loading";
import { Shell } from "@/components/Navigation/shell";

// Import components from the existing implementation
import EventCalendar from "@/_pages_/auth/Entity/Store/Tools/Events/EventCalendar";
import EventList from "@/_pages_/auth/Entity/Store/Tools/Events/EventList";
// Constants
const VIEW_TYPES = {
  CALENDAR: 'calendar',
  LIST: 'list'
};

const TAB_TYPES = {
  UPCOMING: 'upcoming',
  COMPLETE: 'complete'
};

const SEARCH_PARAMS = {
  OWNER_IDS: 'ownerIds',
  LIMIT: 'limit',
  NAME: 'name',
  PAGE: 'page',
  STATUS: 'status',
  GAME_IDS: 'gameIds',
  STARTS_AT: 'startsAt',
  ENDS_AT: 'endsAt'
};

const EVENT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  IN_PROGRESS: 'inProgress',
  CANCELED: 'canceled',
  COMPLETE: 'complete'
};

const formatDateRange = (date) => {
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 });
  const endOfWeekDate = addDays(startOfWeekDate, 6);

  const startMonth = format(startOfWeekDate, 'MMMM d');
  const endMonth = format(endOfWeekDate, 'MMMM d');
  const startYear = format(startOfWeekDate, 'yyyy');
  const endYear = format(endOfWeekDate, 'yyyy');

  const isSameMonth = startOfWeekDate.getMonth() === endOfWeekDate.getMonth();
  const isSameYear = startOfWeekDate.getYear() === endOfWeekDate.getYear();

  return (
    <span>
      <span className="font-bold">{startMonth}</span>
      {!isSameYear && <span className="font-medium">, {startYear}</span>}
      {' - '}
      <span className="font-bold">
        {isSameMonth ? format(endOfWeekDate, 'd') : endMonth}
      </span>
      <span className="font-medium">, {endYear}</span>
    </span>
  );
};

// Hook to fetch events list
const useEventsList = ({
  establishmentId,
  activeTab,
  games,
  searchText,
  page,
  limit,
  viewType,
}) => {
  return useQuery({
    refetchOnWindowFocus: true,
    queryKey: ['events', 'list', establishmentId, activeTab, games, searchText, page, limit],
    queryFn: async () => {
      if (!establishmentId) return { data: [], pagination: { totalResults: 0 } };

      const searchParams = new URLSearchParams({
        [SEARCH_PARAMS.OWNER_IDS]: establishmentId,
        [SEARCH_PARAMS.LIMIT]: limit,
      });

      if (searchText) {
        searchParams.append(SEARCH_PARAMS.NAME, searchText);
      }
      if (page) {
        searchParams.append(SEARCH_PARAMS.PAGE, page);
      }

      if (activeTab === TAB_TYPES.UPCOMING) {
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.DRAFT);
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.PUBLISHED);
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.IN_PROGRESS);
      } else {
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.CANCELED);
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.COMPLETE);
      }

      if (games?.length) {
        games.forEach((gameId) =>
          searchParams.append(SEARCH_PARAMS.GAME_IDS, gameId),
        );
      }

      const res = await webAxiosInstance.get(
        `/api/organize/events?${searchParams.toString()}`,
      );
      return res?.data;
    },
    enabled: viewType === VIEW_TYPES.LIST && !!establishmentId,
  });
};

// Hook to fetch calendar events
const useCalendarEvents = ({ establishmentId, currentDate, games, viewType }) => {
  return useQuery({
    queryKey: ['events', 'calendar', establishmentId, currentDate, games],
    queryFn: async () => {
      if (!establishmentId) return { data: [], pagination: { totalResults: 0 } };

      const searchParams = new URLSearchParams({
        [SEARCH_PARAMS.OWNER_IDS]: establishmentId,
        [SEARCH_PARAMS.LIMIT]: '100', // Higher limit for calendar view
      });

      const monday = startOfWeek(currentDate, { weekStartsOn: 1 });
      const sunday = addDays(monday, 6);
      searchParams.append(SEARCH_PARAMS.STARTS_AT, monday.toISOString());
      searchParams.append(SEARCH_PARAMS.ENDS_AT, sunday.toISOString());

      if (games?.length) {
        games.forEach((gameId) =>
          searchParams.append(SEARCH_PARAMS.GAME_IDS, gameId),
        );
      }

      const res = await webAxiosInstance.get(
        `/api/organize/events?${searchParams.toString()}`,
      );
      return res?.data;
    },
    enabled: viewType === VIEW_TYPES.CALENDAR && !!establishmentId,
  });
};

function EventsPage() {
  const { userData } = useRootStore();
  const user = userData?.user;
  const navigate = useNavigate();

  // State for view controls
  const [viewType, setViewType] = useState(VIEW_TYPES.LIST);
  const [activeTab, setActiveTab] = useState(TAB_TYPES.UPCOMING);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  // Get search store values
  const { games, setPage, setSearchText, setLimit, limit, page } = useEventsSearchStore();

  // Fetch user applications to get approved stores
  const userApplicationsQuery = useUserApplications(user?.id);
  const approvedApplications = userApplicationsQuery.data?.data?.filter(
    app => app.type === "employeeApplication" && app.status === "resolved"
  ) || [];

  // Set first approved establishment as default when data loads
  useEffect(() => {
    if (approvedApplications.length > 0 && !selectedEstablishment) {
      setSelectedEstablishment(approvedApplications[0].target.id);
    }
  }, [approvedApplications, selectedEstablishment]);

  // Fetch events based on view type
  const eventsListQuery = useEventsList({
    establishmentId: selectedEstablishment,
    activeTab,
    games,
    searchText: "",
    page,
    limit,
    viewType,
  });

  const calendarEventsQuery = useCalendarEvents({
    establishmentId: selectedEstablishment,
    currentDate,
    games,
    viewType,
  });

  // Determine which query to use based on view type
  const currentQuery = viewType === VIEW_TYPES.LIST ? eventsListQuery : calendarEventsQuery;
  const events = currentQuery.data?.data || [];
  const pagination = currentQuery.data?.pagination || {};
  const isFetching = currentQuery.isLoading || currentQuery.isFetching;

  return (
    <div className="space-y-6 p-4">
      <Shell title="Events" subtitle="Manage events for your approved stores. You can view events in calendar or list view.">

      {userApplicationsQuery.isLoading ? (
        <Loading />
      ) : approvedApplications.length === 0 ? (
        <Card className="bg-[#1a1a1a] border-none">
          <CardContent className="p-6">
            <p className="text-center text-zinc-300">
              You don't have any approved stores yet. Please apply as staff in the My Stores section.
            </p>
          </CardContent>
        </Card>
      ) : (
        <Card className="bg-[#1a1a1a] border-none">
          <CardHeader className="flex flex-row items-center justify-between p-6">
            <h2 className="text-2xl font-bold">
              {viewType === VIEW_TYPES.CALENDAR ? (
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    onClick={() => setCurrentDate(prev => addDays(prev, -7))}
                    className="flex items-center justify-center text-gray-400 hover:text-gray-300"
                  >
                    <ChevronLeftIcon className="size-8" aria-hidden="true" />
                  </button>
                  <span className="text-2xl">{formatDateRange(currentDate)}</span>
                  <button
                    type="button"
                    onClick={() => setCurrentDate(prev => addDays(prev, 7))}
                    className="flex items-center justify-center text-gray-400 hover:text-gray-300"
                  >
                    <ChevronRightIcon className="size-8" aria-hidden="true" />
                  </button>
                </div>
              ) : (
                <span className="text-2xl font-bold">Event List</span>
              )}
            </h2>
          </CardHeader>

          <div className="grid grid-cols-3 items-center px-6 py-4 border-b border-white/10">
            <div className="flex gap-4 items-center">
              <button
                className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${
                  viewType === VIEW_TYPES.CALENDAR
                    ? 'bg-orange-700 text-white'
                    : 'bg-zinc-800 text-zinc-300 hover:bg-zinc-700'
                }`}
                onClick={() => setViewType(VIEW_TYPES.CALENDAR)}
              >
                <RiCalendarLine className="size-5" />
                Week
              </button>
              <button
                className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${
                  viewType === VIEW_TYPES.LIST
                    ? 'bg-orange-700 text-white'
                    : 'bg-zinc-800 text-zinc-300 hover:bg-zinc-700'
                }`}
                onClick={() => setViewType(VIEW_TYPES.LIST)}
              >
                <RiListUnordered className="size-5" />
                List
              </button>
            </div>

            {viewType === VIEW_TYPES.LIST && (
              <div className="justify-self-center">
                <Tabs
                  value={activeTab}
                  onValueChange={(value) => {
                    setActiveTab(value);
                  }}
                  className="w-[240px]"
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger
                      value={TAB_TYPES.UPCOMING}
                      className="data-[state=active]:bg-orange-700"
                    >
                      Upcoming
                    </TabsTrigger>
                    <TabsTrigger
                      value={TAB_TYPES.COMPLETE}
                      className="data-[state=active]:bg-orange-700"
                    >
                      Complete
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            )}

            <div className="justify-self-end">
              <Select
                value={selectedEstablishment}
                onValueChange={setSelectedEstablishment}
              >
                <SelectTrigger className="w-[250px] bg-[#1a1a1a] border-[#333]">
                  <SelectValue placeholder="Select a store" />
                </SelectTrigger>
                <SelectContent>
                  {approvedApplications.map((app) => (
                    <SelectItem key={app.target.id} value={app.target.id}>
                      {app.target.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {viewType === VIEW_TYPES.CALENDAR ? (
            <EventCalendar
              events={events}
              currentDate={currentDate}
              isFetching={isFetching}
            />
          ) : (
            <EventList
              events={events}
              isFetching={isFetching}
              pagination={pagination}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              navigate={navigate}
            />
          )}
        </Card>
      )}
      </Shell>
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/organizer/events')({
  component: EventsPage
});
