import { useAppliedGames } from "@/store/useGameStore";
import TaskStatusBadge from "./TaskStatusBadge";
import { Button } from '@cardeio/ui/components/ui/button'
import { ChevronDownIcon } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@cardeio/ui/components/ui/dropdown-menu";
import Loading from "@/components/Loading";
import { createEventMutationFn } from "@repo/api/base";
import { useMutation } from "@tanstack/react-query";
import { router } from "@/App";
import { toast } from "sonner";
import { useParams } from "@tanstack/react-router";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { getIsDevEnvironment } from "@/utils";
import { GameCard } from "@/components/GameCard";

const RETAIL_ONLY_GAME_STATUSES = ["order-product",
  "view-inventory",
  "remove-game"];

const OP_ONLY_GAME_STATUSES = ["new-event",
  "new-event-template",
  "remove-game"];

const getGameStatus = (applications) => {
  if (applications?.organizedPlay?.status === 'approved' && applications?.retail?.status === 'approved') {
    return 'approvedRetailOP';
  }
  if (applications?.retail?.status === 'approved') {
    return 'approvedRetailOnly';
  }

  return 'approvedOpOnly';
}

const GameStoreCard = ({ game }) => {
  const gameStatus = getGameStatus(game.applications);
  const createActivityMutation = useMutation({
    mutationFn: createEventMutationFn,
    onSuccess: async ({ data }) => {
      await router.navigate({ to: `/entity/${entityId}/events/${data?.data?.id}/draft` });
    },
    onError: (e) => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with creating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });
  const { entityId } = useParams({ strict: false });
  const { userData } = useRootStore();

  const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);
  const environment = getIsDevEnvironment() ? 'development' : 'production';

  const OPTION_ITEMS = [
    {
      id: "new-event",
      label: "New Event",
      onClick: () => createActivityMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        gameId: game.id,
        data: {
          ownerId: establishment.id,
          ownerType: 'Establishment',
        },
      }),
    },
    {
      id: "order-product",
      label: "Order Product",
      onClick: () => {
        window.open(`https://${game.slug}.${environment === "production" ? "" : "dev."}carde.io/store`, "_blank");
      }
    },
  ]
  const items = OPTION_ITEMS.filter((item) => {
    if (gameStatus === 'approvedRetailOnly') {
      return RETAIL_ONLY_GAME_STATUSES.includes(item.id)
    }
    if (gameStatus === 'approvedOpOnly') {
      return OP_ONLY_GAME_STATUSES.includes(item.id)
    }
    return true;
  });

  return (
    <GameCard
      game={game}
      contentClassName="justify-start gap-4"
    >
      <>
        <TaskStatusBadge status={gameStatus} />
        <div className="flex-1" />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {createActivityMutation.isPending ? <Loading /> :
              <div>
                <Button variant={"outline-gradient"}>
                  <div className="flex justify-between items-center gap-2">
                    Options
                    <ChevronDownIcon className="w-4 h-4" />
                  </div>
                </Button>
              </div>}
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-dropdown-menu text-white border-0">
            {items.map((item) => (
              <DropdownMenuItem onClick={item.onClick} className={`hover:bg-secondary/10 ${item.type === "destructive" ? "text-destructive-dark" : ""}`} key={item.id}>
                <p>{item.label}</p>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    </GameCard>
  );
};

export const StoreGames = ({ search }) => {
  const { data: appliedGames, isLoading } = useAppliedGames();
  if (isLoading) return <Loading />
  return (
    <div className="flex flex-wrap gap-4 justify-start items-start">
      {appliedGames?.filter((game) => game.name.toLowerCase().includes(search.toLowerCase())).map((game) => (
        <GameStoreCard key={game.id} game={game} />
      ))}
    </div>
  );
}
