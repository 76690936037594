import { useEffect, useRef, useState } from "react"
import { Input } from "@cardeio/ui/components/ui/input"
import { Popover, PopoverAnchor, PopoverContent } from "@cardeio/ui/components/ui/popover"
import { cn } from "@cardeio/ui/lib/utils"
import { Command } from "cmdk"
import { Check } from "lucide-react"

interface AutocompleteOption {
	value: string
	label: string
}

interface AutocompleteProps {
	selectedValue: string
	onSelectedValueChange: (value: string) => void
	searchValue: string
	onSearchValueChange: (value: string) => void
	defaultValue?: string
	items?: AutocompleteOption[]
	isLoading?: boolean
	emptyMessage?: (input: string) => React.ReactNode
	onEmptyMessagePressed?: (input: string) => void
	placeholder?: string
	searchOnFocus?: boolean
	className?: string
	inputClassName?: string
	inputVariant?: "default" | "uap"
	onKeyDown?: (e: React.KeyboardEvent) => void
}

export default function AutocompleteInput({
	selectedValue,
	onSelectedValueChange,
	searchValue,
	onSearchValueChange,
	defaultValue,
	items = [],
	isLoading,
	emptyMessage = (input) => `No items found.`,
	onEmptyMessagePressed,
	placeholder = "Search...",
	searchOnFocus = true,
	className,
	inputClassName,
	inputVariant,
	onKeyDown,
}: AutocompleteProps) {
	const [open, setOpen] = useState(false)
	const [highlightedIndex, setHighlightedIndex] = useState(-1)
	const commandListRef = useRef<HTMLDivElement>(null)

	// Reset highlighted index when items change or dropdown opens
	useEffect(() => {
		if (open) {
			// Reset to no selection when dropdown opens or items change
			setHighlightedIndex(-1)
		}
	}, [items, open])

	useEffect(() => {
		if (defaultValue && items.length > 0) {
			const defaultItem = items.find((item) => item.value === defaultValue)
			if (defaultItem) {
				onSelectedValueChange(defaultItem.value)
				onSearchValueChange(defaultItem.label)
			}
		}
	}, [defaultValue, items, onSelectedValueChange, onSearchValueChange])

	const reset = () => {
		onSelectedValueChange("")
		onSearchValueChange("")
	}

	const onInputBlur = () => {
		setOpen(false)
	}

	const onSelectItem = (inputValue: string) => {
		if (inputValue === selectedValue) {
			reset()
		} else {
			onSelectedValueChange(inputValue)
		}
		setOpen(false)
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		// Call the provided onKeyDown prop if it exists
		if (onKeyDown) {
			onKeyDown(e)
			// If the event was prevented by the onKeyDown handler, return early
			if (e.isDefaultPrevented()) {
				return
			}
		}

		// If dropdown is closed, open it on any key except Escape
		if (!open) {
			if (e.key !== "Escape") {
				setOpen(true)
			}
			return
		}

		// Handle keyboard navigation
		switch (e.key) {
			case "ArrowDown":
				e.preventDefault()
				if (items.length > 0) {
					setHighlightedIndex((prevIndex) =>
						prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
					)
				}
				break
			case "ArrowUp":
				e.preventDefault()
				if (items.length > 0) {
					setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
				}
				break
			case "Enter":
				if (
					items.length > 0 &&
					highlightedIndex >= 0 &&
					highlightedIndex < items.length
				) {
					e.preventDefault() // Prevent form submission
					onSelectItem(items[highlightedIndex].value)
				} else if (searchValue && items.length === 0 && onEmptyMessagePressed) {
					e.preventDefault() // Prevent form submission
					onEmptyMessagePressed(searchValue)
				}
				break
			case "Escape":
				setOpen(false)
				break
			case "Tab":
				// Close dropdown on Tab but don't prevent default behavior
				setOpen(false)
				break
		}
	}

	// Scroll highlighted item into view
	useEffect(() => {
		if (open && commandListRef.current && items.length > 0 && highlightedIndex >= 0) {
			const highlightedElement = commandListRef.current.querySelector(
				`[data-highlighted="true"]`
			)
			if (highlightedElement) {
				highlightedElement.scrollIntoView({ block: "nearest" })
			}
		}
	}, [highlightedIndex, open, items.length])

	return (
		<div className="flex items-center w-full">
			<Popover open={open} onOpenChange={setOpen}>
				<Command shouldFilter={false} className={className}>
					<PopoverAnchor asChild>
						<Command.Input
							asChild
							value={searchValue}
							onValueChange={onSearchValueChange}
							onKeyDown={handleKeyDown}
							onMouseDown={() => setOpen((openValue) => !!searchValue || !openValue)}
							onFocus={() => {
								if (searchOnFocus) {
									if ((!searchValue || searchValue.trim() === "") && items.length > 0) {
										setOpen(true)
									} else {
										onSearchValueChange(searchValue)
										setOpen(true)
									}
								}
							}}
							onBlur={onInputBlur}
						>
							<Input
								placeholder={placeholder}
								className={inputClassName}
								variant={inputVariant}
							/>
						</Command.Input>
					</PopoverAnchor>

					{!open && <Command.List aria-hidden="true" className="hidden" />}

					<PopoverContent
						asChild
						onOpenAutoFocus={(e) => e.preventDefault()}
						onInteractOutside={(e) => {
							if (e.target instanceof Element && e.target.hasAttribute("cmdk-input")) {
								e.preventDefault()
							}
						}}
						className={cn(
							"z-50 min-w-[8rem] overflow-hidden rounded-md p-1",
							"data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
							"w-[--radix-popover-trigger-width] p-0"
						)}
					>
						<Command.List ref={commandListRef}>
							{isLoading && (
								<>
									{Array.from({ length: 5 }).map((_, i) => (
										<Command.Loading key={i}>
											<div className="py-1 pl-8 pr-1">
												<div className={"animate-pulse rounded-md bg-muted h-6 w-full"} />
											</div>
										</Command.Loading>
									))}
								</>
							)}

							{items.length > 0 && !isLoading ? (
								<Command.Group>
									{items.map((option, index) => (
										<div key={option.value}>
											<Command.Item
												key={index}
												value={option.value}
												onMouseDown={(e) => e.preventDefault()}
												onSelect={onSelectItem}
												data-highlighted={index === highlightedIndex}
												className={cn(
													"relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors",
													"hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
													index === highlightedIndex
														? "bg-accent text-accent-foreground"
														: ""
												)}
												onMouseEnter={() => setHighlightedIndex(index)}
												onMouseLeave={() => setHighlightedIndex(-1)}
											>
												<Check
													className={cn(
														"mr-2 h-4 w-4",
														selectedValue === option.value ? "opacity-100" : "opacity-0"
													)}
												/>
												{option.label}
											</Command.Item>
										</div>
									))}
								</Command.Group>
							) : null}

							{!isLoading && searchValue ? (
								<Command.Empty
									className="text-sm px-2 py-1 cursor-pointer"
									onMouseDown={(e) => {
										e.preventDefault()
										onEmptyMessagePressed?.(searchValue)
									}}
								>
									{emptyMessage(searchValue)}
								</Command.Empty>
							) : null}
						</Command.List>
					</PopoverContent>
				</Command>
			</Popover>
		</div>
	)
}
