import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@cardeio/ui/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@cardeio/ui/components/ui/card';
import { Pencil } from 'lucide-react';
import stripeImage from '../../../assets/stripe.png';
import { useCreatePayoutAccountLink } from '@/data/manage/payout/createPayoutAccountLink';
import { useSuspenseQuery } from '@tanstack/react-query';
import { payoutAccountOptions } from '@/data/manage/payout/getPayout';
import { CheckIcon } from '@heroicons/react/20/solid/index';
import { useCreatePayoutAccount } from '@/data/manage/payout/useCreatePayoutAccount';
import { get, isEmpty } from 'lodash';
import { toast } from 'sonner';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { queryClient } from '@/lib/queryClient';
import { useUpdatePayoutAccount } from '@/data/manage/payout/useUpdatePayoutAccount';
import { useNavigate, useSearch } from '@tanstack/react-router';

const Financials = ({ id, ownerType }) => {
  const navigate = useNavigate()
  const searchParams = useSearch({ strict: false })
  const [loading, setLoading] = useState(false)
  const createPayoutAccount = useCreatePayoutAccount();
  const createPayoutAccountLink = useCreatePayoutAccountLink();
  const updatePayoutAccountLink = useUpdatePayoutAccount();
  const payoutAccountArguments = useMemo(() => ({ id, params: { lookup: 'ownerId' } }), [id])
  const { data: payoutAccount } = useSuspenseQuery(payoutAccountOptions(payoutAccountArguments))
  const { payoutEnabled } = payoutAccount || { payoutEnabled: false }
  const isPayoutAccountEmpty = isEmpty(payoutAccount)
  const isProcessIncomplete = !isPayoutAccountEmpty && !payoutEnabled

  const onClick = useCallback(async () => {
    if (loading) return
    setLoading(true)
    let newPayoutAccount = payoutAccount
    const returnUrl = window.location.href + '?state=update'
    try {
      if (searchParams?.state === 'update') {
        await updatePayoutAccountLink.mutateAsync(payoutAccountArguments)
        await queryClient.invalidateQueries({ queryKey: [id, 'payoutAccount'] })
        await navigate({
          to: window.location.pathname,
          replace: true
        })
      } else {
        if (isPayoutAccountEmpty) {
          newPayoutAccount = await createPayoutAccount.mutateAsync({ data: { ownerType, ownerId: id } });
        }
        if (!isEmpty(newPayoutAccount)) {
          const data = await createPayoutAccountLink.mutateAsync({ id, data: { refreshUrl: returnUrl, returnUrl: returnUrl }, params: { lookup: 'ownerId' } })
          const redirectUrl = get(data, 'metaData.accountLink.url')
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
      }
    } catch {
      toast.error('Cannot connect stripe at this time.')
    }
    setLoading(false)
  }, [loading, payoutAccount, searchParams?.state, id, ownerType, createPayoutAccount, createPayoutAccountLink, updatePayoutAccountLink, navigate, isPayoutAccountEmpty, payoutAccountArguments])

  const getButtonText = () => {
    if (payoutEnabled) {
      return "Update"
    }
    return "Connect"
  }

  useEffect(() => {
    if (searchParams?.state === 'update') {
      (async () => {
        await onClick()
      })()
    }
  }, [searchParams, onClick]);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Payout</CardTitle>
        <Button
          variant="outline"
          size="sm"
          className="h-8 border-zinc-700 hover:bg-zinc-800"
          onClick={onClick}
          disabled={loading}
        >
          <Pencil className="h-4 w-4 mr-2" />
          {loading ? "CONNECTING..." : "EDIT"}
        </Button>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <h2>Get started with stripe</h2>
          <p className="text-sm text-zinc-500">Please have the owner of this business or someone with significant management responsibility to complete this form</p>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <img src={stripeImage} alt='stripe' height={'50px'} width={'110px'} />
            {(isProcessIncomplete || payoutEnabled) && (
              <div className={`flex items-center gap-1 ${isProcessIncomplete ? 'text-yellow-500' : 'text-green-500'} text-sm`}>
                {isProcessIncomplete ? <ExclamationTriangleIcon className="h-6 w-6" /> : <CheckIcon className="h-6 w-6" />}
                <p>{isProcessIncomplete ? 'Setup Incomplete' : 'Connected'}</p>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default Financials
