import { createFileRoute } from '@tanstack/react-router'
import { GameDirectory } from '@/_pages_/auth/Entity/GameDirectory'
export const Route = createFileRoute(
  '/_authenticated/entity/$entityId/gameDirectory/',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return <GameDirectory />
}
