import { orderBy } from 'lodash';

function getCurrentPhases(data) {
  const results = [];

  for (const key in data) {
    for (const item in data[key]) {
      const phase = data[key][item];
      if (['inProgress', 'generating', 'preview'].includes(phase.status)) {
        results.push(phase);
      }
    }
  }

  return results;
}

const flattenPhases = (phases) => {
  const results = [];

  for (const key in phases) {
    for (const item in phases[key]) {
      const phase = phases[key][item];
      results.push(phase);
    }
  }

  return results;
};

export const setDefaultActivityState = (activity) => {
  let activePhases = getCurrentPhases(activity.phases);
  if (!activePhases.length) {
    activePhases = flattenPhases(activity.phases) ?? [];
  }

  const checkAllCompleted = activePhases?.every((phase) => phase?.status === 'complete');
  const tournamentPhases = activePhases?.filter((phase) => phase?.tournament !== undefined) || [];

  if (checkAllCompleted) {
    activePhases = orderBy(activePhases, 'stage', 'desc');
  }

  const firstActivePhase = activePhases[0];
  const firstTournamentPhase = tournamentPhases[0];

  return {
    allPhases: flattenPhases(activity.phases),
    activePhases,
    activeStage: firstActivePhase?.stage,
    activePhaseName: firstActivePhase?.name || '',
    currentActivityPhaseType: firstActivePhase?.activityPhaseType || '',
    nextPhase: flattenPhases(activity.phases)?.find(phase => phase?.stage === (firstActivePhase?.stage || 0) + 1) || null,
    currentTournamentRound: firstActivePhase?.tournament
      ? firstActivePhase?.tournament?.rounds[firstActivePhase?.tournament?.currentRound - 1]
      : firstTournamentPhase?.tournament?.rounds[firstTournamentPhase?.tournament?.currentRound - 1] ?? null,
    currentTournament: firstActivePhase?.tournament ?? firstTournamentPhase?.tournament ?? null,
  };
};
