import { useEffect, useMemo, useState } from "react";
import { useParams } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from "@/components/Card/CardHeader";
import Pagination from "@repo/ui/card/Pagination";
import { webAxiosInstance } from "@/utils/axios";
import { Button } from "@repo/ui/button";
import { useEventStore } from "@/store/useEventStore";
import { getActivityPhaseRosterMutationFn } from "@repo/api/base";
import { eventQueryOptions } from "@/data/organization/queries";
import { queryClient } from "@/lib/queryClient";
import EventStandingsCard from "@/components/Cards/Variants/EventStandingsCard";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTextItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/select";

const FilterSection = ({
  activeFilterPodId,
  setActiveFilterPodId,
  activePhases,
}) => {
  return (
    <div className="flex">
      <Select
        onValueChange={setActiveFilterPodId}
        defaultValue={activeFilterPodId || "all"}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select a pod" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Pods</SelectLabel>
            <SelectTextItem value={"all"}>All</SelectTextItem>
            {activePhases?.map((phase) => (
              <SelectTextItem key={phase?.id} value={phase?.id}>
                Pod {phase?.pod}
              </SelectTextItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

const EventStandingsTab = ({ activity, registrations } = {}) => {
  const [roster, setRoster] = useState([]);
  const { activePhases } = useEventStore();
  const [activeFilterPodId, setActiveFilterPodId] = useState("all");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const isActivePhasesTournamentPhases = useMemo(() => {
    return activePhases?.some(
      (phase) => phase?.activityPhaseType === "tournament"
    );
  }, [activePhases]);

  const { eventId } = useParams({ strict: false });

  const { data: eventRes } = useSuspenseQuery(eventQueryOptions(eventId));

  const { data: rosterData } = useQuery({
    queryKey: [activity?.id, "activity", "roster"],
    queryFn: async () => {
      if (!activity?.gameId) {
        return null;
      }

      const url = `/api/play/activities/${activity.id}/roster`;
      const res = await webAxiosInstance.get(url, {
        headers: {
          'Game-Id': activity.gameId,
        },
      });

      return res?.data?.data;
    },
    enabled: Boolean(activity?.gameId)
  });

  const refreshCache = () => {
    queryClient.invalidateQueries({
      queryKey: ["event", eventId, "registrations"],
    });
    queryClient.invalidateQueries({
      queryKey: [
        activePhases?.map((p) => p?.id)?.join(","),
        "activity",
        "roster",
      ],
    });
    queryClient.invalidateQueries({ queryKey: [activity?.id, "activity"] });
  };

  useEffect(() => {
    if (registrations) {
      if (rosterData?.length > 0) {
        const normalizedRoster = registrations.map((registration) => {
          const phaseUser = rosterData?.find(
            (r) => r?.user?.id === registration?.userId
          );
          if (phaseUser) {
            return {
              ...registration,
              phaseUser,
            };
          }
          return registration;
        });

        setRoster(
          normalizedRoster.sort((a, b) => {
            const standingA = a?.phaseUser?.standing || Number.MAX_SAFE_INTEGER;
            const standingB = b?.phaseUser?.standing || Number.MAX_SAFE_INTEGER;
            return standingA - standingB;
          })
        );
      } else {
        setRoster(registrations);
      }
    }
  }, [registrations, rosterData]);

  const filteredRoster = useMemo(
    () =>
      roster?.filter((r) => {
        const registration = r?.activityRegistrations?.[0];
        return (
          registration &&
          ["registered", "checkedIn"].includes(registration?.status)
        );
      }),
    [roster]
  );

  return (
    <div className="relative z-10">
      <div className="flex justify-between items-center z-10 mb-4">
        <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
          Event Standings
        </h1>
        <Button
          size={"sm"}
          variant={"outlined"}
          className={"text-white"}
          onClick={refreshCache}
        >
          Refresh
        </Button>
      </div>

      {registrations?.length === 0 ? (
        <div className="flex items-center justify-center">
          <h2 className="text-2xl">No Standings Yet</h2>
        </div>
      ) : isActivePhasesTournamentPhases ? (
        <>
          <CardContainer className="relative z-10">
            <CardHeader
              title="Standings"
              filters={{
                hasFilters: activePhases?.length > 0,
                hasSearch: false,
                isFilterModalOpen,
                setIsFilterModalOpen,
                filterComponent: (
                  <FilterSection
                    activeFilterPodId={activeFilterPodId}
                    setActiveFilterPodId={setActiveFilterPodId}
                    activePhases={activePhases}
                  />
                ),
              }}
            />
            {roster?.length > 0 && (
              <ul role="list" className="divide-y divide-white/5">
                {filteredRoster
                  .filter((r) =>
                    activeFilterPodId !== "all"
                      ? r?.phaseUser?.phase?.id === activeFilterPodId
                      : true
                  )
                  .map((registration) => (
                    <EventStandingsCard
                      activity={activity}
                      event={eventRes?.data}
                      registration={registration}
                    />
                  ))}
                <Pagination
                  data={{
                    activePage: 1,
                    totalPages: 1,
                    resultsThisPage: filteredRoster?.length,
                    totalResults: filteredRoster?.length,
                  }}
                />
              </ul>
            )}
          </CardContainer>
        </>
      ) : (
        <div className="flex items-center justify-center">
          <h2 className="text-2xl">No Standings For Non Tournament Phases</h2>
        </div>
      )}
    </div>
  );
};

export default EventStandingsTab;
