type pauseRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  id: string;
  type: "tournamentRound" | "activityPhase";
};

export const pauseRoundTimerMutationFn = async ({
  clientAxiosInstance,
  id,
  type,
}: pauseRoundTimerMutationFnParams) => {
  const endpoint =
    type === "tournamentRound"
      ? `/api/organize/tournamentRounds/${id}/pauseTimer`
      : `/api/organize/activityPhases/${id}/pauseTimer`;

  return clientAxiosInstance.put(endpoint);
};
