import * as React from "react"
import { cn } from "@cardeio/ui/lib/utils"
import { cva, type VariantProps } from "class-variance-authority"

const inputVariants = cva(
	"flex h-9 w-full rounded-md border px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "border-input bg-transparent",
				uap: "input-background border-border",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, variant, ...props }, ref) => {
		const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
			if (type === "number") {
				e.currentTarget.blur()
			}
		}

		return (
			<input
				type={type}
				onWheel={handleWheel}
				className={cn(inputVariants({ variant, className }))}
				ref={ref}
				{...props}
			/>
		)
	}
)
Input.displayName = "Input"

export { Input, inputVariants }
