export function ShellTitle({ children }) {
  return (
    <h1 className="text-4xl font-medium text-accent-foreground">{children}</h1>
  )
}

export function ShellSubtitle({ children }) {
  return (
    <p className="text-muted-foreground">{children}</p>
  )
}

export function Shell({ title, subtitle, children }) {
  return (
    <div className="min-h-screen p-4 md:p-6 lg:p-10 space-y-10 max-w-screen-xl mx-auto">
      <div className="space-y-1">
        <ShellTitle>{title}</ShellTitle>
        <ShellSubtitle>{subtitle}</ShellSubtitle>
      </div>
      <div className="space-y-6">
        {children}
      </div>
    </div>
  )
}
