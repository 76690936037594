import { createFileRoute } from "@tanstack/react-router";
import TournamentOrganizerProfileForm from "@/components/Forms/TournamentOrganizerProfileForm";

function OrganizerProfilePage() {
  return <TournamentOrganizerProfileForm isNewUser={true} />;
}

export const Route = createFileRoute('/_authenticated/organizer/')({
  component: OrganizerProfilePage
});
