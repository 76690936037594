"use client"

import { BadgeCheck, Bell, ChevronRight, ChevronsUpDown, CreditCard, LogOut, NewspaperIcon, Sparkles } from "lucide-react"

import { Avatar, AvatarFallback, AvatarImage } from "@cardeio/ui/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@repo/ui/dropdown"
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "@cardeio/ui/components/ui/sidebar"
import { Link } from "@tanstack/react-router"
import { ArrowLeftStartOnRectangleIcon, Cog6ToothIcon } from "@heroicons/react/20/solid"

export function NavUser({
  user = {},
}) {
  const { isMobile } = useSidebar()

  const items = [
    { name: 'Settings', description: 'Manage your Carde.io Profile and Settings!', href: '/user', icon: Cog6ToothIcon },
    // { name: 'Support', description: 'Have questions? Reach out to us and we will get back within 24 hours!', href: `${import.meta.env.VITE_WEB_URL}/support`, icon: ChatBubbleBottomCenterTextIcon },
    // { name: 'News', description: 'Stay up to date with the latest news and updates from Carde.io', href: `${import.meta.env.VITE_WEB_URL}/news`, icon: NewspaperIcon },
    { name: 'Logout', description: 'Sign out of your Carde.io Play Account', href: '/logout', icon: ArrowLeftStartOnRectangleIcon },
  ];

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage src="https://storage.googleapis.com/cardeio-images/cardeio/profile/default.png" alt={user.displayName} />
                <AvatarFallback className="rounded-lg">CN</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.displayName}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <ChevronRight className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg bg-sidebar-accent"
            side={isMobile ? "bottom" : "right"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src="https://storage.googleapis.com/cardeio-images/cardeio/profile/default.png" alt={user.displayName} />
                  <AvatarFallback className="rounded-lg"></AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.displayName}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {items.map((item) => (
              <DropdownMenuItem key={item.name}>
                <Link to={item.href} className={"w-full flex gap-2 hover:opacity-80 cursor-pointer hover:bg-sidebar-accent"}>
                  <item.icon className="size-4" />
                  {item.name}
                </Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}

