type AddEntityMemberMutationFnParams = {
  clientAxiosInstance: any;
  entityId: string;
  data: any;
};

export const addEntityMemberMutationFn = async ({
  clientAxiosInstance,
  entityId,
  data,
}: AddEntityMemberMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/manage/entities/${entityId}/users`,
    data,
  );
};
