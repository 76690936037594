import { useEventStore } from "@/store/useEventStore";

const NonTournamentPairingHeader = ({ activePhases = [] }) => {
  const { activity, currentActivityPhaseType } = useEventStore();

  const isPreview = activePhases.some(phase => phase.status === 'preview');

  return (
    <div className="rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60 w-full flex flex-wrap gap-4 items-center justify-between">
      <div className="-ml-4 -mt-2 flex flex-wrap items-start justify-between sm:flex-nowrap">
        <div className="flex self-stretch flex-col items-start justify-between ml-4">
          <div>
            <h3 className="text-2xl font-semibold leading-6 text-zinc-100">
              {`${activity?.name} ${currentActivityPhaseType == "draft" ? "Draft Pods" : currentActivityPhaseType == "deckbuilding" ? "Deckbuilding" : ""} ${isPreview ? " Preview" : ""}`}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonTournamentPairingHeader;
