import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Avatar, AvatarImage } from "@repo/ui/avatar";
import { GripVertical } from "lucide-react";

const getOrdinal = (value) => {
  return (
    value + (["st", "nd", "rd"][((((value + 90) % 100) - 10) % 10) - 1] || "th")
  );
};

const OneVsOnePlayerInfo = ({ isReversed = false, player }) => {
  return (
    <div className={`flex items-center gap-4 px-2 ${isReversed ? 'flex-row-reverse' : ''}`}>
      {/* <GripVertical className="w-4 h-4 hover:cursor-not-allowed" /> */}
      {player?.gameUser?.imageUrl && (
        <Avatar className="h-12 w-12 rounded-full">
          <AvatarImage src={player?.gameUser?.imageUrl} />
        </Avatar>
      )}
      <div className={`flex items-center gap-2 ${isReversed ? 'flex-row-reverse' : ''}`}>
        <span className="text-lg font-medium">{player?.user?.displayName}</span>
        {/* <ChevronDownIcon className="h-5 w-5" /> */}
      </div>
      <div className={`flex-1 ${isReversed ? "text-start ms-2" : "text-end me-2"}`}>
        {player?.startingInfo?.gameResults?.matchesWon || 0} -{" "}
        {player?.startingInfo?.gameResults?.matchesLost || 0} -{" "}
        {player?.startingInfo?.gameResults?.matchesTied || 0}
      </div>
    </div>
  )
};

export default OneVsOnePlayerInfo;
