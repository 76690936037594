type startRoundTimerMutationFnParams = {
  clientAxiosInstance: any;
  id: string;
  type: "tournamentRound" | "activityPhase";
  total?: number;
  start?: boolean;
};

export const startRoundTimerMutationFn = async ({
  clientAxiosInstance,
  id,
  type,
  total,
  start = true,
}: startRoundTimerMutationFnParams) => {
  const endpoint =
    type === "tournamentRound"
      ? `/api/organize/tournamentRounds/${id}/startTimer`
      : `/api/organize/activityPhases/${id}/startTimer`;

  return clientAxiosInstance.post(endpoint, {
    duration: total,
    start: start,
  });
};
