import { useEffect, useState, useCallback } from 'react';

export const useTimer = ({ startTime, endTime, duration }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isCountingDownToFuture, setIsCountingDownToFuture] = useState(false);

  const calculateTimeRemaining = useCallback(() => {
    const now = Date.now();
    
    // If we have a start time in the future
    if (startTime && new Date(startTime).getTime() > now) {
      setIsCountingDownToFuture(true);
      return new Date(startTime).getTime() - now;
    }
    
    // If we have an end time, count down to that
    if (endTime) {
      setIsCountingDownToFuture(false);
      return new Date(endTime).getTime() - now;
    }
    
    // If we only have duration, use that
    if (duration != null) {
      setIsCountingDownToFuture(false);
      return duration * 1000; // Convert seconds to milliseconds
    }

    return 0;
  }, [startTime, endTime, duration]);

  useEffect(() => {
    const updateTimer = () => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);
    };

    // Initial calculation
    updateTimer();

    // Update every second
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeRemaining]);

  return {
    timeRemaining: Math.floor(Math.abs(timeRemaining) / 1000), // Convert to seconds
    isNegative: timeRemaining < 0,
    isCountingDownToFuture
  };
}; 