import { establishmentGameToPublic } from "../transformers/establishment/establishmentGameToPublic";
import { webAxiosInstance } from "@/utils/axios";


export const getEstablishmentAvailableGames = async (establishmentId) => {

  const games = await webAxiosInstance({
    method: 'GET',
    url: `/api/play/establishments/${establishmentId}/games`
  });

  if (!games?.data) throw new Error('Error: Game Establishments is null');

  return establishmentGameToPublic(games.data);
};


const fetchEstablishmentAvailableGames = ({ establishmentId } = {}, axiosInstance = webAxiosInstance) => async () => {
  const { data } = await axiosInstance.get(`/api/play/establishments/${establishmentId}/games`)
  return data?.data;
}

export const getAvailableGamesQueryOptions = ({ establishmentId } = {}, axiosInstance = webAxiosInstance) => ({
  queryKey: ['games', establishmentId],
  queryFn: fetchEstablishmentAvailableGames({ establishmentId }, axiosInstance),
});
