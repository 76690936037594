import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@tanstack/react-router";
import { CardContainer } from "@repo/ui/card";
import { Button } from "@cardeio/ui/components/ui/button";
import useHandleSilentAuth from "@/utils/auth0/useHandleSilentAuth";

const AuthPageCard = ({
  header,
  description,
  buttonText,
  buttonAction,
  className,
}) => {
  return (
    <div
      className={`w-full max-w-md bg-carde-gray-700 rounded-lg py-12 px-7 space-y-4 lg:space-y-12 ${className}`}
    >
      <h1 className="text-2xl font-bold">{header}</h1>
      <p className="text-md font-normal">{description}</p>
      <Button
        variant="gradient"
        className="rounded-full font-medium"
        onClick={buttonAction}
      >
        {buttonText}
      </Button>
    </div>
  );
};

const AuthPage = () => {
  const { isLoading } = useAuth0();
  const { handleSignup, handleLogin } = useHandleSilentAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="grid items-start justify-center px-6 py-8 lg:px-8">
      <div className="text-center">
        <div className="flex w-full items-center justify-center">
          <a href={import.meta.env.VITE_WEB_URL}>
            <img
              src="https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp"
              className="h-28 mb-8 lg:mb-20 animate-slideInFromTop"
              alt="Carde.io Logo"
            />
          </a>
        </div>

        <div className="space-y-5">
          <div className="flex flex-wrap gap-8 justify-center items-center">
            <AuthPageCard
              className="animate-slideInFromLeft"
              header="New to Carde.io?"
              description="Sign up to Carde.io to host and manage tournaments and events for your stores."
              buttonText="Create an Account"
              buttonAction={handleSignup}
            />
            <AuthPageCard
              className="animate-slideInFromRight"
              header="Go to Dashboard"
              description="Welcome back! Log in to manage events, stores, and more from one place."
              buttonText="Sign In"
              buttonAction={handleLogin}
            />
          </div>

          <div
            className={`bg-carde-gray-700 rounded-lg p-7 flex flex-wrap gap-5 justify-center items-center animate-slideInFromBottom`}
          >
            <p className="font-normal text-md">
              Are you a game publisher and want your title to run on the
              Carde.io Play Network?
            </p>
            <Link to="/contact">
              <Button variant="outline" className="rounded-full border-carde-pink-500 bg-transparent">
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthPage;
