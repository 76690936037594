import { webAxiosInstance } from '../../../utils/axios';
import { set } from 'lodash';
import { queryOptions } from '@tanstack/react-query';

export const entityQueryOptions = ({ orgId, entityId }) => queryOptions({
  queryKey: [orgId, entityId],
  queryFn: async () => await getEntity(entityId),
})
export const getEntity = async (entityId) => {
  const data = await webAxiosInstance({
    method: 'GET',
    url: `/api/manage/entities/${entityId}`,
  });
  set(data, 'data.data.resource.address', data?.data?.data?.address)
  return data?.data?.data
};
