import { useAuth0 } from "@auth0/auth0-react";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "./App";
import { Providers } from "./components/Providers";
import Loading from "./components/Loading";
import AnalyticsProvider from "@/AnalyticsProvider";
import IntercomProvider from "./IntercomProvider";

export const InnerApp = () => {
  const auth = useAuth0();
  if (auth.isLoading) return <Loading fullScreen sidebar />;
  if (auth.error) return <div>Oops... {auth.error.message}</div>;
  return (
    <Providers>
      <RouterProvider router={router} context={{ auth }} />
      <AnalyticsProvider />
      <IntercomProvider auth={auth} />
    </Providers>
  );
};
