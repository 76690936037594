import { create } from "zustand";
import { useConfirmDialogStore } from "./useConfirmDialogStore";
import { PRIZE_ORDER } from "@/constants/prizingConstants";

export const usePrizingStore = create((set, get) => ({
  editingPrizing: false,
  prizeChanges: {},
  
  setEditingPrizing: (editingPrizing) =>
    set({
      editingPrizing,
      // Reset prize changes when toggling edit mode off
      prizeChanges: editingPrizing ? get().prizeChanges : {},
    }),
    
  updatePrizeValue: (prizeKey, field, value) => {
    const currentChanges = get().prizeChanges;
    set({
      prizeChanges: {
        ...currentChanges,
        [prizeKey]: {
          ...(currentChanges[prizeKey] || {}),
          [field]: value,
          key: prizeKey, // Ensure the key is preserved
        },
      },
    });
  },
  
  // Add a new function to add a new prize placing
  addNewPrize: (prizeKey) => {
    const currentChanges = get().prizeChanges;
    set({
      prizeChanges: {
        ...currentChanges,
        [prizeKey]: {
          cash: "",
          product: "",
          key: prizeKey,
        },
      },
    });
  },
  
  // Add a new function to remove a prize placing
  removePrize: (prizeKey) => {
    const currentChanges = get().prizeChanges;

    // If the prize exists in the original data, mark it for removal by setting values to null
    // This will be interpreted as a deletion when submitted
    if (currentChanges[prizeKey]) {
      set({
        prizeChanges: {
          ...currentChanges,
          [prizeKey]: {
            cash: null,
            product: null,
            key: prizeKey,
            _toRemove: true, // Special flag to indicate this should be removed
          },
        },
      });
    } else {
      // If it's a newly added prize that hasn't been saved yet, just remove it from prizeChanges
      const newChanges = { ...currentChanges };
      delete newChanges[prizeKey];
      set({
        prizeChanges: newChanges,
      });
    }
  },
  
  // Function to get the last visible prize key
  getLastVisiblePrizeKey: (existingPrizes) => {
    const { prizeChanges } = get();

    // Combine existing prizes with changes
    const allPrizes = [...existingPrizes];

    // Add any prizes from prizeChanges that aren't in existingPrizes
    Object.keys(prizeChanges).forEach((key) => {
      if (
        !existingPrizes.some((p) => p.key === key) &&
        !prizeChanges[key]?._toRemove
      ) {
        allPrizes.push({ key, order: PRIZE_ORDER[key] || 999 });
      }
    });

    // Remove prizes that are marked for removal
    const visiblePrizes = allPrizes.filter(
      (prize) => !prizeChanges[prize.key]?._toRemove
    );

    // Sort by order (highest order is last)
    visiblePrizes.sort((a, b) => a.order - b.order);

    // Return the key of the last prize, or null if there are no prizes
    return visiblePrizes.length > 0
      ? visiblePrizes[visiblePrizes.length - 1].key
      : null;
  },
  
  submitPrizing: (handlePrizeSupportUpdate) => {
    const { open } = useConfirmDialogStore.getState();
    const { prizeChanges } = get();

    open({
      title: "Update Prizing?",
      description:
        "Are you sure you want to update the prizing for this event? This will overwrite any existing prizing and may have unintended consequences.",
      onConfirm: () => {
        // We need to pass ALL prize changes to handlePrizeSupportUpdate, including those marked for removal
        // The handlePrizeSupportUpdate function will handle the actual deletion
        handlePrizeSupportUpdate(prizeChanges);

        // Reset state after submission
        set({
          editingPrizing: false,
          prizeChanges: {},
        });
      },
      confirmText: "Submit",
      typeToConfirmText: "overwrite existing prizes",
    });
  },
}));
