import { createFileRoute } from '@tanstack/react-router'
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { useNavigate, useParams } from "@tanstack/react-router";

import { createEventMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { useApprovedGames } from "@/store/useGameStore";
import { useRootStore } from "@/store/useRootStore";
import { Button } from "@repo/ui/button";
import EventHeader from "@/components/Flows/CreateEvent/EventHeader";
import SearchGamesSection from "@/components/Modal/Event/common/SearchGames";
import { useSelectedGame } from "@/hooks/useSelectedGame";

export const Route = createFileRoute(
  '/_authenticated/entity/$entityId/events/create',
)({
  beforeLoad: () => {
    return {
      getBreadcrumbTitle: () => 'Create a New Event'
    }
  },
  component: CreateEventPage,
})

function CreateEventPage() {
  const { entityId } = useParams({ strict: false });
  const navigate = useNavigate();
  const { data: approvedGames } = useApprovedGames();
  const { userData } = useRootStore();
  const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);

  const [selectedGameId, setSelectedGameId] = useState(null);
  const [step, setStep] = useState(1);

  const { selectedGame, backgroundImageUrl } = useSelectedGame({ gameId: selectedGameId });

  const onGameSelect = (gameId, isSelected) => {
    if (isSelected) {
      setSelectedGameId(null);
    } else {
      setSelectedGameId(gameId);
    }
  };

  const createActivityMutation = useMutation({
    mutationFn: createEventMutationFn,
    onSuccess: async ({ data }) => {
      await navigate({ to: `/entity/${entityId}/events/${data?.data?.id}/draft` });
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with creating this event. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      });
    },
  });

  const handleCreateEventClick = () => {
    createActivityMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      gameId: selectedGameId,
      data: {
        ownerId: establishment.id,
        ownerType: 'Establishment',
      }
    });
  };

  return (
    <div className="w-full h-screen">
      <div className="relative z-10 h-full">
        <div className="px-10 pt-10 sticky top-0 z-100 w-full bg-background before:absolute before:inset-0 before:bg-background before:backdrop-blur-sm">
          <EventHeader
            activeStep={step}
            setStep={setStep}
            title="Create a New Event"
            onContinue={handleCreateEventClick}
            isContinueDisabled={!selectedGameId || createActivityMutation.isPending}
            continueLoading={createActivityMutation.isPending}
            continueText="Create Event"
            entityId={entityId}
          />
        </div>

        <div className={`w-full mx-auto pt-10 h-[calc(100%-theme(spacing.20))] ${backgroundImageUrl ? 'bg-cover bg-center bg-no-repeat relative' : 'bg-background'}`}
          style={backgroundImageUrl ? { backgroundImage: `url(${backgroundImageUrl})` } : {}}>
          {backgroundImageUrl && <div className="absolute inset-0 bg-black/70 backdrop-blur-sm"></div>}
          <div className="relative z-10">
            <div className="w-full max-w-[800px] mx-auto pb-10 p-4 backdrop-blur-sm rounded-lg">
              <SearchGamesSection
                title="Select a game"
                subtitle="What game are you creating an event for?"
                description="Please Select a Game To Create an Event For"
                games={approvedGames}
                handleSelect={onGameSelect}
                selectedGames={[selectedGameId]}
                entityId={entityId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
