export const REGISTRATION_STATUS = {
  registered: 'registered',
  checkedIn: 'checkedIn',
  unregistered: 'unregistered',
  canceled: 'canceled',
  active: 'active'
}

export const ENTITY_TYPE = {
  Establishment: 'Establishment',
  Game: 'Game',
}

export const EVENT_OWNER_TYPE = {
  Establishment: 'Establishment',
  Game: 'Game',
  User: 'User',
}

export const ACTIVITY_LIMIT_TYPES = {
  creationWindow: 'creationWindow',
  recordMatchCount: 'recordMatchCount',
  relationData: 'relationData',
  data: 'data',
}

