import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@cardeio/ui/components/ui/dialog";
import { Button } from "@cardeio/ui/components/ui/button";
import { Input } from "@cardeio/ui/components/ui/input";
import { useEffect, useState } from "react";
import { useConfirmDialogStore } from "@/store/useConfirmDialogStore";

export function ConfirmationDialog() {
  const [dontShowAgain, _setDontShowAgain] = useState(false);
  const {
    isOpen,
    title,
    description,
    confirmText,
    onConfirm,
    close,
    typeToConfirmText,
  } = useConfirmDialogStore();
  const [confirmationTextInputValue, setConfirmationTextInputValue] =
    useState("");

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (isOpen) {
      setConfirmationTextInputValue("");
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent className="w-[580px] p-[30px_40px] bg-[#191A19] border border-[#3B3D3B] rounded-lg">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">{title}</DialogTitle>
          <DialogDescription className="text-base mt-4">
            {description}
          </DialogDescription>
        </DialogHeader>
        {/* <div className="flex items-center space-x-2 mt-6">
          <Checkbox
            id="dontShow"
            checked={dontShowAgain}
            onCheckedChange={(checked) => setDontShowAgain(!!checked)}
          />
          <label htmlFor="dontShow">Don't show me again for this event</label>
        </div> */}
        {typeToConfirmText && (
          <div className="mt-6">
            <p className="text-sm text-muted-foreground mb-2 select-none">
              Type "{typeToConfirmText}" to confirm
            </p>
            <Input
              type="text"
              placeholder={`${typeToConfirmText}`}
              value={confirmationTextInputValue}
              onChange={(e) => setConfirmationTextInputValue(e.target.value)}
              onPaste={handlePaste}
            />
          </div>
        )}

        <DialogFooter className="mt-6 space-x-4">
          <Button
            variant="secondary"
            onClick={close}
            className="bg-[#2B2C2B] hover:bg-[#3B3D3B] text-white"
          >
            Cancel
          </Button>
          <Button
            disabled={
              typeToConfirmText &&
              confirmationTextInputValue !== typeToConfirmText
            }
            onClick={() => {
              onConfirm(dontShowAgain);
              close();
            }}
            className="bg-gradient-to-r from-[#CC045A] to-[#FB5607] hover:opacity-90"
          >
            {confirmText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
