import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/form";
import { Input } from "@cardeio/ui/components/ui/input";
import FormCard from "@cardeio/ui/components/forms/form-card";
import {
  PRIZE_NAMES,
  filterPrizesByCapacity,
} from "@/constants/prizingConstants";

const PrizingDetailsSection = ({ form }) => {
  const watchAllFields = form.watch();
  const tourneyArray = form.watch("configuration.tournaments");
  const playType = tourneyArray?.length ? "tournament" : "play";

  // Use the constants and helper function to generate the prize info
  const prizingInfo = Object.entries(PRIZE_NAMES)
    .map(([key, title]) => ({ title, key }))
    .filter((item) => {
      // Filter prizes based on capacity
      if (item.key.startsWith("top")) {
        const capacityRequired = parseInt(item.key.replace("top", ""), 10);
        return watchAllFields.capacity >= capacityRequired;
      }
      return true;
    })
    .map((obj) => {
      return {
        title: obj.title,
        cashKey: `prizeSupport.${obj.key}.cash`,
        productKey: `prizeSupport.${obj.key}.product`,
        oldCashKey: `configuration.prizing.${obj.key}.cash`,
        oldProductKey: `configuration.prizing.${obj.key}.product`,
      };
    });

  // Only show prizing details for tournament type events
  if (playType !== "tournament") {
    return null;
  }

  // Initialize prizeSupport with values from configuration.prizing if needed
  React.useEffect(() => {
    const values = form.getValues();

    // If prizeSupport is empty but configuration.prizing has values, copy them over
    if (
      (!values.prizeSupport || Object.keys(values.prizeSupport).length === 0) &&
      values.configuration?.prizing &&
      Object.keys(values.configuration.prizing).length > 0
    ) {
      const prizeSupport = {};
      Object.keys(values.configuration.prizing).forEach((key) => {
        prizeSupport[key] = values.configuration.prizing[key];
      });

      form.setValue("prizeSupport", prizeSupport);
    }
  }, [form]);

  return (
    <FormCard 
      title="Prizes" 
      subtitle="Will players earn any rewards for participating in this event?" 
      className="mb-4 border border-border bg-background/80 backdrop-blur-sm"
    >
      <div className="flex flex-col gap-6 flex-wrap justify-between">
        {prizingInfo.map((obj, index) => {
          // Get values from both old and new paths
          const oldCashValue = form.watch(obj.oldCashKey);
          const oldProductValue = form.watch(obj.oldProductKey);
          const newCashValue = form.watch(obj.cashKey);
          const newProductValue = form.watch(obj.productKey);

          // Use new values if they exist, otherwise use old values
          const cashValue =
            newCashValue !== undefined ? newCashValue : oldCashValue;
          const productValue =
            newProductValue !== undefined ? newProductValue : oldProductValue;

          // Set values in the new location if they're from the old location
          if (newCashValue === undefined && oldCashValue !== undefined) {
            form.setValue(obj.cashKey, oldCashValue);
          }

          if (newProductValue === undefined && oldProductValue !== undefined) {
            form.setValue(obj.productKey, oldProductValue);
          }

          return (
            <div key={index}>
              <h4 className="text-lg font-bold">{obj.title}</h4>
              <div className="flex justify-between w-full">
                <FormField
                  control={form.control}
                  name={obj.cashKey}
                  render={({ field }) => (
                    <FormItem className="w-[48%]">
                      <FormLabel>Cash Prize</FormLabel>
                      <FormControl>
                        <Input 
                          placeholder="Cash Prize" 
                          type="number" 
                          min="0"
                          variant="uap" 
                          {...field} 
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={obj.productKey}
                  render={({ field }) => (
                    <FormItem className="w-[48%]">
                      <FormLabel>Product Prize</FormLabel>
                      <FormControl>
                        <Input 
                          placeholder="Product Prize" 
                          type="text"
                          variant="uap" 
                          {...field} 
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-xs text-muted-foreground text-center mt-6">
        To add more prizing tiers, increase the capacity of the event in the
        previous step.
      </div>
    </FormCard>
  );
};

export default PrizingDetailsSection;
