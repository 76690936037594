import { createFileRoute, Link, Outlet, useParams } from '@tanstack/react-router'
import AdminAuthHeaderContainer from '../../../components/Navigation/Top/AdminAuthHeaderContainer';
import { SidebarInset, SidebarProvider, SidebarTrigger } from '@cardeio/ui/components/ui/sidebar';
import { AppSidebar } from '../../../components/Navigation/Side/AppSidebar';
import { Separator } from '@cardeio/ui/components/ui/separator';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@cardeio/ui/components/ui/breadcrumb';
import { useRootStore } from '@/store/useRootStore';
import { BuildingOffice2Icon } from '@heroicons/react/20/solid';
import { NavEntity } from '@/components/Navigation/Side/nav-entity';
import { Fragment } from 'react';

export const Route = createFileRoute('/_authenticated/org')({
  beforeLoad: async () => {
    return {
      getBreadcrumbTitle: () => 'Organizations'
    }
  },
  component: Layout
})

const ORGANIZATION_BREADCRUMB_MAP = {
  'org': 'Organization',
  'entity': 'Entity',
  'events': 'Events'
}


function Layout() {
  const userData = useRootStore((state) => state.userData);
  const { orgId } = useParams({ strict: false });
  const organization = userData.organizations.find((org) => org.id === orgId) || userData.organizations[0];
  const pathname = window.location.pathname;
  return (
    <SidebarProvider>
      <AppSidebar TopNav={() => <NavEntity details={organization} />} items={[{ title: 'Entities', url: `/org/${orgId}`, icon: () => <BuildingOffice2Icon className='w-4 h-4' /> }]} />
      <SidebarInset className="h-screen flex flex-col">
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-background">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                {pathname
                  .split('/')
                  .filter((segment) => segment !== 'org' && segment)
                  .map((segment, index, array) => {
                    const isLast = index === array.length - 1;
                    const org = organization.id === segment;
                    const breadcrumbTitle = org ? organization.name : (ORGANIZATION_BREADCRUMB_MAP[segment] || segment);
                    return (
                      <Fragment key={index}>
                        <BreadcrumbItem>
                          {isLast ? (
                            <BreadcrumbPage>{breadcrumbTitle}</BreadcrumbPage>
                          ) : (
                            <BreadcrumbLink asChild>
                              <Link to={`/${array.slice(0, index + 1).join('/')}`}>
                                {breadcrumbTitle}
                              </Link>
                            </BreadcrumbLink>
                          )}
                        </BreadcrumbItem>
                        {index < array.length - 1 && (
                          <BreadcrumbSeparator />
                        )}
                      </Fragment>
                    )
                  })}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex-1 min-h-0">
          <main className='h-full overflow-y-auto'>
            <AdminAuthHeaderContainer />
            <Outlet />
          </main>
        </div>
      </SidebarInset>

    </SidebarProvider>

  )
}
