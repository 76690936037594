import Loading from "@/components/Loading";
import AdminAuthHeaderContainer from "@/components/Navigation/Top/AdminAuthHeaderContainer";
import {
  Link,
  Outlet,
  createFileRoute,
  useParams,
} from "@tanstack/react-router";
import { queryClient } from "@/lib/queryClient";
import { useRootStore } from "@/store/useRootStore";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@cardeio/ui/components/ui/sidebar";
import { AppSidebar } from "@/components/Navigation/Side/AppSidebar";
import { Separator } from "@radix-ui/react-dropdown-menu";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@cardeio/ui/components/ui/breadcrumb";
import {
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { NavEntity } from "@/components/Navigation/Side/nav-entity";
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { findEntityById } from "@/utils/entity/findEntityById";
import { Fragment } from "react";
import { useEventStore } from "@/store/useEventStore";
import { ENTITY_TYPE } from "@/constants";
import { RiExternalLinkLine } from "@remixicon/react";
import { getIsDevEnvironment } from "@/utils";
import {
  Calendar,
  DicesIcon,
  HomeIcon,
  MailQuestionIcon,
  Store,
  Users,
} from "lucide-react";
import { useOrganizationApplications } from "@/data/establishment/staffApplications";

export const Route = createFileRoute("/_authenticated/entity")({
  loader: async ({ params }) => {
    const { entityId } = params;
    const userData = await queryClient.getQueryData(["userData"]);
    const establishment = userData?.establishments?.find(
      (establishment) => establishment.entityId === entityId
    );
    useRootStore.setState({ establishment });
  },
  component: Layout,
  pendingComponent: () => <Loading fullScreen sidebar />,
});

const ENTITY_BREADCRUMB_MAP = {
  entity: "Entity",
  manage: "Manage",
  financials: "Financials",
  staff: "Staff Management",
  events: "Events",
  gameDirectory: "Game Directory",
  create: "Create a New Event",
  "pending-applications": "Pending Applications",
};

const useEntityNavigationItems = (entityId, orgId) => {
  const { data: applications } = useOrganizationApplications(orgId);
  const pendingApplicationsCount = applications?.data?.filter(
    (app) => app.status !== "resolved"
  )?.length;

  if (!entityId) return [];

  return [
    {
      title: "Store Information",
      url: `/entity/${entityId}/store-information`,
      icon: () => <Store className="w-4 h-4" />,
    },
    {
      title: "Manage Staff",
      icon: () => <Users className="w-4 h-4" />,
      items: [
        {
          title: "Staff & Guest Organizers",
          url: `/entity/${entityId}/manage/staff`,
        },
        {
          title: "Pending Applications",
          url: `/entity/${entityId}/pending-applications`,
          badge: pendingApplicationsCount || undefined,
        },
      ],
    },
  ];
};

const useEntityBottomNavigationItems = (entity) => {
  const { userData } = useRootStore();
  const environment = getIsDevEnvironment() ? "development" : "production";

  const establishment =
    entity?.type === ENTITY_TYPE.Establishment
      ? userData?.establishments?.find(
          (establishment) => establishment.entityId === entity.id
        )
      : null;
  const items = [
    {
      title: "Settings",
      url: `/entity/${entity?.id}/user`,
      icon: () => <Cog6ToothIcon className="w-4 h-4" />,
    },
  ];
  return items;
};

const useEntityTopNavigationItems = (entityId, currentOrg) => {
  const { data: applications } = useOrganizationApplications(currentOrg?.id);
  const pendingApplicationsCount = applications?.data?.filter(
    (app) => app.status !== "resolved"
  )?.length;

  return [
    {
      title: "Events",
      url: `/entity/${entityId}/events?viewType=calendar`,
      icon: Calendar,
    },
    {
      title: "Game Directory",
      url: `/entity/${entityId}/gameDirectory`,
      icon: DicesIcon,
    },
  ];
};

function Layout() {
  const route = window.location.pathname;
  const hasTimer = route.includes("/timer");
  const { userData } = useRootStore();

  const { entityId, eventId } = useParams({ strict: false });
  const currentOrg =
    findOrganizationByEntityId(userData?.organizations, entityId) ||
    userData?.organizations[0];
  const currentEntity =
    findEntityById(currentOrg?.entities, entityId) || currentOrg?.entities[0];
  const { event } = useEventStore();
  const pathname = route;
  const items = useEntityNavigationItems(entityId, currentOrg?.id);
  const bottomItems = useEntityBottomNavigationItems(currentEntity);
  const topItems = useEntityTopNavigationItems(entityId, currentOrg);
  const extraDetails = userData?.establishments?.find(
    (establishment) => establishment.entityId === entityId
  ) || userData?.games?.find(
    (game) => game.entityId === entityId
  ) || {}

  return (
    <SidebarProvider>
      <AppSidebar
        TopNav={() => <NavEntity details={{...currentEntity, extra: extraDetails}} context="entity" />}
        items={items}
        bottomItems={bottomItems}
        topItems={topItems}
      />
      <SidebarInset className="h-screen flex flex-col">
        <header className="flex z-10 h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-background border-b border=[#2C2C2C]">
          <div className="flex items-center gap-2 px-4 sticky">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    asChild
                    className="text-muted-foreground hover:text-foreground"
                  >
                    <Link to={`/org/${currentOrg?.id}`}>
                      {currentOrg?.name}
                    </Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />

                {pathname
                  .split("/")
                  .filter(Boolean)
                  .map((segment, index, array) => {
                    const isLast = index === array.length - 1;
                    const isEntity = currentEntity?.id === segment;
                    const isEvent = event?.id === segment;
                    const breadcrumbTitle = isEntity
                      ? currentEntity.name
                      : isEvent
                        ? event.name
                        : Route.options?.getBreadcrumbTitle?.() ||
                          ENTITY_BREADCRUMB_MAP[segment] ||
                          segment
                            .split("-")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ");
                    if (segment === "entity") {
                      return;
                    }
                    return (
                      <Fragment key={index}>
                        <BreadcrumbItem>
                          {isLast ? (
                            <BreadcrumbPage>{breadcrumbTitle}</BreadcrumbPage>
                          ) : (
                            <BreadcrumbLink
                              asChild
                              className="!text-muted-foreground hover:!text-foreground !bg-transparent !font-normal"
                            >
                              <Link
                                to={`/${array.slice(0, index + 1).join("/")}`}
                              >
                                {breadcrumbTitle}
                              </Link>
                            </BreadcrumbLink>
                          )}
                        </BreadcrumbItem>
                        {index < array.length - 1 && <BreadcrumbSeparator />}
                      </Fragment>
                    );
                  })}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <div className="flex-1 min-h-0">
          <main className="h-full overflow-y-auto">
            {!hasTimer && <AdminAuthHeaderContainer />}
            <Outlet />
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
