import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "@cardeio/ui/components/ui/sidebar"
import { NavUser } from "./nav-user"
import { useRootStore } from "@/store/useRootStore";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@cardeio/ui/components/ui/collapsible";
import { Calendar, ChevronRight, DicesIcon, MailQuestionIcon, MessageCircleMore, Users } from "lucide-react";
import { Link, useParams } from "@tanstack/react-router";
import { show } from "@intercom/messenger-js-sdk";
import { cn } from "@/utils/cn";
import botIcon from '@/assets/carde-bot-icon.png';

const VITE_WEB_URL = import.meta.env.VITE_WEB_URL;

export function AppSidebar({ items = [], TopNav = null, bottomItems = [], topItems = [] }) {
  const { userData } = useRootStore();
  const { entityId, eventId } = useParams({ strict: false });
  const user = userData?.user
  const { isMobile, state } = useSidebar()
  const isCollapsed = state === "collapsed"
  return (
    <Sidebar collapsible={isMobile ? undefined : "icon"} className="h-screen">
      <SidebarHeader className="h-16" >
        {TopNav && <TopNav />}
      </SidebarHeader>

      <SidebarContent className="flex flex-col">
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {topItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <Link to={item.url} className={cn("flex justify-between w-full", item.className)}>
                      <div className="flex items-center gap-2">
                        {item.icon && <item.icon className="w-4 h-4" />}
{!isCollapsed && <span className="text-sm whitespace-nowrap">{item.title}</span>}
                      </div>   
                      {item.badge !== undefined && item.badge > 0 && (
                        <span className="font-semibold text-card-foreground bg-gradient-to-tr from-secondary via-red-500 to-primary rounded-full px-1.5 py-0.5 text-xs">
                          {item.badge}
                        </span>
                      )}
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupLabel>
            {!isCollapsed && "Management"}
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => {
                const Icon = item.icon
                const isActive = window.location.pathname.includes(item.url)
                return item.items && !isCollapsed ? (
                  <Collapsible key={item.title} defaultOpen className="group/collapsible">
                    <SidebarMenuItem>
                      <CollapsibleTrigger className="flex items-center gap-2 w-full p-2 hover:bg-sidebar-accent rounded-md">
                        {Icon && <Icon className="h-4 w-4" />}
                        <span>{item.title}</span>
                        <ChevronRight className="ml-auto h-4 w-4 transition-transform group-data-[state=open]/collapsible:rotate-90" />
                      </CollapsibleTrigger>
                    </SidebarMenuItem>
                    <CollapsibleContent>
                      {item.items.map((subItem) => (
                        <SidebarMenuItem key={subItem.title}>
                          <SidebarMenuButton asChild tooltip={{
                            children: subItem.title,
                            className: 'bg-sidebar-accent'
                          }}>
                            <Link to={subItem.url} className="flex items-center gap-2 pl-8 hover:text-orange-300">
                              {!isCollapsed && <span>{subItem.title}</span>}
                              {subItem.badge !== undefined && subItem.badge > 0 && (
                                <span className="bg-gradient-to-tr from-secondary via-red-500 to-primary text-sidebar-foreground rounded-full px-1.5 py-0.5 text-xs">
                                  {subItem.badge}
                                </span>
                              )}
                            </Link>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      ))}
                    </CollapsibleContent>
                  </Collapsible>
                ) : (
                  <SidebarMenuItem key={item.title} isActive={isActive}>
                    <SidebarMenuButton asChild tooltip={{
                      children: item.title,
                      className: 'bg-sidebar-accent'
                    }}>
                      <Link activeOptions={{ exact: true }} to={item.url} className="flex items-center gap-2 hover:text-orange-300">
                        {Icon && <Icon className="h-4 w-4" />}
                        {!isCollapsed && <span>{item.title}</span>}
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )
              })}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup className="mt-auto">
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton onClick={() => show()} tooltip={{
                  children: 'Support',
                  className: 'bg-sidebar-accent'
                }}>
                  <>
                    <img src={botIcon} className="w-4 h-5" alt="Carde Bot" />
                    {!isCollapsed && <span>Contact Support (Live)</span>}
                  </>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild tooltip={{
                  children: 'Feedback',
                  className: 'bg-sidebar-accent'
                }}>
                  <a href={`${VITE_WEB_URL}/contact`} target="_blank" rel="noopener noreferrer">
                    <MessageCircleMore className='w-4 h-4' />
                    {!isCollapsed && <span>Feedback</span>}
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {bottomItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton tooltip={{
                    children: item.title,
                    className: 'bg-sidebar-accent'
                  }} asChild>
                    <Link to={item.url}>
                      {item.icon && <item.icon />}
                      {!isCollapsed && <span>{item.title}</span>}
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="p-2" >
        <NavUser user={user} />
      </SidebarFooter>
    </Sidebar>
  )
}
