import { Button } from "@cardeio/ui/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { webAxiosInstance } from "@/utils/axios";
import Cookies from "js-cookie";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@cardeio/ui/components/ui/dropdown-menu";
import React from "react";

interface PopulateActivityButtonProps {
  activityId: string;
}

export function PopulateActivityButton({
  activityId,
}: PopulateActivityButtonProps) {
  const queryClient = useQueryClient();
  const allCookies = Cookies.get();
  const spoofEmail = allCookies?.["spoof-user-email"];
  if (!spoofEmail) return null;

  const [open, setOpen] = React.useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: async (count: number) => {
      const res = await webAxiosInstance.post(
        `/api/admin/activities/${activityId}/populate`,
        {
          count,
        },
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
      toast.success("Populated activity with players");
    },
    onError: (error) => {
      console.error(error);
      toast.error("There was an error populating players");
    },
  });

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className={` font-mono bg-black text-[#00FF41]/50 hover:text-[#00FF41] ${open ? "text-[#00FF41]" : ""}`}
          disabled={isPending}
        >
          {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Populate (DEV)
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="bg-black">
        {[1, 7, 8, 9, 10, 11, 16, 24, 32, 64, 128, 256].map((count) => (
          <DropdownMenuItem
            key={count}
            onClick={() => mutate(count)}
            className="text-[#00FF41]/50 hover:text-[#00FF41]"
          >
            {count} players
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
