import { Select, SelectContent, SelectGroup, SelectLabel, SelectTextItem, SelectTrigger, SelectValue } from "@repo/ui/select";
import { useEffect } from "react";

const PodFilter = ({ activeFilterPodId, setActiveFilterPodId, activePhases, activePhaseOutOfBounds = false }) => {
    return (
        <div className="flex flex-col">
            <p className="text-sm mb-1">Pod</p>
            <Select
                onValueChange={setActiveFilterPodId}
                defaultValue={activeFilterPodId || 'all'}
                value={activePhaseOutOfBounds ? "all" : activeFilterPodId}
            >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select a pod" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectTextItem value={'all'}>All</SelectTextItem>
              {activePhases?.map(phase => (
                <SelectTextItem key={phase?.id} value={phase?.id}>Pod {phase?.pod}</SelectTextItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    )
  }

  export default PodFilter;
