import { createFileRoute } from '@tanstack/react-router'
import TournamentOrganizerProfileForm from '@/components/Forms/TournamentOrganizerProfileForm'

function UserProfilePage() {
  return (
    <div className="container py-6">
      <TournamentOrganizerProfileForm showEmailSwap={true} />
    </div>
  )
}

export const Route = createFileRoute('/_authenticated/entity/$entityId/user/')({
  component: UserProfilePage
})
