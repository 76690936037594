"use client"

import * as React from "react"
import { cn } from "@cardeio/ui/lib/utils"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cva, type VariantProps } from "class-variance-authority"

const switchVariants = cva(
	"peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
				gradient:
					"data-[state=checked]:carde-gradient-light data-[state=unchecked]:bg-carde-gray-400 border-none pl-0.5",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

const thumbVariants = cva(
	"pointer-events-none block h-4 w-4 rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
	{
		variants: {
			variant: {
				default: "bg-background",
				gradient: "data-[state=checked]:bg-white data-[state=unchecked]:bg-[#191A19]",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

export interface SwitchProps
	extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
		VariantProps<typeof switchVariants> {}

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(({ className, variant, ...props }, ref) => (
	<SwitchPrimitives.Root
		className={cn(switchVariants({ variant, className }))}
		{...props}
		ref={ref}
	>
		<SwitchPrimitives.Thumb className={cn(thumbVariants({ variant }))} />
	</SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
