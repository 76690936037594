import Footer from "@/components/Footer";
import AuthPage from "./non-auth/Auth";
import bgImage from '@/assets/faded-red-bg.jpg';

const LandingPage = () => {
	return (
		<div className="isolate overflow-hidden min-h-screen flex flex-col bg-cover bg-center bg-no-repeat relative" style={{ backgroundImage: `url(${bgImage})` }}>
			<div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent/10 via-black/30 via-black/50 via-black/70 to-black pointer-events-none" />
			<div className="flex flex-col flex-1 overflow-auto mx-auto max-h-screen w-full relative">
				<div className="flex-1 flex flex-col justify-center mt-[15vh] md:mt-8 lg:mt-[15vh] mb-[10vh] md:mb-8 lg:mb-[10vh]">
					<AuthPage />
				</div>
				<Footer />
			</div>
		</div>
	)
}

export default LandingPage;
