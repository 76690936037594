import { useEffect, useMemo, useState } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { startOfWeek, addDays } from 'date-fns'
import {
  VIEW_TYPES,
  TAB_TYPES,
  SEARCH_PARAMS,
  EVENT_STATUS,
} from '@/_pages_/auth/Entity/Store/Tools/Events/constants'
import { useQuery } from '@tanstack/react-query'
import { useEventsSearchStore } from '@/store/useEventsSearchStore'
import { webAxiosInstance } from '@/utils/axios'
import Loading from '@/components/Loading'
import EventToolLanding from '@/_pages_/auth/Entity/Store/Tools/Events'
import { queryClient } from '@/lib/queryClient'
import { findOrganizationByEntityId } from '@/utils/entity/allOrgEntities'
import { findEntityById } from '@/utils/entity/findEntityById'

const useEventsList = ({
  establishment,
  activeTab,
  games,
  searchText,
  page,
  limit,
  viewType,
}) => {
  return useQuery({
    queryKey: ['events', 'list', activeTab, games, searchText, page, limit],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        [SEARCH_PARAMS.OWNER_IDS]: establishment.id,
        [SEARCH_PARAMS.LIMIT]: limit,
      })

      if (searchText) {
        searchParams.append(SEARCH_PARAMS.NAME, searchText)
      }
      if (page) {
        searchParams.append(SEARCH_PARAMS.PAGE, page)
      }

      if (activeTab === TAB_TYPES.UPCOMING) {
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.DRAFT)
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.PUBLISHED)
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.IN_PROGRESS)
      } else {
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.CANCELED)
        searchParams.append(SEARCH_PARAMS.STATUS, EVENT_STATUS.COMPLETE)
      }

      if (games?.length) {
        games.forEach((gameId) =>
          searchParams.append(SEARCH_PARAMS.GAME_IDS, gameId),
        )
      }

      const res = await webAxiosInstance.get(
        `/api/organize/events?${searchParams.toString()}`,
      )
      return res?.data
    },
    enabled: viewType === VIEW_TYPES.LIST,
  })
}

const useCalendarEvents = ({ establishment, currentDate, games, viewType }) => {
  return useQuery({
    queryKey: ['events', 'calendar', currentDate, games],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        [SEARCH_PARAMS.OWNER_IDS]: establishment.id,
        [SEARCH_PARAMS.LIMIT]: '100', // Higher limit for calendar view
      })

      const monday = startOfWeek(currentDate, { weekStartsOn: 1 })
      const sunday = addDays(monday, 6)
      searchParams.append(SEARCH_PARAMS.STARTS_AT, monday.toISOString())
      searchParams.append(SEARCH_PARAMS.ENDS_AT, sunday.toISOString())

      if (games?.length) {
        games.forEach((gameId) =>
          searchParams.append(SEARCH_PARAMS.GAME_IDS, gameId),
        )
      }

      const res = await webAxiosInstance.get(
        `/api/organize/events?${searchParams.toString()}`,
      )
      return res?.data
    },
    enabled: viewType === VIEW_TYPES.CALENDAR,
  })
}

export const Route = createFileRoute(
  '/_authenticated/entity/$entityId/events/',
)({
  loader: async ({ params }) => {
    const { entityId } = params
    const userData = await queryClient.getQueryData(['userData'])
    const org = findOrganizationByEntityId(userData?.organizations, entityId)
    const entity = findEntityById(org?.entities, entityId)
    const establishment = userData?.establishments?.find(
      (est) => est.entityId === entityId,
    )

    return { entity, entityId, establishment, org }
  },
  component: () => <EventToolLandingPage />,
  pendingComponent: () => <Loading />,
})

const EventToolLandingPage = () => {
  const {
    tab: defaultTab,
    page: defaultPage,
    limit: defaultLimit,
    viewType: defaultViewType,
  } = Route.useSearch()
  
  const [activeTab, setActiveTab] = useState(defaultTab || TAB_TYPES.UPCOMING)
  const [viewType, setViewType] = useState(defaultViewType || VIEW_TYPES.LIST)
  const { entity, establishment, org } = Route.useLoaderData()
  const { games, page, searchText, limit, setPage, setLimit } =
    useEventsSearchStore()
  const [currentDate, setCurrentDate] = useState(new Date())

  const memoizedSearchText = useMemo(() => searchText, [searchText])

  useEffect(() => {
    if (defaultPage) setPage(defaultPage)
    if (defaultLimit) setLimit(defaultLimit)
  }, [])

  const listQuery = useEventsList({
    establishment,
    activeTab,
    games,
    searchText: memoizedSearchText,
    page,
    limit,
    viewType,
  })

  const calendarQuery = useCalendarEvents({
    establishment,
    currentDate,
    games,
    viewType,
  })

  const currentQuery = viewType === VIEW_TYPES.LIST ? listQuery : calendarQuery

  return (
    <EventToolLanding
      activeTab={activeTab}
      currentDate={currentDate}
      setCurrentDate={setCurrentDate}
      entity={entity}
      entityId={entity?.id}
      establishment={establishment}
      events={currentQuery.data?.data}
      isFetching={currentQuery.isLoading}
      org={org}
      pagination={currentQuery.data?.pagination}
      setActiveTab={setActiveTab}
      viewType={viewType}
      setViewType={setViewType}
    />
  )
}
