import { RiMailUnreadLine } from '@remixicon/react';
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "@cardeio/ui/components/ui/button";
import { clientAxiosInstance } from "@repo/api/web";
import ParallaxBackgroundImage from "@/components/Image/ParallaxBackgroundImage";

const VerifyEmail = ({ search }) => {
  const { loginWithRedirect, logout } = useAuth0();
  const userId = search?.user_id;
  const email = search?.email;
  const state = search?.state;

  const logoutAction = async () => {
    try {
      await logout({ logoutParams: { returnTo: window.location.origin } });
    }
    catch {
      console.error('Cannot logout at this time!')
    }
  }

  const resendEmailMutation = useMutation({
    mutationFn: async () => {
      return clientAxiosInstance('/api/v1/users/resend-verification', {
        method: 'POST',
        data: {
          user_id: userId,
          client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
        },
      });
    },
    onSuccess: () => {
      toast.success('Success', { description: 'Verification Email Sent!' });

    },
    onError: () => {
      toast.error('Failure', { description: 'Error sending verification email' });
    }
  })

  if (!userId || !email || !state) {
    return window.location.href = '/';
  }

  return (
    <>
      <ParallaxBackgroundImage
        hasGradient
        hasShine
      />
      <div className={'flex justify-center pt-2 m-auto max-w-[1500px] h-screen items-center'}>
        <div className='flex'>
          <div className='flex flex-col justify-center items-center xs:px-2'>
            <RiMailUnreadLine className='xs:h-[100px] sm:h-[125px] xs:w-[100px] sm:w-[125px]' />

            <h2 className='text-center text-2xl mb-2 font-bold'>
              Verify Your Email
            </h2>

            <div className='max-w-[700px] flex flex-col gap-4 text-center'>
              <p className=''>
                Welcome to the Carde.io Dashboard! To ensure your account security and grant full access to our platform, we need you to verify your email address.
              </p>
              <p>
                The verification email has been sent to the following email address:
              </p>
              <p className='text-lg font-bold'>
                {email || ''}
              </p>
              <p>
                Please check your inbox (including spam folder) for an email from us containing a verification link.
                Clicking on the link will complete the verification process.
              </p>

              <p className='text-xs mb-2'>
                * Please note that the verification link or instructions are time-sensitive and will expire after a
                certain period. If the verification link expires, you may need to request another email verification.
              </p>
            </div>

            <div className='flex flex-col gap-4 mt-4'>
              <Button
                id='verify-email-login-button'
                data-testid='verify-email-login-button'
                onClick={loginWithRedirect}
                variant='outline'
                className='rounded-full'
              >
                Log In
              </Button>
              <Button
                className='rounded-full'
                id='verify-email-resend-email-button'
                data-testid='verify-email-resend-email-button'
                onClick={() => resendEmailMutation.mutate()}
                variant='gradient'
              >
                Resend Verification Email
              </Button>

              <Button
                className='my-4 gap-1'
                id='verify-email-logout-button'
                data-testid='verify-email-logout-button'
                onClick={logoutAction}
                size='sm'
                variant='text'
              >
                <p>If you're not this user -</p>
                <p className='underline'>Logout</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
