import { get, isArray, isEqual, isObject, uniqBy } from "lodash";
import { evaluateCondition, getUnknownKey } from "../createEventHelpers";
import { useEffect, useMemo } from "react";

const useDynamicFormFields = (formData, watchAllFields, setValue) => {

  const formFieldsObject = useMemo(() => {
    if (!formData) return {}
    const fieldsObject = Object.values(formData?.fields).reduce((acc, item) => {

      if (isArray(item)) {
        item.forEach((item) => {
          if (acc[getUnknownKey(item)]) {
            acc[getUnknownKey(item)] = [...acc[getUnknownKey(item)], item];
          } else {
            acc[getUnknownKey(item)] = [item];
          }
        });
        return acc
      }
      if (isObject(item)) {
        return { ...acc, ...item };
      }
      return acc;
    }, {});

    return fieldsObject;
  }, [formData]);

  const validFields = useMemo(() => {
    return Object.entries(formFieldsObject).reduce((acc, [key, value]) => {
      return {
        ...acc, [key]: value.filter((item) => {
          return evaluateCondition(watchAllFields, item.conditions)
        })
      }
    }, {});
  }, [formFieldsObject, watchAllFields])


  const validOptions = useMemo(() => Object.entries(validFields).reduce((acc, [key, value]) => {
    if (!value.length) return acc;
    if (value.length > 1) acc[key] = uniqBy(value?.flatMap(v => v[key].options), 'value');
    else acc[key] = value[0][key].options;
    return acc;
  }, {}), [validFields]);

  const fieldDefaults = useMemo(() => {
    return Object.entries(validFields).reduce((acc, [key, value]) => {
      if (!value.length) return acc;
      acc[key] = value[0][key].default
      return acc
    }, {});
  }, [validFields])

  useEffect(() => {
    Object.keys(formFieldsObject).forEach((key) => {
      if (key === "activityLevelId" && validFields[key].length > 1) return;
      if (key === "gameActivityId" && validFields[key].length > 1) return;
      const currentFormValue = get(watchAllFields, key);
      if (!validFields[key].length && currentFormValue !== undefined) return setValue(key, undefined)
      if (!validOptions[key]) return;
      if (isEqual(fieldDefaults[key], currentFormValue)) return;
      if (!validOptions[key].find(o => isEqual(o?.value, currentFormValue))) return setValue(key, fieldDefaults[key])
    })
  }, [fieldDefaults, formFieldsObject, setValue, validFields, validOptions, watchAllFields])

  const pendingFields = useMemo(() => {
    return Object.entries(validOptions).filter(([key, value]) => {
      const currentFormValue = get(watchAllFields, key);

      if (!validFields[key].length) return false;
      return !value.find(o => isEqual(o?.value, currentFormValue))
    })
  }, [validFields, validOptions, watchAllFields])


  return { formFieldsObject, validFields, pendingFields }
};

export default useDynamicFormFields;