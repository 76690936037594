import React from "react"
import { ArrowRight, Check } from "lucide-react"

import { cn } from "../lib/utils"

export type StepStatus = "COMPLETE" | "IN_PROGRESS" | "UPCOMING"

export interface Step {
	id: string
	name: string
	status: StepStatus
	stepNumber?: number
	href?: string
}

interface StepItemProps {
	step: Step
	isActive: boolean
	onClick?: (step: Step) => void
}

const StepItem = ({ step, isActive, onClick }: StepItemProps) => {
	const isClickable = onClick && step.status !== "UPCOMING"

	const getStatusStyles = () => {
		switch (step.status) {
			case "COMPLETE":
				return "text-white border-white"
			case "IN_PROGRESS":
				return "border-orange-500"
			case "UPCOMING":
				return "text-gray-50 opacity-50"
			default:
				return ""
		}
	}

	const content = (
		<div className="flex flex-col items-start">
			{step.stepNumber && (
				<span
					className={`text-xs uppercase mb-1 ${step.status === "IN_PROGRESS" ? "text-orange-200" : ""}`}
				>
					STEP {step.stepNumber}
				</span>
			)}
			<div className="flex items-center">
				<span
					className={`max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis ${
						step.status === "IN_PROGRESS" ? "font-bold text-white" : ""
					}`}
				>
					{step.name}
				</span>
				{step.status === "COMPLETE" && (
					<Check className="ml-2 h-4 w-4 text-white" aria-hidden="true" />
				)}
			</div>
		</div>
	)

	if (step.href) {
		return (
			<a
				href={step.href}
				className={cn(
					"inline-flex items-center gap-x-1 text-sm font-semibold leading-6 focus:outline-none",
					isActive ? "border-b-2 pb-1" : "",
					getStatusStyles(),
					step.status === "UPCOMING"
						? "cursor-not-allowed pointer-events-none"
						: "cursor-pointer"
				)}
			>
				{content}
			</a>
		)
	}

	return (
		<button
			className={cn(
				"inline-flex items-center gap-x-1 text-sm font-semibold leading-6 focus:outline-none",
				isActive ? "border-b-2 pb-1" : "",
				getStatusStyles(),
				isClickable ? "cursor-pointer" : "cursor-not-allowed pointer-events-none"
			)}
			onClick={isClickable ? () => onClick?.(step) : undefined}
			disabled={!isClickable}
		>
			{content}
		</button>
	)
}

export interface StepNavigationProps {
	steps: Step[]
	activeStepId?: string
	onStepClick?: (step: Step) => void
	className?: string
	showArrows?: boolean
}

export const StepNavigation = ({
	steps,
	activeStepId,
	onStepClick,
	className,
	showArrows = true,
}: StepNavigationProps) => {
	if (!steps || steps.length === 0) {
		return null
	}

	return (
		<div
			className={cn(
				"w-full p-4 bg-background rounded-lg flex flex-row gap-8 items-center",
				className
			)}
		>
			{steps.map((step, index) => {
				const isBeforeActive = steps.findIndex((s) => s.id === activeStepId) > index
				return (
					<React.Fragment key={step.id}>
						<StepItem
							step={step}
							isActive={step.id === activeStepId}
							onClick={onStepClick}
						/>
						{showArrows && index !== steps.length - 1 && (
							<ArrowRight
								className={`h-5 w-5 ${isBeforeActive ? "text-white" : "text-gray-50"}`}
								aria-hidden="true"
							/>
						)}
					</React.Fragment>
				)
			})}
		</div>
	)
}

export default StepNavigation
