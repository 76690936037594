import React from "react";
import { CheckBadgeIcon, PlayIcon } from "@heroicons/react/20/solid";

import { Choice } from "@repo/ui/button";
import { Label } from "@repo/ui/label";
import { ComboBox } from "@repo/ui/combobox";
import { Input } from "@repo/ui/input";

const HostingConfigContainer = ({
  externalHostingSite,
  externalSites,
  hostingInfo,
  isActive = true,
  isHostedOnCardeio,
  selectedExternalSite,
  setExternalHostingSite,
  setHostingInfo,
  setIsHostedOnCardeio,
}) => {
  const externalSiteOptions = externalSites?.map((siteDetails) => ({ id: siteDetails.id, isOfficial: siteDetails?.official, label: siteDetails?.urls?.eventManagement?.name, logo: siteDetails?.logo, value: siteDetails?.urls?.eventManagement?.name })).sort((a) => a.official ? 1 : -1);

  return (
    <div className={`flex justify-between ${isActive ? 'block animate-slideInFromBottom' : 'hidden'}`}>
      <div className='flex flex-col gap-4 pb-12 w-full'>
        <h2 className='text-xl font-bold'>Where Are You Running This Event?</h2>
        <p className='text-sm text-zinc-200/60 mb-8'>You can run all of your events on the Carde.io Play Platform regardless of Game System. You could also run this event on another platform in which case we will ask for the link out for your players.</p>
        <Choice
          icon={<img src='https://storage.googleapis.com/cardeio-images/cardeio/carde-white.png' className='h-6 w-6' />}
          isSelected={isHostedOnCardeio}
          onClick={() => setIsHostedOnCardeio(true)}
        >
          <p className='text-sm text-start'>Run Event With Carde.io Play</p>
          <p className='text-2xs mt-1 text-zinc-300/50 text-start font-light'>This will have the official link for this event go to the Carde.io Play Network</p>
        </Choice>
        <Choice
          icon={<PlayIcon className='h-6 w-6' />}
          isSelected={!isHostedOnCardeio}
          onClick={() => setIsHostedOnCardeio(false)}
        >
          <p className='text-sm text-start'>Run Event Elsewhere</p>
          <p className='text-2xs mt-1 text-zinc-300/50 text-start font-light'>This will have the official link for this event go to another Play Platform</p>
        </Choice>

        <div className={`grid w-full items-center gap-1.5 ${isHostedOnCardeio ? 'hidden' : 'block'}`}>
          {externalSiteOptions?.length > 0 && (
            <>
              <Label htmlFor="external-platform">Which Platform Are you Running the Event On?</Label>
              <ComboBox
                className='w-full mb-4'
                itemDisplay={(item) => (
                  <div className='flex flex-col'>
                    <div className='flex items-center'>
                      <img src={item.logo} className='h-6 mr-1' />
                      {item.isOfficial && (
                        <CheckBadgeIcon className='ml-1 h-5 w-5 fill-secondary pointer-events-auto' />
                      )}
                    </div>
                    {item.isOfficial && (
                      <span className='text-2xs text-zinc-200/60'>This is the Officially Sanctioned Platform </span>
                    )}
                  </div>
                )}
                items={externalSiteOptions}
                setValue={(value) => {
                  setExternalHostingSite(value);
                }}
                value={externalHostingSite}
              />
            </>
          )}

          {selectedExternalSite?.urls?.eventManagement?.joinCode?.name ? (
            <>
              <Label htmlFor="external-url">{selectedExternalSite?.urls?.eventManagement?.joinCode?.name || 'Event Code'}</Label>
              <Input
                disabled={externalSiteOptions?.length > 0 && !externalHostingSite}
                id="hosting-url-input"
                maxLength={selectedExternalSite?.urls?.eventManagement?.joinCode?.rules?.exactLength || selectedExternalSite?.urls?.eventManagement?.joinCode?.rules?.maxLength || null}
                minLength={selectedExternalSite?.urls?.eventManagement?.joinCode?.rules?.exactLength || selectedExternalSite?.urls?.eventManagement?.joinCode?.rules?.minLength || null}
                onChange={(e) => setHostingInfo(e.target.value)}
                placeholder={selectedExternalSite?.urls?.eventManagement?.joinCode?.name || 'Event Code'}
                type={selectedExternalSite?.urls?.eventManagement?.joinCode?.rules?.type || 'text'}
                value={hostingInfo}
              />
              <p className={'text-3xs text-zinc-100/80'}>{selectedExternalSite?.urls?.eventManagement?.joinCode?.description || 'Please only include the code necessary to join the event. This will be unlocked for the user once they are registered.'}</p>
            </>
          ) : selectedExternalSite?.urls?.eventManagement?.eventId?.name ?
            (
              <>
                <Label htmlFor="external-url">{selectedExternalSite?.urls?.eventManagement?.eventId?.name || 'Event Code'}</Label>
                <Input
                  disabled={externalSiteOptions?.length > 0 && !externalHostingSite}
                  id="hosting-url-input"
                  maxLength={selectedExternalSite?.urls?.eventManagement?.eventId?.rules?.exactLength || selectedExternalSite?.urls?.eventManagement?.eventId?.rules?.maxLength || null}
                  minLength={selectedExternalSite?.urls?.eventManagement?.eventId?.rules?.exactLength || selectedExternalSite?.urls?.eventManagement?.eventId?.rules?.minLength || null}
                  onChange={(e) => setHostingInfo(e.target.value)}
                  placeholder={selectedExternalSite?.urls?.eventManagement?.eventId?.name || 'Event Code'}
                  type={selectedExternalSite?.urls?.eventManagement?.eventId?.rules?.type || 'text'}
                  value={hostingInfo}
                />
                <p className={'text-3xs text-zinc-100/80'}>{selectedExternalSite?.urls?.eventManagement?.eventId?.description || 'Please only include the code necessary to join the event. This will be unlocked for the user once they are registered.'}</p>
              </>
            ) : (
              <>
                <Label htmlFor="external-url">Event Link</Label>
                <Input
                  disabled={externalSiteOptions?.length > 0 && !externalHostingSite}
                  type="text"
                  id="hosting-url-input"
                  onChange={(e) => setHostingInfo(e.target.value)}
                  placeholder="Event Link"
                  value={hostingInfo}
                />
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default HostingConfigContainer;
