import { useState, useMemo } from 'react';
import { CalendarClock, CalendarOffIcon } from 'lucide-react';
import { Button } from '@cardeio/ui/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@cardeio/ui/components/ui/popover';
import { ScrollArea } from '@cardeio/ui/components/ui/scroll-area';

const FIVE_HOURS_IN_SECONDS = 5 * 60 * 60; // 5 hours
const FIVE_MINUTES_IN_SECONDS = 5 * 60; // 5 second increments

export const FutureTimeSelector = ({ 
  onSelectTime, 
  onClearTime, 
  disabled, 
  hasStartTime 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const timeOptions = useMemo(() => {
    const options = [];
    const now = Date.now();
    
    // Calculate the next 5-minute interval
    const msToNextInterval = FIVE_MINUTES_IN_SECONDS * 1000 - (now % (FIVE_MINUTES_IN_SECONDS * 1000));
    const nextIntervalTime = now + msToNextInterval;
    
    // Start from the next 5-minute interval
    for (let seconds = 0; seconds <= FIVE_HOURS_IN_SECONDS; seconds += FIVE_MINUTES_IN_SECONDS) {
      const futureTime = new Date(nextIntervalTime + seconds * 1000);
      
      options.push({
        value: Math.ceil((futureTime - now) / 1000), // Calculate exact seconds until this time
        time: futureTime.toLocaleTimeString([], { 
          hour: '2-digit', 
          minute: '2-digit',
          hour12: true 
        })
      });
    }
    return options;
  }, []);

  const handleSelectTime = (seconds) => {
    const startTime = new Date(Date.now() + seconds * 1000).toISOString();
    onSelectTime(startTime);
    setIsOpen(false);
  };

  if (hasStartTime) {
    return (
      <Button
        variant="ghost"
        size="icon"
        onClick={onClearTime}
        disabled={disabled}
        className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
      >
        <CalendarClock width={40} height={40} strokeWidth={1.25} />
      </Button>
    );
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          disabled={disabled}
          className="hover:opacity-80 hover:scale-105 transition-all duration-150 disabled:opacity-50"
        >
          <CalendarClock width={40} height={40} strokeWidth={1.25} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-32 p-0 bg-primary">
        <ScrollArea className="h-[300px]">
          <div className="flex flex-col p-2">
            {timeOptions.map(({ value, time }) => (
              <Button
                key={value}
                variant="ghost"
                onClick={() => handleSelectTime(value)}
                className="justify-between h-auto py-2"
              >
                <span className="text-xs text-muted-foreground">{time}</span>
              </Button>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}; 