"use client";

import { useEffect, useRef, useState } from "react";
import { getGameColor } from '@/utils/gameColors';
import Loading from '@/components/Loading';
import CalendarEvent from '@/components/Cards/Variants/CalendarEvent';
import { cn } from "@/utils/cn";

// Grid constants
const MINUTES_PER_INTERVAL = 10;
const INTERVALS_PER_HOUR = 60 / MINUTES_PER_INTERVAL; // 6 intervals per hour
const HEADER_ROW_OFFSET = 2;
const TOTAL_INTERVALS = 24 * INTERVALS_PER_HOUR; // 144 intervals for 24 hours

const getEventGridPosition = (startTime, endTime) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  
  const startHour = startDate.getHours();
  const startMinute = startDate.getMinutes();
  const endHour = endDate.getHours();
  const endMinute = endDate.getMinutes();

  // Calculate grid positions using intervals
  const startRow = (startHour * INTERVALS_PER_HOUR) + Math.floor(startMinute / MINUTES_PER_INTERVAL) + HEADER_ROW_OFFSET;
  const endRow = (endHour * INTERVALS_PER_HOUR) + Math.floor(endMinute / MINUTES_PER_INTERVAL) + HEADER_ROW_OFFSET;
  const span = Math.max(endRow - startRow, 1);

  return `${startRow} / span ${span}`;
};

const getColumnNumber = (eventDate) => {
  const date = new Date(eventDate);
  const dayOfWeek = date.getDay();
  // Convert Sunday (0) to 7, otherwise add 1 to match grid columns
  return dayOfWeek === 0 ? 7 : dayOfWeek;
};

const doEventsOverlap = (event1, event2) => {
  const start1 = new Date(event1.startsAt).getTime();
  const end1 = new Date(event1.endsAt).getTime();
  const start2 = new Date(event2.startsAt).getTime();
  const end2 = new Date(event2.endsAt).getTime();

  return !(end1 <= start2 || start1 >= end2);
};

const getOverlappingEvents = (event, allEvents) => {
  return allEvents.filter(
    (e) => e.id !== event.id && doEventsOverlap(event, e)
  );
};

const formatEventType = (event) => {
  return event.activities?.[0]?.name || '';
};

// Update isToday to use date-fns for consistent comparison
const isToday = (date) => {
  if (!date) return false;
  
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

// Update getGameName to just return the gameId
const getGameName = (event) => {
  const activity = event.activities?.[0];
  return activity?.gameId || 'default';
};

const formatHourLabel = (hour) => {
  if (hour === 0) return "12AM";
  if (hour < 12) return `${hour}AM`;
  if (hour === 12) return "12PM";
  return `${hour - 12}PM`;
};

export default function EventCalendar({ events, currentDate, isFetching }) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [weekDays, setWeekDays] = useState([]);
  const [weekEvents, setWeekEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredOverlappingEvents, setFilteredOverlappingEvents] = useState([]);

  useEffect(() => {
    // Only set scroll position after loading is complete
    if (isFetching) return;

    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    if (container.current && containerNav.current && containerOffset.current) {
      container.current.scrollTop =
        ((container.current.scrollHeight -
          containerNav.current.offsetHeight -
          containerOffset.current.offsetHeight) *
          currentMinute) /
        1440;
    }
  }, [isFetching]); // Add isFetching as a dependency

  useEffect(() => {
    // Create a new date object to avoid mutating currentDate
    const date = new Date(currentDate);
    date.setHours(0, 0, 0, 0);  // Set to midnight
    
    const currentDay = date.getDay();
    const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1;
    
    // Create Monday date
    const monday = new Date(date);
    monday.setDate(date.getDate() - daysToSubtract);

    const days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      return date;
    });

    setWeekDays(days);
  }, [currentDate]);

  useEffect(() => {
    if (weekDays.length === 0 || !events?.length) {
      setWeekEvents([]);
      return;
    }

    const weekStart = weekDays[0].toISOString().split("T")[0];
    const weekEnd = weekDays[6].toISOString().split("T")[0];

    const filteredWeekEvents = events.filter((event) => {
      const eventDate = new Date(event.startsAt).toISOString().split("T")[0];
      return eventDate >= weekStart && eventDate <= weekEnd;
    });

    setWeekEvents(filteredWeekEvents);
  }, [events, weekDays]);

  useEffect(() => {
    // Reset states when weekEvents changes
    setFilteredEvents([]);
    setFilteredOverlappingEvents([]);

    // First pass: find all overlapping events
    const overlappingEventIds = new Set();
    weekEvents.forEach((event) => {
      const overlaps = getOverlappingEvents(event, weekEvents);
      if (overlaps.length > 0) {
        overlappingEventIds.add(event.id);
        overlaps.forEach(e => overlappingEventIds.add(e.id));
      }
    });

    // Second pass: group overlapping events and collect non-overlapping ones
    const overlappingGroups = [];
    const nonOverlappingEvents = [];

    weekEvents.forEach((event) => {
      if (!overlappingEventIds.has(event.id)) {
        // Event doesn't overlap with anything
        nonOverlappingEvents.push(event);
        return;
      }

      // Skip if event is already in a group
      if (overlappingGroups.some(group => 
        group.events.some(e => e.id === event.id)
      )) {
        return;
      }

      const overlaps = getOverlappingEvents(event, weekEvents);
      if (overlaps.length > 0) {
        // Create new group with all overlapping events
        const allGroupEvents = [event, ...overlaps].sort((a, b) => {
          return new Date(a.startsAt) - new Date(b.startsAt);
        });

        overlappingGroups.push({
          id: `group-${event.id}`,
          startsAt: new Date(Math.min(...allGroupEvents.map(e => new Date(e.startsAt)))).toISOString(),
          endsAt: new Date(Math.max(...allGroupEvents.map(e => new Date(e.endsAt)))).toISOString(),
          events: allGroupEvents,
          column: getColumnNumber(event.startsAt).toString(),
        });
      }
    });

    // Update states with new calculations
    setFilteredEvents(nonOverlappingEvents.map((event) => ({
      ...event,
      column: getColumnNumber(event.startsAt).toString(),
      width: 100,
      offset: 0,
    })));

    setFilteredOverlappingEvents(overlappingGroups.map((group) => ({
      ...group,
      width: 100,
      offset: 0,
    })));

  }, [weekEvents]);

  useEffect(() => {
    // Reset filtered events when events prop changes
    setFilteredEvents([]);
    setFilteredOverlappingEvents([]);
    
    // Force a reflow/resize
    if (container.current) {
      const currentScroll = container.current.scrollTop;
      container.current.style.display = 'none';
      // Force reflow
      container.current.offsetHeight;
      container.current.style.display = '';
      container.current.scrollTop = currentScroll;
    }
  }, [events]); // Add events as a dependency

  return (
    <>
      {isFetching ? (
        <Loading fullScreen />
      ) : (
        <div className="flex h-full max-h-[75vh] flex-col">
          <div
            ref={container}
            className={cn(
              "isolate flex flex-auto flex-col overflow-auto bg-zinc-950"
            )}
          >
            <div
              ref={containerNav}
              className={cn(
                "sticky top-0 z-30 flex-none bg-zinc-950 shadow",
                "ring-1 ring-gray-100/20 overflow-hidden"
              )}
            >
              <div className="grid grid-cols-7 divide-x divide-gray-100/20 border-r border-gray-100/20 text-sm/6 text-zinc-400">
                <div className="col-end-1 w-14" />
                {weekDays.length > 0 && weekDays.map((date, i) => (
                  <div 
                    key={i} 
                    className={cn(
                      "flex flex-col items-center py-2",
                      isToday(date) && "bg-zinc-800/50"
                    )}
                  >
                    <span
                      className={cn(
                        "flex size-6 items-center justify-center font-semibold",
                        date.toDateString() === new Date().toDateString() 
                          ? "text-secondary" 
                          : "text-zinc-100"
                      )}
                    >
                      {date.getDate()}
                    </span>
                    <span className="text-xs">
                      {date.toLocaleString("default", { weekday: "short" })}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className={cn(
                "sticky left-0 z-10 w-14 flex-none bg-zinc-950",
                "ring-1 ring-gray-100/20"
              )} />
              <div className="grid flex-auto grid-cols-1 grid-rows-1 border-r border-gray-100/20">
                {/* Horizontal lines */}
                <div
                  className={cn(
                    "col-start-1 col-end-2 row-start-1 grid",
                    "divide-y divide-gray-100/20"
                  )}
                  style={{ gridTemplateRows: "repeat(24, minmax(2.5rem, 1fr))" }}
                >
                  <div ref={containerOffset} className="row-end-1 h-7" />
                  {Array.from({ length: 24 }, (_, i) => (
                    <div key={`time-${i}`}>
                      <div className={cn(
                        "sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2",
                        "text-right text-xs/5 text-zinc-400"
                      )}>
                        {formatHourLabel(i)}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 grid-rows-1 divide-x divide-gray-100/20">
                  {weekDays.length > 0 && (
                    <>
                      <div
                        className={cn(
                          "col-start-1",
                          "row-span-full",
                          isToday(weekDays[0]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-2",
                          "row-span-full",
                          isToday(weekDays[1]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-3",
                          "row-span-full",
                          isToday(weekDays[2]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-4",
                          "row-span-full",
                          isToday(weekDays[3]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-5",
                          "row-span-full",
                          isToday(weekDays[4]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-6",
                          "row-span-full",
                          isToday(weekDays[5]) && "bg-zinc-800/50"
                        )}
                      />
                      <div
                        className={cn(
                          "col-start-7",
                          "row-span-full",
                          isToday(weekDays[6]) && "bg-zinc-800/50"
                        )}
                      />
                    </>
                  )}
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-7"
                  style={{
                    gridTemplateRows: `2rem 0.5rem repeat(${TOTAL_INTERVALS}, minmax(0, 1fr)) auto`
                  }}
                >
                  {filteredEvents.map((event) => (
                    <li
                      key={event.id}
                      className={cn(
                        "relative mt-px flex",
                        `col-start-${event.column}`,
                        isToday(new Date(event.startsAt)) && "bg-zinc-800/50"
                      )}
                      style={{
                        gridRow: getEventGridPosition(
                          event.startsAt,
                          event.endsAt
                        ),
                      }}
                    >
                      <CalendarEvent 
                        event={event}
                        getGameName={getGameName}
                        formatEventType={formatEventType}
                        classNames={cn}
                      />
                    </li>
                  ))}

                  {filteredOverlappingEvents.map((group) => (
                    group.events.map((event, idx) => {
                      const offset = idx * 24;
                      const maxOffset = Math.min(offset, 72);
                      
                      return (
                        <li
                          key={event.id}
                          className={cn(
                            "relative mt-px flex",
                            `col-start-${group.column}`
                          )}
                          style={{
                            gridRow: getEventGridPosition(
                              event.startsAt,
                              event.endsAt
                            ),
                          }}
                        >
                          <div className="absolute inset-0 overflow-hidden">
                            <div className="absolute inset-1 overflow-visible">
                              <CalendarEvent 
                                event={event}
                                idx={idx}
                                maxOffset={maxOffset}
                                isOverlapping={true}
                                getGameName={getGameName}
                                formatEventType={formatEventType}
                                classNames={cn}
                              />
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
