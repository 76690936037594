import { useMutation } from "@tanstack/react-query";
import { useEventStore } from "@/store/useEventStore";
import { Button } from "@repo/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/select";

import RoundSwitcher from "../../../RoundSwitcher";
import { RiAlertLine } from "@remixicon/react";
import RevertToPreviousRoundModal from "@/components/Modal/Event/Organize/RevertToPreviousRoundModal";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { useMemo, useState } from "react";
import { Undo } from "lucide-react";

const PodFilter = ({ filters, setFilters, pods }) => (
  <div>
    <p className="text-sm mb-1">Pod Visibility</p>
    <Select
      defaultValue="all"
      onValueChange={(value) =>
        setFilters({ ...filters, podId: value === "all" ? null : value })
      }
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select Pod" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectTextItem value="all">Show All Pods</SelectTextItem>
          {pods.map((pod) => (
            <SelectTextItem key={pod.id} value={pod.id}>
              Pod {pod.pod}
            </SelectTextItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  </div>
);

const PairingsTableHeader = ({
  refetchPairings,
  activePhases = [],
  filters,
  setFilters,
}) => {
  const { currentTournament, currentTournamentRound, refetch, activePhaseName, readOnlyRoundId, allPhases } =
    useEventStore();

  const isPreview = activePhases.some((phase) => phase.status === "preview");

  const [isRevertPreviousRoundModalOpen, setIsRevertPreviousRoundModalOpen] = useState(false);

  const PreviousRoundModalContainer = ({ buttonVariant = 'text' }) => {
    return (
      <div className="flex-shrink-0">
        <Modal open={isRevertPreviousRoundModalOpen} onOpenChange={setIsRevertPreviousRoundModalOpen}>
          <ModalTrigger asChild>
            <Button
              rounded
              className='flex items-center text-base'
              onClick={() => setIsRevertPreviousRoundModalOpen(true)}
              size='sm'
              variant={buttonVariant}
            >
              <Undo className='h-4 w-4 mr-1 text-red-500' /><span>{`Fix Round ${currentTournamentRound?.roundNumber - 1} Results`}</span>
            </Button>
          </ModalTrigger>
          <RevertToPreviousRoundModal handleClose={() => setIsRevertPreviousRoundModalOpen(false)} />
        </Modal>
      </div>
    )
  }

  const isReadOnlyRound = useMemo(() => {
    return readOnlyRoundId && readOnlyRoundId !== currentTournamentRound?.id;
  }, [readOnlyRoundId, currentTournamentRound?.id]);

  const roundNumber = allPhases?.[0]?.tournament?.rounds?.find(round => round.id === readOnlyRoundId)?.roundNumber;
  const showRevertToPreviousRound = currentTournament?.currentRound > 1 && roundNumber === currentTournamentRound?.roundNumber - 1;


  return (
    <div className="rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60 w-full flex flex-wrap gap-4 items-center justify-between">
      <div className="-ml-4 -mt-2 flex flex-wrap items-start justify-between sm:flex-nowrap">
        <div className="flex self-stretch flex-col items-start justify-between ml-4">
          <div>
            <h3 className="text-2xl font-semibold leading-6 text-zinc-100">{`${activePhaseName} - Round ${isReadOnlyRound ? roundNumber : currentTournamentRound?.roundNumber} Pairings ${isPreview ? "Preview" : isReadOnlyRound ? "Results" : ""}`}</h3>

          </div>
          <RoundSwitcher />
          {/* TEMP REMOVE POD SWITCHER */}
          {/* {activePhases?.length > 1 ? <div className="mt-6"><PodSwitcher activePhases={activePhases} activePhase={activePhasePod} /></div> : null} */}
        </div >
      </div >

      <div className="flex flex-col items-start justify-start gap-4">
        {!(isReadOnlyRound) ? <Button
          onClick={refetchPairings}
          variant="outline"
          size="sm"
          className="text-sm"
        >
          Refresh Pairings
        </Button> : showRevertToPreviousRound && <PreviousRoundModalContainer buttonVariant="destructive" />}
      </div>
    </div >
  );
};

export default PairingsTableHeader;
