import { useCallback, useMemo, useState } from "react";

import OneVsOnePlayerInfo from "@/components/Cards/Variants/Pairings/OneVsOne/PlayerInfo";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import ManagePairingModal from "@/components/Modal/Event/Pairings/ManagePairing";
import { useEventStore } from "@/store/useEventStore";

const colors = {
  none: "rgb(88, 64, 43)", // Brown for unreported
  win: "rgb(34, 97, 55)", // Green
  loss: "rgb(127, 29, 29)", // Red
  tie: "rgb(30, 58, 138)", // Blue
};

const OneVsOnePairingCard = ({ pairing, isPreview = false }) => {
  const [isManagePairingModalOpen, setIsManagePairingModalOpen] = useState(false);
  const { currentTournamentRound, readOnlyRoundId } = useEventStore();
  const seatOne = pairing?.tournamentPairingUsers?.[0] || {};
  const seatTwo = pairing?.tournamentPairingUsers?.[1] || {};

  const viewOnlyCard = useMemo(() => {
    return readOnlyRoundId !== currentTournamentRound?.id;
  }, [readOnlyRoundId, currentTournamentRound?.id]);

  const { seatOneWins, seatTwoWins } = useMemo(() => {
    let oneWins = 0;
    let twoWins = 0;
    if (!pairing?.result?.isDoubleLoss) {
      pairing?.result?.games?.forEach((game) => {
        if (game?.winner === seatOne?.id) {
          oneWins += 1;
        }
        if (game?.winner === seatTwo?.id) {
          twoWins += 1;
        }
      });
    }
    return { seatOneWins: oneWins, seatTwoWins: twoWins };
  }, [
    pairing?.result?.isDoubleLoss,
    pairing?.result?.games,
    seatOne?.id,
    seatTwo?.id,
  ]);

  const { seatOneOutcome, seatTwoOutcome } = useMemo(() => {
    let oneOutcome = "none";
    let twoOutcome = "none";

    if (pairing?.result?.isDoubleLoss) {
      oneOutcome = "loss";
      twoOutcome = "loss";
    } else if (seatOneWins > seatTwoWins || !seatTwo?.id) {
      oneOutcome = "win";
      twoOutcome = "loss";
    } else if (seatOneWins < seatTwoWins) {
      oneOutcome = "loss";
      twoOutcome = "win";
    } else if (seatOneWins !== 0 && seatTwoWins !== 0) {
      oneOutcome = "tie";
      twoOutcome = "tie";
    }

    return { seatOneOutcome: oneOutcome, seatTwoOutcome: twoOutcome };
  }, [pairing?.result?.isDoubleLoss, seatOneWins, seatTwo?.id, seatTwoWins]);

  const handleClose = useCallback(() => {
    setIsManagePairingModalOpen(false);
  }, []);

  return (
    <>
      <li key={pairing.id} className="mx-2 group flex items-center rounded-xl">
        <Modal
          open={isManagePairingModalOpen && !!seatTwo?.id && !viewOnlyCard}
          onOpenChange={setIsManagePairingModalOpen}
        >
          <ModalTrigger asChild>
            <button className="w-full h-full flex items-center gap-3">
              <div className="bg-zinc-900 rounded-xl flex items-center justify-center">
                <span className="font-medium flex flex-col items-center justify-center p-2 py-3">
                  <div className="text-sm">TABLE</div>
                  <span className="text-lg">{pairing.tableNumber}</span>
                </span>
              </div>
              <div
                className={`grid grid-cols-12 w-full items-center rounded-xl px-2 ${seatOneOutcome !== "none" && seatTwoOutcome !== "none" ? "" : isPreview ? "bg-[#FFA95A66]" : "bg-[#4D4D4D]"}`}
              >
                <div
                  className={`col-span-5 ${seatOneOutcome === "win" ? "bg-[#16A34A66] border border-green-500/50 -ms-2 -me-2 h-full flex items-center rounded-s-xl" : seatOneOutcome === "loss" ? "bg-[#DC262666]/40 border border-red-500/50 -ms-2 -me-2 h-full flex items-center rounded-s-xl" : seatOneOutcome === "tie" ? "bg-[#0EA5E966] border border-sky-500/50 -ms-2 -me-2 h-full flex items-center rounded-s-xl" : ""}`}
                >
                  <OneVsOnePlayerInfo
                    player={seatOne}
                    outcome={seatOneOutcome}
                  />
                </div>
                <div className="col-span-2 text-center bg-[#2C2C2C] h-full aspect-square p-4 py-6 z-20">
                  {seatOneOutcome == "none" && seatTwoOutcome == "none" ? (
                    <span className="text-lg font-bold">VS</span>
                  ) : seatOneWins != seatTwoWins ? (
                    <span className="text-lg font-bold">
                      {seatOneWins} - {seatTwoWins}
                    </span>
                  ) : (
                    <span className="text-lg font-bold">TIE</span>
                  )}
                </div>
                <div
                  className={`col-span-5 ${seatTwoOutcome === "win" ? "bg-[#16A34A66] border border-green-500/50 -ms-2 -me-2 h-full flex justify-end items-center rounded-e-xl" : seatTwoOutcome === "loss" ? "bg-[#DC262666] border border-red-500/50 -ms-2 -me-2 h-full flex items-center justify-end rounded-e-xl" : seatTwoOutcome === "tie" ? "bg-[#0EA5E966] border border-sky-500/50 -ms-2 -me-2 h-full flex items-center justify-end rounded-e-xl" : ""}`}
                >
                  <OneVsOnePlayerInfo
                    isReversed
                    player={seatTwo}
                    outcome={seatTwoOutcome}
                  />
                </div>
              </div>
            </button>
          </ModalTrigger>
          <ManagePairingModal
            handleClose={handleClose}
            pairing={pairing}
            isPreview={isPreview}
          />
        </Modal>
      </li>
    </>
  );
};

export default OneVsOnePairingCard;
