import React from 'react';
import { Button } from "@cardeio/ui/components/ui/button";

const ButtonGroupSingleField = ({ data, evaluateConditions, field, formValues, invalidFields = [] }) => {
  const { options } = data;
  const { onChange, value } = field;

  const handleChange = (v) => {
    onChange(v);
  };

  return (
    <div className='flex gap-3 flex-wrap'>
      {options
        .filter((opt) => {
          if (opt.conditions) {
            return evaluateConditions(formValues, opt.conditions);
          } else {
            return true;
          }
        })
        .map((option) => (
          <Button
            className={`mb-2`}
            onClick={() => handleChange(option.value)}
            variant={value === option.value ? 'default' : invalidFields.length > 0 && invalidFields.includes(option.value) ? 'dotted-outline' : 'outline'}
            key={option.value}
            size="sm"
          >
            {option.label}
          </Button>
        ))}
    </div>
  );
}

export default ButtonGroupSingleField;
