import React, { useEffect, useState } from "react";
import { Form } from "@repo/ui/form";
import { get } from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@cardeio/ui/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@cardeio/ui/components/ui/dialog";

import EventDateTimeSection from "./EventDateTime";
import EventLocationSection from "./EventLocation";
import EventBasicDetailsSection from "./EventBasicDetails";
import EventAdditionalInfo from "./EventAdditionalInfo";
import { evaluateCondition, sortByOrder } from "@/lib/createEventHelpers";
import { useMemo } from "react";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { Button } from "@repo/ui/button";
import { Button as ShadcnButton } from "@cardeio/ui/components/ui/button";
import { toast } from "sonner";
import ActivityConfigContainer from "../ActivityConfiguration/ConfigContainer";

import { useRootStore } from "@/store/useRootStore";
import { useConfirmDialogStore } from "@/store/useConfirmDialogStore";

// Helper function to deduplicate objects in an array based on a key
const dedupObjects = (array, key) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = JSON.stringify(item[key]);
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
};

const getPowerOfTwoOptions = () => {
  const options = [];
  for (let i = 1; i <= 6; i++) {
    const value = Math.pow(2, i);
    options.push({
      label: value.toString(),
      value: value,
    });
  }
  return options;
};

const powerOfTwoOptions = getPowerOfTwoOptions();

const StepTwo = ({
  activeStep,
  form,
  game,
  hasPayoutEnabled,
  flow,
  prefilledFields,
  onClickPreFill,
  formFields,
}) => {
  const stepNumber = 2;
  const isActive = activeStep === stepNumber;
  const [activePreset, setActivePreset] = useState(null);
  const { establishment } = useRootStore();
  const { open: openConfirmDialog, close: closeConfirmDialog } =
    useConfirmDialogStore();

  const { setValue } = form;

  const checkRestrictions = (presetId) => {
    if (!game) return null;

    const checkLimits = (limits) => {
      if (!limits) return null;

      for (const limit of limits) {
        const condition = limit.conditions;

        // Check for date range restriction
        if (condition.all && Array.isArray(condition.all)) {
          const dateConditions = condition.all.filter(
            (c) => c.field === "startsAt"
          );
          if (dateConditions.length === 2) {
            const startDate = dateConditions.find(
              (c) => c.operator === "greaterThanEqual"
            )?.value;
            const endDate = dateConditions.find(
              (c) => c.operator === "lessThanEqual"
            )?.value;

            if (startDate && endDate) {
              const start = new Date(startDate);
              const end = new Date(endDate);
              // Set the form's start date to the restriction start date
              setValue("startsAt", start);
              setValue("endsAt", end);
              return {
                title: "This event type has restrictions",
                description: `${game.name} has specified that events can only be scheduled between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.

Your event has been pre-set to the earliest date; however, you may change it to another date within range.`,
              };
            }
          }
        }

        // Check single date restriction
        if (
          condition.field === "startsAt" &&
          condition.operator === "greaterThanEqual"
        ) {
          const restrictionDate = new Date(condition.value);
          // Set the form's start date to the restriction date
          setValue("startsAt", restrictionDate);
          setValue("endsAt", restrictionDate);
          return {
            title: "This event type has restrictions",
            description: `${game.name} has specified that ${condition.match?.startsAt || ""} events can only start on ${restrictionDate.toLocaleDateString()}.

Your event has been pre-set to the earliest date; however, you may change it to another date within range.`,
          };
        }

        // Check approved sets restriction
        if (condition.field === "additionalInfo.firstApprovedSet") {
          const approvedSets = condition.value;
          
          // Get the release date from the match condition if it exists
          if (limit.match?.startsAt?.lte) {
            const releaseDate = new Date(limit.match.startsAt.lte);
            // Set the start date to now or the site launch date (whichever is later)
            const startDate = new Date(Math.max(new Date().getTime(), new Date('2025-04-08T12:00:00.000Z').getTime()));
            setValue("startsAt", startDate);
            setValue("endsAt", releaseDate);
          }
          
          return {
            title: "This event type has restrictions",
            description: `${game.name} has specified that ${condition.match?.additionalInfo?.firstApprovedSet || ""} events can only use sets between ${approvedSets.join(" - ")}.

Your event has been pre-set to the earliest available date; however, you may change it to another date within range.`,
          };
        }
      }
      return null;
    };

    // Get the preset being checked
    const preset = prefilledFields?.find((field) => field.id === presetId);
    if (!preset) return null;

    // Check activity level restrictions
    if (preset.activityLevelId) {
      const activityLevel = game.activityLevels?.find(
        (level) => level.id === preset.activityLevelId
      );
      if (activityLevel?.limits) {
        const error = checkLimits(activityLevel.limits);
        if (error) return error;
      }
    }

    // Check game activity restrictions
    if (preset.gameActivityId) {
      const gameActivity = game.gameActivities?.find(
        (activity) => activity.id === preset.gameActivityId
      );
      if (gameActivity?.limits) {
        const error = checkLimits(gameActivity.limits);
        if (error) return error;
      }
    }

    // Check activity template restrictions
    if (preset.activityTemplateId) {
      const activityTemplate = game.activityTemplates?.find(
        (template) => template.id === preset.activityTemplateId
      );
      if (activityTemplate?.limits) {
        const error = checkLimits(activityTemplate.limits);
        if (error) return error;
      }
    }

    return null;
  };

  const handlePresetSelect = (presetId) => {
    const restriction = checkRestrictions(presetId);

    if (presetId === activePreset) {
      toast.success("Pre-set Removed!");
      setActivePreset(null);
      onClickPreFill(presetId);
      return;
    }

    if (restriction) {
      openConfirmDialog({
        title: restriction.title,
        description: restriction.description,
        confirmText: "Continue",
        onConfirm: () => {
          closeConfirmDialog();
          setActivePreset(presetId);
          onClickPreFill(presetId);
          toast.success("Pre-set Applied!");
        },
        typeToConfirmText: null,
      });
      return;
    }

    setActivePreset(presetId);
    onClickPreFill(presetId);
    toast.success("Pre-set Applied!");
  };

  // Initialize inPersonEvent as the opposite of isOnline when component mounts
  useEffect(() => {
    const isOnline = form.getValues("isOnline");
    if (isOnline !== undefined) {
      setValue("inPersonEvent", !isOnline);
    }

    // Set up a watch on inPersonEvent to keep isOnline in sync (for backward compatibility)
    const subscription = form.watch((value, { name, type }) => {
      // Skip if this is a recursive update (setValue from within this subscription)
      if (type === "change") {
        // Use a more targeted approach to prevent circular updates
        if (name === "inPersonEvent" && value.inPersonEvent !== undefined) {
          // Only update if the values are out of sync
          if (value.isOnline === value.inPersonEvent) {
            setValue("isOnline", !value.inPersonEvent);
          }
        } else if (name === "isOnline" && value.isOnline !== undefined) {
          // Only update if the values are out of sync
          if (value.inPersonEvent === value.isOnline) {
            setValue("inPersonEvent", !value.isOnline);
          }
        } else if (name === "isRecurring" && value.isRecurring !== undefined) {
          // Only update if the values are out of sync
          if (value.recurring !== value.isRecurring) {
            setValue("recurring", value.isRecurring);
          }
        } else if (name === "recurring" && value.recurring !== undefined) {
          // Only update if the values are out of sync
          if (value.isRecurring !== value.recurring) {
            setValue("isRecurring", value.recurring);
          }
        } else if (
          name === "recurringType" &&
          value.recurringType !== undefined
        ) {
          // Only update if the values are out of sync
          if (value.recurringInterval !== value.recurringType) {
            setValue("recurringInterval", value.recurringType);
          }
        } else if (
          name === "recurringInterval" &&
          value.recurringInterval !== undefined
        ) {
          // Only update if the values are out of sync
          if (value.recurringType !== value.recurringInterval) {
            setValue("recurringType", value.recurringInterval);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [form, setValue]);

  const fields = useMemo(() => {
    if (!flow?.fields) return [];
    const flowFields = { ...(flow?.fields || {}) };

    const advancesNextPhaseRaw =
      flowFields?.["Advanced Options"]?.[
        "configuration.tournaments.0.advancesNextPhase"
      ]?.[0];
    const advancesNextPhaseConfig =
      advancesNextPhaseRaw?.["configuration.tournaments.0.advancesNextPhase"];

    if (advancesNextPhaseConfig) {
      const newAdvanceNextOptions = {
        ...advancesNextPhaseConfig,
        options: dedupObjects(
          [
            ...(advancesNextPhaseConfig.options || []),
            {
              label: "None",
              value: { type: "topTotal", value: null },
            },
            ...powerOfTwoOptions.map((option) => ({
              label: option.label,
              value: { type: "topTotal", value: option.value },
            })),
          ],
          "value"
        ),
        visualization: "selectSingle",
      };

      const newRoundsOptions = {
        options: Array.from({ length: 21 }, (_, i) => ({
          label: !i ? "Dynamic" : `${i} Round${i === 1 ? "" : "s"}`,
          value: i,
        })),
        visualization: "selectSingle",
        default: 0,
        label: "Swiss Rounds",
      };
      // Only update if we have valid path
      if (
        flowFields?.["Advanced Options"]?.[
          "configuration.tournaments.0.advancesNextPhase"
        ]?.[0]
      ) {
        flowFields["Advanced Options"][
          "configuration.tournaments.0.advancesNextPhase"
        ][0]["configuration.tournaments.0.advancesNextPhase"] =
          newAdvanceNextOptions;
        flowFields["Advanced Options"]["configuration.tournaments.0.rounds"] = [
          {
            conditions: {
              all: dedupObjects(
                [
                  ...(advancesNextPhaseRaw.conditions.all || []),
                  {
                    field: "configuration.tournaments.0.tournamentFormat",
                    value: "swiss",
                    operator: "equal",
                  },
                ],
                "field"
              ),
            },
            "configuration.tournaments.0.rounds": newRoundsOptions,
          },
        ];
      }
    }

    return sortByOrder(Object.keys(flowFields), flow?.order || []).map(
      (key) => ({
        field: key,
        data: flowFields?.[key],
      })
    );
  }, [flow]);

  const formValues = form.watch();

  const showTogglePlaySetting = useMemo(() => {
    return formValues?.activityLevelId && formValues?.gameActivityId && evaluateCondition(formValues, flow?.fields?.["Advanced Options"]?.[
      "configuration.playSetting"
    ]?.[0]?.conditions);
  }, [formValues, flow]);


  // Keep the checkFieldsRef for form validation
  form.checkFieldsRef = form.checkFieldsRef || { current: [] };

  return (
    <div
      className={`${isActive ? "block animate-slideInFromBottom" : "hidden"} space-y-6`}
    >
      {prefilledFields?.length > 0 && (
        <FormCard
          title="Common Event Types"
          subtitle="Based on the game or your store settings, there are pre-build event templates you can use to auto-populate the event form. If you wish to save this current event as a template, you will have that option when it is time to publish."
        >
          <Table className="w-full border border-border">
            <TableHeader className="bg-background">
              <TableRow>
                <TableHead className="border-border border-s">
                  Common Event Types
                </TableHead>
                <TableHead className="border-border border-s">
                  Created by
                </TableHead>
                <TableHead className="border-border border-s text-right">
                  Actions
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {prefilledFields?.map((field) => (
                <TableRow key={field.id}>
                  <TableCell className="border-border border-s">
                    {field.activityName}
                  </TableCell>
                  <TableCell className="border-border border-s">
                    {field.source === "gameActivityPresets"
                      ? game?.name || "Game Publisher"
                      : field.source === "activityPresets"
                        ? establishment?.name || "Store"
                        : "Default"}
                  </TableCell>
                  <TableCell className="border-border border-s text-right">
                    <ShadcnButton
                      variant={
                        !activePreset || activePreset === field.id
                          ? "gradient"
                          : "secondary"
                      }
                      className={`rounded-full ${activePreset && activePreset !== field.id ? "bg-gray-700" : ""}`}
                      onClick={() => handlePresetSelect(field.id)}
                    >
                      {activePreset === field.id ? "Remove" : "Apply"}
                    </ShadcnButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FormCard>
      )}
      <Form {...form}>
        {flow && <ActivityConfigContainer fields={formFields} flow={flow} form={form} />}
        <EventLocationSection form={form} showToggle={showTogglePlaySetting} />
        <EventDateTimeSection form={form} game={game} flow={flow} />
        <EventBasicDetailsSection
          form={form}
          hasPayoutEnabled={hasPayoutEnabled}
        />

        <EventAdditionalInfo form={form} />
      </Form>
    </div>
  );
};

export default StepTwo;
