/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as VerifyEmailImport } from "./routes/verify-email";
import { Route as SilentLoginImport } from "./routes/silentLogin";
import { Route as LogoutImport } from "./routes/logout";
import { Route as LoginImport } from "./routes/login";
import { Route as AuthenticatedImport } from "./routes/_authenticated";
import { Route as AuthenticatedUserImport } from "./routes/_authenticated/user";
import { Route as AuthenticatedReleaseNotesImport } from "./routes/_authenticated/releaseNotes";
import { Route as AuthenticatedNotificationsImport } from "./routes/_authenticated/notifications";
import { Route as AuthenticatedOrganizerRouteImport } from "./routes/_authenticated/organizer/route";
import { Route as AuthenticatedOrgRouteImport } from "./routes/_authenticated/org/route";
import { Route as AuthenticatedEntityRouteImport } from "./routes/_authenticated/entity/route";
import { Route as AuthenticatedUserIndexImport } from "./routes/_authenticated/user/index";
import { Route as AuthenticatedOrganizerIndexImport } from "./routes/_authenticated/organizer/index";
import { Route as AuthenticatedAdminIndexImport } from "./routes/_authenticated/admin/index";
import { Route as AuthenticatedOrganizerMyStoresImport } from "./routes/_authenticated/organizer/my-stores";
import { Route as AuthenticatedOrganizerEventsImport } from "./routes/_authenticated/organizer/events";
import { Route as AuthenticatedToolsDistributionIndexImport } from "./routes/_authenticated/tools/distribution/index";
import { Route as AuthenticatedOrgOrgIdIndexImport } from "./routes/_authenticated/org/$orgId/index";
import { Route as AuthenticatedEntityEntityIdIndexImport } from "./routes/_authenticated/entity/$entityId/index";
import { Route as AuthenticatedEntityEntityIdPendingApplicationsRouteImport } from "./routes/_authenticated/entity/$entityId/pending-applications/route";
import { Route as AuthenticatedOrgOrgIdManageIndexImport } from "./routes/_authenticated/org/$orgId/manage/index";
import { Route as AuthenticatedEntityEntityIdUserIndexImport } from "./routes/_authenticated/entity/$entityId/user/index";
import { Route as AuthenticatedEntityEntityIdGamesIndexImport } from "./routes/_authenticated/entity/$entityId/games/index";
import { Route as AuthenticatedEntityEntityIdGameDirectoryIndexImport } from "./routes/_authenticated/entity/$entityId/gameDirectory/index";
import { Route as AuthenticatedEntityEntityIdEventsIndexImport } from "./routes/_authenticated/entity/$entityId/events/index";
import { Route as AuthenticatedEntityEntityIdAnalyticsIndexImport } from "./routes/_authenticated/entity/$entityId/analytics/index";
import { Route as AuthenticatedOrgOrgIdManageMembersImport } from "./routes/_authenticated/org/$orgId/manage/members";
import { Route as AuthenticatedOrgOrgIdManageFinancialsImport } from "./routes/_authenticated/org/$orgId/manage/financials";
import { Route as AuthenticatedEntityEntityIdManageFinancialsImport } from "./routes/_authenticated/entity/$entityId/manage/financials";
import { Route as AuthenticatedEntityEntityIdEventsCreateImport } from "./routes/_authenticated/entity/$entityId/events/create";
import { Route as AuthenticatedEntityEntityIdAnalyticsUsersImport } from "./routes/_authenticated/entity/$entityId/analytics/users";
import { Route as AuthenticatedEntityEntityIdAnalyticsTicketsImport } from "./routes/_authenticated/entity/$entityId/analytics/tickets";
import { Route as AuthenticatedToolsDistributionProductProductIdIndexImport } from "./routes/_authenticated/tools/distribution/product/$productId/index";
import { Route as AuthenticatedToolsDistributionBrandCategorySlugIndexImport } from "./routes/_authenticated/tools/distribution/brand/$categorySlug/index";
import { Route as AuthenticatedEntityEntityIdEventsEventIdIndexImport } from "./routes/_authenticated/entity/$entityId/events/$eventId/index";
import { Route as AuthenticatedEntityEntityIdEventsEventIdDraftIndexImport } from "./routes/_authenticated/entity/$entityId/events/$eventId/draft/index";
import { Route as AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexImport } from "./routes/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/index";

// Create Virtual Routes

const AboutLazyImport = createFileRoute("/about")();
const AuthenticatedIndexLazyImport = createFileRoute("/_authenticated/")();
const AuthenticatedEntityEntityIdStoreInformationIndexLazyImport =
  createFileRoute("/_authenticated/entity/$entityId/store-information/")();
const AuthenticatedEntityEntityIdManageIndexLazyImport = createFileRoute(
  "/_authenticated/entity/$entityId/manage/",
)();
const AuthenticatedEntityEntityIdManageStaffLazyImport = createFileRoute(
  "/_authenticated/entity/$entityId/manage/staff",
)();
const AuthenticatedEntityEntityIdManageOrganizersLazyImport = createFileRoute(
  "/_authenticated/entity/$entityId/manage/organizers",
)();

// Create/Update Routes

const AboutLazyRoute = AboutLazyImport.update({
  id: "/about",
  path: "/about",
  getParentRoute: () => rootRoute,
} as any).lazy(() => import("./routes/about.lazy").then((d) => d.Route));

const VerifyEmailRoute = VerifyEmailImport.update({
  id: "/verify-email",
  path: "/verify-email",
  getParentRoute: () => rootRoute,
} as any);

const SilentLoginRoute = SilentLoginImport.update({
  id: "/silentLogin",
  path: "/silentLogin",
  getParentRoute: () => rootRoute,
} as any);

const LogoutRoute = LogoutImport.update({
  id: "/logout",
  path: "/logout",
  getParentRoute: () => rootRoute,
} as any);

const LoginRoute = LoginImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any);

const AuthenticatedRoute = AuthenticatedImport.update({
  id: "/_authenticated",
  getParentRoute: () => rootRoute,
} as any);

const AuthenticatedIndexLazyRoute = AuthenticatedIndexLazyImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() =>
  import("./routes/_authenticated/index.lazy").then((d) => d.Route),
);

const AuthenticatedUserRoute = AuthenticatedUserImport.update({
  id: "/user",
  path: "/user",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedReleaseNotesRoute = AuthenticatedReleaseNotesImport.update({
  id: "/releaseNotes",
  path: "/releaseNotes",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedNotificationsRoute = AuthenticatedNotificationsImport.update(
  {
    id: "/notifications",
    path: "/notifications",
    getParentRoute: () => AuthenticatedRoute,
  } as any,
);

const AuthenticatedOrganizerRouteRoute =
  AuthenticatedOrganizerRouteImport.update({
    id: "/organizer",
    path: "/organizer",
    getParentRoute: () => AuthenticatedRoute,
  } as any);

const AuthenticatedOrgRouteRoute = AuthenticatedOrgRouteImport.update({
  id: "/org",
  path: "/org",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedEntityRouteRoute = AuthenticatedEntityRouteImport.update({
  id: "/entity",
  path: "/entity",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedUserIndexRoute = AuthenticatedUserIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedUserRoute,
} as any);

const AuthenticatedOrganizerIndexRoute =
  AuthenticatedOrganizerIndexImport.update({
    id: "/",
    path: "/",
    getParentRoute: () => AuthenticatedOrganizerRouteRoute,
  } as any);

const AuthenticatedAdminIndexRoute = AuthenticatedAdminIndexImport.update({
  id: "/admin/",
  path: "/admin/",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedOrganizerMyStoresRoute =
  AuthenticatedOrganizerMyStoresImport.update({
    id: "/my-stores",
    path: "/my-stores",
    getParentRoute: () => AuthenticatedOrganizerRouteRoute,
  } as any);

const AuthenticatedOrganizerEventsRoute =
  AuthenticatedOrganizerEventsImport.update({
    id: "/events",
    path: "/events",
    getParentRoute: () => AuthenticatedOrganizerRouteRoute,
  } as any);

const AuthenticatedToolsDistributionIndexRoute =
  AuthenticatedToolsDistributionIndexImport.update({
    id: "/tools/distribution/",
    path: "/tools/distribution/",
    getParentRoute: () => AuthenticatedRoute,
  } as any);

const AuthenticatedOrgOrgIdIndexRoute = AuthenticatedOrgOrgIdIndexImport.update(
  {
    id: "/$orgId/",
    path: "/$orgId/",
    getParentRoute: () => AuthenticatedOrgRouteRoute,
  } as any,
);

const AuthenticatedEntityEntityIdIndexRoute =
  AuthenticatedEntityEntityIdIndexImport.update({
    id: "/$entityId/",
    path: "/$entityId/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdPendingApplicationsRouteRoute =
  AuthenticatedEntityEntityIdPendingApplicationsRouteImport.update({
    id: "/$entityId/pending-applications",
    path: "/$entityId/pending-applications",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute =
  AuthenticatedEntityEntityIdStoreInformationIndexLazyImport.update({
    id: "/$entityId/store-information/",
    path: "/$entityId/store-information/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any).lazy(() =>
    import(
      "./routes/_authenticated/entity/$entityId/store-information/index.lazy"
    ).then((d) => d.Route),
  );

const AuthenticatedEntityEntityIdManageIndexLazyRoute =
  AuthenticatedEntityEntityIdManageIndexLazyImport.update({
    id: "/$entityId/manage/",
    path: "/$entityId/manage/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any).lazy(() =>
    import("./routes/_authenticated/entity/$entityId/manage/index.lazy").then(
      (d) => d.Route,
    ),
  );

const AuthenticatedOrgOrgIdManageIndexRoute =
  AuthenticatedOrgOrgIdManageIndexImport.update({
    id: "/$orgId/manage/",
    path: "/$orgId/manage/",
    getParentRoute: () => AuthenticatedOrgRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdUserIndexRoute =
  AuthenticatedEntityEntityIdUserIndexImport.update({
    id: "/$entityId/user/",
    path: "/$entityId/user/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdGamesIndexRoute =
  AuthenticatedEntityEntityIdGamesIndexImport.update({
    id: "/$entityId/games/",
    path: "/$entityId/games/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdGameDirectoryIndexRoute =
  AuthenticatedEntityEntityIdGameDirectoryIndexImport.update({
    id: "/$entityId/gameDirectory/",
    path: "/$entityId/gameDirectory/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdEventsIndexRoute =
  AuthenticatedEntityEntityIdEventsIndexImport.update({
    id: "/$entityId/events/",
    path: "/$entityId/events/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdAnalyticsIndexRoute =
  AuthenticatedEntityEntityIdAnalyticsIndexImport.update({
    id: "/$entityId/analytics/",
    path: "/$entityId/analytics/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdManageStaffLazyRoute =
  AuthenticatedEntityEntityIdManageStaffLazyImport.update({
    id: "/$entityId/manage/staff",
    path: "/$entityId/manage/staff",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any).lazy(() =>
    import("./routes/_authenticated/entity/$entityId/manage/staff.lazy").then(
      (d) => d.Route,
    ),
  );

const AuthenticatedEntityEntityIdManageOrganizersLazyRoute =
  AuthenticatedEntityEntityIdManageOrganizersLazyImport.update({
    id: "/$entityId/manage/organizers",
    path: "/$entityId/manage/organizers",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any).lazy(() =>
    import(
      "./routes/_authenticated/entity/$entityId/manage/organizers.lazy"
    ).then((d) => d.Route),
  );

const AuthenticatedOrgOrgIdManageMembersRoute =
  AuthenticatedOrgOrgIdManageMembersImport.update({
    id: "/$orgId/manage/members",
    path: "/$orgId/manage/members",
    getParentRoute: () => AuthenticatedOrgRouteRoute,
  } as any);

const AuthenticatedOrgOrgIdManageFinancialsRoute =
  AuthenticatedOrgOrgIdManageFinancialsImport.update({
    id: "/$orgId/manage/financials",
    path: "/$orgId/manage/financials",
    getParentRoute: () => AuthenticatedOrgRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdManageFinancialsRoute =
  AuthenticatedEntityEntityIdManageFinancialsImport.update({
    id: "/$entityId/manage/financials",
    path: "/$entityId/manage/financials",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdEventsCreateRoute =
  AuthenticatedEntityEntityIdEventsCreateImport.update({
    id: "/$entityId/events/create",
    path: "/$entityId/events/create",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdAnalyticsUsersRoute =
  AuthenticatedEntityEntityIdAnalyticsUsersImport.update({
    id: "/$entityId/analytics/users",
    path: "/$entityId/analytics/users",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdAnalyticsTicketsRoute =
  AuthenticatedEntityEntityIdAnalyticsTicketsImport.update({
    id: "/$entityId/analytics/tickets",
    path: "/$entityId/analytics/tickets",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedToolsDistributionProductProductIdIndexRoute =
  AuthenticatedToolsDistributionProductProductIdIndexImport.update({
    id: "/tools/distribution/product/$productId/",
    path: "/tools/distribution/product/$productId/",
    getParentRoute: () => AuthenticatedRoute,
  } as any);

const AuthenticatedToolsDistributionBrandCategorySlugIndexRoute =
  AuthenticatedToolsDistributionBrandCategorySlugIndexImport.update({
    id: "/tools/distribution/brand/$categorySlug/",
    path: "/tools/distribution/brand/$categorySlug/",
    getParentRoute: () => AuthenticatedRoute,
  } as any);

const AuthenticatedEntityEntityIdEventsEventIdIndexRoute =
  AuthenticatedEntityEntityIdEventsEventIdIndexImport.update({
    id: "/$entityId/events/$eventId/",
    path: "/$entityId/events/$eventId/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute =
  AuthenticatedEntityEntityIdEventsEventIdDraftIndexImport.update({
    id: "/$entityId/events/$eventId/draft/",
    path: "/$entityId/events/$eventId/draft/",
    getParentRoute: () => AuthenticatedEntityRouteRoute,
  } as any);

const AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute =
  AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexImport.update(
    {
      id: "/$entityId/events/$eventId/activity/$activityId/timer/",
      path: "/$entityId/events/$eventId/activity/$activityId/timer/",
      getParentRoute: () => AuthenticatedEntityRouteRoute,
    } as any,
  );

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/_authenticated": {
      id: "/_authenticated";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AuthenticatedImport;
      parentRoute: typeof rootRoute;
    };
    "/login": {
      id: "/login";
      path: "/login";
      fullPath: "/login";
      preLoaderRoute: typeof LoginImport;
      parentRoute: typeof rootRoute;
    };
    "/logout": {
      id: "/logout";
      path: "/logout";
      fullPath: "/logout";
      preLoaderRoute: typeof LogoutImport;
      parentRoute: typeof rootRoute;
    };
    "/silentLogin": {
      id: "/silentLogin";
      path: "/silentLogin";
      fullPath: "/silentLogin";
      preLoaderRoute: typeof SilentLoginImport;
      parentRoute: typeof rootRoute;
    };
    "/verify-email": {
      id: "/verify-email";
      path: "/verify-email";
      fullPath: "/verify-email";
      preLoaderRoute: typeof VerifyEmailImport;
      parentRoute: typeof rootRoute;
    };
    "/about": {
      id: "/about";
      path: "/about";
      fullPath: "/about";
      preLoaderRoute: typeof AboutLazyImport;
      parentRoute: typeof rootRoute;
    };
    "/_authenticated/entity": {
      id: "/_authenticated/entity";
      path: "/entity";
      fullPath: "/entity";
      preLoaderRoute: typeof AuthenticatedEntityRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/org": {
      id: "/_authenticated/org";
      path: "/org";
      fullPath: "/org";
      preLoaderRoute: typeof AuthenticatedOrgRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/organizer": {
      id: "/_authenticated/organizer";
      path: "/organizer";
      fullPath: "/organizer";
      preLoaderRoute: typeof AuthenticatedOrganizerRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/notifications": {
      id: "/_authenticated/notifications";
      path: "/notifications";
      fullPath: "/notifications";
      preLoaderRoute: typeof AuthenticatedNotificationsImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/releaseNotes": {
      id: "/_authenticated/releaseNotes";
      path: "/releaseNotes";
      fullPath: "/releaseNotes";
      preLoaderRoute: typeof AuthenticatedReleaseNotesImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/user": {
      id: "/_authenticated/user";
      path: "/user";
      fullPath: "/user";
      preLoaderRoute: typeof AuthenticatedUserImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/": {
      id: "/_authenticated/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof AuthenticatedIndexLazyImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/organizer/events": {
      id: "/_authenticated/organizer/events";
      path: "/events";
      fullPath: "/organizer/events";
      preLoaderRoute: typeof AuthenticatedOrganizerEventsImport;
      parentRoute: typeof AuthenticatedOrganizerRouteImport;
    };
    "/_authenticated/organizer/my-stores": {
      id: "/_authenticated/organizer/my-stores";
      path: "/my-stores";
      fullPath: "/organizer/my-stores";
      preLoaderRoute: typeof AuthenticatedOrganizerMyStoresImport;
      parentRoute: typeof AuthenticatedOrganizerRouteImport;
    };
    "/_authenticated/admin/": {
      id: "/_authenticated/admin/";
      path: "/admin";
      fullPath: "/admin";
      preLoaderRoute: typeof AuthenticatedAdminIndexImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/organizer/": {
      id: "/_authenticated/organizer/";
      path: "/";
      fullPath: "/organizer/";
      preLoaderRoute: typeof AuthenticatedOrganizerIndexImport;
      parentRoute: typeof AuthenticatedOrganizerRouteImport;
    };
    "/_authenticated/user/": {
      id: "/_authenticated/user/";
      path: "/";
      fullPath: "/user/";
      preLoaderRoute: typeof AuthenticatedUserIndexImport;
      parentRoute: typeof AuthenticatedUserImport;
    };
    "/_authenticated/entity/$entityId/pending-applications": {
      id: "/_authenticated/entity/$entityId/pending-applications";
      path: "/$entityId/pending-applications";
      fullPath: "/entity/$entityId/pending-applications";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdPendingApplicationsRouteImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/": {
      id: "/_authenticated/entity/$entityId/";
      path: "/$entityId";
      fullPath: "/entity/$entityId";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/org/$orgId/": {
      id: "/_authenticated/org/$orgId/";
      path: "/$orgId";
      fullPath: "/org/$orgId";
      preLoaderRoute: typeof AuthenticatedOrgOrgIdIndexImport;
      parentRoute: typeof AuthenticatedOrgRouteImport;
    };
    "/_authenticated/tools/distribution/": {
      id: "/_authenticated/tools/distribution/";
      path: "/tools/distribution";
      fullPath: "/tools/distribution";
      preLoaderRoute: typeof AuthenticatedToolsDistributionIndexImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/entity/$entityId/analytics/tickets": {
      id: "/_authenticated/entity/$entityId/analytics/tickets";
      path: "/$entityId/analytics/tickets";
      fullPath: "/entity/$entityId/analytics/tickets";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdAnalyticsTicketsImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/analytics/users": {
      id: "/_authenticated/entity/$entityId/analytics/users";
      path: "/$entityId/analytics/users";
      fullPath: "/entity/$entityId/analytics/users";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdAnalyticsUsersImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/events/create": {
      id: "/_authenticated/entity/$entityId/events/create";
      path: "/$entityId/events/create";
      fullPath: "/entity/$entityId/events/create";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdEventsCreateImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/manage/financials": {
      id: "/_authenticated/entity/$entityId/manage/financials";
      path: "/$entityId/manage/financials";
      fullPath: "/entity/$entityId/manage/financials";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdManageFinancialsImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/org/$orgId/manage/financials": {
      id: "/_authenticated/org/$orgId/manage/financials";
      path: "/$orgId/manage/financials";
      fullPath: "/org/$orgId/manage/financials";
      preLoaderRoute: typeof AuthenticatedOrgOrgIdManageFinancialsImport;
      parentRoute: typeof AuthenticatedOrgRouteImport;
    };
    "/_authenticated/org/$orgId/manage/members": {
      id: "/_authenticated/org/$orgId/manage/members";
      path: "/$orgId/manage/members";
      fullPath: "/org/$orgId/manage/members";
      preLoaderRoute: typeof AuthenticatedOrgOrgIdManageMembersImport;
      parentRoute: typeof AuthenticatedOrgRouteImport;
    };
    "/_authenticated/entity/$entityId/manage/organizers": {
      id: "/_authenticated/entity/$entityId/manage/organizers";
      path: "/$entityId/manage/organizers";
      fullPath: "/entity/$entityId/manage/organizers";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdManageOrganizersLazyImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/manage/staff": {
      id: "/_authenticated/entity/$entityId/manage/staff";
      path: "/$entityId/manage/staff";
      fullPath: "/entity/$entityId/manage/staff";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdManageStaffLazyImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/analytics/": {
      id: "/_authenticated/entity/$entityId/analytics/";
      path: "/$entityId/analytics";
      fullPath: "/entity/$entityId/analytics";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdAnalyticsIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/events/": {
      id: "/_authenticated/entity/$entityId/events/";
      path: "/$entityId/events";
      fullPath: "/entity/$entityId/events";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdEventsIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/gameDirectory/": {
      id: "/_authenticated/entity/$entityId/gameDirectory/";
      path: "/$entityId/gameDirectory";
      fullPath: "/entity/$entityId/gameDirectory";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdGameDirectoryIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/games/": {
      id: "/_authenticated/entity/$entityId/games/";
      path: "/$entityId/games";
      fullPath: "/entity/$entityId/games";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdGamesIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/user/": {
      id: "/_authenticated/entity/$entityId/user/";
      path: "/$entityId/user";
      fullPath: "/entity/$entityId/user";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdUserIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/org/$orgId/manage/": {
      id: "/_authenticated/org/$orgId/manage/";
      path: "/$orgId/manage";
      fullPath: "/org/$orgId/manage";
      preLoaderRoute: typeof AuthenticatedOrgOrgIdManageIndexImport;
      parentRoute: typeof AuthenticatedOrgRouteImport;
    };
    "/_authenticated/entity/$entityId/manage/": {
      id: "/_authenticated/entity/$entityId/manage/";
      path: "/$entityId/manage";
      fullPath: "/entity/$entityId/manage";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdManageIndexLazyImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/store-information/": {
      id: "/_authenticated/entity/$entityId/store-information/";
      path: "/$entityId/store-information";
      fullPath: "/entity/$entityId/store-information";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdStoreInformationIndexLazyImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/events/$eventId/": {
      id: "/_authenticated/entity/$entityId/events/$eventId/";
      path: "/$entityId/events/$eventId";
      fullPath: "/entity/$entityId/events/$eventId";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdEventsEventIdIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/tools/distribution/brand/$categorySlug/": {
      id: "/_authenticated/tools/distribution/brand/$categorySlug/";
      path: "/tools/distribution/brand/$categorySlug";
      fullPath: "/tools/distribution/brand/$categorySlug";
      preLoaderRoute: typeof AuthenticatedToolsDistributionBrandCategorySlugIndexImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/tools/distribution/product/$productId/": {
      id: "/_authenticated/tools/distribution/product/$productId/";
      path: "/tools/distribution/product/$productId";
      fullPath: "/tools/distribution/product/$productId";
      preLoaderRoute: typeof AuthenticatedToolsDistributionProductProductIdIndexImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/entity/$entityId/events/$eventId/draft/": {
      id: "/_authenticated/entity/$entityId/events/$eventId/draft/";
      path: "/$entityId/events/$eventId/draft";
      fullPath: "/entity/$entityId/events/$eventId/draft";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdEventsEventIdDraftIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
    "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/": {
      id: "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/";
      path: "/$entityId/events/$eventId/activity/$activityId/timer";
      fullPath: "/entity/$entityId/events/$eventId/activity/$activityId/timer";
      preLoaderRoute: typeof AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexImport;
      parentRoute: typeof AuthenticatedEntityRouteImport;
    };
  }
}

// Create and export the route tree

interface AuthenticatedEntityRouteRouteChildren {
  AuthenticatedEntityEntityIdPendingApplicationsRouteRoute: typeof AuthenticatedEntityEntityIdPendingApplicationsRouteRoute;
  AuthenticatedEntityEntityIdIndexRoute: typeof AuthenticatedEntityEntityIdIndexRoute;
  AuthenticatedEntityEntityIdAnalyticsTicketsRoute: typeof AuthenticatedEntityEntityIdAnalyticsTicketsRoute;
  AuthenticatedEntityEntityIdAnalyticsUsersRoute: typeof AuthenticatedEntityEntityIdAnalyticsUsersRoute;
  AuthenticatedEntityEntityIdEventsCreateRoute: typeof AuthenticatedEntityEntityIdEventsCreateRoute;
  AuthenticatedEntityEntityIdManageFinancialsRoute: typeof AuthenticatedEntityEntityIdManageFinancialsRoute;
  AuthenticatedEntityEntityIdManageOrganizersLazyRoute: typeof AuthenticatedEntityEntityIdManageOrganizersLazyRoute;
  AuthenticatedEntityEntityIdManageStaffLazyRoute: typeof AuthenticatedEntityEntityIdManageStaffLazyRoute;
  AuthenticatedEntityEntityIdAnalyticsIndexRoute: typeof AuthenticatedEntityEntityIdAnalyticsIndexRoute;
  AuthenticatedEntityEntityIdEventsIndexRoute: typeof AuthenticatedEntityEntityIdEventsIndexRoute;
  AuthenticatedEntityEntityIdGameDirectoryIndexRoute: typeof AuthenticatedEntityEntityIdGameDirectoryIndexRoute;
  AuthenticatedEntityEntityIdGamesIndexRoute: typeof AuthenticatedEntityEntityIdGamesIndexRoute;
  AuthenticatedEntityEntityIdUserIndexRoute: typeof AuthenticatedEntityEntityIdUserIndexRoute;
  AuthenticatedEntityEntityIdManageIndexLazyRoute: typeof AuthenticatedEntityEntityIdManageIndexLazyRoute;
  AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute: typeof AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute;
  AuthenticatedEntityEntityIdEventsEventIdIndexRoute: typeof AuthenticatedEntityEntityIdEventsEventIdIndexRoute;
  AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute: typeof AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute;
  AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute: typeof AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute;
}

const AuthenticatedEntityRouteRouteChildren: AuthenticatedEntityRouteRouteChildren =
  {
    AuthenticatedEntityEntityIdPendingApplicationsRouteRoute:
      AuthenticatedEntityEntityIdPendingApplicationsRouteRoute,
    AuthenticatedEntityEntityIdIndexRoute:
      AuthenticatedEntityEntityIdIndexRoute,
    AuthenticatedEntityEntityIdAnalyticsTicketsRoute:
      AuthenticatedEntityEntityIdAnalyticsTicketsRoute,
    AuthenticatedEntityEntityIdAnalyticsUsersRoute:
      AuthenticatedEntityEntityIdAnalyticsUsersRoute,
    AuthenticatedEntityEntityIdEventsCreateRoute:
      AuthenticatedEntityEntityIdEventsCreateRoute,
    AuthenticatedEntityEntityIdManageFinancialsRoute:
      AuthenticatedEntityEntityIdManageFinancialsRoute,
    AuthenticatedEntityEntityIdManageOrganizersLazyRoute:
      AuthenticatedEntityEntityIdManageOrganizersLazyRoute,
    AuthenticatedEntityEntityIdManageStaffLazyRoute:
      AuthenticatedEntityEntityIdManageStaffLazyRoute,
    AuthenticatedEntityEntityIdAnalyticsIndexRoute:
      AuthenticatedEntityEntityIdAnalyticsIndexRoute,
    AuthenticatedEntityEntityIdEventsIndexRoute:
      AuthenticatedEntityEntityIdEventsIndexRoute,
    AuthenticatedEntityEntityIdGameDirectoryIndexRoute:
      AuthenticatedEntityEntityIdGameDirectoryIndexRoute,
    AuthenticatedEntityEntityIdGamesIndexRoute:
      AuthenticatedEntityEntityIdGamesIndexRoute,
    AuthenticatedEntityEntityIdUserIndexRoute:
      AuthenticatedEntityEntityIdUserIndexRoute,
    AuthenticatedEntityEntityIdManageIndexLazyRoute:
      AuthenticatedEntityEntityIdManageIndexLazyRoute,
    AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute:
      AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute,
    AuthenticatedEntityEntityIdEventsEventIdIndexRoute:
      AuthenticatedEntityEntityIdEventsEventIdIndexRoute,
    AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute:
      AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute,
    AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute:
      AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute,
  };

const AuthenticatedEntityRouteRouteWithChildren =
  AuthenticatedEntityRouteRoute._addFileChildren(
    AuthenticatedEntityRouteRouteChildren,
  );

interface AuthenticatedOrgRouteRouteChildren {
  AuthenticatedOrgOrgIdIndexRoute: typeof AuthenticatedOrgOrgIdIndexRoute;
  AuthenticatedOrgOrgIdManageFinancialsRoute: typeof AuthenticatedOrgOrgIdManageFinancialsRoute;
  AuthenticatedOrgOrgIdManageMembersRoute: typeof AuthenticatedOrgOrgIdManageMembersRoute;
  AuthenticatedOrgOrgIdManageIndexRoute: typeof AuthenticatedOrgOrgIdManageIndexRoute;
}

const AuthenticatedOrgRouteRouteChildren: AuthenticatedOrgRouteRouteChildren = {
  AuthenticatedOrgOrgIdIndexRoute: AuthenticatedOrgOrgIdIndexRoute,
  AuthenticatedOrgOrgIdManageFinancialsRoute:
    AuthenticatedOrgOrgIdManageFinancialsRoute,
  AuthenticatedOrgOrgIdManageMembersRoute:
    AuthenticatedOrgOrgIdManageMembersRoute,
  AuthenticatedOrgOrgIdManageIndexRoute: AuthenticatedOrgOrgIdManageIndexRoute,
};

const AuthenticatedOrgRouteRouteWithChildren =
  AuthenticatedOrgRouteRoute._addFileChildren(
    AuthenticatedOrgRouteRouteChildren,
  );

interface AuthenticatedOrganizerRouteRouteChildren {
  AuthenticatedOrganizerEventsRoute: typeof AuthenticatedOrganizerEventsRoute;
  AuthenticatedOrganizerMyStoresRoute: typeof AuthenticatedOrganizerMyStoresRoute;
  AuthenticatedOrganizerIndexRoute: typeof AuthenticatedOrganizerIndexRoute;
}

const AuthenticatedOrganizerRouteRouteChildren: AuthenticatedOrganizerRouteRouteChildren =
  {
    AuthenticatedOrganizerEventsRoute: AuthenticatedOrganizerEventsRoute,
    AuthenticatedOrganizerMyStoresRoute: AuthenticatedOrganizerMyStoresRoute,
    AuthenticatedOrganizerIndexRoute: AuthenticatedOrganizerIndexRoute,
  };

const AuthenticatedOrganizerRouteRouteWithChildren =
  AuthenticatedOrganizerRouteRoute._addFileChildren(
    AuthenticatedOrganizerRouteRouteChildren,
  );

interface AuthenticatedUserRouteChildren {
  AuthenticatedUserIndexRoute: typeof AuthenticatedUserIndexRoute;
}

const AuthenticatedUserRouteChildren: AuthenticatedUserRouteChildren = {
  AuthenticatedUserIndexRoute: AuthenticatedUserIndexRoute,
};

const AuthenticatedUserRouteWithChildren =
  AuthenticatedUserRoute._addFileChildren(AuthenticatedUserRouteChildren);

interface AuthenticatedRouteChildren {
  AuthenticatedEntityRouteRoute: typeof AuthenticatedEntityRouteRouteWithChildren;
  AuthenticatedOrgRouteRoute: typeof AuthenticatedOrgRouteRouteWithChildren;
  AuthenticatedOrganizerRouteRoute: typeof AuthenticatedOrganizerRouteRouteWithChildren;
  AuthenticatedNotificationsRoute: typeof AuthenticatedNotificationsRoute;
  AuthenticatedReleaseNotesRoute: typeof AuthenticatedReleaseNotesRoute;
  AuthenticatedUserRoute: typeof AuthenticatedUserRouteWithChildren;
  AuthenticatedIndexLazyRoute: typeof AuthenticatedIndexLazyRoute;
  AuthenticatedAdminIndexRoute: typeof AuthenticatedAdminIndexRoute;
  AuthenticatedToolsDistributionIndexRoute: typeof AuthenticatedToolsDistributionIndexRoute;
  AuthenticatedToolsDistributionBrandCategorySlugIndexRoute: typeof AuthenticatedToolsDistributionBrandCategorySlugIndexRoute;
  AuthenticatedToolsDistributionProductProductIdIndexRoute: typeof AuthenticatedToolsDistributionProductProductIdIndexRoute;
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedEntityRouteRoute: AuthenticatedEntityRouteRouteWithChildren,
  AuthenticatedOrgRouteRoute: AuthenticatedOrgRouteRouteWithChildren,
  AuthenticatedOrganizerRouteRoute:
    AuthenticatedOrganizerRouteRouteWithChildren,
  AuthenticatedNotificationsRoute: AuthenticatedNotificationsRoute,
  AuthenticatedReleaseNotesRoute: AuthenticatedReleaseNotesRoute,
  AuthenticatedUserRoute: AuthenticatedUserRouteWithChildren,
  AuthenticatedIndexLazyRoute: AuthenticatedIndexLazyRoute,
  AuthenticatedAdminIndexRoute: AuthenticatedAdminIndexRoute,
  AuthenticatedToolsDistributionIndexRoute:
    AuthenticatedToolsDistributionIndexRoute,
  AuthenticatedToolsDistributionBrandCategorySlugIndexRoute:
    AuthenticatedToolsDistributionBrandCategorySlugIndexRoute,
  AuthenticatedToolsDistributionProductProductIdIndexRoute:
    AuthenticatedToolsDistributionProductProductIdIndexRoute,
};

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
);

export interface FileRoutesByFullPath {
  "": typeof AuthenticatedRouteWithChildren;
  "/login": typeof LoginRoute;
  "/logout": typeof LogoutRoute;
  "/silentLogin": typeof SilentLoginRoute;
  "/verify-email": typeof VerifyEmailRoute;
  "/about": typeof AboutLazyRoute;
  "/entity": typeof AuthenticatedEntityRouteRouteWithChildren;
  "/org": typeof AuthenticatedOrgRouteRouteWithChildren;
  "/organizer": typeof AuthenticatedOrganizerRouteRouteWithChildren;
  "/notifications": typeof AuthenticatedNotificationsRoute;
  "/releaseNotes": typeof AuthenticatedReleaseNotesRoute;
  "/user": typeof AuthenticatedUserRouteWithChildren;
  "/": typeof AuthenticatedIndexLazyRoute;
  "/organizer/events": typeof AuthenticatedOrganizerEventsRoute;
  "/organizer/my-stores": typeof AuthenticatedOrganizerMyStoresRoute;
  "/admin": typeof AuthenticatedAdminIndexRoute;
  "/organizer/": typeof AuthenticatedOrganizerIndexRoute;
  "/user/": typeof AuthenticatedUserIndexRoute;
  "/entity/$entityId/pending-applications": typeof AuthenticatedEntityEntityIdPendingApplicationsRouteRoute;
  "/entity/$entityId": typeof AuthenticatedEntityEntityIdIndexRoute;
  "/org/$orgId": typeof AuthenticatedOrgOrgIdIndexRoute;
  "/tools/distribution": typeof AuthenticatedToolsDistributionIndexRoute;
  "/entity/$entityId/analytics/tickets": typeof AuthenticatedEntityEntityIdAnalyticsTicketsRoute;
  "/entity/$entityId/analytics/users": typeof AuthenticatedEntityEntityIdAnalyticsUsersRoute;
  "/entity/$entityId/events/create": typeof AuthenticatedEntityEntityIdEventsCreateRoute;
  "/entity/$entityId/manage/financials": typeof AuthenticatedEntityEntityIdManageFinancialsRoute;
  "/org/$orgId/manage/financials": typeof AuthenticatedOrgOrgIdManageFinancialsRoute;
  "/org/$orgId/manage/members": typeof AuthenticatedOrgOrgIdManageMembersRoute;
  "/entity/$entityId/manage/organizers": typeof AuthenticatedEntityEntityIdManageOrganizersLazyRoute;
  "/entity/$entityId/manage/staff": typeof AuthenticatedEntityEntityIdManageStaffLazyRoute;
  "/entity/$entityId/analytics": typeof AuthenticatedEntityEntityIdAnalyticsIndexRoute;
  "/entity/$entityId/events": typeof AuthenticatedEntityEntityIdEventsIndexRoute;
  "/entity/$entityId/gameDirectory": typeof AuthenticatedEntityEntityIdGameDirectoryIndexRoute;
  "/entity/$entityId/games": typeof AuthenticatedEntityEntityIdGamesIndexRoute;
  "/entity/$entityId/user": typeof AuthenticatedEntityEntityIdUserIndexRoute;
  "/org/$orgId/manage": typeof AuthenticatedOrgOrgIdManageIndexRoute;
  "/entity/$entityId/manage": typeof AuthenticatedEntityEntityIdManageIndexLazyRoute;
  "/entity/$entityId/store-information": typeof AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute;
  "/entity/$entityId/events/$eventId": typeof AuthenticatedEntityEntityIdEventsEventIdIndexRoute;
  "/tools/distribution/brand/$categorySlug": typeof AuthenticatedToolsDistributionBrandCategorySlugIndexRoute;
  "/tools/distribution/product/$productId": typeof AuthenticatedToolsDistributionProductProductIdIndexRoute;
  "/entity/$entityId/events/$eventId/draft": typeof AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute;
  "/entity/$entityId/events/$eventId/activity/$activityId/timer": typeof AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute;
}

export interface FileRoutesByTo {
  "/login": typeof LoginRoute;
  "/logout": typeof LogoutRoute;
  "/silentLogin": typeof SilentLoginRoute;
  "/verify-email": typeof VerifyEmailRoute;
  "/about": typeof AboutLazyRoute;
  "/entity": typeof AuthenticatedEntityRouteRouteWithChildren;
  "/org": typeof AuthenticatedOrgRouteRouteWithChildren;
  "/notifications": typeof AuthenticatedNotificationsRoute;
  "/releaseNotes": typeof AuthenticatedReleaseNotesRoute;
  "/": typeof AuthenticatedIndexLazyRoute;
  "/organizer/events": typeof AuthenticatedOrganizerEventsRoute;
  "/organizer/my-stores": typeof AuthenticatedOrganizerMyStoresRoute;
  "/admin": typeof AuthenticatedAdminIndexRoute;
  "/organizer": typeof AuthenticatedOrganizerIndexRoute;
  "/user": typeof AuthenticatedUserIndexRoute;
  "/entity/$entityId/pending-applications": typeof AuthenticatedEntityEntityIdPendingApplicationsRouteRoute;
  "/entity/$entityId": typeof AuthenticatedEntityEntityIdIndexRoute;
  "/org/$orgId": typeof AuthenticatedOrgOrgIdIndexRoute;
  "/tools/distribution": typeof AuthenticatedToolsDistributionIndexRoute;
  "/entity/$entityId/analytics/tickets": typeof AuthenticatedEntityEntityIdAnalyticsTicketsRoute;
  "/entity/$entityId/analytics/users": typeof AuthenticatedEntityEntityIdAnalyticsUsersRoute;
  "/entity/$entityId/events/create": typeof AuthenticatedEntityEntityIdEventsCreateRoute;
  "/entity/$entityId/manage/financials": typeof AuthenticatedEntityEntityIdManageFinancialsRoute;
  "/org/$orgId/manage/financials": typeof AuthenticatedOrgOrgIdManageFinancialsRoute;
  "/org/$orgId/manage/members": typeof AuthenticatedOrgOrgIdManageMembersRoute;
  "/entity/$entityId/manage/organizers": typeof AuthenticatedEntityEntityIdManageOrganizersLazyRoute;
  "/entity/$entityId/manage/staff": typeof AuthenticatedEntityEntityIdManageStaffLazyRoute;
  "/entity/$entityId/analytics": typeof AuthenticatedEntityEntityIdAnalyticsIndexRoute;
  "/entity/$entityId/events": typeof AuthenticatedEntityEntityIdEventsIndexRoute;
  "/entity/$entityId/gameDirectory": typeof AuthenticatedEntityEntityIdGameDirectoryIndexRoute;
  "/entity/$entityId/games": typeof AuthenticatedEntityEntityIdGamesIndexRoute;
  "/entity/$entityId/user": typeof AuthenticatedEntityEntityIdUserIndexRoute;
  "/org/$orgId/manage": typeof AuthenticatedOrgOrgIdManageIndexRoute;
  "/entity/$entityId/manage": typeof AuthenticatedEntityEntityIdManageIndexLazyRoute;
  "/entity/$entityId/store-information": typeof AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute;
  "/entity/$entityId/events/$eventId": typeof AuthenticatedEntityEntityIdEventsEventIdIndexRoute;
  "/tools/distribution/brand/$categorySlug": typeof AuthenticatedToolsDistributionBrandCategorySlugIndexRoute;
  "/tools/distribution/product/$productId": typeof AuthenticatedToolsDistributionProductProductIdIndexRoute;
  "/entity/$entityId/events/$eventId/draft": typeof AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute;
  "/entity/$entityId/events/$eventId/activity/$activityId/timer": typeof AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/_authenticated": typeof AuthenticatedRouteWithChildren;
  "/login": typeof LoginRoute;
  "/logout": typeof LogoutRoute;
  "/silentLogin": typeof SilentLoginRoute;
  "/verify-email": typeof VerifyEmailRoute;
  "/about": typeof AboutLazyRoute;
  "/_authenticated/entity": typeof AuthenticatedEntityRouteRouteWithChildren;
  "/_authenticated/org": typeof AuthenticatedOrgRouteRouteWithChildren;
  "/_authenticated/organizer": typeof AuthenticatedOrganizerRouteRouteWithChildren;
  "/_authenticated/notifications": typeof AuthenticatedNotificationsRoute;
  "/_authenticated/releaseNotes": typeof AuthenticatedReleaseNotesRoute;
  "/_authenticated/user": typeof AuthenticatedUserRouteWithChildren;
  "/_authenticated/": typeof AuthenticatedIndexLazyRoute;
  "/_authenticated/organizer/events": typeof AuthenticatedOrganizerEventsRoute;
  "/_authenticated/organizer/my-stores": typeof AuthenticatedOrganizerMyStoresRoute;
  "/_authenticated/admin/": typeof AuthenticatedAdminIndexRoute;
  "/_authenticated/organizer/": typeof AuthenticatedOrganizerIndexRoute;
  "/_authenticated/user/": typeof AuthenticatedUserIndexRoute;
  "/_authenticated/entity/$entityId/pending-applications": typeof AuthenticatedEntityEntityIdPendingApplicationsRouteRoute;
  "/_authenticated/entity/$entityId/": typeof AuthenticatedEntityEntityIdIndexRoute;
  "/_authenticated/org/$orgId/": typeof AuthenticatedOrgOrgIdIndexRoute;
  "/_authenticated/tools/distribution/": typeof AuthenticatedToolsDistributionIndexRoute;
  "/_authenticated/entity/$entityId/analytics/tickets": typeof AuthenticatedEntityEntityIdAnalyticsTicketsRoute;
  "/_authenticated/entity/$entityId/analytics/users": typeof AuthenticatedEntityEntityIdAnalyticsUsersRoute;
  "/_authenticated/entity/$entityId/events/create": typeof AuthenticatedEntityEntityIdEventsCreateRoute;
  "/_authenticated/entity/$entityId/manage/financials": typeof AuthenticatedEntityEntityIdManageFinancialsRoute;
  "/_authenticated/org/$orgId/manage/financials": typeof AuthenticatedOrgOrgIdManageFinancialsRoute;
  "/_authenticated/org/$orgId/manage/members": typeof AuthenticatedOrgOrgIdManageMembersRoute;
  "/_authenticated/entity/$entityId/manage/organizers": typeof AuthenticatedEntityEntityIdManageOrganizersLazyRoute;
  "/_authenticated/entity/$entityId/manage/staff": typeof AuthenticatedEntityEntityIdManageStaffLazyRoute;
  "/_authenticated/entity/$entityId/analytics/": typeof AuthenticatedEntityEntityIdAnalyticsIndexRoute;
  "/_authenticated/entity/$entityId/events/": typeof AuthenticatedEntityEntityIdEventsIndexRoute;
  "/_authenticated/entity/$entityId/gameDirectory/": typeof AuthenticatedEntityEntityIdGameDirectoryIndexRoute;
  "/_authenticated/entity/$entityId/games/": typeof AuthenticatedEntityEntityIdGamesIndexRoute;
  "/_authenticated/entity/$entityId/user/": typeof AuthenticatedEntityEntityIdUserIndexRoute;
  "/_authenticated/org/$orgId/manage/": typeof AuthenticatedOrgOrgIdManageIndexRoute;
  "/_authenticated/entity/$entityId/manage/": typeof AuthenticatedEntityEntityIdManageIndexLazyRoute;
  "/_authenticated/entity/$entityId/store-information/": typeof AuthenticatedEntityEntityIdStoreInformationIndexLazyRoute;
  "/_authenticated/entity/$entityId/events/$eventId/": typeof AuthenticatedEntityEntityIdEventsEventIdIndexRoute;
  "/_authenticated/tools/distribution/brand/$categorySlug/": typeof AuthenticatedToolsDistributionBrandCategorySlugIndexRoute;
  "/_authenticated/tools/distribution/product/$productId/": typeof AuthenticatedToolsDistributionProductProductIdIndexRoute;
  "/_authenticated/entity/$entityId/events/$eventId/draft/": typeof AuthenticatedEntityEntityIdEventsEventIdDraftIndexRoute;
  "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/": typeof AuthenticatedEntityEntityIdEventsEventIdActivityActivityIdTimerIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ""
    | "/login"
    | "/logout"
    | "/silentLogin"
    | "/verify-email"
    | "/about"
    | "/entity"
    | "/org"
    | "/organizer"
    | "/notifications"
    | "/releaseNotes"
    | "/user"
    | "/"
    | "/organizer/events"
    | "/organizer/my-stores"
    | "/admin"
    | "/organizer/"
    | "/user/"
    | "/entity/$entityId/pending-applications"
    | "/entity/$entityId"
    | "/org/$orgId"
    | "/tools/distribution"
    | "/entity/$entityId/analytics/tickets"
    | "/entity/$entityId/analytics/users"
    | "/entity/$entityId/events/create"
    | "/entity/$entityId/manage/financials"
    | "/org/$orgId/manage/financials"
    | "/org/$orgId/manage/members"
    | "/entity/$entityId/manage/organizers"
    | "/entity/$entityId/manage/staff"
    | "/entity/$entityId/analytics"
    | "/entity/$entityId/events"
    | "/entity/$entityId/gameDirectory"
    | "/entity/$entityId/games"
    | "/entity/$entityId/user"
    | "/org/$orgId/manage"
    | "/entity/$entityId/manage"
    | "/entity/$entityId/store-information"
    | "/entity/$entityId/events/$eventId"
    | "/tools/distribution/brand/$categorySlug"
    | "/tools/distribution/product/$productId"
    | "/entity/$entityId/events/$eventId/draft"
    | "/entity/$entityId/events/$eventId/activity/$activityId/timer";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/login"
    | "/logout"
    | "/silentLogin"
    | "/verify-email"
    | "/about"
    | "/entity"
    | "/org"
    | "/notifications"
    | "/releaseNotes"
    | "/"
    | "/organizer/events"
    | "/organizer/my-stores"
    | "/admin"
    | "/organizer"
    | "/user"
    | "/entity/$entityId/pending-applications"
    | "/entity/$entityId"
    | "/org/$orgId"
    | "/tools/distribution"
    | "/entity/$entityId/analytics/tickets"
    | "/entity/$entityId/analytics/users"
    | "/entity/$entityId/events/create"
    | "/entity/$entityId/manage/financials"
    | "/org/$orgId/manage/financials"
    | "/org/$orgId/manage/members"
    | "/entity/$entityId/manage/organizers"
    | "/entity/$entityId/manage/staff"
    | "/entity/$entityId/analytics"
    | "/entity/$entityId/events"
    | "/entity/$entityId/gameDirectory"
    | "/entity/$entityId/games"
    | "/entity/$entityId/user"
    | "/org/$orgId/manage"
    | "/entity/$entityId/manage"
    | "/entity/$entityId/store-information"
    | "/entity/$entityId/events/$eventId"
    | "/tools/distribution/brand/$categorySlug"
    | "/tools/distribution/product/$productId"
    | "/entity/$entityId/events/$eventId/draft"
    | "/entity/$entityId/events/$eventId/activity/$activityId/timer";
  id:
    | "__root__"
    | "/_authenticated"
    | "/login"
    | "/logout"
    | "/silentLogin"
    | "/verify-email"
    | "/about"
    | "/_authenticated/entity"
    | "/_authenticated/org"
    | "/_authenticated/organizer"
    | "/_authenticated/notifications"
    | "/_authenticated/releaseNotes"
    | "/_authenticated/user"
    | "/_authenticated/"
    | "/_authenticated/organizer/events"
    | "/_authenticated/organizer/my-stores"
    | "/_authenticated/admin/"
    | "/_authenticated/organizer/"
    | "/_authenticated/user/"
    | "/_authenticated/entity/$entityId/pending-applications"
    | "/_authenticated/entity/$entityId/"
    | "/_authenticated/org/$orgId/"
    | "/_authenticated/tools/distribution/"
    | "/_authenticated/entity/$entityId/analytics/tickets"
    | "/_authenticated/entity/$entityId/analytics/users"
    | "/_authenticated/entity/$entityId/events/create"
    | "/_authenticated/entity/$entityId/manage/financials"
    | "/_authenticated/org/$orgId/manage/financials"
    | "/_authenticated/org/$orgId/manage/members"
    | "/_authenticated/entity/$entityId/manage/organizers"
    | "/_authenticated/entity/$entityId/manage/staff"
    | "/_authenticated/entity/$entityId/analytics/"
    | "/_authenticated/entity/$entityId/events/"
    | "/_authenticated/entity/$entityId/gameDirectory/"
    | "/_authenticated/entity/$entityId/games/"
    | "/_authenticated/entity/$entityId/user/"
    | "/_authenticated/org/$orgId/manage/"
    | "/_authenticated/entity/$entityId/manage/"
    | "/_authenticated/entity/$entityId/store-information/"
    | "/_authenticated/entity/$entityId/events/$eventId/"
    | "/_authenticated/tools/distribution/brand/$categorySlug/"
    | "/_authenticated/tools/distribution/product/$productId/"
    | "/_authenticated/entity/$entityId/events/$eventId/draft/"
    | "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren;
  LoginRoute: typeof LoginRoute;
  LogoutRoute: typeof LogoutRoute;
  SilentLoginRoute: typeof SilentLoginRoute;
  VerifyEmailRoute: typeof VerifyEmailRoute;
  AboutLazyRoute: typeof AboutLazyRoute;
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  SilentLoginRoute: SilentLoginRoute,
  VerifyEmailRoute: VerifyEmailRoute,
  AboutLazyRoute: AboutLazyRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.jsx",
      "children": [
        "/_authenticated",
        "/login",
        "/logout",
        "/silentLogin",
        "/verify-email",
        "/about"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.jsx",
      "children": [
        "/_authenticated/entity",
        "/_authenticated/org",
        "/_authenticated/organizer",
        "/_authenticated/notifications",
        "/_authenticated/releaseNotes",
        "/_authenticated/user",
        "/_authenticated/",
        "/_authenticated/admin/",
        "/_authenticated/tools/distribution/",
        "/_authenticated/tools/distribution/brand/$categorySlug/",
        "/_authenticated/tools/distribution/product/$productId/"
      ]
    },
    "/login": {
      "filePath": "login.jsx"
    },
    "/logout": {
      "filePath": "logout.jsx"
    },
    "/silentLogin": {
      "filePath": "silentLogin.jsx"
    },
    "/verify-email": {
      "filePath": "verify-email.jsx"
    },
    "/about": {
      "filePath": "about.lazy.jsx"
    },
    "/_authenticated/entity": {
      "filePath": "_authenticated/entity/route.jsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/entity/$entityId/pending-applications",
        "/_authenticated/entity/$entityId/",
        "/_authenticated/entity/$entityId/analytics/tickets",
        "/_authenticated/entity/$entityId/analytics/users",
        "/_authenticated/entity/$entityId/events/create",
        "/_authenticated/entity/$entityId/manage/financials",
        "/_authenticated/entity/$entityId/manage/organizers",
        "/_authenticated/entity/$entityId/manage/staff",
        "/_authenticated/entity/$entityId/analytics/",
        "/_authenticated/entity/$entityId/events/",
        "/_authenticated/entity/$entityId/gameDirectory/",
        "/_authenticated/entity/$entityId/games/",
        "/_authenticated/entity/$entityId/user/",
        "/_authenticated/entity/$entityId/manage/",
        "/_authenticated/entity/$entityId/store-information/",
        "/_authenticated/entity/$entityId/events/$eventId/",
        "/_authenticated/entity/$entityId/events/$eventId/draft/",
        "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/"
      ]
    },
    "/_authenticated/org": {
      "filePath": "_authenticated/org/route.jsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/org/$orgId/",
        "/_authenticated/org/$orgId/manage/financials",
        "/_authenticated/org/$orgId/manage/members",
        "/_authenticated/org/$orgId/manage/"
      ]
    },
    "/_authenticated/organizer": {
      "filePath": "_authenticated/organizer/route.jsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/organizer/events",
        "/_authenticated/organizer/my-stores",
        "/_authenticated/organizer/"
      ]
    },
    "/_authenticated/notifications": {
      "filePath": "_authenticated/notifications.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/releaseNotes": {
      "filePath": "_authenticated/releaseNotes.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/user": {
      "filePath": "_authenticated/user.jsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/user/"
      ]
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.lazy.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/organizer/events": {
      "filePath": "_authenticated/organizer/events.jsx",
      "parent": "/_authenticated/organizer"
    },
    "/_authenticated/organizer/my-stores": {
      "filePath": "_authenticated/organizer/my-stores.jsx",
      "parent": "/_authenticated/organizer"
    },
    "/_authenticated/admin/": {
      "filePath": "_authenticated/admin/index.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/organizer/": {
      "filePath": "_authenticated/organizer/index.jsx",
      "parent": "/_authenticated/organizer"
    },
    "/_authenticated/user/": {
      "filePath": "_authenticated/user/index.jsx",
      "parent": "/_authenticated/user"
    },
    "/_authenticated/entity/$entityId/pending-applications": {
      "filePath": "_authenticated/entity/$entityId/pending-applications/route.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/": {
      "filePath": "_authenticated/entity/$entityId/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/org/$orgId/": {
      "filePath": "_authenticated/org/$orgId/index.jsx",
      "parent": "/_authenticated/org"
    },
    "/_authenticated/tools/distribution/": {
      "filePath": "_authenticated/tools/distribution/index.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/entity/$entityId/analytics/tickets": {
      "filePath": "_authenticated/entity/$entityId/analytics/tickets.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/analytics/users": {
      "filePath": "_authenticated/entity/$entityId/analytics/users.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/events/create": {
      "filePath": "_authenticated/entity/$entityId/events/create.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/manage/financials": {
      "filePath": "_authenticated/entity/$entityId/manage/financials.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/org/$orgId/manage/financials": {
      "filePath": "_authenticated/org/$orgId/manage/financials.jsx",
      "parent": "/_authenticated/org"
    },
    "/_authenticated/org/$orgId/manage/members": {
      "filePath": "_authenticated/org/$orgId/manage/members.jsx",
      "parent": "/_authenticated/org"
    },
    "/_authenticated/entity/$entityId/manage/organizers": {
      "filePath": "_authenticated/entity/$entityId/manage/organizers.lazy.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/manage/staff": {
      "filePath": "_authenticated/entity/$entityId/manage/staff.lazy.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/analytics/": {
      "filePath": "_authenticated/entity/$entityId/analytics/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/events/": {
      "filePath": "_authenticated/entity/$entityId/events/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/gameDirectory/": {
      "filePath": "_authenticated/entity/$entityId/gameDirectory/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/games/": {
      "filePath": "_authenticated/entity/$entityId/games/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/user/": {
      "filePath": "_authenticated/entity/$entityId/user/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/org/$orgId/manage/": {
      "filePath": "_authenticated/org/$orgId/manage/index.jsx",
      "parent": "/_authenticated/org"
    },
    "/_authenticated/entity/$entityId/manage/": {
      "filePath": "_authenticated/entity/$entityId/manage/index.lazy.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/store-information/": {
      "filePath": "_authenticated/entity/$entityId/store-information/index.lazy.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/events/$eventId/": {
      "filePath": "_authenticated/entity/$entityId/events/$eventId/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/tools/distribution/brand/$categorySlug/": {
      "filePath": "_authenticated/tools/distribution/brand/$categorySlug/index.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/tools/distribution/product/$productId/": {
      "filePath": "_authenticated/tools/distribution/product/$productId/index.jsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/entity/$entityId/events/$eventId/draft/": {
      "filePath": "_authenticated/entity/$entityId/events/$eventId/draft/index.jsx",
      "parent": "/_authenticated/entity"
    },
    "/_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/": {
      "filePath": "_authenticated/entity/$entityId/events/$eventId/activity/$activityId/timer/index.jsx",
      "parent": "/_authenticated/entity"
    }
  }
}
ROUTE_MANIFEST_END */
