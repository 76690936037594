import React from "react";
import { cn } from "../utils";

const buttonConfig = {
  size: {
    xs: "px-3 py-1 text-xs ",
    sm: "px-4 py-2 text-2xs ",
    md: "px-16 py-3 text-lg",
    lg: "py-3 px-[64px] text-2xl ",
    xl: "px-10 py-4 text-lg ",
  },
  variants: {
    outlined: "text-white border-2",
    "gradient-outlined":
      "p-0.5 transition-all duration-500 bg-gradient-to-tr from-secondary via-red-500 to-primary bg-size-150 bg-pos-0 hover:bg-pos-100",
    contained: "text-white bg-primary",
    "gradient-contained":
      "text-black transition-all duration-500 bg-gradient-to-bl from-secondary via-red-500 to-primary bg-size-150 bg-pos-0 hover:bg-pos-100",
    text: "text-white",
    secondary: "text-white bg-secondary",
    destructive: "text-foreground bg-destructive/30 border border-red-900",
  },
  disabled: "opacity-50 cursor-not-allowed pointer-events-none",
};

const spinner = (
  <svg
    aria-hidden="true"
    className="animate-spin h-7 w-7 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const StyledButton = React.forwardRef(
  (
    {
      loading,
      className,
      children,
      disabled,
      onClick,
      size = "md",
      style,
      variant,
      rounded = false,
      ...props
    },
    forwardRef
  ) => {
    return (
      <button
        className={cn(`${rounded ? "rounded-full" : "rounded"} font-semibold focus:outline-none transition-transform duration-500 hover:transition-transform hover:duration-250 scale-100 hover:scale-105 ${buttonConfig.size[size]}${buttonConfig.variants[variant]}${disabled ? ` ${buttonConfig.disabled}` : ""}`, className)}
        onClick={onClick}
        style={style}
        ref={forwardRef}
        {...props}
      >
        {loading ? spinner : children}
      </button>
    );
  }
);

const Button = React.forwardRef(
  (
    {
      loading,
      children,
      className,
      disabled,
      onClick,
      type,
      size = "md",
      variant = "contained",
      style,
      rounded = false,
      dataTooltipTarget = "tooltip-default",
      ...props
    },
    forwardRef
  ) => {
    if (variant === "gradient-outlined") {
      return (
        <button
          className={cn(rounded ? "rounded-full" : "rounded", `font-semibold focus:outline-none transition-transform duration-500 hover:transition-transform hover:duration-250 scale-100 hover:scale-105 h-fit ${buttonConfig.variants["gradient-outlined"]} ${disabled ? ` ${buttonConfig.disabled}` : ""}`)}
          onClick={onClick}
          style={style}
          ref={forwardRef}
          type={type}
          disabled={disabled}
          {...props}
        >
          {/*<button dataTooltipTarget={dataTooltipTarget} className={`${className} p-[2px]`} disabled={disabled} onClick={onClick}*/}
          {/*              type={type} variant={variant} style={style} {...props} ref={forwardRef}>*/}
          <span
            className={cn(`flex ${rounded ? "rounded-full" : "rounded"} w-full bg-secondary text-white ${buttonConfig.size[size]}`, className)}
          >
            <span className="bg-gradient-to-tr from-secondary to-primary inline-block bg-clip-text">
              {loading ? spinner : children}
            </span>
          </span>
        </button>
      );
    }
    return (
      <StyledButton
        className={className}
        dataTooltipTarget={dataTooltipTarget}
        disabled={disabled}
        onClick={onClick}
        ref={forwardRef}
        size={size}
        style={style}
        type={type}
        variant={variant}
        rounded={rounded}
        {...props}
      >
        {loading ? spinner : children}
      </StyledButton>
    );
  }
);

export default Button;
