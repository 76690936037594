import { cn } from "@/utils/cn";

const CardContainer = ({ children, className }) => {
  return (
    <div className={cn(`w-full border border-zinc-800 rounded-xl ${className}`)}>
      {children}
    </div>
  )
}

export default CardContainer;
