import ErrorPage from '@/components/ErrorPage'
import Loading from '@/components/Loading'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import NotFoundPage from '@repo/ui/notFound'
import * as Sentry from '@sentry/react'
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'

export const Route = createRootRouteWithContext()({
	component: () => {
		return (
			<>
				<Outlet />
				{/*<TanStackRouterDevtools />*/}
			</>
		)
	},
	errorComponent: ({
		error,
		info,
		reset
	}) => {
		// Capture the error in Sentry
		Sentry.captureException(error, {
			extra: {
				componentStack: info?.componentStack,
				digest: info?.digest
			}
		});

		return <ErrorPage error={error} info={info} reset={reset} />;
	},
	pendingComponent: () => <Loading fullScreen sidebar />,
	notFoundComponent: () => <NotFoundPage />
})
