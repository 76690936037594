import { create } from 'zustand';
import { setDefaultActivityState } from "@repo/utils/events";
import { queryClient } from '@/lib/queryClient';

const findPhaseInfoFromTournamentRoundId = (allPhases, tournamentRoundId) => {
	let selectedPhase = {};
	allPhases.forEach((phase) => {
		(phase?.tournament?.rounds || []).forEach((tournamentRound) => {
			if (tournamentRound.id === tournamentRoundId) selectedPhase = phase;
		})
	})

	return {
		phasePod: selectedPhase || [],
		allPhases: allPhases?.filter((phase) => phase?.stage === selectedPhase.stage) || {},
		tournament: selectedPhase.tournament || {},
		tournamentRound: selectedPhase?.tournament?.rounds?.find((tournamentRound) => tournamentRound.id === tournamentRoundId) || {},
		activePhaseName: selectedPhase?.name || null,
		activityPhaseType: selectedPhase?.activityPhaseType || null,
		nextPhase: allPhases?.find(phase => phase?.stage === selectedPhase.stage + 1) || null,
	}
};

const findPhaseInfoFromPhaseId = (allPhases, activityPhaseId) => {
	let selectedPhase = {};
	allPhases.forEach((phase) => {
		if (phase.id === activityPhaseId) selectedPhase = phase;
	})

	return {
		phasePod: selectedPhase || [],
		allPhases: allPhases?.filter((phase) => phase?.stage === selectedPhase.stage) || {},
		tournament: null,
		tournamentRound: null,
		activePhaseName: selectedPhase?.name || null,
		activityPhaseType: selectedPhase?.activityPhaseType || null,
		nextPhase: allPhases?.find(phase => phase?.stage === selectedPhase.stage + 1) || null,
	}
};

export const useEventStore = create((set) => ({
	setDefault: (activity, event) => set(() => {
		const defaultState = setDefaultActivityState(activity);
		return {
			activity,
			event,
			refetch: () => {
				queryClient.invalidateQueries();
			},
			...defaultState,
		};
	}),
	onChangeStageSelection: ({ activityPhaseId, tournamentRoundId }) => set((initialData) => {
		let activePhases = initialData?.activePhases;
		let activeStage = initialData?.activeStage;
		let activePhaseName = initialData?.activePhaseName;
		let currentActivityPhaseType = initialData?.currentActivityPhaseType;
		let currentTournament = initialData?.currentTournament;
		let currentTournamentRound = initialData?.currentTournamentRound;
		let nextPhase = null;

		if (tournamentRoundId) {
			const { allPhases, phasePod, tournament, tournamentRound, activePhaseName: activePhaseNameFromTournamentRoundId, activityPhaseType } = findPhaseInfoFromTournamentRoundId(initialData?.allPhases || [], tournamentRoundId);
			activePhases = allPhases;
			activeStage = phasePod.stage;
			activePhaseName = activePhaseNameFromTournamentRoundId;
			currentActivityPhaseType = activityPhaseType;
			currentTournament = tournament;
			currentTournamentRound = tournamentRound;
			nextPhase = allPhases?.find(phase => phase?.stage === activeStage + 1 && phase?.pod === phasePod.pod);
		} else if (activityPhaseId) {
			const { allPhases, phasePod, tournament, tournamentRound, activePhaseName: activePhaseNameFromPhaseId, activityPhaseType } = findPhaseInfoFromPhaseId(initialData?.allPhases || [], activityPhaseId)
			activePhases = allPhases;
			activePhaseName = activePhaseNameFromPhaseId;
			activeStage = phasePod.stage;
			currentActivityPhaseType = activityPhaseType;
			currentTournament = tournament;
			currentTournamentRound = tournamentRound;
			nextPhase = allPhases?.find(phase => phase?.stage === activeStage + 1 && phase?.pod === phasePod.pod);
		}

		return {
			...initialData,
			activePhases,
			activeStage,
			activePhaseName,
			currentActivityPhaseType,
			currentTournament,
			currentTournamentRound,
			nextPhase,
		}
	}),
	activePhases: [],
	activeStage: {},
	activePhaseName: '',
	activity: {},
	event: {},
	allPhases: [],
	nextPhase: null,
	currentActivityPhaseType: null,
	currentTournament: {},
	currentTournamentRound: null,
	setShouldSetDefaultEventState: (boolValue) => set((initialData) => {
		return {
			...initialData,
			shouldSetDefaultEventState: boolValue,
		};
	}),
	shouldSetDefaultEventState: true,
	refetch: () => { },
	readOnlyRoundId: undefined,
	setReadOnlyRoundId: (roundId) => set((initialData) => {
		return {
			...initialData,
			readOnlyRoundId: roundId,
		};
	}),
}));
