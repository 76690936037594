import { Link } from "@tanstack/react-router";
import { getGameColor } from '@/utils/gameColors';

const BASE_Z_INDEX = 90;
const Z_INDEX_STEP = 10;

const CalendarEvent = ({
  event,
  idx,
  isOverlapping = false,
  maxOffset = 0,
  getGameName,
  formatEventType,
  classNames
}) => {
  if (isOverlapping) {
    return (
      <Link
        key={event.id}
        to={`/entity/${event.owner.entityId}/events/${event.id}`}
        className={classNames(
          "absolute inset-0 rounded-lg cursor-pointer transition-transform hover:z-100 hover:scale-[1.02] shadow-xl text-xs/5 overflow-hidden",
          getGameColor(getGameName(event)),
          `z-${BASE_Z_INDEX - (idx * Z_INDEX_STEP)}`
        )}
        style={{
          left: `${maxOffset}px`,
          right: `${-maxOffset}px`,
          maxWidth: 'calc(100% - 32px)',
          minWidth: '60px'
        }}
      >
        {event.activities?.[0]?.gameLogos?.[0] && (
          <div className="relative h-8 w-full mb-1">
            <img
              src={event.activities[0].gameLogos[0]}
              alt={`${event.name} game logo`}
              className="absolute inset-0 w-full h-full object-contain bg-black/70 p-1"
            />
          </div>
        )}
        <div className="px-2 pb-2">
          <p className="font-semibold text-white">
            {event.name}
          </p>
          <p className="text-blue-200">
            {formatEventType(event)}
          </p>
          <p className="text-blue-200">
            {event.registrationCount || 0} Players
          </p>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={`/entity/${event.owner.entityId}/events/${event.id}`}
      style={{
        width: `calc(${event.width}% - 0.5rem)`,
        marginLeft: `${event.offset}%`,
      }}
      className={classNames(
        "group absolute inset-1 flex flex-col overflow-hidden rounded-lg text-xs/5 shadow-xl transition-all",
        getGameColor(getGameName(event)),
        "hover:z-100 hover:scale-[1.02]"
      )}
    >
      {event.activities?.[0]?.gameLogos?.[0] && (
        <div className="relative h-8 w-full mb-1">
          <img
            src={event.activities[0].gameLogos[0]}
            alt={`${event.name} game logo`}
            className="absolute inset-0 w-full h-full object-contain bg-black/70 p-1"
          />
        </div>
      )}
      <div className="px-2 pb-2">
        <p className="font-semibold text-white">
          {event.name}
        </p>
        <p className="text-blue-200">
          {formatEventType(event)}
        </p>
        <p className="text-blue-200">
          {event.registrationCount || 0} Players
        </p>
      </div>
    </Link>
  );
};

export default CalendarEvent;
