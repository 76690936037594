import CardContainer from "@repo/ui/card/CardContainer";
import Pagination from "@repo/ui/card/Pagination";
import OneVsOnePairingCard from "@/components/Cards/Variants/Pairings/OneVsOne/OneVsOneCard";
import ByeCard from "@/components/Cards/Variants/Pairings/ByeCard";
import Loading from "@/components/Loading";
import { Input } from "@repo/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/select";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@cardeio/ui/components/ui/accordion";
import { useEventStore } from "@/store/useEventStore";
import PairingCountdownTimer from "./PairingCountdownTimer";

const OrganizePairings = ({
  filters,
  isFetching,
  pairings,
  pagination,
  searchInput,
  setFilters,
  setSearchInput,
  activePhases,
  playerRegistrations,
}) => {
  const { currentTournament, currentTournamentRound } = useEventStore();

  const isPreview = activePhases.some((phase) => phase.status === "preview");

  // Group registrations by activity phase and pod, and sort by seed
  const registrationsByPhase = playerRegistrations?.reduce(
    (acc, registration) => {
      // Find the phase user that corresponds to one of our active phases
      const phaseUser =
        registration.activityRegistrations[0].activityPhaseUsers?.find(
          (phaseUser) =>
            activePhases.some((phase) => phase.id === phaseUser.activityPhaseId)
        );
      if (!phaseUser) {
        return acc;
      }

      const phase = activePhases?.find(
        (p) => p.id === phaseUser.activityPhaseId
      );
      if (!phase) {
        return acc;
      }

      const podNumber = phase.pod;

      if (!acc[podNumber]) {
        const podPhase = activePhases.find((p) => p.pod === Number(podNumber));

        acc[podNumber] = {
          podSize: podPhase.configuration?.podSize || 8,
          pairings: [],
          startingTableNumber: podPhase.configuration?.startingTableNumber || 1,
          id: podPhase?.id,
          startTime: podPhase?.startTime,
          endTime: podPhase?.endTime,
          duration: podPhase?.duration,
        };
      }

      // Find the pairing for this player
      const playerPairing = pairings?.find((pairing) => {
        // Check if this pairing belongs to the current phase
        if (
          pairing.tournamentPairingUsers.every(
            (pairingUser) =>
              pairingUser &&
              pairingUser.activityPhaseId !== phaseUser.activityPhaseId
          )
        ) {
          return false;
        }
        // Check if this player is in the pairing
        return pairing.tournamentPairingUsers.some(
          (pairingUser) =>
            pairingUser && pairingUser.user.id === registration.userId
        );
      });

      if (playerPairing && !acc[podNumber].pairings.includes(playerPairing)) {
        acc[podNumber].pairings.push(playerPairing);
      }

      return acc;
    },
    {}
  );

  // Sort registrationsByPhase.pairings by tableNumber
  Object.values(registrationsByPhase).forEach((podData) => {
    podData.pairings.sort((a, b) => a.tableNumber - b.tableNumber);
  });

  return (
    <CardContainer className="relative z-10 px-2 w-full border-x-0 border-b-0 rounded-none ">
      <div className="flex flex-row flex-wrap gap-4 p-2 justify-start mt-2">
        {activePhases.length > 1 && (
          <div>
            <p className="text-sm mb-1">Pod Visibility</p>
            <Select
              defaultValue="all"
              onValueChange={(value) =>
                setFilters({
                  ...filters,
                  podId: value === "all" ? null : value,
                })
              }
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select Pod" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectTextItem value="all">Show All Pods</SelectTextItem>
                  {Object.entries(registrationsByPhase || {}).map(
                    ([podNumber, podData]) => (
                      <SelectTextItem key={podNumber} value={podNumber}>
                        Pod {podNumber}
                      </SelectTextItem>
                    )
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        <div>
          <p className="text-sm mb-1">Find User</p>
          <Input
            onChange={(e) => {
              setFilters({ ...filters, page: 1 });
              setSearchInput(e.target.value);
            }}
            placeholder="Search by Name..."
            className="w-[250px] h-9 rounded-md border border-white bg-transparent px-3 py-2 text-sm shadow-sm"
            value={searchInput}
          />
        </div>
        <div>
          <p className="text-sm mb-1">Pairing Status</p>
          <Select
            defaultValue={"all"}
            onValueChange={(value) => {
              setFilters({
                page: 1,
                resultSubmitted: value === "all" ? null : value === "submitted",
              });
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a Pairing Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectTextItem value="all">Show All</SelectTextItem>
                <SelectTextItem value="remaining">
                  Show Not Submitted
                </SelectTextItem>
                <SelectTextItem value="submitted">
                  Show Submitted
                </SelectTextItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      {isFetching ? (
        <div className="flex items-center justify-center h-72">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-2 my-2">
          {Object.keys(registrationsByPhase || {}).length > 0 ? (
            <Accordion
              type="multiple"
              defaultValue={["pod-1"]}
              className="w-full"
            >
              {Object.entries(registrationsByPhase || {})
                .sort(([a], [b]) => Number(a) - Number(b))
                .filter(
                  ([podNumber]) => !filters.podId || podNumber === filters.podId
                )
                .map(([podNumber, podData]) => (
                  <AccordionItem
                    key={`pod-${podNumber}`}
                    value={`pod-${podNumber}`}
                    className="border-b border-zinc-700"
                  >
                    <AccordionTrigger className="px-4 hover:no-underline">
                      <div className="flex gap-6 justify-start items-center">
                        <span className="text-lg font-medium text-orange-300">
                          {activePhases.length > 1
                            ? `Pod ${podNumber}`
                            : `Round ${currentTournamentRound.roundNumber} Pairings`}
                        </span>
                        <span className="text-sm text-white">
                          {podData.pairings.length} pairings
                        </span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="flex flex-col gap-2 px-2">
                        <PairingCountdownTimer
                          id={podData.id}
                          podData={podData}
                          type="activityPhase"
                        />
                        {podData.pairings.map((pairing) => (
                          <>
                            {pairing?.tournamentPairingUsers.filter(Boolean)
                              .length === 1 ? (
                              <ByeCard pairing={pairing} />
                            ) : (
                              <OneVsOnePairingCard
                                pairing={pairing}
                                isPreview={isPreview}
                              />
                            )}
                          </>
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
            </Accordion>
          ) : (
            <div className="flex items-center justify-center w-full h-[250px] p-8 uppercase text-2xl font-bold text-zinc-100/80">
              No Pairings Found
            </div>
          )}
        </div>
      )}
      <Pagination
        data={pagination}
        filters={filters}
        setActivePage={(pageNum) => {
          setFilters({ ...filters, page: pageNum });
        }}
      />
    </CardContainer>
  );
};

export default OrganizePairings;
