import { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import * as z from "zod";
import { toast } from "sonner";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";

import { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle, ModalTrigger } from '@repo/ui/modal';
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { RiUserAddLine } from "@remixicon/react";

import { addNonPlayerToEventMutationFn, addPlayerToEventMutationFn, createGhostUserMutationFn, searchActivityUserMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { queryClient } from "@/lib/queryClient";
import { REGISTRATION_STATUS } from "@/constants";
import { Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";
import { Checkbox } from "@repo/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { cn } from "@/utils/cn";
import { getEventUserRoles } from "@repo/utils/roles";
import { fetchEventRegistrationsQueryOptions } from "@repo/api/web";

const RadioGroup = ({ items, selectedValue, onValueChange }) => {
  return (
    <div className="flex gap-4">
      {items.map((item) => (
        <button key={item.value} value={item.value} className={cn("flex flex-col items-center p-2 rounded-md flex-1",
          {
            'bg-red-900 text-white': selectedValue === item.value,
            'bg-gray-500 text-white': selectedValue !== item.value,
          }
        )} onClick={() => onValueChange(item.value)}>
          <div className="flex flex-col items-center my-4">
            <p className="text-md font-bold">{item.label}</p>
            <p className="text-sm text-white">{item.description}</p>
          </div>
        </button>
      ))}
    </div>
  )
}

const findParticipantFormSchema = z
  .object({
    email: z.string("email is required").email("Email must be valid").min(1, "Email is required"),
    requestStatus: z.enum(["success", "pending", "failed", null]).nullable(),
    userId: z.string("userId is required").optional().nullable(),
    role: z.string("role is required").optional().nullable(),
    initialByes: z.number("initialByes is required").optional().nullable(),
    firstName: z.string("firstName is required").optional().nullable(),
    lastName: z.string("lastName is required").optional().nullable(),
    parentEmail: z.string("parentEmail is required").email("Parent email must be valid").optional().nullable(),
    over13: z.boolean("over13 is required").optional().nullable(),
    permission: z.boolean("permission is required").optional().nullable(),
  })
  .refine(
    (data) => data.requestStatus !== "success" || !!data.role,
    {
      path: ["role"], // Highlight error on the role field
      message: "Role is required if requestStatus is success",
    }
  )
  .refine(
    (data) =>
      data.requestStatus !== "success" ||
      data.userId ||
      !data.over13 ||
      (!!data.firstName && !!data.lastName),
    {
      path: ["firstName"], // Highlight error on firstName for missing names
      message:
        "First name and last name are required if requestStatus is success, userId is valid, and over13 is true",
    }
  )
  .refine(
    (data) =>
      data.requestStatus !== "success" ||
      data.userId ||
      data.over13 ||
      (!!data.parentEmail && data.permission),
    {
      path: ["parentEmail"], // Highlight error on parentEmail for missing fields
      message:
        "Parent email and permission are required if requestStatus is success, userId is valid, and over13 is false",
    }
  );

const AddParticipantModal = ({ activity, event }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [hasError, setHasError] = useState();

  const { data: registrationsRaw } = useSuspenseQuery(fetchEventRegistrationsQueryOptions({
    axiosInstance: webAxiosInstance,
    eventId: event?.id,
    searchParams: { limit: 1000000 },
  }));

  const registrations = registrationsRaw?.data;
  const showInitialByes = !['casual', 'local'].includes(activity?.activityLevel?.activityLevelType);
  const findParticipantForm = useForm({
    defaultValues: {
      email: "",
      requestStatus: null,
      userId: null,
      role: null,
      initialByes: null,
      firstName: undefined,
      lastName: undefined,
      parentEmail: undefined,
      over13: false,
      permission: false,
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    findParticipantForm.reset();
  };

  const participantForm = findParticipantForm.watch();

  const getUserByEmailMutation = useMutation({
    mutationFn: async ({
      clientAxiosInstance,
      context,
      email,
    })=>{
      const res = await searchActivityUserMutationFn({
        clientAxiosInstance,
        context,
        email,
      });
      if(res?.data?.data?.length > 0){
        const user = res?.data?.data[0];
        if (user.parentId && !user.parentConfirmed)
          throw new Error('UNAUTHORIZED_MINOR');
        return res;
      }
      return res;
    },
    onSuccess: ({ data }) => {
      setUser(data.data[0]);
    },
    onError: (error) => {
      if(error.message === 'UNAUTHORIZED_MINOR'){
        return setHasError('Players under 13 must have a legal guardian approve their account to join.');
      }
      toast("We apologize for this inconvenience.", {
        description: "There was an error finding a user by email.",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Searching User`,
        },
      })
    },
  })

  useEffect(() => {
    findParticipantForm.setValue('requestStatus', getUserByEmailMutation?.status)
    findParticipantForm.setValue('userId', user?.id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserByEmailMutation?.status, user?.id]);


  useEffect(() => {
    getUserByEmailMutation.reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[participantForm.email])
  const checkInMutation = useMutation({
    mutationFn: async ({ id, gameId }) => {
      await webAxiosInstance.put(`/api/organize/registrations/${id}`, {
        status: REGISTRATION_STATUS.checkedIn,
      }, {
        headers: {
          'game-id': gameId,
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event', event.id]})
      queryClient.invalidateQueries({ queryKey: ['activity', 'roster']});
    },
    onError: () => {
      toast.error('Cannot check in player at this time.')
    }
  });

  const createGhostUserMutation = useMutation({
    mutationFn: createGhostUserMutationFn,
    onSuccess: async ({ data }, variables) => {
      if (activity.status === 'inProgress') checkInMutation.mutate({ gameId: activity?.gameId, id: data?.data?.activityRegistrations?.[0]?.id })

      const createdUser = data?.data?.user;
      if(variables?.role === 'player'){
        addPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userId: createdUser.id, shouldClose: variables?.shouldClose });
        toast.success('Player added to event!')
      } else {
        addNonPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userRole: variables?.role, userId: createdUser.id, shouldClose: variables?.shouldClose });
      }


    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error adding a player to the event.",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  })

  const addPlayerToEventMutation = useMutation({
    mutationFn: addPlayerToEventMutationFn,
    onSuccess: async (res, variables) => {

      if (activity.status === 'inProgress') checkInMutation.mutate({ gameId: activity?.gameId, id: res?.data?.data?.activityRegistrations?.[0]?.id })
      await queryClient.invalidateQueries({queryKey:['event', event.id, 'registrations']});
      await queryClient.invalidateQueries({queryKey:[activity.id, 'activity' ]});
      if(variables?.shouldClose) return handleClose();
      findParticipantForm.reset();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error adding a player to the event.",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  })

  const addNonPlayerToEventMutation = useMutation({
    mutationFn: addNonPlayerToEventMutationFn,
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({queryKey:['event', event.id, 'registrations']});
      await queryClient.invalidateQueries({queryKey:[activity.id, 'activity' ]});
      if(variables?.shouldClose) handleClose();
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error adding a non-player to the event.",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  })

  const onSearchEmail = (values) => {
    getUserByEmailMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      context: {
        ownerId: event?.ownerId,
        ownerType: event?.ownerType,
        activityId: activity?.id,
      },
      email: values?.email,
    });
  };

  const onAddParticipant = (values, shouldClose) => {
    // If there's no user, add a ghost user.
    if (!user?.id) {
      createGhostUserMutation.mutate({
        clientAxiosInstance: webAxiosInstance, activity, user: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: participantForm?.email,
          parentEmail: values.parentEmail,
        },
        shouldClose,
        role: values.role,
      });
      return;
    }

    // If user role is not player, add non-player.
    if (values.role !== 'player') {
      addNonPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userRole: values.role, userId: user?.id, shouldClose });
      return
    }

    const registeredUser = registrations?.find(r => r?.user?.email.toLowerCase() === values?.email.toLowerCase() && r?.roles?.includes('general'));
    if(registeredUser && registeredUser?.status !== REGISTRATION_STATUS.checkedIn && activity.status === 'inProgress'){
      checkInMutation.mutate({ id: registeredUser?.id, gameId: activity?.gameId });
      return
    }

    if(registeredUser && registeredUser?.status === REGISTRATION_STATUS.checkedIn && activity.status === 'inProgress'){
      toast.error('Player is already checked in to this event.')
      return
    }

    if(registeredUser){
      toast.error('Player is already registered to this event.')
      return
    }

    addPlayerToEventMutation.mutate({ clientAxiosInstance: webAxiosInstance, activity, event, userId: user?.id, shouldClose });
  }

  useEffect(() => {
    findParticipantForm.setValue('firstName', undefined)
    findParticipantForm.setValue('lastName', undefined)
    findParticipantForm.setValue('parentEmail', undefined)
    findParticipantForm.setValue('permission', false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantForm?.over13])

  useEffect(() => {
    const roles = getEventUserRoles(user);
    if (roles?.length > 0 && !findParticipantForm.getValues('role')) {
      findParticipantForm.setValue('role', roles[0].value);
    }
  }, [user, findParticipantForm]);

  const errors = findParticipantFormSchema.safeParse(findParticipantForm.getValues())

  const isLoading = createGhostUserMutation.isPending || addNonPlayerToEventMutation.isPending || checkInMutation.isPending || addPlayerToEventMutation.isPending
  return (
    <div className="flex-shrink-0 h-full">
      <Modal open={isOpen} onOpenChange={setIsOpen}>
        <ModalTrigger asChild>
          <Tooltip delayDuration={200}>
            <TooltipTrigger className="h-full">
              <Button
                className="h-full flex items-center justify-center gap-1 text-xs font-normal"
                onClick={() => setIsOpen(true)}
                variant="gradient-contained"
                rounded
                style={{ padding: "0.5rem 1rem", fontSize: "0.875rem" }}
              >
                <RiUserAddLine className="w-4 h-4 text-zinc-100" />
                <span className="ml-1 text-zinc-100">Add User</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-sm">Add User To Event</p>
            </TooltipContent>
          </Tooltip>
        </ModalTrigger>
        <ModalContent className="content-between">
          <ModalHeader>
            <ModalTitle>Add User to Event</ModalTitle>
            <p className='text-sm text-zinc-200/60 italic'>Players, Judges, Organizers and Commentators</p>
          </ModalHeader>

          <Form {...findParticipantForm}>
            <div className="flex flex-col gap-4">
              <label className="text-md text-white font-bold">Email</label>
              <div className="flex flex-row gap-2">
              <Input placeholder="Email" {...findParticipantForm.register('email')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchEmail(findParticipantForm.getValues());
                  }
                }}
                 />
                 <Button
                  disabled={!findParticipantForm.getValues('email')}
                  onClick={() => {
                    onSearchEmail(findParticipantForm.getValues())
                  }} type="button" variant="gradient-contained" rounded
                  style={{ padding: "0.5rem 1rem", fontSize: "0.875rem" }} className="pt-2 text-white">Search</Button>
                </div>
            </div>
            {getUserByEmailMutation?.status === 'success' &&
            <div className="flex flex-col gap-4">
              <label className="text-lg text-white font-bold">Select a user role</label>
              <RadioGroup items={getEventUserRoles(user)
            } selectedValue={findParticipantForm.getValues('role')} onValueChange={(value) => {
                findParticipantForm.setValue('role', value)
              }}
                id={"role"}
              />
              {showInitialByes && <div className="flex flex-col gap-4">
                <label className="text-lg text-white font-bold">Initial Byes</label>
                <Input placeholder="Initial Byes" {...findParticipantForm.register('initialByes')} />
              </div>}
            </div>}

            {hasError && <div className="flex flex-col gap-4">
              <p className="text-red-500 text-center mt-4">
                {hasError}
              </p>
            </div>}
            {getUserByEmailMutation?.status === 'success' && !user?.id && <div className="flex flex-col gap-4">
              <div className="rounded bg-gray-700/70 p-4">
                <div className="flex gap-4 justify-around items-center">
                  <p>
                    Is this person Over the age of 13?
                  </p>
                  <div className="flex gap-4">
                    <Button onClick={() => findParticipantForm.setValue('over13', true)} className={cn("rounded w-fit px-8 text-lg", {
                      '!bg-green-900': findParticipantForm.getValues('over13'),
                      '!bg-slate-700/50': !findParticipantForm.getValues('over13'),
                    })} variant="contained">
                      Yes
                    </Button>
                    <Button onClick={() => findParticipantForm.setValue('over13', false)} className={cn("rounded w-fit px-8 text-lg", {
                      '!bg-red-900': !findParticipantForm.getValues('over13'),
                      '!bg-slate-700/50': findParticipantForm.getValues('over13'),
                    })} variant="contained">
                      No
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="text-red-300 text-center mt-4">
                    Minors under the age of 13 must sign up for the Play Network with their parent or guardian. If they are registering in-person, their guardian must express permission for child to participate in this event.
                  </p>
                </div>
              </div>
              {findParticipantForm.getValues('over13') && <div className="flex gap-4">
                <FormField
                  control={findParticipantForm.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className='w-full sm:w-[70%]'>
                      <FormLabel>First Name*</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="First Name"
                          onChange={(e) => findParticipantForm.setValue('firstName', e.target.value)}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={findParticipantForm.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className='w-full sm:w-[70%]'>
                      <FormLabel>Last Name*</FormLabel>
                      <FormControl>
                        <Input placeholder="Last Name" onChange={(e) => findParticipantForm.setValue('lastName', e.target.value)} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>}
              {!findParticipantForm.getValues('over13') && <div className="flex gap-4">
                <FormField
                  control={findParticipantForm.control}
                  name="parentEmail"
                  render={({ field }) => (
                    <FormItem className='w-full sm:w-[70%]'>
                      <FormLabel>Parent/Guardian Email*</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Parent/Guardian Email"
                          onChange={(e) => findParticipantForm.setValue('parentEmail', e.target.value)}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-4 items-center">
                  <Checkbox id="permission" onCheckedChange={(value) => findParticipantForm.setValue('permission', value)}/>
                  <label htmlFor="permission">
                    I have received permission from a parent or guardian to register this user to his event
                  </label>
                </div>
              </div>}
            </div>}

            <ModalFooter>
              <div className="flex gap-4">
                {getUserByEmailMutation?.status === 'success' && <> <Button variant="ghost" onClick={() => {
                  handleClose();
                }}>Cancel</Button>
                  <Button
                  disabled={!errors.success || isLoading}
                  variant="outlined" size="md" className="rounded-full" onClick={() => {
                    onAddParticipant(findParticipantForm.getValues(), false)
                  }}>Submit & Add Another</Button>
                  <Button
                  disabled={!errors.success || isLoading}
                  variant="gradient-contained" size="md" className="rounded-full" onClick={() => {
                    onAddParticipant(findParticipantForm.getValues(), true)
                  }}>Submit</Button>
                </>
                }
              </div>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </div>
  )
};

export default AddParticipantModal;
