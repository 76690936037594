import { useState } from "react";
import { RiUserAddLine } from "@remixicon/react";

import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { Button } from "@repo/ui/button";
import AddParticipantModal from "@/components/Modal/Event/AddParticipant";
import StartEventModal from "@/components/Modal/Event/Organize/StartEventModal";
import { TOURNAMENT_FORMATS } from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/Steps/constants";
import { useEventStore } from "@/store/useEventStore";
import { format, isBefore, isEqual } from "date-fns";

const HeaderActionButtons = ({ activity, event }) => {
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const { currentTournamentRound } = useEventStore();

  const StartEventModalContainer = () => {
    const startDate = new Date(event?.startsAt)
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(event?.endsAt)
    endDate.setHours(0, 0, 0, 0);
    const todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0);
    const isInTime = isBefore(startDate, todayDate) || isEqual(startDate, todayDate)
    const outOfTimeMessage = isEqual(startDate, endDate) ? `This event can only be started ${format(startDate, 'MMM d')}` : `This event can only be started ${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`
    return (
      <div className="flex-shrink-0 h-full">
        <Modal open={isStartModalOpen} onOpenChange={setIsStartModalOpen}>
          <ModalTrigger asChild>
            <Tooltip delayDuration={200}>
              <TooltipTrigger className="h-full">
                <Button
                  className="h-full flex items-center justify-center gap-1 text-xs font-normal"
                  onClick={() => setIsStartModalOpen(true)}
                  variant="gradient-contained"
                  rounded
                  style={{ padding: "0.5rem 1rem", fontSize: "0.875rem" }}
                  disabled={!isInTime}
                >
                  Start Event
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm">{!isInTime ? outOfTimeMessage : "Start Event"}</p>
              </TooltipContent>
            </Tooltip>
          </ModalTrigger>
          <StartEventModal
            activity={activity}
            eventId={event?.id}
            handleClose={() => setIsStartModalOpen(false)}
            handleStart={() => { }}
            isOpen={isStartModalOpen}
            phase={{}}
          />
        </Modal>
      </div>
    );
  };

  const tournamentDetails = activity?.configuration?.tournaments?.[0];

  const isSwissTournament = tournamentDetails?.tournamentFormat
    ? tournamentDetails?.tournamentFormat === TOURNAMENT_FORMATS.swiss
    : true;

  const addLateUser = tournamentDetails?.swissAddLateRoundCutoff
    ? tournamentDetails.swissAddLateRoundCutoff >=
    currentTournamentRound.roundNumber
    : true;

  return (
    <div className="flex gap-2 items-center ">
      {activity?.status === "published" ? (
        <AddParticipantModal activity={activity} event={event} />
      ) : null}
      {activity?.status === "published" && <StartEventModalContainer />}
    </div>
  );
};

export default HeaderActionButtons;
