import { useEventStore } from "@/store/useEventStore";
import { useState, useEffect } from "react";
import { add } from "date-fns";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@repo/ui/tooltip";
import AdvanceRoundModal from "@/components/Modal/Event/Organize/AdvanceRoundModal";

export const AdvanceRoundModalContainer = ({ buttonVariant = 'gradient-outlined', isAdvanceRoundModalOpen, setIsAdvanceRoundModalOpen, showRecommendedAdvanceRound }) => {
  const { currentTournamentRound, currentTournament } = useEventStore()
  const [roundTimeout, setRoundTimeout] = useState(false);

  useEffect(() => {
    const checkTimeout = () => {
      const isTimeout = currentTournamentRound?.endTime
        ? new Date(currentTournamentRound.endTime) < new Date()
        : currentTournamentRound?.createdAt
          ? add(new Date(currentTournamentRound.createdAt), { minutes: 40 }) < new Date()
          : false;
      setRoundTimeout(isTimeout);
    };

    checkTimeout();
    const interval = setInterval(checkTimeout, 1000); 

    return () => clearInterval(interval);
  }, [currentTournamentRound]);
  return (
    <div className="flex-shrink-0">
      <Modal open={isAdvanceRoundModalOpen} onOpenChange={setIsAdvanceRoundModalOpen}>
        <ModalTrigger asChild>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  className='flex items-center text-sm text-white'
                  disabled={currentTournament?.pairingInfo?.active > 0}
                  onClick={() => setIsAdvanceRoundModalOpen(true)}
                  rounded
                  size="sm"
                  variant={roundTimeout ? 'gradient-contained' : buttonVariant}
                >
                  <span>{showRecommendedAdvanceRound ? `Start Round ${currentTournament?.currentRound + 1}` : 'Add Another Round'}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {currentTournament?.pairingInfo?.active > 0 ? 'You cannot advance to the next round until all pairings are reported.' : ''}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </ModalTrigger>
        <AdvanceRoundModal handleClose={() => setIsAdvanceRoundModalOpen(false)} isRecommended={showRecommendedAdvanceRound} />
      </Modal>
    </div>
  )
}