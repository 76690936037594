import React, { useState } from "react";
import dayjs from "dayjs";

import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@repo/ui/modal";

import { Button } from "@repo/ui/button";
import { webAxiosInstance } from "@/utils/axios";
import { CurrencyInput } from "@repo/ui/input";
import {
  getAmountToTicketValue,
  isZeroDecimalCurrency,
} from "@repo/utils/currency";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@cardeio/ui/components/ui/select";
import { Label } from "@cardeio/ui/components/ui/label";
import { 
  useCreateActivityTicketMutation,
  useDeleteTicketPriceMutation,
  useUpdateTicketPriceMutation
} from "@/mutations/ManageTicketMutations";

const ManageTicketModal = ({ activity, eventId, handleClose, ticket }) => {
  const [currency, setCurrency] = useState(ticket?.currency || "USD");
  const [ticketPrice, setTicketPrice] = useState(
    ticket?.amount
      ? isZeroDecimalCurrency(currency)
        ? ticket.amount
        : ticket?.amount / 100
      : 0
  );
  const [paymentMethod, setPaymentMethod] = useState(
    ticket?.paymentMethod || "onlineAndInPerson"
  );

  const createActivityTicketMutation = useCreateActivityTicketMutation(eventId, handleClose);
  const deleteTicketPriceMutation = useDeleteTicketPriceMutation(eventId, handleClose);
  const updateTicketPriceMutation = useUpdateTicketPriceMutation(eventId, handleClose);

  const handleUpdateTicketPriceClick = () => {
    const isMajorChange = 
      ticket?.currency !== currency || 
      ticket?.amount !== getAmountToTicketValue(ticketPrice, currency) ||
      (ticket?.paymentMethod !== paymentMethod && 
        (paymentMethod === "online" || paymentMethod === "inPerson"));

    if (ticket && isMajorChange) {
      // For major changes, delete the existing ticket first then create a new one
      deleteTicketPriceMutation.mutate(
        {
          clientAxiosInstance: webAxiosInstance,
          ticketPriceId: ticket.id,
        },
        {
          onSuccess: () => {
            // After successful deletion, create a new ticket
            createActivityTicketMutation.mutate({
              clientAxiosInstance: webAxiosInstance,
              activityId: activity.id,
              data: {
                amount: getAmountToTicketValue(ticketPrice, currency),
                currency,
                paymentMethod,
                role: "player",
                startsAt: dayjs().toISOString(),
              },
            });
          },
        }
      );
    } else if (ticket) {
      // For minor changes, just update the existing ticket
      updateTicketPriceMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        ticketPriceId: ticket.id,
        data: {
          amount: getAmountToTicketValue(ticketPrice, currency),
          currency,
          paymentMethod,
          role: "player",
          startsAt: dayjs().toISOString(),
        },
      });
    } else {
      // If no ticket exists yet, create a new one
      createActivityTicketMutation.mutate({
        clientAxiosInstance: webAxiosInstance,
        activityId: activity.id,
        data: {
          amount: getAmountToTicketValue(ticketPrice, currency),
          currency,
          paymentMethod,
          role: "player",
          startsAt: dayjs().toISOString(),
        },
      });
    }
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Manage Ticket Information</ModalTitle>
      </ModalHeader>

      <div className="w-48 mx-auto">
        <CurrencyInput
          placeholder="Entry Fee"
          onChange={(e) => setTicketPrice(e.target.value)}
          onChangeCurrency={setCurrency}
          selectedCurrency={currency}
          value={ticketPrice}
        />
      </div>

      <div className="w-48 mx-auto mt-4">
        <Label className="mb-2 block">Payment Method</Label>
        <Select
          value={paymentMethod}
          onValueChange={setPaymentMethod}
        >
          <SelectTrigger className="input-background border-border">
            <SelectValue placeholder="Select payment method" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="onlineAndInPerson">
              In-person and online
            </SelectItem>
            <SelectItem value="inPerson">
              In-person only
            </SelectItem>
            <SelectItem value="online">
              Online only
            </SelectItem>
          </SelectContent>
        </Select>
      </div>

      <ModalFooter className="items-end">
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: "0.75rem 2rem", fontSize: "0.75rem" }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            className="h-fit"
            disabled={
              ticket?.currency === currency &&
              ticket?.ticketPrice === ticketPrice &&
              ticket?.paymentMethod === paymentMethod
            }
            dataTooltipTarget="tooltip-default"
            onClick={handleUpdateTicketPriceClick}
            variant="gradient-contained"
            style={{ padding: "0.75rem 2rem", fontSize: "0.875rem" }}
          >
            Update Ticket
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  );
};

export default ManageTicketModal;
