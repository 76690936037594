import React from "react";
import { Form } from "@repo/ui/form";
import FormCard from "@cardeio/ui/components/forms/form-card";
import { format, parseISO, isSameDay, subMinutes } from "date-fns";
import { Button } from "@repo/ui/button";
import { Pencil } from "lucide-react";
import { convertCentsToAmount } from "packages/utils/src/currency";

// EventAttribute component for displaying key-value pairs
const EventAttribute = ({ label, value, className = "" }) => {
  if (value === undefined || value === null || value === "") return null;

  // Ensure value is a string to prevent rendering objects directly
  const displayValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

  return (
    <div className={`mb-2.5 ${className}`}>
      <div className="text-xs text-muted-foreground uppercase">{label}</div>
      <div className="text-base font-medium">{displayValue}</div>
    </div>
  );
};

// Custom FormCard with border under title
const FormCardWithBorder = ({ title, children, className = "" }) => {
  return (
    <FormCard title={title} className={className}>
      <div className="border-t border-border pt-4">
        {children}
      </div>
    </FormCard>
  );
};

const ReviewDraftActivity = ({ activeStep, form, onEditSection }) => {
  const stepNumber = 4;
  const isActive = activeStep === stepNumber;

  const watchAllFields = form.watch();

  // Format date and time
  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      // Handle both string dates and Date/dayjs objects
      if (typeof dateString === 'string') {
        return format(parseISO(dateString), "EEEE, MMMM d, yyyy");
      } else {
        // Assume it's a Date or dayjs object
        return format(dateString, "EEEE, MMMM d, yyyy");
      }
    } catch (e) {
      console.error("Error formatting date:", e);
      return String(dateString);
    }
  };

  const formatTime = (dateString) => {
    if (!dateString) return "";
    try {
      // Handle both string dates and Date/dayjs objects
      if (typeof dateString === 'string') {
        return format(parseISO(dateString), "h:mma");
      } else {
        // Assume it's a Date or dayjs object
        return format(dateString, "h:mma");
      }
    } catch (e) {
      console.error("Error formatting time:", e);
      return String(dateString);
    }
  };

  // Get start and end times
  const startTime = formatTime(watchAllFields.startsAt);
  const endTime = formatTime(watchAllFields.endsAt);
  const timeRange = startTime && endTime ? `${startTime} - ${endTime} ${getTimezone()}` : "";

  // Get timezone
  function getTimezone() {
    if (!watchAllFields.timezone) return "PST";
    const timezoneAbbreviations = {
      "America/Los_Angeles": "PST",
      "America/Denver": "MST",
      "America/Chicago": "CST",
      "America/New_York": "EST",
      // Add more as needed
    };
    return timezoneAbbreviations[watchAllFields.timezone] || watchAllFields.timezone;
  }

  // Get tournament format if available
  const tournaments = watchAllFields.configuration?.tournaments || [];
  const tournamentFormat = tournaments.length > 0 ? tournaments[0].tournamentFormat : "";
  const formatDisplay = tournamentFormat ? tournamentFormat.charAt(0).toUpperCase() + tournamentFormat.slice(1) : "";

  // Get capacity
  const capacity = watchAllFields.capacity ? String(watchAllFields.capacity) : "";

  // Get entry fee
  let ticketPrice = watchAllFields.ticketPrice || watchAllFields.configuration?.defaultTicketPrice || 0;
  if (ticketPrice === watchAllFields.configuration?.defaultTicketPrice) {
    ticketPrice = convertCentsToAmount(ticketPrice, watchAllFields.currency);
  }
  const formattedPrice = ticketPrice ? `$${Number(ticketPrice).toFixed(2)}` : "Free";

  // Get location info
  const inPersonEvent = watchAllFields.inPersonEvent;
  const address = watchAllFields.address || {};
  const locationDisplay = !inPersonEvent ? "Online" :
    address.address1 ? `${address.name || address.address1}, ${address.city}, ${address.state} ${address.zip}` : "";

  // Get check-in info
  const allowSelfCheckin = watchAllFields.configuration?.allowSelfCheckin === "allow" ? "Yes" : "No";
  const entryTimeMinutes = watchAllFields.configuration?.entryTime || 0;

  // Simplified check-in time display
  let checkInTime = "";
  if (watchAllFields.startsAt && entryTimeMinutes > 0) {
    try {
      // Handle both string dates and Date/dayjs objects
      const startDate = typeof watchAllFields.startsAt === 'string'
        ? parseISO(watchAllFields.startsAt)
        : watchAllFields.startsAt;

      const checkInDate = subMinutes(startDate, entryTimeMinutes);
      const checkInTimeStr = format(checkInDate, "h:mma");
      const timezone = getTimezone();

      // Format the entry time in hours/minutes
      const timeDisplay = entryTimeMinutes >= 60
        ? `${Math.floor(entryTimeMinutes / 60)} hour${Math.floor(entryTimeMinutes / 60) > 1 ? 's' : ''}`
        : `${entryTimeMinutes} minutes`;

      // Check if check-in is on a different day
      if (!isSameDay(startDate, checkInDate)) {
        checkInTime = `${format(checkInDate, "EEEE, MMMM d")} at ${checkInTimeStr} ${timezone} (${timeDisplay} before event)`;
      } else {
        checkInTime = `${checkInTimeStr} ${timezone} (${timeDisplay} before event)`;
      }
    } catch (e) {
      console.error("Error formatting check-in time:", e);
      checkInTime = "";
    }
  }

  // Get tournament details
  const getBestOfGames = () => {
    if (!tournaments.length || !tournaments[0].bestOf) return 1;
    return tournaments[0].bestOf.games || 1;
  };

  const getRoundDuration = () => {
    if (!tournaments.length || !tournaments[0].bestOf) return 0;
    return tournaments[0].bestOf.duration || 0;
  };

  const getRounds = () => {
    if (!tournaments.length) return 0;
    return tournaments[0].rounds || 3;
  };

  // Handle edit button clicks
  const handleEditClick = (sectionIndex) => {
    if (onEditSection) {
      onEditSection(sectionIndex);
    }
  };

  // Format prize money
  const formatPrizeMoney = (amount) => {
    if (!amount) return "";
    return `$${amount}`;
  };

  // Format rounds and duration
  const formatRoundsAndDuration = () => {
    const rounds = getRounds();
    const games = getBestOfGames();
    const duration = getRoundDuration();
    return `${rounds} Rounds, Best of ${games} Games, ${duration}min each round`;
  };

  // Check if there are player rewards
  const hasPlayerRewards = () => {
    return Array.isArray(watchAllFields.configuration?.playerRewards) &&
      watchAllFields.configuration.playerRewards.length > 0;
  };

  // Get recurring event info
  const isRecurringEvent = watchAllFields.isRecurring || watchAllFields.recurring;
  let recurringInfo = "";
  if (isRecurringEvent) {
    const recurringType = watchAllFields.recurringType || watchAllFields.recurringInterval || "weekly";
    const recurringEndDate = watchAllFields.recurringEndDate
      ? (typeof watchAllFields.recurringEndDate === 'string'
        ? format(parseISO(watchAllFields.recurringEndDate), "MMMM d, yyyy")
        : format(watchAllFields.recurringEndDate, "MMMM d, yyyy"))
      : "";
    const recurringAmount = watchAllFields.recurringAmount;

    if (recurringEndDate) {
      recurringInfo = `${recurringType.charAt(0).toUpperCase() + recurringType.slice(1)} until ${recurringEndDate}`;
    } else if (recurringAmount) {
      recurringInfo = `${recurringType.charAt(0).toUpperCase() + recurringType.slice(1)}, ${recurringAmount} occurrences`;
    } else {
      recurringInfo = `${recurringType.charAt(0).toUpperCase() + recurringType.slice(1)}`;
    }
  }

  return (
    <div className={`${isActive ? 'block animate-slideInFromBottom' : 'hidden'} space-y-6`}>
      <Form {...form}>
        <div className="relative">
          <FormCardWithBorder title="Event Details" className="mb-4 border border-border">
            <div className="absolute top-4 right-4">
              <Button
                variant="ghost"
                size="sm"
                className="flex items-center gap-1 text-muted-foreground hover:text-foreground"
                onClick={() => handleEditClick(0)}
              >
                <Pencil size={16} />
                EDIT
              </Button>
            </div>

            <EventAttribute label="EVENT NAME" value={watchAllFields.name} />

            <EventAttribute label="DATE" value={formatDate(watchAllFields.startsAt)} />

            <EventAttribute label="EVENT START & END TIME" value={timeRange} />

            {isRecurringEvent && (
              <EventAttribute label="RECURRING" value={recurringInfo} />
            )}

            <EventAttribute label="LOCATION" value={locationDisplay} />

            <div className="flex gap-8">
              <EventAttribute label="ENTRY FEE" value={formattedPrice} className="flex-1" />
              <EventAttribute label="PAYMENT WALLET" value="Carde.io Wallet" className="flex-1" />
            </div>

            <EventAttribute label="CAPACITY" value={capacity} />

            <div className="flex gap-8">
              <EventAttribute label="CHECK-INS REQUIRED" value="Yes" className="flex-1" />
              <EventAttribute label="CHECK-IN TIME" value={checkInTime} className="flex-1" />
              <EventAttribute label="ALLOW PLAYERS TO SELF-CHECK IN?" value={allowSelfCheckin} className="flex-1" />
            </div>
          </FormCardWithBorder>
        </div>

        {tournaments.length > 0 && (
          <div className="relative">
            <FormCardWithBorder title="Activity Configuration" className="mb-4 border border-border">
              <div className="absolute top-4 right-4">
                <Button
                  variant="ghost"
                  size="sm"
                  className="flex items-center gap-1 text-muted-foreground hover:text-foreground"
                  onClick={() => handleEditClick(1)}
                >
                  <Pencil size={16} />
                  EDIT
                </Button>
              </div>

              <div className="flex gap-8">
                <EventAttribute label="FORMAT" value="Tournament" className="flex-1" />
                <EventAttribute label="PLAY PROGRAM" value="Freeform" className="flex-1" />
                <EventAttribute label="TOURNAMENT TYPE" value="Constructed" className="flex-1" />
              </div>

              <EventAttribute label="PAIRING METHOD" value={formatDisplay || "Swiss"} />

              <EventAttribute
                label="ROUNDS & DURATION"
                value={formatRoundsAndDuration()}
              />

              <EventAttribute label="PLAYOFFS" value="Top 8" />

              {(watchAllFields.prizeSupport || watchAllFields.configuration?.prizing) && (
                <>
                  <EventAttribute label="PRIZES" value=" " />
                  <div className="ml-4">
                    {/* Product prizes */}
                    {(watchAllFields.prizeSupport?.first?.product ||
                      watchAllFields.prizeSupport?.second?.product ||
                      watchAllFields.prizeSupport?.third?.product ||
                      watchAllFields.prizeSupport?.top4?.product ||
                      watchAllFields.prizeSupport?.top8?.product ||
                      watchAllFields.prizeSupport?.top16?.product ||
                      watchAllFields.prizeSupport?.top32?.product ||
                      watchAllFields.prizeSupport?.top64?.product ||
                      watchAllFields.prizeSupport?.top128?.product ||
                      watchAllFields.prizeSupport?.top256?.product ||
                      watchAllFields.prizeSupport?.top512?.product ||
                      watchAllFields.prizeSupport?.top1024?.product ||
                      watchAllFields.prizeSupport?.perWin?.product ||
                      watchAllFields.prizeSupport?.participation?.product ||
                      watchAllFields.configuration?.prizing?.first?.product ||
                      watchAllFields.configuration?.prizing?.second?.product ||
                      watchAllFields.configuration?.prizing?.third?.product ||
                      watchAllFields.configuration?.prizing?.top4?.product ||
                      watchAllFields.configuration?.prizing?.top8?.product ||
                      watchAllFields.configuration?.prizing?.top16?.product ||
                      watchAllFields.configuration?.prizing?.top32?.product ||
                      watchAllFields.configuration?.prizing?.top64?.product ||
                      watchAllFields.configuration?.prizing?.top128?.product ||
                      watchAllFields.configuration?.prizing?.top256?.product ||
                      watchAllFields.configuration?.prizing?.top512?.product ||
                      watchAllFields.configuration?.prizing?.top1024?.product ||
                      watchAllFields.configuration?.prizing?.perWin?.product ||
                      watchAllFields.configuration?.prizing?.participation?.product) && (
                        <EventAttribute label="Product:" value="" />
                      )}

                    {(watchAllFields.prizeSupport?.first?.product || watchAllFields.configuration?.prizing?.first?.product) && (
                      <EventAttribute label="1st" value={watchAllFields.prizeSupport?.first?.product || watchAllFields.configuration?.prizing?.first?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.second?.product || watchAllFields.configuration?.prizing?.second?.product) && (
                      <EventAttribute label="2nd" value={watchAllFields.prizeSupport?.second?.product || watchAllFields.configuration?.prizing?.second?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.third?.product || watchAllFields.configuration?.prizing?.third?.product) && (
                      <EventAttribute label="3rd-4th" value={watchAllFields.prizeSupport?.third?.product || watchAllFields.configuration?.prizing?.third?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top4?.product || watchAllFields.configuration?.prizing?.top4?.product) && (
                      <EventAttribute label="3rd-4th" value={watchAllFields.prizeSupport?.top4?.product || watchAllFields.configuration?.prizing?.top4?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top8?.product || watchAllFields.configuration?.prizing?.top8?.product) && (
                      <EventAttribute label="5th-8th" value={watchAllFields.prizeSupport?.top8?.product || watchAllFields.configuration?.prizing?.top8?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top16?.product || watchAllFields.configuration?.prizing?.top16?.product) && (
                      <EventAttribute label="9th-16th" value={watchAllFields.prizeSupport?.top16?.product || watchAllFields.configuration?.prizing?.top16?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top32?.product || watchAllFields.configuration?.prizing?.top32?.product) && (
                      <EventAttribute label="17th-32nd" value={watchAllFields.prizeSupport?.top32?.product || watchAllFields.configuration?.prizing?.top32?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top64?.product || watchAllFields.configuration?.prizing?.top64?.product) && (
                      <EventAttribute label="33rd-64th" value={watchAllFields.prizeSupport?.top64?.product || watchAllFields.configuration?.prizing?.top64?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top128?.product || watchAllFields.configuration?.prizing?.top128?.product) && (
                      <EventAttribute label="65th-128th" value={watchAllFields.prizeSupport?.top128?.product || watchAllFields.configuration?.prizing?.top128?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.perWin?.product || watchAllFields.configuration?.prizing?.perWin?.product) && (
                      <EventAttribute label="Per Win" value={watchAllFields.prizeSupport?.perWin?.product || watchAllFields.configuration?.prizing?.perWin?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top256?.product || watchAllFields.configuration?.prizing?.top256?.product) && (
                      <EventAttribute label="257th-512th" value={watchAllFields.prizeSupport?.top256?.product || watchAllFields.configuration?.prizing?.top256?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top512?.product || watchAllFields.configuration?.prizing?.top512?.product) && (
                      <EventAttribute label="513th-1024th" value={watchAllFields.prizeSupport?.top512?.product || watchAllFields.configuration?.prizing?.top512?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.top1024?.product || watchAllFields.configuration?.prizing?.top1024?.product) && (
                      <EventAttribute label="1025th+" value={watchAllFields.prizeSupport?.top1024?.product || watchAllFields.configuration?.prizing?.top1024?.product} />
                    )}
                    {(watchAllFields.prizeSupport?.participation?.product || watchAllFields.configuration?.prizing?.participation?.product) && (
                      <EventAttribute label="Participation" value={watchAllFields.prizeSupport?.participation?.product || watchAllFields.configuration?.prizing?.participation?.product} />
                    )}

                    {/* Cash prizes */}
                    {(watchAllFields.prizeSupport?.first?.cash ||
                      watchAllFields.prizeSupport?.second?.cash ||
                      watchAllFields.prizeSupport?.third?.cash ||
                      watchAllFields.prizeSupport?.top4?.cash ||
                      watchAllFields.prizeSupport?.top8?.cash ||
                      watchAllFields.prizeSupport?.top16?.cash ||
                      watchAllFields.prizeSupport?.top32?.cash ||
                      watchAllFields.prizeSupport?.top64?.cash ||
                      watchAllFields.prizeSupport?.top128?.cash ||
                      watchAllFields.prizeSupport?.top256?.cash ||
                      watchAllFields.prizeSupport?.top512?.cash ||
                      watchAllFields.prizeSupport?.top1024?.cash ||
                      watchAllFields.prizeSupport?.perWin?.cash ||
                      watchAllFields.prizeSupport?.participation?.cash ||
                      watchAllFields.configuration?.prizing?.first?.cash ||
                      watchAllFields.configuration?.prizing?.second?.cash ||
                      watchAllFields.configuration?.prizing?.third?.cash ||
                      watchAllFields.configuration?.prizing?.top4?.cash ||
                      watchAllFields.configuration?.prizing?.top8?.cash ||
                      watchAllFields.configuration?.prizing?.top16?.cash ||
                      watchAllFields.configuration?.prizing?.top32?.cash ||
                      watchAllFields.configuration?.prizing?.top64?.cash ||
                      watchAllFields.configuration?.prizing?.top128?.cash ||
                      watchAllFields.configuration?.prizing?.top256?.cash ||
                      watchAllFields.configuration?.prizing?.top512?.cash ||
                      watchAllFields.configuration?.prizing?.top1024?.cash ||
                      watchAllFields.configuration?.prizing?.perWin?.cash ||
                      watchAllFields.configuration?.prizing?.participation?.cash) && (
                        <EventAttribute label="Cash:" value="" />
                      )}

                    {(watchAllFields.prizeSupport?.first?.cash > 0 || watchAllFields.configuration?.prizing?.first?.cash > 0) && (
                      <EventAttribute label="1st" value={formatPrizeMoney(watchAllFields.prizeSupport?.first?.cash || watchAllFields.configuration?.prizing?.first?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.second?.cash > 0 || watchAllFields.configuration?.prizing?.second?.cash > 0) && (
                      <EventAttribute label="2nd" value={formatPrizeMoney(watchAllFields.prizeSupport?.second?.cash || watchAllFields.configuration?.prizing?.second?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.third?.cash > 0 || watchAllFields.configuration?.prizing?.third?.cash > 0) && (
                      <EventAttribute label="3rd-4th" value={formatPrizeMoney(watchAllFields.prizeSupport?.third?.cash || watchAllFields.configuration?.prizing?.third?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top4?.cash > 0 || watchAllFields.configuration?.prizing?.top4?.cash > 0) && (
                      <EventAttribute label="3rd-4th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top4?.cash || watchAllFields.configuration?.prizing?.top4?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top8?.cash > 0 || watchAllFields.configuration?.prizing?.top8?.cash > 0) && (
                      <EventAttribute label="5th-8th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top8?.cash || watchAllFields.configuration?.prizing?.top8?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top16?.cash > 0 || watchAllFields.configuration?.prizing?.top16?.cash > 0) && (
                      <EventAttribute label="9th-16th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top16?.cash || watchAllFields.configuration?.prizing?.top16?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top32?.cash > 0 || watchAllFields.configuration?.prizing?.top32?.cash > 0) && (
                      <EventAttribute label="17th-32nd" value={formatPrizeMoney(watchAllFields.prizeSupport?.top32?.cash || watchAllFields.configuration?.prizing?.top32?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top64?.cash > 0 || watchAllFields.configuration?.prizing?.top64?.cash > 0) && (
                      <EventAttribute label="33rd-64th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top64?.cash || watchAllFields.configuration?.prizing?.top64?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top128?.cash > 0 || watchAllFields.configuration?.prizing?.top128?.cash > 0) && (
                      <EventAttribute label="65th-128th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top128?.cash || watchAllFields.configuration?.prizing?.top128?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top256?.cash > 0 || watchAllFields.configuration?.prizing?.top256?.cash > 0) && (
                      <EventAttribute label="257th-512th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top256?.cash || watchAllFields.configuration?.prizing?.top256?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top512?.cash > 0 || watchAllFields.configuration?.prizing?.top512?.cash > 0) && (
                      <EventAttribute label="513th-1024th" value={formatPrizeMoney(watchAllFields.prizeSupport?.top512?.cash || watchAllFields.configuration?.prizing?.top512?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.top1024?.cash > 0 || watchAllFields.configuration?.prizing?.top1024?.cash > 0) && (
                      <EventAttribute label="1025th+" value={formatPrizeMoney(watchAllFields.prizeSupport?.top1024?.cash || watchAllFields.configuration?.prizing?.top1024?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.perWin?.cash > 0 || watchAllFields.configuration?.prizing?.perWin?.cash > 0) && (
                      <EventAttribute label="Per Win" value={formatPrizeMoney(watchAllFields.prizeSupport?.perWin?.cash || watchAllFields.configuration?.prizing?.perWin?.cash)} />
                    )}
                    {(watchAllFields.prizeSupport?.participation?.cash > 0 || watchAllFields.configuration?.prizing?.participation?.cash > 0) && (
                      <EventAttribute label="Participation" value={formatPrizeMoney(watchAllFields.prizeSupport?.participation?.cash || watchAllFields.configuration?.prizing?.participation?.cash)} />
                    )}
                  </div>

                  {hasPlayerRewards() && (
                    <>
                      <EventAttribute label="Points" value="" />
                      <div className="ml-4">
                        {/* First show placement rewards in order */}
                        {watchAllFields.configuration.playerRewards
                          .filter(reward => reward.type === 'placement')
                          .sort((a, b) => a.standing - b.standing)
                          .map((reward, index) => (
                            <EventAttribute
                              key={`placement-${index}`}
                              label={`${reward.standing}${getOrdinalSuffix(reward.standing)} Place:`}
                              value={`${reward.amount} Points`}
                            />
                          ))
                        }

                        {/* Then show flat/participation rewards */}
                        {watchAllFields.configuration.playerRewards
                          .filter(reward => reward.type === 'flat')
                          .map((reward, index) => (
                            <EventAttribute
                              key={`flat-${index}`}
                              label="Participation:"
                              value={`${reward.amount} Points`}
                            />
                          ))
                        }
                      </div>
                    </>
                  )}
                </>
              )}
            </FormCardWithBorder>
          </div>
        )}
      </Form>
    </div>
  );
};

// Helper function to get ordinal suffix for numbers
function getOrdinalSuffix(num) {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

export default ReviewDraftActivity;
