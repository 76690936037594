import { Skeleton } from "@cardeio/ui/components/ui/skeleton"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarProvider,
  SidebarInset,
  SidebarTrigger,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuSkeleton,
} from "@cardeio/ui/components/ui/sidebar"

function TopNavSkeleton() {
  return (
    <div className="flex items-center gap-2 pr-4 py-4">
      <Skeleton className="h-6 w-6 bg-white/10" />
      <Skeleton className="h-6 w-40 bg-white/10" />
    </div>
  )
}

export function SidebarSkeleton() {
  return (
    <SidebarProvider>
      <Sidebar collapsible="icon" className="h-screen">
        <SidebarHeader className="h-16">
          <TopNavSkeleton />
        </SidebarHeader>

        <SidebarContent className="flex flex-col">
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {Array.from({ length: 3 }).map((_, index) => (
                  <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton showIcon className="bg-white/10 [&>*]:bg-white/10" />
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarGroup>
            <SidebarGroupLabel>
              <Skeleton className="h-4 w-24 my-2 bg-white/10" />
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {Array.from({ length: 2 }).map((_, index) => (
                  <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton showIcon className="bg-white/10 [&>*]:bg-white/10" />
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarGroup className="mt-auto">
            <SidebarGroupContent>
              <SidebarMenu>
                {Array.from({ length: 3 }).map((_, index) => (
                  <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton showIcon className="bg-white/10 [&>*]:bg-white/10" />
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter className="p-2">
          <Skeleton className="h-14 w-full rounded-lg bg-white/10" />
        </SidebarFooter>
      </Sidebar>
      <SidebarInset className="h-screen flex flex-col">
        <header className="flex z-10 h-16 shrink-0 items-center gap-2 bg-background border-b border=[#2C2C2C]">
          <div className="flex items-center gap-2 px-4">
            <Skeleton className="h-7 w-7 -ml-1 bg-white/10" />
            <Skeleton className="h-4 w-px mx-2 bg-white/10" />
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-24 bg-white/10" />
              <Skeleton className="h-4 w-4 bg-white/10" />
              <Skeleton className="h-4 w-32 bg-white/10" />
            </div>
          </div>
        </header>
        <div className="flex-1 min-h-0">
          <main className="h-full overflow-y-auto p-4">
            <div className="space-y-4">
              <Skeleton className="h-8 w-full bg-white/10" />
              <Skeleton className="h-32 w-full bg-white/10" />
              <Skeleton className="h-32 w-full bg-white/10" />
            </div>
          </main>
        </div>
      </SidebarInset>
    </SidebarProvider>
  )
}
