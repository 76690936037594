import { useState } from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import {
  RiAlertLine,
  RiCornerUpRightLine,
  RiSettings3Fill,
} from "@remixicon/react";

import { useEventStore } from "@/store/useEventStore";
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import SkipToNextPhaseModal from "@/components/Modal/Event/Organize/SkipToNextPhaseModal";
import RevertToPreviousRoundModal from "@/components/Modal/Event/Organize/RevertToPreviousRoundModal";
import {
  reportAllPairingsMutationFn,
  finalizePreviewMutationFn,
  activitiesFinalizeAllPreviewsMutationFn,
} from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { useRootStore } from "@/store/useRootStore";
import { queryClient } from "@/lib/queryClient";
import { AdvanceRoundModalContainer } from "./AdvanceRoundModalContainer";

const OrganizeActions = ({ refetchPairings }) => {
  const {
    allPhases,
    currentTournament,
    currentTournamentRound,
    activePhases,
    activity,
    activePhaseName,
    nextPhase,
    activeStage,
  } = useEventStore();
  const hasAdminTools =
    window?.location.origin.includes("dev.carde.io") ||
    window?.location.origin.includes("localhost");

  const [isAdvanceRoundModalOpen, setIsAdvanceRoundModalOpen] = useState(false);
  const [isRevertPreviousRoundModalOpen, setIsRevertPreviousRoundModalOpen] =
    useState(false);
  const [isSkipToNextPhaseModalOpen, setIsSkipToNextPhaseModalOpen] =
    useState(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isPreview = activePhases.some((phase) => phase.status === "preview");
  const highestStageNumberForPod = allPhases?.reduce(
    (max, phase) => Math.max(max, phase?.stage),
    0
  );
  const showRevertToPreviousRound = currentTournament?.currentRound > 1;
  const showRecommendedFinalizeActivity =
    highestStageNumberForPod === activeStage &&
    currentTournament?.currentRound === currentTournament?.totalRounds &&
    !isPreview;
  const showRecommendedAdvanceRound =
    !showRecommendedFinalizeActivity &&
    currentTournament?.currentRound < currentTournament?.totalRounds + 1 &&
    !isPreview;
  const showSkipToNextPhaseOption = allPhases?.length > activeStage;
  const showAdvanceRoundOption =
    showRecommendedFinalizeActivity &&
    currentTournament?.currentRound < currentTournament?.totalRounds + 1 &&
    !isPreview;

  const reportAllResultsMutation = useMutation({
    mutationFn: reportAllPairingsMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activity.id, "activity", "pairings"],
      });

      toast("Successfully Reported All Results!");
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const finalizePreviewMutation = useMutation({
    mutationFn: finalizePreviewMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activity.id, "activity", "pairings"],
      });

      toast("Successfully Approved Preview!");
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description:
          "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => (window.location.href = "https://carde.io/support"),
        },
      });
    },
  });

  const activitiesFinalizeAllPreviewsMutation = useMutation({
    mutationFn: activitiesFinalizeAllPreviewsMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activity.id, "activity", "pairings"],
      });

      toast("Successfully Approved Preview!");
    },
  });

  const handleFinalizeAllPreviewsClick = () => {
    activitiesFinalizeAllPreviewsMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityId: activity?.id,
    });
  };

  const handleReportAllResultsClick = () => {
    reportAllResultsMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentRoundId: currentTournamentRound?.id,
    });
  };

  const approvePreviewClick = () => {
    finalizePreviewMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      activityPhaseId: activePhases[0]?.id,
    });
  };

  const ApprovePreviewContainer = () => {
    return (
      <>
        {activePhases?.length == 1 && (
          <Button
            onClick={approvePreviewClick}
            loading={finalizePreviewMutation.isPending}
            variant={isPreview ? "gradient-contained" : "gradient-outlined"}
            rounded
            size="sm"
            style={{
              padding: isPreview ? null : "0.5rem 1rem",
              fontSize: isPreview ? null : "0.875rem",
            }}
            className="text-sm text-white rounded-full"
          >
            <span>Approve and Publish Pairings</span>
          </Button>
        )}
        {activePhases?.length > 1 && (
          <Button
            onClick={handleFinalizeAllPreviewsClick}
            loading={activitiesFinalizeAllPreviewsMutation.isPending}
            variant={isPreview ? "gradient-contained" : "gradient-outlined"}
            rounded
            size="sm"
            style={{
              padding: isPreview ? null : "0.5rem 1rem",
              fontSize: isPreview ? null : "0.875rem",
            }}
            className="text-sm text-white rounded-full"
          >
            <span>Finalize All Pairings</span>
          </Button>
        )}
      </>
    );
  };

  const SkipToNextPhaseModalContainer = ({ buttonVariant = "text" }) => {
    return (
      <div className="flex-shrink-0">
        <Modal
          open={isSkipToNextPhaseModalOpen}
          onOpenChange={setIsSkipToNextPhaseModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              className="flex items-center"
              onClick={() => setIsSkipToNextPhaseModalOpen(true)}
              size="sm"
              variant={buttonVariant}
            >
              <span>Skip to Next Phase</span>
              <RiCornerUpRightLine className="h-4 w-4 ml-1" />
            </Button>
          </ModalTrigger>
          <SkipToNextPhaseModal
            handleClose={() => setIsSkipToNextPhaseModalOpen(false)}
          />
        </Modal>
      </div>
    );
  };

  const PreviousRoundModalContainer = ({ buttonVariant = "text" }) => {
    return (
      <div className="flex-shrink-0">
        <Modal
          open={isRevertPreviousRoundModalOpen}
          onOpenChange={setIsRevertPreviousRoundModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              className="flex items-center"
              onClick={() => setIsRevertPreviousRoundModalOpen(true)}
              size="sm"
              variant={buttonVariant}
            >
              <RiAlertLine className="h-4 w-4 mr-1" />
              <span>Revert to Previous Round</span>
            </Button>
          </ModalTrigger>
          <RevertToPreviousRoundModal
            handleClose={() => setIsRevertPreviousRoundModalOpen(false)}
          />
        </Modal>
      </div>
    );
  };

  const { userData } = useRootStore();
  const AdditionalActionsPopover = () => {
    return (
      <div className="z-10">
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <button className="rounded-lg pointer p-2 hover:bg-zinc-200/10">
              <RiSettings3Fill className="h-5 w-5 flex-none text-zinc-200" />
            </button>
          </PopoverTrigger>
          <PopoverContent
            className="bg-zinc-950 border-zinc-200/10 w-fit z-10"
            align="end"
          >
            <div className="flex flex-col items-center gap-4">
              {showRevertToPreviousRound && <PreviousRoundModalContainer />}
              {showSkipToNextPhaseOption && <SkipToNextPhaseModalContainer />}
              {/* TODO: Bring it back after Create new round is fixed */}
              {false && showAdvanceRoundOption && (
                <AdvanceRoundModalContainer
                  isAdvanceRoundModalOpen={isAdvanceRoundModalOpen}
                  setIsAdvanceRoundModalOpen={setIsAdvanceRoundModalOpen}
                  buttonVariant="text"
                />
              )}
              {hasAdminTools && (
                <Button
                  className="bg-red-700 flex items-center"
                  onClick={handleReportAllResultsClick}
                  size="sm"
                  variant="text"
                >
                  <RiAlertLine className="h-4 w-4 mr-1" />
                  <span>Report All Results</span>
                </Button>
              )}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  };

  return (
    <div className="flex justify-end gap-4">
      <div className="flex items-center justify-center gap-2">
        <AdditionalActionsPopover />
        {showRecommendedAdvanceRound && (
          <AdvanceRoundModalContainer
            isAdvanceRoundModalOpen={isAdvanceRoundModalOpen}
            setIsAdvanceRoundModalOpen={setIsAdvanceRoundModalOpen}
            showRecommendedAdvanceRound={showRecommendedAdvanceRound}
          />
        )}
        {isPreview && <ApprovePreviewContainer />}
      </div>
    </div>
  );
};

export default OrganizeActions;
